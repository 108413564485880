import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Box from "../../../components/box";
import CustomBadge from "../../../components/custom-badge";
import DatafieldSection, {
  DatafieldLabel,
} from "../../../components/datafield-section";
import { CkycDataDto } from "../../../dto/ckyc.dto";
import { ReduxState } from "../../../reducers";
import { dateToDDMMYYYY } from "../../../utils";
import { ReactComponent as PadLockIcon } from "../../../images/pad-lock.svg";
import { ReactComponent as PadLockOpenIcon } from "../../../images/pad-lock-open.svg";
import CustomAlert from "../../../components/custom-alert";
import Button from "../../../components/button";

const ckycDatafieldLabel: DatafieldLabel[] = [
  {
    items: [
      {
        key: "ckycId",
        label: "CKYC ID :",
      },
      {
        key: "fullName",
        label: "Name :",
      },
      {
        key: "parentOrSpouseName",
        label: "Parent/Spouse Name :",
      },
      {
        key: "gender",
        label: "Gender :",
      },
      {
        key: "dob",
        label: "Date of Birth :",
        formatMethod: dateToDDMMYYYY,
      },
      {
        key: "pan",
        label: "PAN :",
      },
    ],
  },
  {
    items: [
      {
        key: "permanentAddress",
        label: "Address :",
        formatMethod: (address) => {
          const { houseInfo, localityInfo } = address || {};
          return [houseInfo, localityInfo].filter(Boolean).join(", ");
        },
      },
      {
        key: "permanentAddress.city",
        label: "City :",
      },
      {
        key: "permanentAddress.state",
        label: "State :",
      },
      {
        key: "permanentAddress.pinCode",
        label: "PIN Code :",
      },
      {
        key: "email",
        label: "Email :",
      },
      {
        key: "mobile",
        label: "Mobile Number :",
      },
    ],
  },
];

function renderAlert(variant: "success" | "warning") {
  return (
    <CustomAlert
      icon={variant === "success" ? <PadLockOpenIcon /> : <PadLockIcon />}
      variant={variant}
    >
      {variant === "success"
        ? "You can fetch CKYC details now."
        : "Verify PAN to view the CKYC Details."}
    </CustomAlert>
  );
}

const Header = (
  <div className="d-flex align-items-center justify-content-start">
    <h3 className="user-page-title mr-3">CKYC Details</h3>
    <CustomBadge>New</CustomBadge>
  </div>
);

interface CkycProps {
  isPanDataFetched?: boolean;
  autoFetchOnMount?: boolean;
  onFetchClick?: () => Promise<any>;
}

export default function Ckyc({
  isPanDataFetched = false,
  autoFetchOnMount = false,
  onFetchClick,
}: CkycProps) {
  const ckycDetails: CkycDataDto | null = useSelector(
    (state: ReduxState) => state.user.ckycDetails
  );

  const [isCkecked, setIsCkecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (ckycDetails) return;
    if (isPanDataFetched && autoFetchOnMount) handleFetch();
  }, [isPanDataFetched, autoFetchOnMount]);

  async function handleFetch() {
    try {
      setIsLoading(true);
      await onFetchClick?.();
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(error?.message);
    } finally {
      setIsLoading(false);
    }
  }

  if (ckycDetails !== null && ckycDetails !== undefined) {
    return (
      <DatafieldSection
        title={Header}
        labels={ckycDatafieldLabel}
        data={ckycDetails}
        withinBox
        footer={
          <span className="small">
            CKYC documents are accessible from the documents section
          </span>
        }
      />
    );
  }

  return (
    <Box header={Header}>
      <div>
        {errorMessage ? (
          <CustomAlert variant="danger">{errorMessage}</CustomAlert>
        ) : ckycDetails === null ? (
          <CustomAlert variant="warning">
            User entry not available in CKYC registry. Please perform in person
            KYC
          </CustomAlert>
        ) : (
          renderAlert(isPanDataFetched ? "success" : "warning")
        )}
        {/* TODO: Wrap checkbox into an component */}
        <div className="d-flex align-items-center my-3">
          <input
            type="checkbox"
            name="ckyc"
            id="ckyc"
            className="mr-2"
            checked={isCkecked}
            onChange={() => setIsCkecked((prev) => !prev)}
            disabled={!isPanDataFetched}
          />
          <label className="m-0" htmlFor="ckyc">
            By clicking, I confirm that I have taken the user's consent for
            searching and downloading CKYC
          </label>
        </div>
        <Button disabled={!isCkecked} onClick={handleFetch} loading={isLoading}>
          Download CKYC
        </Button>
      </div>
    </Box>
  );
}
