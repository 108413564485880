export enum InvoiceType {
  TRANSACTION_CHARGES = "TRANSACTION_CHARGES",
  PROCESSING_CHARGES = "PROCESSING_CHARGES",
  ESCROW_CHARGES = "ESCROW_CHARGES",
  INTEREST_CHARGES = "INTEREST_CHARGES",
  PENAL_CHARGES = "PENAL_CHARGES",
}

export interface ChargeDetail {
  amount: number;
  gstOnAmount?: number;
  chargeType: InvoiceType;
}

export type VirtualPaymentAccount = {
  ifsc: string;
  accountNumber: string;
};

export interface InvoiceDetails {
  _id: string;
  name: string;
  address: {
    locationType: string;
    localityInfo: string;
    state: string;
    pincode: string;
  };
  amountBreakup: ChargeDetail[];
  invoiceAttachmentExists: boolean;
  gstNumber: string;
  vpa?: VirtualPaymentAccount;
  createdAt: string;
  updatedAt: string;
}

export interface CreateInvoiceDto {
  interestCharge: ChargeItem;
  penalCharge: ChargeItem;
  transactionCharge: ChargeWithGST;
  escrowCharge: ChargeWithGST;
  processingCharge: ChargeWithGST;
}

interface ChargeItem {
  amount: number;
}

interface ChargeWithGST extends ChargeItem {
  gstOnAmount: number;
}

export interface InvoiceResponse {
  _id: string;
  entityId: string;
  entityType: string;
  charges: ChargeDetail[];
  lenderId: string;
  clientId: string;
  createdAt: string;
  updatedAt: string;
  __v: 0;
}
