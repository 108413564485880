import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dispatch from "../../middleware";
import Button from "../../components/button";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicationByEnterpriseId,
  getApplicationByUserId,
} from "../../actions/application.action";
import { ReduxState } from "../../reducers";
import {
  ApplicationStatusInfo,
  ApplicationType,
  ProductType,
  RoutePaths,
} from "../../models";
import {
  RetailODApplicationDetails,
  TermLoanApplicationDetails,
  UserApplicationsDetails,
  UserDetails,
} from "../../dto";
import Badge from "../../components/badge";
import Table from "../../components/table";
import Modal from "../../components/modal";
import CreateOrEditApplicationForm from "./createApplicationForm";
import { EnterpriseDetails } from "../../dto/enterprise.dto";
import { isUserAnEnterprise } from "./helpers";
import { isLoadingActive, splitKeyWithSpace } from "../../utils";

interface UserApplicationsProps {
  userSelfieUrl: string | null;
  userDetails: UserDetails | EnterpriseDetails;
}

export default function UserApplications({
  userSelfieUrl,
  userDetails,
}: UserApplicationsProps) {
  const storeDispatch = useDispatch();
  const loadingQueue = useSelector(
    (state: ReduxState) => state.application.loadingQueue
  );
  const applications: UserApplicationsDetails = useSelector(
    (state: ReduxState) => state.application.userApplications
  );
  const [isModalActive, setIsModalActive] = useState(false);

  const isEnterprise = isUserAnEnterprise(userDetails._id);

  useEffect(() => {
    const entityId = userDetails._id;
    dispatch(
      storeDispatch,
      isEnterprise
        ? getApplicationByEnterpriseId(entityId)
        : getApplicationByUserId(entityId)
    );
  }, []);

  const termLoanColumns = [
    { field: "_id", label: "Application ID" },
    { field: "applicationType", label: "Application Type" },
    { field: "approvedAmount", label: "Approved Amount" },
    { field: "tenureInMonths", label: "Tenure (months)" },
    { field: "status", label: "Status" },
  ];
  const retailODColumns = [
    { field: "_id", label: "Application ID" },
    { field: "applicationType", label: "Application Type" },
    { field: "maxAmount", label: "Max Amount" },
    { field: "maxValidMonths", label: "Max Valid (months)" },
    { field: "status", label: "Status" },
  ];

  function getIdLink(id: string) {
    return (
      <Link to={`${RoutePaths.APPLICATIONS}/${id}`} className="table-link">
        {id}
      </Link>
    );
  }
  function getApplicationType(type: ApplicationType) {
    if (type === "RETAIL_OD_APP") return "Retail OD";
    if (type === "TERM_LOAN_APP") return "Term Loan";
    return "";
  }
  function getStatusBadge(status: ApplicationStatusInfo) {
    if (status?.data === "APPROVED")
      return <Badge type="success">Approved</Badge>;
    if (status?.data === "REJECTED")
      return <Badge type="reject">Rejected</Badge>;
    return (
      <Badge type="pending" capitalize>
        {splitKeyWithSpace(status?.data).toLowerCase()}
      </Badge>
    );
  }

  function getTableDataForTerm(applications: TermLoanApplicationDetails[]) {
    return applications.map(
      ({ _id, approvedAmount, applicationType, tenureInMonths, status }) => ({
        _id: getIdLink(_id),
        applicationType: getApplicationType(applicationType),
        approvedAmount,
        tenureInMonths,
        status: getStatusBadge(status),
      })
    );
  }

  function getTableDataForOD(applications: RetailODApplicationDetails[]) {
    return applications.map(
      ({ _id, maxAmount, maxValidMonths, applicationType, status }) => ({
        _id: getIdLink(_id),
        applicationType: getApplicationType(applicationType),
        maxAmount,
        maxValidMonths,
        status: getStatusBadge(status),
      })
    );
  }

  return (
    <>
      <div className="d-flex flex-md-row flex-column justify-content-between align-items-start mb-4">
        <h3 className="user-page-title mb-2 mb-md-0">OD Applications</h3>
        <Button size="md" onClick={() => setIsModalActive(true)}>
          Create application
        </Button>
      </div>
      <Table
        loading={isLoadingActive(loadingQueue)}
        columns={retailODColumns}
        data={getTableDataForOD(applications.retailODApplications)}
      />
      <div className="mb-4">
        <h3 className="user-page-title">Term Loan Applications</h3>
      </div>
      <Table
        loading={isLoadingActive(loadingQueue)}
        columns={termLoanColumns}
        data={getTableDataForTerm(applications.termLoanApplications)}
      />
      <Modal
        open={isModalActive}
        title="New application"
        size="large"
        hasSubmitButton={false}
        hasCancelButton={false}
        onClose={() => setIsModalActive(false)}
      >
        <CreateOrEditApplicationForm
          selfieUrl={userSelfieUrl}
          userDetails={userDetails}
        />
      </Modal>
    </>
  );
}
