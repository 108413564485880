import {
  EnterpriseBankAccountTypeEnum,
  EnterpriseCoApplicant,
  EnterpriseDetailsV1,
  EnterpriseLocationTypeEnum,
  NewEnterpriseCoApplicant,
  UpdateEnterpriseV1,
} from "../../dto/enterprise.dto";

const DEFAULT_OFFICE_ADDRESS = {
  localityInfo: "",
  locationType: EnterpriseLocationTypeEnum.REGISTERED_OFFICE_REQUIRED,
  state: "",
  pincode: "",
};

const DEFAULT_BANK_AC_INFO = {
  accountType: EnterpriseBankAccountTypeEnum.CURRENT,
  accountNumber: "",
  ifsc: "",
};

const DEFAULT_RELATION_TYPE = {
  relationType: "",
  relationship: "",
  metadata: {
    dateOfBirth: "",
    pan: "",
    addressLine1: "",
    pincode: "",
    stateOrUnionTerritoryName: "",
  },
};

const DEFAULT_COAPPLICANT_ADDRESS = {
  houseInfo: "",
  localityInfo: "",
  city: "",
  state: "",
  pinCode: "",
};

export const DEFAULT_COAPPLICANT_DATA = {
  name: "",
  fatherName: "",
  gender: "",
  designation: "",
  address: DEFAULT_COAPPLICANT_ADDRESS,
  dob: "",
  pan: "",
  phone: "",
};

export function getCoApplicantsData(
  coApplicants?: (EnterpriseCoApplicant & {
    _id: string;
  })[],
  returnDefaultWhenUndefined: boolean = true
): NewEnterpriseCoApplicant[] {
  // TODO: Handle when coApplicants from DB is empty Array
  if (!coApplicants) {
    if (returnDefaultWhenUndefined) return [DEFAULT_COAPPLICANT_DATA];
    return [];
  }
  return coApplicants.map((coApplicant) => ({
    coApplicantId: coApplicant?._id,
    name: coApplicant?.name.data || "",
    fatherName: coApplicant?.fatherName.data || "",
    gender: coApplicant?.gender.data || "",
    designation: coApplicant?.designation.data || "",
    address: coApplicant?.address.data || DEFAULT_COAPPLICANT_ADDRESS,
    dob: coApplicant?.dob.data || "",
    pan: coApplicant?.pan.data || "",
    phone: coApplicant?.phone.data.number || "",
  }));
}

export function getDefaultFormData(
  enterpriseData?: EnterpriseDetailsV1
): () => UpdateEnterpriseV1 {
  return () => ({
    name: enterpriseData?.name || "",
    clientRefId: enterpriseData?.clientId || "",
    legalConstitution: enterpriseData?.legalConstitution || "",
    businessCategory: enterpriseData?.businessCategory || "",
    industryType: enterpriseData?.industryType || "",
    assessmentAgency: enterpriseData?.assessmentAgency || "",
    pan: enterpriseData?.pan || "",
    address: enterpriseData?.address || DEFAULT_OFFICE_ADDRESS,
    bankAccountInfo: enterpriseData?.bankAccountInfo || DEFAULT_BANK_AC_INFO,
    relationshipInfo: enterpriseData?.relationshipInfo || DEFAULT_RELATION_TYPE,
    virtualPaymentAccount: enterpriseData?.virtualPaymentAccount || undefined,
    gstin: enterpriseData?.gstin || "",
  });
}
