import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getApplicationDoc,
  getApplicationDocsById,
} from "../../actions/application.action";
import { getUserDoc, getUserDocList } from "../../actions/user.action";
import Button from "../../components/button";
import Modal from "../../components/modal";
import Table from "../../components/table";
import dispatch from "../../middleware";
import { ApplicantType } from "../../models";
import { downloadFileFromBase64, splitKeyWithSpace } from "../../utils";

enum DocumentType {
  APPLICATION = "APPLICATION",
  USER = "USER",
}

type DownloadDocument = {
  userId: string;
  documentName: string;
  applicationId?: string;
  applicantType?: ApplicantType;
  type: DocumentType;
};

type Column = {
  field: string;
  label: string;
};

type Row = {
  documentName: string;
  documentType: DocumentType;
  document: JSX.Element;
};

interface DocumentListProps {
  appId: string;
  userId: string;
  applicationDocList: string[];
  userDocList: string[];
  isLoading: boolean;
}

function getColumns(): Column[] {
  return [
    { field: "documentName", label: "Document Name" },
    { field: "document", label: "View Document" },
    { field: "documentType", label: "Document Type" },
  ];
}

async function getApplicationAndUserDocs(
  storeDispatch: any,
  userId: string,
  appId: string
) {
  await dispatch(storeDispatch, getUserDocList(userId));
  await dispatch(storeDispatch, getApplicationDocsById(appId));
}

export default function DocumentList({
  appId,
  userId,
  isLoading,
  applicationDocList,
  userDocList,
}: DocumentListProps) {
  const storeDispatch = useDispatch();
  const [modalInfo, setModalInfo] = useState<{
    title: string;
    message: string;
    active: boolean;
  }>({ title: "", message: "", active: false });

  useEffect(() => {
    getApplicationAndUserDocs(storeDispatch, userId, appId);
  }, [appId, userId]);

  const documentTableEntries = useMemo(
    () => getDocumentsData(userDocList, applicationDocList),
    [applicationDocList, userDocList]
  );
  async function getDocument({
    userId,
    documentName,
    applicationId,
    applicantType,
    type,
  }: DownloadDocument) {
    try {
      if (!userId || !documentName) {
        throw new Error("UserId or Document Name not present.");
      }
      if (
        type === DocumentType.APPLICATION &&
        (!applicationId || !applicantType)
      ) {
        throw new Error("ApplicationId or Application Type not present.");
      }
      let response: { body: string; type: string } | null = null;
      if (type === DocumentType.USER) {
        response = await dispatch(
          storeDispatch,
          getUserDoc(userId, documentName)
        );
      } else if (
        type === DocumentType.APPLICATION &&
        applicationId &&
        applicantType
      ) {
        response = await dispatch(
          storeDispatch,
          getApplicationDoc({
            entityId: userId,
            appId: applicationId,
            documentName,
            applicantType,
          })
        );
      }
      const base64 = response?.body;
      const fileType = response?.type;
      if (!base64 || !fileType) throw new Error("Failed To Download Document");
      //INFO: browser does not add extension type for json file type
      downloadFileFromBase64({ base64, fileName: documentName, fileType });
    } catch (e: any) {
      setModalInfo({
        title: "Document List",
        message: e?.message ?? "Something Went Wrong!!",
        active: true,
      });
    }
  }

  function renderDownloadDocumentButton(props: DownloadDocument) {
    return (
      <Button variant="blue" onClick={() => getDocument(props)}>
        Click Here
      </Button>
    );
  }

  function getDocumentsData(
    userDocList: string[],
    applicationDocList: string[]
  ): Row[] {
    const userDocRows = userDocList.map(
      (documentName: string): Row => ({
        documentName: splitKeyWithSpace(documentName, "_"),
        documentType: DocumentType.USER,
        document: renderDownloadDocumentButton({
          userId,
          documentName,
          type: DocumentType.USER,
        }),
      })
    );
    const applicationDocRows = applicationDocList.map(
      (documentName: string): Row => ({
        documentName: splitKeyWithSpace(documentName, "_"),
        documentType: DocumentType.APPLICATION,
        document: renderDownloadDocumentButton({
          userId,
          documentName,
          type: DocumentType.APPLICATION,
          applicationId: appId,
          applicantType: ApplicantType.INDIVIDUAL,
        }),
      })
    );
    return [...applicationDocRows, ...userDocRows];
  }
  if (documentTableEntries.length < 1) return null;
  return (
    <>
      <h2 className="user-page-title mb-4">Document List</h2>
      <div className="dashboard-container">
        <Table
          loading={isLoading}
          columns={getColumns()}
          data={documentTableEntries}
        />
      </div>
      <Modal
        open={modalInfo.active}
        title={modalInfo.title}
        onClose={() =>
          setModalInfo((prevModalInfo) => ({ ...prevModalInfo, active: false }))
        }
        hasCancelButton={false}
        hasSubmitButton={false}
      >
        {modalInfo.message}
      </Modal>
    </>
  );
}
