import { useEffect, useState } from "react";
import Button from "../../components/button";
import Modal from "../../components/modal";
import InvoiceTable from "./invoiceTable";
import UploadInvoice from "./uploadInvoice";
import useQueryParams from "../../hooks/useQueryParams";
import Pagination from "../../components/pagination";
import {
  downloadCSV,
  downloadFileFromBase64,
  getPaginationCount,
} from "../../utils";
import { useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import {
  downloadInvoiceByInvoiceId,
  getInvoices,
} from "../../actions/invoice.action";
import { InvoiceDetails } from "../../dto/invoice.dto";
import dispatch from "../../middleware";
import { useDispatch } from "react-redux";
import { ReactComponent as LoaderIcon } from "../../images/loader.svg";
import useScreenName from "../../hooks/useScreenName";
import { TableColumns } from "../../components/table";
import { getTableData } from "./helper";
import { InvoiceStatusType } from "../../models";
import Badge from "../../components/badge";

type SearchQueryType = {
  pageNo: number;
  searchText: string;
  numberOfEntitiesPerPage: number;
  status: string[];
  source: string[];
};

const DEFAULT_SEARCH_QUERY_STATE: SearchQueryType = {
  pageNo: 1,
  searchText: "",
  numberOfEntitiesPerPage: 10,
  status: [],
  source: [],
};

const INVOICE_TABLE_COLUMNS: TableColumns[] = [
  { field: "id", label: "Invoice ID" },
  { field: "name", label: "Name" },
  { field: "address", label: "Adress" },
  { field: "totalCharge", label: "Total Charge" },
  { field: "totalGst", label: "Total GST" },
  { field: "totalAmount", label: "Total Amount" },
  { field: "status", label: "Status" },
  { field: "fileUpload", label: "File upload" },
];

const INVOICE_TABLE_COLUMNS_CSV: TableColumns[] = [
  { field: "vpa", label: "VPA" },
  { field: "gstNumber", label: "Gst Number" },
  { field: "interestCharge", label: "Interest Charge" },
  { field: "penalCharge", label: "Penal Charge" },
  { field: "processingCharge", label: "Processing Charge" },
  { field: "processingChargeGst", label: "Processing Charge GST" },
  { field: "totalProcessingCharge", label: "Total Processing Charge" },
  { field: "transactionCharge", label: "Transaction Charge" },
  { field: "transactionChargeGst", label: "Transaction Charge GST" },
  { field: "totalTransactionCharge", label: "Total Transaction Charge" },
  { field: "escrowCharge", label: "Escrow Charge" },
  { field: "escrowChargeGst", label: "Escrow Charge GST" },
  { field: "totalEscrowCharge", label: "Total Escrow Charge" },
];

const TABLE_COLUMNS = [...INVOICE_TABLE_COLUMNS, ...INVOICE_TABLE_COLUMNS_CSV];

function getStatusBadge(status: InvoiceStatusType): JSX.Element | null {
  if (status === "PENDING") return <Badge type="pending">Pending</Badge>;
  if (status === "SUCCESS") return <Badge type="success">Success</Badge>;
  return null;
}

export default function Invoice() {
  useScreenName("Invoice");
  const storeDispatch = useDispatch();
  const [isUploadFileModalActive, setIsUploadFileModalActive] = useState(false);
  const [invoiceId, setInvoiceId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorModal, setErrorModal] = useState<{
    title: string;
    message: string;
    active: boolean;
  }>({ title: "", message: "", active: false });
  const loading: boolean = useSelector(
    (state: ReduxState) => state.invoice.loading
  );
  const invoices: InvoiceDetails[] = useSelector(
    (state: ReduxState) => state.invoice.invoices
  );
  const paginationCount: number = useSelector(
    (state: ReduxState) => state.invoice.paginationCount
  );

  const [{ pageNo, numberOfEntitiesPerPage }, setSearchQuery] =
    useQueryParams<SearchQueryType>(DEFAULT_SEARCH_QUERY_STATE);

  function handleUploadButtonClick(invoiceId: string) {
    setIsUploadFileModalActive(true);
    setInvoiceId(invoiceId);
  }

  function handleDownloadSummary() {
    downloadCSV(getTableData(invoices), TABLE_COLUMNS, "Invoice_Summary");
  }

  async function fetchInvoices() {
    setIsLoading(true);
    try {
      await dispatch(
        storeDispatch,
        getInvoices({ pageNumber: pageNo, pageSize: numberOfEntitiesPerPage })
      );
    } catch (error: any) {
      setErrorModal({
        title: "Invoice List",
        message: error?.message ?? "Something Went Wrong!!",
        active: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDownloadInvoiceDoc(invoiceId: string) {
    if (!invoiceId) return;
    setIsLoading(true);
    try {
      const response = await dispatch(
        storeDispatch,
        downloadInvoiceByInvoiceId(invoiceId)
      );
      const base64 = response?.body;
      if (!base64) return;
      downloadFileFromBase64({
        base64,
        fileName: invoiceId,
        fileType: "application/pdf",
      });
    } catch (error: any) {
      setErrorModal({
        title: "Download Invoice",
        message: error?.message ?? "Something Went Wrong!!",
        active: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchInvoices();
  }, [pageNo, numberOfEntitiesPerPage]);

  return (
    <>
      <div className="d-flex justify-content-end mb-5">
        <Button
          style={{ marginRight: "16px" }}
          onClick={handleDownloadSummary}
          variant="blue-outline"
        >
          Download Summary
        </Button>
      </div>

      {isLoading ? (
        <LoaderIcon />
      ) : (
        <InvoiceTable
          invoices={invoices}
          loading={loading}
          columns={INVOICE_TABLE_COLUMNS}
          statusBadge={getStatusBadge}
          onDownloadInvoice={handleDownloadInvoiceDoc}
          onUploadClick={handleUploadButtonClick}
        />
      )}

      <Pagination
        currentPage={pageNo}
        totalEntries={getPaginationCount(
          paginationCount,
          numberOfEntitiesPerPage
        )}
        numberOfEntriesPerPage={numberOfEntitiesPerPage}
        onPageChange={(pageNo) =>
          setSearchQuery((prevState) => ({
            ...prevState,
            pageNo,
          }))
        }
        onPerPageCountChange={(countPerPage) =>
          setSearchQuery((prevState) => ({
            ...prevState,
            numberOfEntitiesPerPage: countPerPage,
            pageNo: 1,
          }))
        }
      />

      <Modal
        open={isUploadFileModalActive}
        title="Upload File"
        size="medium"
        hasSubmitButton={false}
        hasCancelButton={false}
        onClose={() => setIsUploadFileModalActive(false)}
      >
        <UploadInvoice
          invoiceId={invoiceId}
          onSuccess={() =>
            dispatch(
              storeDispatch,
              getInvoices({
                pageNumber: pageNo,
                pageSize: numberOfEntitiesPerPage,
              })
            )
          }
        />
      </Modal>
      <Modal
        open={errorModal.active}
        title={errorModal.title}
        onClose={() =>
          setErrorModal((prevError) => ({ ...prevError, active: false }))
        }
        hasCancelButton={false}
        hasSubmitButton={false}
      >
        {errorModal.message}
      </Modal>
    </>
  );
}
