import { ChangeEvent, FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { addUser } from "../../actions/user.action";
import Alert from "../../components/alert";
import Button from "../../components/button";
import { InputField } from "../../components/input-functions";
import { UserDetails } from "../../dto";
import dispatch from "../../middleware";
import { NewUser } from "../../models";
import { clearFormData } from "../../utils";
import { ReactComponent as TickIcon } from "../../images/tick.svg";

const defaultFormData: NewUser = {
  name: "",
  clientUniqueRefId: "",
  pan: "",
  email: "",
  phone: "",
};

export default function CreateUserForm() {
  const [formData, setFormData] = useState<NewUser>(defaultFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const storeDispatch = useDispatch();

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      if (!formData.email && !formData.phone) {
        throw new Error("Email or Phone is mandatory to create an user");
      }
      setIsLoading(true);
      const clearedFormData = clearFormData(formData);
      const data: UserDetails = await dispatch(
        storeDispatch,
        addUser(clearedFormData)
      );
      // setFormData(defaultFormData);
      setSuccessMessage(
        `User created successfully with a User ID # ${data?._id}`
      );
    } catch (error) {
      setErrorMessage(
        error?.message || "Something went wrong, Try again later"
      );
    } finally {
      setIsLoading(false);
    }
  }
  if (successMessage) {
    return (
      <div className="d-flex flex-column align-items-center">
        <TickIcon color="#5BC97C" />
        <p style={{ marginTop: "20px", marginBottom: "0" }}>{successMessage}</p>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <Alert
        type="danger"
        message={errorMessage}
        canDismiss={true}
        onDismissClick={() => setErrorMessage("")}
      />
      <p className="rf-form-msg">
        Email or Phone No is required to create an user
      </p>
      <InputField
        label="Full Name"
        type="text"
        name="name"
        placeholder="Rahul Roy"
        value={formData.name}
        onChange={handleChange}
        minLength={3}
        required
      />
      <InputField
        label="Phone Number"
        type="tel"
        name="phone"
        placeholder="8975894848"
        maxLength={10}
        minLength={10}
        value={formData.phone}
        onChange={handleChange}
      />
      <InputField
        label="Email ID"
        type="email"
        name="email"
        placeholder="rahulroy@example.com"
        value={formData.email}
        onChange={handleChange}
      />
      <InputField
        label="PAN"
        type="text"
        name="pan"
        placeholder="ABCDE1234F"
        maxLength={10}
        minLength={10}
        value={formData.pan}
        onChange={handleChange}
        pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
        title="Please enter a valid PAN like ABCDE1234F"
      />
      <InputField
        label="Unique ID"
        type="text"
        name="clientUniqueRefId"
        placeholder="USR10000203"
        value={formData.clientUniqueRefId}
        onChange={handleChange}
      />
      <Button loading={isLoading} type="submit">
        Create user
      </Button>
    </form>
  );
}
