import { MiddlewareAction } from "../middleware";
import { RefundApprovalStatus } from "../models";

export enum RefundAction {
  REQUEST_GET_REFUNDS = "REQUEST_GET_REFUNDS",
  REQUEST_ADD_REFUNDS = "REQUEST_ADD_REFUNDS",
  REQUEST_APPROVE_REFUNDS = "REQUEST_APPROVE_REFUNDS",
  REQUEST_APPROVE_BULK_REFUNDS = "REQUEST_APPROVE_REFUNDS",
  REQUEST_UPDATE_REFUNDS_REJECTED = "REQUEST_UPDATE_REFUNDS_REJECTED",
}

export function getRefunds(body: {
  pageNumber: number;
  pageSize: number;
  status: RefundApprovalStatus;
}): MiddlewareAction {
  const { pageNumber, pageSize, status } = body;
  return {
    actionName: RefundAction.REQUEST_GET_REFUNDS,
    type: "GET",
    url: `/v1/refunds?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}`,
  };
}

export function addRefunds(body: {
  userId: string;
  amount: number;
  type: "EXCESS_COLLECTION";
  cliendActorId: string;
  remarks: string;
}): MiddlewareAction {
  return {
    actionName: RefundAction.REQUEST_ADD_REFUNDS,
    type: "GET",
    url: "/v1/refunds",
    body,
  };
}

export function approveRefunds(body: {
  referenceId: string;
  reason: string;
}): MiddlewareAction {
  return {
    actionName: RefundAction.REQUEST_APPROVE_REFUNDS,
    type: "POST",
    url: "/v1/refunds/approve",
    body,
  };
}

export function approveBulkRefunds(body: {
  bulkApproveRefunds: { referenceId: string; reason: string }[];
}): MiddlewareAction {
  return {
    actionName: RefundAction.REQUEST_APPROVE_REFUNDS,
    type: "POST",
    url: "/v1/refunds/bulk/approve",
    body,
  };
}

export function updateRefundsRejected(body: {
  referenceId: string;
  reason: string;
}): MiddlewareAction {
  return {
    actionName: RefundAction.REQUEST_UPDATE_REFUNDS_REJECTED,
    type: "PATCH",
    url: "/v1/refunds/reject",
    body,
  };
}
