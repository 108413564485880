import { ChangeEvent, FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { InputField } from "../../components/input-functions";
import Button from "../../components/button";
import { LoanDisbursalPayload } from "../../models";
import { TermLoanDetails, UserDetails } from "../../dto";
import { getDefaultBankAccount } from "../user-details/helpers";
import { ReactComponent as TickIcon } from "../../images/tick.svg";
import Alert from "../../components/alert";
import { dateInputToString, getTodayDateForInputField } from "../../utils";
import dispatch from "../../middleware";
import { addLoanDisbursal } from "../../actions/loan.action";
import { EnterpriseDetails } from "../../dto/enterprise.dto";

interface AddDisbursalFormProps {
  loanDetails: TermLoanDetails;
  userDetails: UserDetails | EnterpriseDetails | null;
}

export default function AddDisbursalForm({
  loanDetails,
  userDetails,
}: AddDisbursalFormProps) {
  const { _id: loanId, approvedAmount, pfPostGst, enterpriseId } = loanDetails;

  const bankAccountsInfo = enterpriseId
    ? (userDetails as EnterpriseDetails | null)?.bankAccountsInfo
    : (userDetails as UserDetails | null)?.bankAccountInfo;

  const defaultFormData = (): Record<keyof LoanDisbursalPayload, string> => ({
    loanId,
    date: getTodayDateForInputField(),
    transactionRefNo: "",
    disbursedAmount: String(approvedAmount - pfPostGst || ""),
    processingFees: String(pfPostGst || ""),
    bankAccountNo:
      getDefaultBankAccount(bankAccountsInfo)?.data.accountNumber || "",
    ifscCode: getDefaultBankAccount(bankAccountsInfo)?.data.ifsc || "",
    bankName: "",
  });
  const [formData, setFormData] =
    useState<Record<keyof LoanDisbursalPayload, string>>(defaultFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const storeDispatch = useDispatch();

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  }
  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { date, disbursedAmount, processingFees, ...restFormData } =
        formData;
      const payload: LoanDisbursalPayload = {
        ...restFormData,
        date: dateInputToString(date),
        disbursedAmount: Number(disbursedAmount),
        processingFees: Number(processingFees),
      };
      const resp = await dispatch(storeDispatch, addLoanDisbursal(payload));
      setSuccessMessage(
        `Loan of Id: ${resp?._id} has been disbursed successfully`
      );
    } catch (error) {
      setErrorMessage(error?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  }
  if (successMessage) {
    return (
      <div className="d-flex flex-column align-items-center">
        <TickIcon color="#5BC97C" />
        <p style={{ marginTop: "20px", marginBottom: "0" }}>{successMessage}</p>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <Alert
        type="danger"
        message={errorMessage}
        canDismiss={true}
        onDismissClick={() => setErrorMessage("")}
      />
      <InputField
        label="Date"
        type="date"
        name="date"
        placeholder="Enter Disbursal Date"
        value={formData.date}
        onChange={handleChange}
        required
      />
      <InputField
        label="UTR Number"
        name="transactionRefNo"
        placeholder="Enter the UTR Number"
        value={formData.transactionRefNo}
        onChange={handleChange}
        required
      />
      <InputField
        label="Disbursed Amount"
        type="text"
        name="disbursedAmount"
        min={0}
        max={approvedAmount}
        placeholder="Enter the Disbursed Amount"
        value={formData.disbursedAmount}
        onChange={handleChange}
        required
      />
      <InputField
        label="Processing Fees (Processing Fee With GST)"
        type="text"
        name="processingFees"
        min={0}
        placeholder="Enter the Processing Fees"
        value={formData.processingFees}
        onChange={handleChange}
      />
      <InputField
        label="Bank Name"
        name="bankName"
        placeholder="Enter the Bank name"
        value={formData.bankName}
        onChange={handleChange}
        required
      />
      <InputField
        label="Bank A/c Number"
        name="bankAccountNo"
        placeholder="Enter the Bank A/c Number"
        value={formData.bankAccountNo}
        onChange={handleChange}
        pattern="[0-9]{9,18}"
        title="Please enter a valid bank account number"
        required
      />
      <InputField
        label="IFSC"
        name="ifscCode"
        placeholder="Enter the IFSC code"
        value={formData.ifscCode}
        onChange={handleChange}
        pattern="^[A-Za-z]{4}[a-zA-Z0-9]{7}$"
        title="Please enter a valid IFSC code"
        required
      />
      <Button loading={isLoading} type="submit">
        Submit
      </Button>
    </form>
  );
}
