import { UserActions } from "../actions/user.action";
import { CreditDetails, UserDetails, UserDocType, VkycDetails } from "../dto";
import { CkycataDtoV1, CkycDataDto } from "../dto/ckyc.dto";
import { SearchFilter } from "../models";
import { addToLoadingQueue, removeFromLoadingQueue } from "../utils";

export interface UserState {
  loadingQueue: string[];
  users: UserDetails[];
  singleUser: UserDetails | null;
  singleUserDocList: string[];
  searchFilters: SearchFilter[];
  paginationCount: number | null;
  count: number | null;
  docTypes: UserDocType[];
  creditDetails: CreditDetails[];
  ckycDetails?: CkycDataDto | null;
  ckycDetailsV1: CkycataDtoV1 | null;
  vkycDetails: VkycDetails | null;
}

//TODO: fill the single user with an empty object of type `UserDetails` & should not be null
const defaultState: UserState = {
  loadingQueue: [],
  users: [],
  singleUser: null,
  singleUserDocList: [],
  searchFilters: [],
  paginationCount: null,
  count: null,
  docTypes: [],
  creditDetails: [],
  ckycDetailsV1: null,
  vkycDetails: null,
};

export default function UserStateReducer(
  state = defaultState,
  action: any
): UserState {
  switch (action.type) {
    case UserActions.REQUEST_GET_PAGINATED_USERS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_GET_PAGINATED_USERS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        users: action?.payload,
      };
    case `${UserActions.REQUEST_GET_PAGINATED_USERS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        users: [],
      };
    case UserActions.REQUEST_GET_USER_BY_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_GET_USER_BY_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleUser: action?.payload,
      };
    case `${UserActions.REQUEST_GET_USER_BY_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleUser: null,
      };
    case UserActions.REQUEST_ADD_USER:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_ADD_USER}_SUCCESS`: {
      const prevStateUsers = state.users;
      prevStateUsers.unshift(action?.payload);
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        users: [...prevStateUsers],
      };
    }
    case `${UserActions.REQUEST_ADD_USER}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case UserActions.REQUEST_UPDATE_USER:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_UPDATE_USER}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleUser: action?.payload,
      };
    case `${UserActions.REQUEST_UPDATE_USER}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case UserActions.CLEAR_USER:
      return {
        ...state,
        singleUser: null,
      };
    case UserActions.REQUEST_VERIFY_USER:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_VERIFY_USER}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleUser: action?.payload,
      };
    case `${UserActions.REQUEST_VERIFY_USER}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case UserActions.REQUEST_UPDATE_USER_STATUS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_UPDATE_USER_STATUS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleUser: action?.payload,
      };
    case `${UserActions.REQUEST_UPDATE_USER_STATUS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case UserActions.REQUEST_STORE_DOC_OF_USER:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_STORE_DOC_OF_USER}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_STORE_DOC_OF_USER}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case UserActions.REQUEST_GET_DOC_OF_USER:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_GET_DOC_OF_USER}_SUCCESS`: {
      const prevState = state;
      if (prevState.singleUser) {
        prevState.singleUser.documents = Array.isArray(action?.payload)
          ? action?.payload
          : [];
      }
      return {
        ...prevState,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    }
    case `${UserActions.REQUEST_GET_DOC_OF_USER}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case UserActions.REQUEST_GET_USER_COUNT:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_GET_USER_COUNT}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        count: action?.payload,
      };
    case `${UserActions.REQUEST_GET_USER_COUNT}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        count: null,
      };
    case UserActions.REQUEST_GET_USER_DOC_TYPES:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_GET_USER_DOC_TYPES}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        docTypes: action?.payload,
      };
    case `${UserActions.REQUEST_GET_USER_DOC_TYPES}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        docTypes: [],
      };
    case UserActions.REQUEST_GET_USERS_BY_SEARCH:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_GET_USERS_BY_SEARCH}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        users: action?.payload,
      };
    case `${UserActions.REQUEST_GET_USERS_BY_SEARCH}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        users: [],
      };
    case UserActions.REQUEST_GET_PAGINATION_USER_COUNT:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_GET_PAGINATION_USER_COUNT}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        paginationCount: action?.payload?.individualUserCount,
      };
    case `${UserActions.REQUEST_GET_PAGINATION_USER_COUNT}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        paginationCount: null,
      };
    case UserActions.REQUEST_GET_CREDIT_DETAILS_BY_USER_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_GET_CREDIT_DETAILS_BY_USER_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        creditDetails: action?.payload,
      };
    case `${UserActions.REQUEST_GET_CREDIT_DETAILS_BY_USER_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        creditDetails: [],
      };
    case UserActions.REQUEST_GET_USERDOC_LIST_BY_USER_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_GET_USERDOC_LIST_BY_USER_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleUserDocList: action?.payload,
      };
    case `${UserActions.REQUEST_GET_USERDOC_LIST_BY_USER_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleUserDocList: [],
      };
    case UserActions.REQUEST_GET_OR_FETCH_CREDIT_DETAILS_BY_USER_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_GET_OR_FETCH_CREDIT_DETAILS_BY_USER_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        creditDetails: action?.payload,
      };
    case `${UserActions.REQUEST_GET_OR_FETCH_CREDIT_DETAILS_BY_USER_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        creditDetails: [],
      };
    case UserActions.REQUEST_GET_USER_SEARCH_FILTERS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_GET_USER_SEARCH_FILTERS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        searchFilters: action?.payload?.filters || [],
      };
    case `${UserActions.REQUEST_GET_USER_SEARCH_FILTERS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        searchFilters: [],
      };
    case `${UserActions.REQUEST_GET_CKYC_DATA_BY_USER_ID}_SUCCESS`: {
      // INFO: Converting empty response / string as null
      const resp = action?.payload === "" ? null : action?.payload;
      return {
        ...state,
        ckycDetails: resp,
      };
    }
    case `${UserActions.REQUEST_GET_CKYC_DATA_BY_USER_ID}_FAILURE`:
      return {
        ...state,
        ckycDetails: undefined,
      };
    case UserActions.REQUEST_GET_V1_CKYC_DATA_BY_USER_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_GET_V1_CKYC_DATA_BY_USER_ID}_SUCCESS`: {
      const response = action?.payload === "" ? null : action?.payload;
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        ckycDetailsV1: response,
      };
    }
    case `${UserActions.REQUEST_GET_V1_CKYC_DATA_BY_USER_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        ckycDetailsV1: null,
      };
    case UserActions.REQUEST_GET_USER_VKYC_DETAILS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${UserActions.REQUEST_GET_USER_VKYC_DETAILS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        vkycDetails: action?.payload ?? null,
      };
    case `${UserActions.REQUEST_GET_USER_VKYC_DETAILS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        vkycDetails: null,
      };
    case UserActions.CLEAR_CKYC_DATA:
      return {
        ...state,
        ckycDetails: undefined,
      };
    default:
      return state;
  }
}
