import { ReactNode, useEffect } from "react";
import Button from "../button";
import "./modalStyles.css";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  title?: string;
  description?: string;
  centered?: boolean;
  size?: "small" | "medium" | "large";
  hasCloseIcon?: boolean;
  hasSubmitButton?: boolean;
  hasSubmitButtonLoading?: boolean;
  submitButtonText?: string;
  submitButtonDisable?: boolean;
  onSubmit?: () => void;
  hasCancelButton?: boolean;
  cancelButtonText?: string;
  isCancelButtonLoading?: boolean;
  closeOnBackdropClick?: boolean;
  closeOnEscPress?: boolean;
  removeScrollBarFromBodyWhenOpened?: boolean;
  children?: ReactNode;
}

export default function Modal({
  open = false,
  onClose,
  onCancel,
  title,
  description,
  centered = true,
  size = "medium",
  hasCloseIcon = true,
  hasSubmitButton = true,
  hasSubmitButtonLoading = false,
  submitButtonText = "Submit",
  submitButtonDisable = false,
  onSubmit,
  hasCancelButton = true,
  cancelButtonText = "Close",
  isCancelButtonLoading = false,
  removeScrollBarFromBodyWhenOpened = true,
  closeOnBackdropClick = true,
  children,
}: ModalProps) {
  function handleClose(e: any) {
    // e.stopPropagation();
    if (typeof onClose === "function") {
      onClose();
    }
  }
  function handleSubmit() {
    if (typeof onSubmit === "function") {
      onSubmit();
    }
  }
  function getSizeClassName(size: "small" | "medium" | "large") {
    if (size === "large") return " modal-lg";
    if (size === "small") return " modal-sm";
    return "";
  }
  useEffect(() => {
    if (!removeScrollBarFromBodyWhenOpened) return;
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [open]);
  return (
    <div
      className="modal rf-modal"
      tabIndex={-1}
      role="dialog"
      style={{ display: open ? "block" : "none" }}
      aria-labelledby={title}
      aria-describedby={description}
      aria-hidden={!open}
      // onClick={closeOnBackdropClick ? handleClose : undefined}
    >
      <div
        className={`modal-dialog ${
          centered ? "modal-dialog-centered" : ""
        }${getSizeClassName(size)}`}
        role="document"
      >
        <div className="modal-content rf-modal-content">
          {(title || hasCloseIcon) && (
            <div className="modal-header rf-modal-header">
              {title && <h5 className="modal-title">{title}</h5>}
              {hasCloseIcon && (
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleClose}
                  disabled={hasSubmitButtonLoading}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
            </div>
          )}
          <div className="modal-body">{open && children}</div>
          {(hasSubmitButton || hasCancelButton) && (
            <div className="modal-footer rf-modal-footer">
              {hasSubmitButton && (
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={isCancelButtonLoading || submitButtonDisable}
                  loading={hasSubmitButtonLoading}
                >
                  {submitButtonText}
                </Button>
              )}
              {hasCancelButton && (
                <Button
                  variant="blue-outline"
                  onClick={onCancel ?? handleClose}
                  disabled={hasSubmitButtonLoading}
                  loading={isCancelButtonLoading}
                >
                  {cancelButtonText}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
