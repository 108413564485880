import { Actions } from "../actions/invoice.action";
import { InvoiceDetails } from "../dto/invoice.dto";

export interface InvoiceState {
  loading: boolean;
  invoices: InvoiceDetails[];
  paginationCount: number;
}

const defalutState: InvoiceState = {
  loading: false,
  invoices: [],
  paginationCount: 0,
};

export default function InvoiceStateReducer(
  state = defalutState,
  action: any
): InvoiceState {
  switch (action.type) {
    case Actions.REQUEST_GET_INVOICES:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_GET_INVOICES}_SUCCESS`:
      return {
        ...state,
        loading: false,
        invoices: action.payload?.data ?? [],
        paginationCount: action.payload?.paginationDetails.totalCount ?? 0,
      };
    case `${Actions.REQUEST_GET_INVOICES}_FAILURE`:
      return {
        ...state,
        loading: false,
        invoices: [],
        paginationCount: 0,
      };
    default:
      return state;
  }
}
