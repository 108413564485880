import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  bulkSignAgreement,
  getAgreementDocById,
  getAgreementFilters,
  getAgreements,
  getAgreementsCount,
  initiateOtpForSigning,
  signAgreement,
} from "../../actions/agreement.action";
import Alert from "../../components/alert";
import Badge from "../../components/badge";
import Button from "../../components/button";
import { Select } from "../../components/input-functions";
import Pagination from "../../components/pagination";
import Table, { TableColumns } from "../../components/table";
import Tabs from "../../components/tabs";
import { AGREEMENTS_SCREEN_NAME } from "../../constants";
import {
  Agreement,
  AgreementCount,
  AgreementFilter,
  AgreementSignOtpResponse,
  AgreementsSearchQuery,
  AgreementStatus,
  AgreementType,
} from "../../dto/agreement.dto";
import useQueryParams from "../../hooks/useQueryParams";
import useScreenName from "../../hooks/useScreenName";
import dispatch from "../../middleware";
import { ReduxState } from "../../reducers";
import {
  dateToDDMMYYYYHHMMAP,
  downloadFileFromBase64,
  getPaginationCount,
  isLoadingActive,
} from "../../utils";
import {
  AgreementsTabItems,
  getSignAgreementButtonText,
  getRecordsCountBasedOnStatus,
} from "./helpers";
import OtpSign from "./otpSign";
import "./styles.css";

const DEFAULT_SEARCH_QUERY_STATE: AgreementsSearchQuery = {
  agreementType: "CREDIT_LINE_AGREEMENT",
  pageNumber: 1,
  pageSize: 10,
  status: "all",
};

const OTP_FAILED_ERROR_CODES = ["E70001"];

function getColumns(): TableColumns[] {
  return [
    { field: "username", label: "User Name" },
    { field: "userId", label: "User ID" },
    { field: "createdAt", label: "Date of Application" },
    { field: "amount", label: "Amount" },
    { field: "entityType", label: "Entity Type" },
    { field: "download", label: "Agreement", style: { textAlign: "center" } },
    { field: "status", label: "Status" },
  ];
}

function renderDownloadAgreementButton(
  type: AgreementType,
  onClick: () => void
) {
  let docTypeShortForm = "";
  if (type === "TERM_LOAN_AGREEMENT") docTypeShortForm = "TLA";
  else if (type === "CREDIT_LINE_AGREEMENT") docTypeShortForm = "CLA";
  else docTypeShortForm = "KFS";

  return (
    <Button variant="blue" onClick={onClick}>
      <span className="text__underline">Download {docTypeShortForm}</span>
    </Button>
  );
}

function renderStatusBadge(status: AgreementStatus) {
  if (status === "signed") return <Badge type="success">Signed</Badge>;
  return <Badge type="pending">Pending</Badge>;
}

function getFormattedAgreementRecords(
  agreements: Agreement[],
  onDownloadClick: (agreementId: string) => void
) {
  return agreements.map((agreement) => {
    return {
      _id: agreement._id,
      username: agreement.username,
      userId: agreement.userId,
      createdAt: dateToDDMMYYYYHHMMAP(agreement.createdAt),
      amount: agreement.amount ?? "-",
      entityType: agreement.entityType,
      download: renderDownloadAgreementButton(agreement.agreementType, () =>
        onDownloadClick(agreement._id)
      ),
      status: renderStatusBadge(agreement.status),
      statusValue: agreement.status,
    };
  });
}

// TODO: Commenting the code for now will uncomment when required

/*
function renderPendingCountInfo(
  agreementsCountToStatusMap: Record<string, AgreementCount | null>
) {
  const claPendingCount =
    agreementsCountToStatusMap["CREDIT_LINE_AGREEMENT"]?.pending ?? 0;
  const kfsPendingCount =
    agreementsCountToStatusMap["KEY_FACT_STATEMENT"]?.pending ?? 0;

  const pendingInfoArr = [];
  if (claPendingCount > 0) pendingInfoArr.push(`${claPendingCount} new CLA`);
  if (kfsPendingCount > 0) pendingInfoArr.push(`${kfsPendingCount} new KFS`);

  if (pendingInfoArr.length > 0) {
    const message = `${pendingInfoArr.join(" & ")} are pending to sign`;
    return <Alert type="info" message={message} />;
  }
  return null;
}
*/

function renderOtpDisclaimer(isBulkSign: boolean) {
  if (!isBulkSign) return;
  return (
    <Alert
      type="info"
      message={
        <>
          Sign all only supports maximum of <b>2000</b> agreements at a time.{" "}
        </>
      }
    />
  );
}

export default function Agreements() {
  useScreenName(AGREEMENTS_SCREEN_NAME);
  const storeDispatch = useDispatch();

  const loadingQueue = useSelector(
    (state: ReduxState) => state.agreeement.loadingQueue
  );
  const agreements: Agreement[] = useSelector(
    (state: ReduxState) => state.agreeement.agreements
  );
  const agreementsCount: AgreementCount | null = useSelector(
    (state: ReduxState) => state.agreeement.count
  );
  const agreementsCountToStatusMap: Record<string, AgreementCount | null> =
    useSelector((state: ReduxState) => state.agreeement.countToStatusMap);
  const filters: AgreementFilter[] = useSelector(
    (state: ReduxState) => state.agreeement.filters
  );
  const otpRequest: AgreementSignOtpResponse | null = useSelector(
    (state: ReduxState) => state.agreeement.otpRequest
  );

  const [isOtpTriggerButtonLoading, setIsOtpTriggerButtonLoading] =
    useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isOtpModalActive, setIsOtpModalActive] = useState(false);
  const [otpSignResponse, setOtpSignResponse] = useState<{
    success: boolean;
    isOtpError?: boolean;
    message: string;
  } | null>(null);

  const [{ agreementType, pageNumber, pageSize, status }, setSearchQuery] =
    useQueryParams<AgreementsSearchQuery>(DEFAULT_SEARCH_QUERY_STATE);

  function fetchAgreements(payload: {
    agreementType: AgreementType;
    pageNumber: number;
    pageSize: number;
    status?: AgreementStatus;
  }) {
    if (payload.status === "all") delete payload.status;
    dispatch(storeDispatch, getAgreements(payload));
  }

  const isAnyUnsignedAgreementAvailable = useMemo(
    () => agreements.some((agreement) => agreement.status === "pending"),
    [agreements]
  );

  useEffect(() => {
    dispatch(storeDispatch, getAgreementFilters());

    // INFO: Hack to fetch other type of agreements count on load
    AgreementsTabItems.forEach(({ value }) => {
      if (value === agreementType) return;
      dispatch(storeDispatch, getAgreementsCount(value));
    });
  }, []);

  useEffect(() => {
    dispatch(storeDispatch, getAgreementsCount(agreementType));
  }, [agreementType]);

  useEffect(() => {
    fetchAgreements({ agreementType, pageNumber, pageSize, status });
    setSelectedItems([]);
  }, [agreementType, pageNumber, pageSize, status]);

  async function downloadAgreementDoc(agreementId: string) {
    const resp = await dispatch(
      storeDispatch,
      getAgreementDocById(agreementId)
    );
    const base64 = resp?.base64Doc;
    if (!base64) return;
    downloadFileFromBase64({
      base64,
      fileName: agreementId,
      fileType: "application/pdf",
    });
  }

  async function triggerOtp() {
    setOtpSignResponse(null);
    try {
      setIsOtpTriggerButtonLoading(true);
      return await dispatch(
        storeDispatch,
        initiateOtpForSigning(agreementType)
      );
    } catch (error) {
      // TODO: Show a pop-up if required
    } finally {
      setIsOtpTriggerButtonLoading(false);
    }
  }

  async function initiateSign() {
    await triggerOtp();
    setIsOtpModalActive(true);
  }

  async function handleSign(requestId: string, otp: string) {
    const hasSelection = selectedItems.length > 0;

    let signResp: { success: boolean } = { success: false };

    try {
      if (hasSelection) {
        signResp = await dispatch(
          storeDispatch,
          signAgreement({
            requestId,
            otp,
            agreementType,
            agreementIds: selectedItems,
          })
        );
      } else {
        signResp = await dispatch(
          storeDispatch,
          bulkSignAgreement({
            requestId,
            otp,
            agreementType,
          })
        );
      }

      if (!signResp.success) {
        throw new Error("Some documents did not get signed. Please try again");
      }

      setOtpSignResponse({
        success: true,
        message: `${agreementType.replaceAll(
          "_",
          " "
        )}(s) have been successfully signed`,
      });
      setSelectedItems([]);
      fetchAgreements({ agreementType, pageNumber, pageSize, status });
      dispatch(storeDispatch, getAgreementsCount(agreementType));
    } catch (error) {
      const isOtpError: boolean | undefined =
        error?.cause?.errorCode &&
        OTP_FAILED_ERROR_CODES.includes(error.cause.errorCode);
      setOtpSignResponse({
        success: false,
        isOtpError: isOtpError,
        message: error?.message,
      });
    }
  }

  return (
    <>
      {/* {renderPendingCountInfo(agreementsCountToStatusMap)} */}
      <div className="dashboard-container p-3">
        <Tabs
          items={AgreementsTabItems}
          value={agreementType}
          disable={isLoadingActive(loadingQueue)}
          onChange={(value) => {
            setSearchQuery({
              ...DEFAULT_SEARCH_QUERY_STATE,
              agreementType: value,
            });
          }}
        />
      </div>
      <div className="dashboard-container p-3">
        <div className="d-flex flex-lg-row flex-column align-items-lg-end mb-4">
          <div className="search-input-user mr-lg-3 mb-4 mb-lg-0">
            <Select
              options={filters[0]?.options ?? []}
              value={status}
              onChange={(e) => {
                const value = e.currentTarget.value.trim() || "all";
                setSearchQuery((prev) => ({
                  ...prev,
                  pageNumber: 1,
                  status: value as AgreementStatus,
                }));
              }}
            />
          </div>
          <div className="signin-buttons--container">
            <Button
              size="md"
              disabled={!selectedItems.length}
              onClick={initiateSign}
              loading={isOtpTriggerButtonLoading}
            >
              {getSignAgreementButtonText(selectedItems.length)}
            </Button>
            <Button
              size="md"
              disabled={
                !agreements.length ||
                !!selectedItems.length ||
                !isAnyUnsignedAgreementAvailable
              }
              onClick={initiateSign}
              loading={isOtpTriggerButtonLoading}
            >
              Sign All
            </Button>
          </div>
        </div>
        <Table
          hasRowSelect
          loading={isLoadingActive(loadingQueue)}
          columns={getColumns()}
          data={getFormattedAgreementRecords(agreements, downloadAgreementDoc)}
          selectedItems={selectedItems}
          onSelectChange={setSelectedItems}
          isRowCheckable={(record: any) => record.statusValue !== "signed"}
          keyField={"_id"}
        />
        <Pagination
          currentPage={pageNumber}
          totalEntries={getPaginationCount(
            getRecordsCountBasedOnStatus(agreementsCount, status),
            pageSize
          )}
          numberOfEntriesPerPage={pageSize}
          onPageChange={(pageNumber) =>
            setSearchQuery((prevState) => ({
              ...prevState,
              pageNumber,
            }))
          }
          onPerPageCountChange={(countPerPage) =>
            setSearchQuery((prevState) => ({
              ...prevState,
              pageSize: countPerPage,
              pageNumber: 1,
            }))
          }
        />
      </div>
      {isOtpModalActive && (
        <OtpSign
          agreementType={agreementType}
          otpRequestResponse={otpRequest}
          otpSignResponse={otpSignResponse}
          disclaimer={renderOtpDisclaimer(!selectedItems.length)}
          onClose={() => setIsOtpModalActive(false)}
          onResendClick={triggerOtp}
          onSubmitClick={handleSign}
        />
      )}
    </>
  );
}
