import { Link } from "react-router-dom";

interface AvatarProps {
  size?: number;
  displayName?: string;
  displayPhotoUrl?: string; //TODO: Use this url to show image when available
  initialCount?: number;
  backgroundColor?: string;
  color?: string;
  border?: string;
  boxShadow?: string;
  clickable?: boolean;
  linkTo?: string;
}

export default function Avatar({
  size = 50,
  displayName,
  displayPhotoUrl,
  initialCount = 2,
  backgroundColor = "var(--clr-brand-default)",
  color = "#fff",
  border,
  boxShadow = "0px 3px 6px #1717172D",
  clickable = false,
  linkTo,
}: AvatarProps) {
  function getUserInitial(userName = "", initialCount: number) {
    if (userName === "") return "";
    return userName
      .split(" ")
      .map((n) => n.charAt(0))
      .join("")
      .substr(0, initialCount)
      .toUpperCase();
  }

  function renderAvatar() {
    return displayPhotoUrl ? (
      <figure
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: "50%",
          WebkitBorderRadius: "50%",
          border,
          boxShadow,
        }}
      >
        <img
          className="rounded-circle"
          alt={displayName}
          src={displayPhotoUrl}
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
        />
      </figure>
    ) : (
      <div
        style={{
          width: `${size}px`,
          height: `${size}px`,
          backgroundColor,
          textAlign: "center",
          borderRadius: "50%",
          WebkitBorderRadius: "50%",
          border,
        }}
      >
        <span
          style={{
            position: "relative",
            top: `${size / 4}px`,
            fontSize: `${size / 2}px`,
            lineHeight: `${size / 2}px`,
            color,
            fontWeight: 700,
          }}
        >
          {getUserInitial(displayName, initialCount)}
        </span>
      </div>
    );
  }

  if (clickable) {
    return (
      <Link to={linkTo} style={{ textDecoration: "none" }}>
        {renderAvatar()}
      </Link>
    );
  }

  return renderAvatar();
}
