export enum ReportType {
  ACCOUNTING_REPORT = "ACCOUNTING_REPORT",
  BUREAU_REPORT = "BUREAU_REPORT",
}

export interface GenerateReport {
  reportType: ReportType;
  fromDate: string;
  toDate: string;
}

export interface ReportDetails {
  reportId: string;
  asOf?: string;
  // INFO: Temp Change for client demo, might need to revert back
  // reportType: string;
  // fromDate?: string;
  // toDate?: string;
  // createdDate?: string;
}

export interface ReportDoc {
  fileName: string;
  fileType: string;
  base64: string;
}
