import {Actions} from "../actions/login.action";

export interface FirebaseResponse {
    type: string;
    response: {
        code: string;
        message: string;
    }
}

export interface LoginState {
    isLoggedIn: boolean;
    loading: boolean;
    firebaseResponse: FirebaseResponse;
    email: string;
}

const defaultFirebaseResponse = {
    type: "",
    response: {
        code: "",
        message: "",
    },
};
const defaultState: LoginState = {
    isLoggedIn: false,
    loading: false,
    firebaseResponse: defaultFirebaseResponse,
    email: "",
};

export default function LoginStateReducer(state = defaultState, action: any) : LoginState {
    switch (action.type) {
        case Actions.FIREBASE_LOGIN:
            return {
                ...state,
                loading: true,
                firebaseResponse: defaultFirebaseResponse,
            };
        case `${Actions.FIREBASE_LOGIN}_SUCCESS`:
            return {
                ...state,
                loading: false,
                isLoggedIn: true,
                firebaseResponse: defaultFirebaseResponse,
            };
        case `${Actions.FIREBASE_LOGIN}_FAILURE`:
            return {
                ...state,
                loading: false,
                isLoggedIn: false,
                firebaseResponse: {
                    type: "FAILURE",
                    response: action.payload.response,
                },
            };
        case Actions.CLEARE_FIREBASE_RESPONSE:
            return {
                ...state,
                firebaseResponse: defaultFirebaseResponse,
            };
        case Actions.FIREBASE_REGISTER:
            return {
                ...state,
                loading: true,
                firebaseResponse: defaultFirebaseResponse,
            };
        case `${Actions.FIREBASE_REGISTER}_FAILURE`:
            return {
                ...state,
                loading: false,
                firebaseResponse: {
                    type: "FAILURE",
                    response: action.payload.response,
                },
            };
        case `${Actions.FIREBASE_REGISTER}_SUCCESS`:
            return {
                ...state,
                loading: false,
                firebaseResponse: {
                    type: "SUCCESS",
                    response: {
                        code: "",
                        message: "You have successfully signed up!",
                    },
                },
            };
        case Actions.FIREBASE_VERIFICATION_MAIL:
            return {
                ...state,
                loading: true,
                firebaseResponse: defaultFirebaseResponse,
            }
        case `${Actions.FIREBASE_VERIFICATION_MAIL}_SUCCESS`:
            return {
                ...state,
                loading: false,
                firebaseResponse: {
                    type: "SUCCESS",
                    response: {
                        code: "",
                        message: "A verification mail has been sent to your email id. Please click on that link and verify your login",
                    },
                },
            };
        case `${Actions.FIREBASE_VERIFICATION_MAIL}_FAILURE`:
            return {
                ...state,
                loading: false,
                firebaseResponse: {
                    type: "FAILURE",
                    response: {
                        code: "",
                        message: action.payload.response.message,
                    },
                },
            };
        case Actions.STOP_LOADING:
            return {
                ...state,
                loading: false,
            };
        case Actions.FIREBASE_PASSWORD_UPDATE:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.FIREBASE_PASSWORD_UPDATE}_SUCCESS`:
            return {
                ...state,
                loading: false,
            };
         case `${Actions.FIREBASE_PASSWORD_UPDATE}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        default:
            return state
    }
}