import { Actions } from "../actions/admin.action";
import {
  AdminDetails,
  ClosingBalanceFinancialStats,
  CommercialODFinancialStats,
  MultiMonthFinancialStats,
  OneMonthFinancialStats,
  SummaryStats,
} from "../dto";
import { FinancialDataDto } from "../dto/portfolio.dto";
import { addToLoadingQueue, removeFromLoadingQueue } from "../utils";

export interface AdminState {
  loadingQueue: string[];
  data: AdminDetails | null;
  lenderSources: string[];
  summaryStats: SummaryStats | null;
  oneMonthFinancialStats: OneMonthFinancialStats | null;
  multiMonthFinancialStats: MultiMonthFinancialStats | null;
  commercialOdFinancialStats: CommercialODFinancialStats | null;
  closingBalanceFinancialStats: ClosingBalanceFinancialStats | null;
  portfolioStats: FinancialDataDto | null;
}

const defaultState: AdminState = {
  loadingQueue: [],
  data: {
    _id: "",
    email: "",
    name: "",
    lenderId: "",
    roles: ["ADMIN"],
    clientId: "",
  },
  lenderSources: [],
  summaryStats: null,
  oneMonthFinancialStats: null,
  multiMonthFinancialStats: null,
  commercialOdFinancialStats: null,
  closingBalanceFinancialStats: null,
  portfolioStats: null,
};

export default function AdminStateReducer(
  state: AdminState = defaultState,
  action: any
): AdminState {
  switch (action.type) {
    case Actions.REQUEST_GET_ADMIN:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_ADMIN}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        data: action?.payload,
      };
    case `${Actions.REQUEST_GET_ADMIN}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        data: null,
      };
    case Actions.REQUEST_ADD_ADMIN:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    // TODO: Need changes to set state as per tha data from API
    case `${Actions.REQUEST_ADD_ADMIN}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_ADD_ADMIN}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case Actions.REQUEST_GET_LENDER_SOURCES:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_LENDER_SOURCES}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        lenderSources: action?.payload,
      };
    case `${Actions.REQUEST_GET_LENDER_SOURCES}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        lenderSources: [],
      };
    case Actions.REQUEST_GET_SUMMARY_STATS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_SUMMARY_STATS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        summaryStats: action?.payload,
      };
    case `${Actions.REQUEST_GET_SUMMARY_STATS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        summaryStats: null,
      };
    case Actions.REQUEST_GET_ONE_MONTH_FINANCIAL_STATS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_ONE_MONTH_FINANCIAL_STATS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        oneMonthFinancialStats: action?.payload,
      };
    case `${Actions.REQUEST_GET_ONE_MONTH_FINANCIAL_STATS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        oneMonthFinancialStats: null,
      };
    case Actions.REQUEST_GET_MULTI_MONTH_FINANCIAL_STATS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_MULTI_MONTH_FINANCIAL_STATS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        multiMonthFinancialStats: action?.payload,
      };
    case `${Actions.REQUEST_GET_MULTI_MONTH_FINANCIAL_STATS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        multiMonthFinancialStats: null,
      };
    case Actions.REQUEST_GET_COMMERCIAL_OD_FINANCIAL_STATS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_COMMERCIAL_OD_FINANCIAL_STATS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        commercialOdFinancialStats: action?.payload,
      };
    case `${Actions.REQUEST_GET_COMMERCIAL_OD_FINANCIAL_STATS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        commercialOdFinancialStats: null,
      };
    case Actions.REQUEST_GET_PORTFOLIO_STATS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_PORTFOLIO_STATS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        portfolioStats: action?.payload ?? null,
      };
    case `${Actions.REQUEST_GET_PORTFOLIO_STATS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        portfolioStats: null,
      };
    case Actions.REQUEST_GET_CLOAING_BALANCE_FINANCIAL_STATS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_CLOAING_BALANCE_FINANCIAL_STATS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        closingBalanceFinancialStats: action?.payload ?? null,
      };
    case `${Actions.REQUEST_GET_CLOAING_BALANCE_FINANCIAL_STATS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        closingBalanceFinancialStats: null,
      };
    default:
      return state;
  }
}
