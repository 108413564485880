import React, { ReactNode } from "react";
import { FieldLabel } from "../../models";
import { getProp } from "../../utils";
import Box from "../box";

export interface DatafieldLabel {
  title?: string;
  colSize?: string;
  items: FieldLabel[];
}

interface DatafieldSectionProps {
  title?: ReactNode;
  className?: string;
  labels: DatafieldLabel[];
  data?: Record<string, any> | null;
  placeholder?: string;
  withinBox?: boolean;
  footer?: ReactNode;
}

export default function DatafieldSection({
  title,
  className,
  labels,
  data,
  placeholder = "-",
  withinBox = false,
  footer,
}: DatafieldSectionProps) {
  function renderFieldData(data: Record<string, any>, label: FieldLabel) {
    let fieldValue: string | number | React.ReactElement | undefined =
      undefined;
    let fieldItem = getProp(data, label.key);
    if (fieldItem && label.formatMethod) {
      fieldItem = label.formatMethod(fieldItem);
    }
    if (typeof fieldItem === "string" || typeof fieldItem === "number") {
      fieldValue = label.prefix ? `${label.prefix} ${fieldItem}` : fieldItem;
      if (label.suffix) {
        fieldValue = `${fieldValue} ${label.suffix}`;
      }
    } else if (React.isValidElement(fieldItem)) {
      fieldValue = fieldItem;
    }
    return fieldValue ?? placeholder;
  }
  const BodyElem = (
    <>
      {data && (
        <div className="row">
          {labels.map(({ items, title: columnTitle, colSize }, labelIndex) => (
            <div
              className={colSize ? `col-${colSize}` : "col"}
              key={labelIndex}
            >
              {columnTitle && <h5 className="mb-4">{columnTitle}</h5>}
              {items.map((eachItemLabel, index) => (
                <div
                  key={index}
                  className={`d-flex flex-row align-items-${
                    eachItemLabel.alignItem || "start"
                  }`}
                  style={{ marginBottom: "1rem" }}
                >
                  <span className="user-details-item-left text-thin">
                    {eachItemLabel.label}
                  </span>
                  {/* TODO: Fix responsiveness */}
                  <div
                    className="d-flex align-items-center"
                    style={{
                      maxWidth: "300px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    <span
                      className="user-details-item-right"
                      style={{ whiteSpace: eachItemLabel.wrap }}
                    >
                      {renderFieldData(data, eachItemLabel)}
                    </span>
                    {eachItemLabel.SuffixComponent && (
                      <div className="ml-md-2">
                        {eachItemLabel.SuffixComponent}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      {footer}
    </>
  );
  if (withinBox)
    return (
      <Box
        className={className}
        header={title && <h3 className="user-page-title">{title}</h3>}
      >
        {BodyElem}
      </Box>
    );
  return (
    <div className={className}>
      {title && <h3 className="user-page-title mb-4">{title}</h3>}
      {BodyElem}
    </div>
  );
}
