import { Actions } from "../actions/application.action";
import {
  EnachSubscriptionDto,
  RetailODApplicationDetails,
  SingleWCTLApplication,
  TermLoanApplicationDetails,
  UserApplicationsDetails,
} from "../dto";
import { ApplicationType, MonthlyAnalysis, SearchFilter } from "../models";
import { addToLoadingQueue, removeFromLoadingQueue } from "../utils";

export interface ApplicationState {
  loadingQueue: string[];
  applications: TermLoanApplicationDetails[] | RetailODApplicationDetails[];
  singleApplication: object | null;
  singleCommercialOdApplication: object | null;
  singleWCTLApplication: SingleWCTLApplication | null;
  userApplications: UserApplicationsDetails;
  searchFilters: Record<ApplicationType, SearchFilter[]>;
  paginationCount: {
    termLoanApplicationCount: number | null;
    retailOdApplicationCount: number | null;
  };
  count: number | null;
  docTypes: string[];
  docs: string[];
  eNachSubscription: EnachSubscriptionDto | null;
  monthlyAnalysis: MonthlyAnalysis | null;
  entityId: string | null;
}

const defaultUserApplications: UserApplicationsDetails = {
  retailODApplications: [],
  termLoanApplications: [],
};

const defaultState: ApplicationState = {
  loadingQueue: [],
  applications: [],
  singleApplication: null,
  singleCommercialOdApplication: null,
  singleWCTLApplication: null,
  userApplications: defaultUserApplications,
  searchFilters: {
    TERM_LOAN_APP: [],
    RETAIL_OD_APP: [],
    COMMERCIAL_OD_APP: [],
    WORKING_CAPITAL_TERM_LOAN_APP: [],
  },
  paginationCount: {
    termLoanApplicationCount: null,
    retailOdApplicationCount: null,
  },
  count: null,
  docTypes: [],
  docs: [],
  eNachSubscription: null,
  monthlyAnalysis: null,
  entityId: null,
};

export default function ApplicationStateReducer(
  state: ApplicationState = defaultState,
  action: any
): ApplicationState {
  switch (action.type) {
    case Actions.REQUEST_GET_PAGINATED_APPLICATIONS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_PAGINATED_APPLICATIONS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        applications: action?.payload,
      };
    case `${Actions.REQUEST_GET_PAGINATED_APPLICATIONS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        applications: [],
      };
    case Actions.REQUEST_GET_APPLICATION_BY_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_APPLICATION_BY_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleApplication: action?.payload,
      };
    case `${Actions.REQUEST_GET_APPLICATION_BY_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleApplication: null,
      };
    case Actions.REQUEST_ADD_TERM_LOAN_APPLICATION:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_ADD_TERM_LOAN_APPLICATION}_SUCCESS`: {
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    }
    case `${Actions.REQUEST_ADD_TERM_LOAN_APPLICATION}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case Actions.REQUEST_ADD_RETAIL_OD_APPLICATION:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_ADD_RETAIL_OD_APPLICATION}_SUCCESS`: {
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    }
    case `${Actions.REQUEST_ADD_RETAIL_OD_APPLICATION}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case Actions.REQUEST_UPDATE_APPLICATION_STATUS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_UPDATE_APPLICATION_STATUS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleApplication: action?.payload,
      };
    case `${Actions.REQUEST_UPDATE_APPLICATION_STATUS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    //TODO: Have to check API response
    case Actions.REQUEST_STORE_DOC_OF_APPLICATION:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    // TODO: DO something to store the doc link maybe
    case `${Actions.REQUEST_STORE_DOC_OF_APPLICATION}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_STORE_DOC_OF_APPLICATION}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case Actions.REQUEST_GET_DOC_OF_APPLICATION:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_DOC_OF_APPLICATION}_SUCCESS`: {
      const prevState = state;
      //TODO: FIx it after dto
      //   if (prevState.singleApplication) {
      //     prevState.singleApplication.documents = Array.isArray(action?.payload)
      //       ? action?.payload
      //       : [];
      //   }
      return {
        ...prevState,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    }
    case `${Actions.REQUEST_GET_DOC_OF_APPLICATION}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case Actions.REQUEST_GET_APPLICATIONS_BY_USER_ID:
    case Actions.REQUEST_GET_APPLICATIONS_BY_ENTERPRISE_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_APPLICATIONS_BY_USER_ID}_SUCCESS`:
    case `${Actions.REQUEST_GET_APPLICATIONS_BY_ENTERPRISE_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        userApplications: action?.payload,
      };
    case `${Actions.REQUEST_GET_APPLICATIONS_BY_USER_ID}_FAILURE`:
    case `${Actions.REQUEST_GET_APPLICATIONS_BY_ENTERPRISE_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        userApplications: defaultUserApplications,
      };
    case Actions.REQUEST_GET_APPLICATION_COUNT:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_APPLICATION_COUNT}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        count: action?.payload,
      };
    case `${Actions.REQUEST_GET_APPLICATION_COUNT}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        count: null,
      };
    case Actions.REQUEST_GET_APPLICATION_DOC_TYPES:
    case Actions.REQUEST_GET_APPLICATION_DOCS_BY_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_APPLICATION_DOC_TYPES}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        docTypes: action?.payload,
      };
    case `${Actions.REQUEST_GET_APPLICATION_DOC_TYPES}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        docTypes: [],
      };
    case `${Actions.REQUEST_GET_APPLICATION_DOCS_BY_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        docs: Array.isArray(action?.payload) ? action?.payload : [],
      };
    case `${Actions.REQUEST_GET_APPLICATION_DOCS_BY_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        docs: [],
      };
    case Actions.REQUEST_GET_APPLICATION_SEARCH_FILTERS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_APPLICATION_SEARCH_FILTERS}_SUCCESS`: {
      const { applicationType } = action?.metadata || {};
      if (!applicationType) return state;
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        searchFilters: Object.assign({}, state.searchFilters, {
          [applicationType]: action?.payload?.filters || [],
        }),
      };
    }
    case `${Actions.REQUEST_GET_APPLICATION_SEARCH_FILTERS}_FAILURE`: {
      const { applicationType } = action?.metadata || {};
      if (!applicationType) return state;
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        searchFilters: Object.assign({}, state.searchFilters, {
          [applicationType]: [],
        }),
      };
    }
    case Actions.REQUEST_GET_APPLICATIONS_BY_SEARCH:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_APPLICATIONS_BY_SEARCH}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        applications: action?.payload,
      };
    case `${Actions.REQUEST_GET_APPLICATIONS_BY_SEARCH}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        applications: [],
      };
    case Actions.REQUEST_GET_COMMERCIAL_OD_APPLICATIONS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_COMMERCIAL_OD_APPLICATIONS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        applications: action?.payload?.data,
      };
    case `${Actions.REQUEST_GET_COMMERCIAL_OD_APPLICATIONS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        applications: [],
      };
    case Actions.REQUEST_GET_COMMERCIAL_OD_APPLICATION_BY_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_COMMERCIAL_OD_APPLICATION_BY_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleCommercialOdApplication: action?.payload,
      };
    case `${Actions.REQUEST_GET_COMMERCIAL_OD_APPLICATION_BY_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case Actions.REQUEST_GET_APPLICATION_PAGINATION_COUNT:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_APPLICATION_PAGINATION_COUNT}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        paginationCount: {
          ...state.paginationCount,
          ...action?.payload,
        },
      };
    case `${Actions.REQUEST_GET_APPLICATION_PAGINATION_COUNT}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case Actions.REQUEST_UPDATE_TERM_LOAN_APPLICATION:
    case Actions.REQUEST_UPDATE_RETAIL_OD_APPLICATION:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_UPDATE_TERM_LOAN_APPLICATION}_SUCCESS`:
    case `${Actions.REQUEST_UPDATE_TERM_LOAN_APPLICATION}_FAILURE`:
    case `${Actions.REQUEST_UPDATE_RETAIL_OD_APPLICATION}_SUCCESS`:
    case `${Actions.REQUEST_UPDATE_RETAIL_OD_APPLICATION}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case Actions.REQUEST_GET_ENACH_BY_APPLICATION_ID:
    case Actions.REQUEST_INITIATE_ENACH_BY_APPLICATION_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_ENACH_BY_APPLICATION_ID}_SUCCESS`:
    case `${Actions.REQUEST_INITIATE_ENACH_BY_APPLICATION_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        eNachSubscription: action?.payload,
      };
    case `${Actions.REQUEST_GET_ENACH_BY_APPLICATION_ID}_FAILURE`:
    case `${Actions.REQUEST_INITIATE_ENACH_BY_APPLICATION_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        eNachSubscription: null,
      };
    case Actions.CLEAR_ENACH_STATE:
      return {
        ...state,
        eNachSubscription: null,
      };
    case Actions.REQUEST_BANK_STATEMENT_MONTHLY_ANALYSIS:
    case Actions.REQUEST_BANK_STATEMENT_PROCESS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_BANK_STATEMENT_MONTHLY_ANALYSIS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        monthlyAnalysis: action?.payload ?? null,
      };
    case `${Actions.REQUEST_BANK_STATEMENT_MONTHLY_ANALYSIS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        monthlyAnalysis: null,
      };
    case `${Actions.REQUEST_BANK_STATEMENT_PROCESS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        entityId: action?.payload?.entityId,
      };
    case `${Actions.REQUEST_BANK_STATEMENT_PROCESS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        entityId: null,
      };
    case Actions.REQUEST_GET_WCTL_APPLICATIONS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_WCTL_APPLICATIONS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        applications: action?.payload?.data,
      };
    case `${Actions.REQUEST_GET_WCTL_APPLICATIONS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        applications: [],
      };
    case Actions.REQUEST_GET_WCTL_APPLICATION_BY_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_WCTL_APPLICATION_BY_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleWCTLApplication: action?.payload ?? null,
      };
    case `${Actions.REQUEST_GET_WCTL_APPLICATION_BY_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleWCTLApplication: null,
      };
    default:
      return state;
  }
}
