import { ChangeEvent, FormEvent, InputHTMLAttributes, useState } from "react";
import { PinCodeDetails, UserDetails } from "../../../dto";
import { CompanyTypeEnum, EmploymentTypeEnum, UpdateUser, UserDocCategory, UserDocumentKey, UserWorkAddress } from "../../../models";
import { clearKycData, getDefaultBankAccount, getLatestWorkInfo, getWorkAddress } from "../helpers";
import dispatch from "../../../middleware";
import { useDispatch } from "react-redux";
import { clearFormData, dateInputToNumber, dateNumberToInputDate, splitKeyWithSpace, stringToNumber } from "../../../utils";
import { getUserById, updateUser } from "../../../actions/user.action";
import Button from "../../../components/button";
import Alert from "../../../components/alert";
import { ReactComponent as TickIcon } from "../../../images/tick.svg";
import { getCityAndStateByPin } from "../../../actions/user.action"
import { GroupInputField, GroupInputFieldsType, HorizontalInputField, HorizontalSelect, SelectOption } from "../../../components/input-functions";
import CustomRegex from "../../../constants/customRegex";

interface EditUserFormProps {
  data?: UserDetails;
  kycDocTypes: Record<UserDocCategory, SelectOption[]>
}

const emptyAddress = {
  houseInfo: "",
  localityInfo: "",
  pinCode: "",
  city: "",
  state: "",
};
const emptyBankInfo = {
  accountNumber: "",
  ifsc: "",
};

const kycInputProps: Record<
  UserDocumentKey,
  InputHTMLAttributes<HTMLInputElement>
> = {
  aadhaarNumber: {
    placeholder: "Enter Aadhar Number",
    minLength: 12,
    maxLength: 12,
    pattern: CustomRegex.AADHAAR_NUMBER,
    title: "Please enter a valid Aadhar Number like 270366373385",
  },
  passportNumber: {
    placeholder: "Enter Passport Number",
    minLength: 8,
    maxLength: 8,
    pattern: CustomRegex.PASSPORT_NUMBER,
    title: "Please enter a valid Passport Number like J5036277",
  },
  drivingLicenseNumber: {
    placeholder: "Enter Driving License Number",
    pattern: CustomRegex.DRIVING_LICENCE_REGEX,
    title: "Please enter a valid DL Number like KA-1920176702038",
  },
  voterIdNumber: {
    placeholder: "Enter Voter ID Card Number",
    minLength: 10,
    maxLength: 10,
    pattern: CustomRegex.VOTER_ID,
    title: "Please enter a valid Voter ID Number like YUG1234567",
  },
  pan: {
    minLength: 10,
    maxLength: 10,
    pattern: CustomRegex.PAN,
    title: "Please enter a valid PAN like ABCPE1234F",
    placeholder: "Enter PAN Card Number",
  },
};

export default function EditUserForm({ data, kycDocTypes }: EditUserFormProps) {
  const latestWorkInfo = getLatestWorkInfo(data?.workInfo);
  const defaultFormData = (): UpdateUser => ({
    userId: data?._id || "",
    address: data?.address?.data || emptyAddress,
    parentOrSpouseName: data?.parentOrSpouseName?.data || "",
    bankAccountInfo:
      getDefaultBankAccount(data?.bankAccountInfo)?.data || emptyBankInfo,
    dob: dateNumberToInputDate(data?.dob?.data),
    phone: data?.phone?.data.number || "",
    email: data?.email?.data || "",
    gender: data?.gender || "",
    pan: data?.pan?.data?.pan || "",
    aadhaarNumber: data?.aadhaarNumber?.data?.aadhaar || "",
    passportNumber: data?.passportNumber?.data?.passportNumber || "",
    drivingLicenseNumber: data?.drivingLicenseNumber?.data?.drivingLicenseNumber || "",
    voterIdNumber: data?.voterIdNumber?.data?.voterIdNumber || "",  
    workInfo: {
      employmentType: latestWorkInfo?.data?.employmentType || "",
      employerName: latestWorkInfo?.data?.employerName || "",
      companyType: latestWorkInfo?.data?.companyType || "",
      designation: latestWorkInfo?.data?.designation || "",
      currentMonthlySalary: latestWorkInfo?.data?.currentMonthlySalary,
      workEmail: latestWorkInfo?.data?.workEmail || "",
      workMobile: latestWorkInfo?.data?.workMobile || "",
      workAddress: getWorkAddress(latestWorkInfo?.data?.workAddress),
    }  
  });

  const defaultKycSelectItem = (): Record<"id" | "address", UserDocumentKey | ""> => ({
    id: data?.identityProofType || "", 
    address: data?.addressProofType || ""
  })

  const [formData, setFormData] = useState<UpdateUser>(defaultFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [pinDataLoading, setPinDataLoading] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState(defaultKycSelectItem);
  const storeDispatch = useDispatch();

  async function fetchPinData(pinCode: string, key: "address" | "workAddress" = "address") {
    if (isNaN(Number(pinCode)) || pinCode.length !== 6) return;
    setPinDataLoading(true);
    try {
      const pinData: PinCodeDetails = await dispatch(
        storeDispatch,
        getCityAndStateByPin(pinCode)
      );
      if (key === "workAddress") {
        setFormData((prevState) => ({
          ...prevState,
          workInfo: {
            ...prevState.workInfo,
            workAddress: {
              ...prevState.workInfo.workAddress,
              city: pinData[pinCode]?.city || "",
              state: pinData[pinCode]?.state || "",
            },
          }
        }));
        return;
      }
      setFormData((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          city: pinData[pinCode]?.city || "",
          state: pinData[pinCode]?.state || "",
        },
      }));
    } catch {
      // TODO: do something with the error later
    } finally {
      setPinDataLoading(false);
    }
  }
  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  }
  function handleWorkInfoChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      workInfo: {
        ...prevFormData.workInfo,
        [e.target.name]: e.target.value,
      }
    }));
  }
  function handleAddressChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.name === "pinCode") {
      fetchPinData(e.target.value);
    }
    setFormData((prevFormData) => {
      prevFormData.address[
        e.target.name as
          | "houseInfo"
          | "localityInfo"
          | "pinCode"
          | "city"
          | "state"
      ] = e.target.value;
      return { ...prevFormData };
    });
  }
  function handleWorkAddressChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.name === "pinCode") {
      fetchPinData(e.target.value, "workAddress");
    }
    setFormData((prevState) => {
      const tempFormData = { ...prevState };
      tempFormData.workInfo.workAddress[e.target.name as keyof UserWorkAddress] =
        e.target.value;
      return tempFormData;
    });
  }
  function handleBankInfoChange(e: ChangeEvent<HTMLInputElement>) {
    setFormData((prevFormData) => {
      prevFormData.bankAccountInfo[e.target.name as "accountNumber" | "ifsc"] =
        e.target.value;
      return { ...prevFormData };
    });
  }

  function handleDocTypeChange(e: ChangeEvent<HTMLSelectElement>) {
    setSelectedDocType((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }
 
  function renderKycInput(docKey?: UserDocumentKey | ""): GroupInputFieldsType {
    if (!docKey) return [{}];
    return [{
      element: "input",
      name: docKey,
      value: formData[docKey],
      onChange: handleChange,
      ...kycInputProps[docKey],
      required: true,
    }];
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
      e.preventDefault();
      try {
        setIsLoading(true);
        const dobAsNumber = dateInputToNumber(formData.dob);
        const { workInfo } = formData;
        workInfo.currentMonthlySalary = stringToNumber(workInfo.currentMonthlySalary);
        const clearedFormData = clearFormData({ ...formData, dob: dobAsNumber, workInfo });
        const formDatawithClearedKyc = clearKycData(clearedFormData, selectedDocType);
        const data: UserDetails = await dispatch(storeDispatch, updateUser(formDatawithClearedKyc));
        // Need to re-fetch user data as PATCH response might be incomplete
        // As the PATCH was successfull, GET should succeed also
        // Leaving the catch blank just to mitigate unhandled promise rejection
        if (formData.userId) {
          dispatch(storeDispatch, getUserById(formData.userId)).catch(() => {});
        }
        setSuccessMessage(
          `User details of ${data?.name?.data} # ${data?._id} updated successfully`
        );
      }  catch (error) {
        setErrorMessage(error.message || "Something went wrong, Try again later");
      } finally {
        setIsLoading(false);
      }
  }
  if (successMessage) {
    return (
      <div className="d-flex flex-column align-items-center">
        <TickIcon color="#5BC97C" />
        <p style={{ marginTop: "20px", marginBottom: "0" }}>{successMessage}</p>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <Alert
        type="danger"
        message={errorMessage}
        canDismiss={true}
        onDismissClick={() => setErrorMessage("")}
      />
      <HorizontalInputField
        label="User Id:"
        className="form-control-plaintext"
        name="userId"
        value={formData.userId}
        readOnly
      />
      <h3 className="user-page-title user-details-title mb-4">
        Personal details
      </h3>
      <HorizontalInputField
        label="Mobile No:"
        type="tel"
        name="phone"
        minLength={10}
        maxLength={10}
        placeholder="8978987898"
        value={formData.phone}
        onChange={handleChange}
        pattern={CustomRegex.INDIA_MOBILE_WITHOUT_CODE}
        title="Please enter a valid mobile number"
      />
      <HorizontalInputField
        label="Email:"
        type="email"
        name="email"
        placeholder="email@example.com"
        value={formData.email}
        onChange={handleChange}
      />
      <HorizontalInputField
        label="Father's Name:"
        type="text"
        name="parentOrSpouseName"
        placeholder="Enter Parent or Spouse Name"
        value={formData.parentOrSpouseName}
        onChange={handleChange}
        pattern={CustomRegex.STRING_WITH_SINGLE_SPACES_IN_BETWEEN}
        title="Please enter a valid name"
      />
      <HorizontalSelect
        label="Gender:"
        name="gender"
        value={formData.gender}
        onChange={handleChange}
        placeholder="Select the Gender"
        options={[
          { label: "Male", value: "MALE" },
          { label: "Female", value: "FEMALE" },
          { label: "Others", value: "OTHERS" },
        ]}
      />
      <HorizontalInputField
        label="Date of Birth:"
        type="date"
        className="form-control"
        name="dob"
        value={formData.dob}
        onChange={handleChange}
      />
      <div className="form-group row">
        <label htmlFor="address" className="col-sm-3 col-form-label">
          Address:
        </label>
        <div className="col-sm-9">
          <GroupInputField
            className="mb-3"
            onChange={handleAddressChange}
            fields={[
              {
                element: "input",
                placeholder: "PIN Code",
                name: "pinCode",
                minLength: 6,
                maxLength: 6,
                value: formData.address.pinCode,
                pattern: "[0-9]{6}",
                title: "Please enter a valid PIN Code",
              },
            ]}
          />
          <GroupInputField
            className="mb-3"
            onChange={handleAddressChange}
            fields={[
              {
                element: "input",
                placeholder: "House No",
                name: "houseInfo",
                value: formData.address.houseInfo,
              },
              {
                element: "input",
                placeholder: "Locality",
                name: "localityInfo",
                value: formData.address.localityInfo,
              },
            ]}
          />
          <GroupInputField
            className="mb-3"
            onChange={handleAddressChange}
            fields={[
              {
                element: "input",
                placeholder: "City",
                name: "city",
                value: formData.address.city,
                readOnly: pinDataLoading,
              },
              {
                element: "input",
                placeholder: "State",
                name: "state",
                value: formData.address.state,
                readOnly: pinDataLoading,
              },
              {
                element: "input",
                placeholder: "Country",
                // We support only India now, thus hardcoded
                value: "India",
                disabled: true,
              },
            ]}
          />
        </div>
      </div>
      <h3 className="user-page-title user-details-title mb-4">Bank details</h3>
      <HorizontalInputField
        label="Account No:"
        name="accountNumber"
        minLength={9}
        maxLength={18}
        placeholder="Enter bank account number"
        value={formData.bankAccountInfo.accountNumber}
        onChange={handleBankInfoChange}
        pattern={CustomRegex.BANK_ACCOUNT_NUMBER}
        title="Please enter a valid bank account number like 123456789"
      />
      <HorizontalInputField
        label="IFSC:"
        name="ifsc"
        minLength={11}
        maxLength={11}
        placeholder="Bank branch IFSC code"
        value={formData.bankAccountInfo.ifsc}
        onChange={handleBankInfoChange}
        pattern={CustomRegex.IFSC}
        title="Please enter a valid IFSC code like SBIN0011859"
      />
      <h3 className="user-page-title user-details-title mb-4">KYC Details</h3>
      <div className="form-group row">
        <label htmlFor="accountNumber" className="col-sm-3 col-form-label">
          ID Proof:
        </label>
        <div className="col-sm-9">
          <GroupInputField
            fields={[
              {
                element: "select",
                name: "id",
                placeholder: "Select an ID Proof Document",
                options: kycDocTypes.IDENTITY_PROOF,
                value: selectedDocType.id,
                onChange: handleDocTypeChange,
              },
              ...renderKycInput(selectedDocType.id),
            ]}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="accountNumber" className="col-sm-3 col-form-label">
          Address Proof:
        </label>
        <div className="col-sm-9">
          <GroupInputField
            fields={[
              {
                element: "select",
                name: "address",
                placeholder: "Select an Address Proof Document",
                options: kycDocTypes.ADDRESS_PROOF,
                value: selectedDocType.address,
                onChange: handleDocTypeChange,
              },
              ...renderKycInput(selectedDocType.address),
            ]}
          />
        </div>
      </div>
      <h3 className="user-page-title user-details-title mb-4">Work Informations</h3>
      <HorizontalSelect
        label="Employment Type:"
        name="employmentType"
        value={formData.workInfo.employmentType}
        onChange={handleWorkInfoChange}
        placeholder="Select Employment Type"
        options={Object.values(EmploymentTypeEnum).map(value => ({ label: splitKeyWithSpace(value), value }))}
      />
      <HorizontalInputField
        label="Employer Name:"
        type="text"
        name="employerName"
        placeholder="ABC Pvt Ltd"
        value={formData.workInfo.employerName}
        onChange={handleWorkInfoChange}
      />
      <HorizontalSelect
        label="Company Type:"
        name="companyType"
        value={formData.workInfo.companyType}
        onChange={handleWorkInfoChange}
        placeholder="Select Entity Type"
        options={Object.values(CompanyTypeEnum).map(value => ({ label: splitKeyWithSpace(value), value }))}
      />
      <HorizontalInputField
        label="Designation:"
        type="text"
        name="designation"
        placeholder="Enter Designation here"
        value={formData.workInfo.designation}
        onChange={handleWorkInfoChange}
        pattern="^[A-Za-z]+([ ]([A-Za-z]+))*$"
      />
      <HorizontalInputField
        label="Current Monthly Salary:"
        type="number"
        name="currentMonthlySalary"
        placeholder="Enter Current Monthly Salary salary here"
        value={formData.workInfo.currentMonthlySalary}
        onChange={handleWorkInfoChange}
        min={0}
      />
      <HorizontalInputField
        label="Work Email:"
        type="email"
        name="workEmail"
        placeholder="Enter Work Email here"
        value={formData.workInfo.workEmail}
        onChange={handleWorkInfoChange}
      />
      <HorizontalInputField
        label="Work Phone:"
        type="tel"
        name="workMobile"
        placeholder="Enter Work Phone number here"
        value={formData.workInfo.workMobile}
        onChange={handleWorkInfoChange}
        pattern="^([6-9]{1})([0-9]{9})$"
        title="Please enter a valid mobile number"
      />
      <div className="form-group row">
        <label htmlFor="address" className="col-sm-3 col-form-label">
          Work Address:
        </label>
        <div className="col-sm-9">
          <GroupInputField
            className="mb-3"
            onChange={handleWorkAddressChange}
            fields={[
              {
                element: "input",
                placeholder: "PIN Code",
                name: "pinCode",
                minLength: 6,
                maxLength: 6,
                value: formData.workInfo.workAddress.pinCode,
                pattern: "[0-9]{6}",
                title: "Please enter a valid PIN Code",
              },
            ]}
          />
          <GroupInputField
            className="mb-3"
            onChange={handleWorkAddressChange}
            fields={[
              {
                element: "input",
                placeholder: "Address Line",
                name: "addressLine",
                value: formData.workInfo.workAddress.addressLine,
              },
            ]}
          />
          <GroupInputField
            className="mb-3"
            onChange={handleWorkAddressChange}
            fields={[
              {
                element: "input",
                placeholder: "City",
                name: "city",
                value: formData.workInfo.workAddress.city,
                readOnly: pinDataLoading,
              },
              {
                element: "input",
                placeholder: "State",
                name: "state",
                value: formData.workInfo.workAddress.state,
                readOnly: pinDataLoading,
              },
              {
                element: "input",
                placeholder: "Country",
                // We support only India now, thus hardcoded
                value: "India",
                disabled: true,
              },
            ]}
          />
        </div>
      </div>
      <Button loading={isLoading} disabled={pinDataLoading} type="submit">
        Update
      </Button>
    </form>
  );
}
