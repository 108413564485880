import { InvoiceSearchDto } from "../dto";
import { MiddlewareAction } from "../middleware";

export enum Actions {
  REQUEST_GET_INVOICES = "REQUEST_GET_INVOICES",
  REQUEST_UPLOAD_INVOICE_BY_INVOICE_ID = "REQUEST_UPLOAD_INVOICE_BY_INVOICE_ID",
  REQUEST_DOWNLOAD_INVOICE_BY_INVOICE_ID = "REQUEST_DOWNLOAD_INVOICE_BY_INVOICE_ID",
}

export function getInvoices(queryParams: InvoiceSearchDto): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_INVOICES,
    type: "GET",
    url: "/invoices",
    queryParams: { ...queryParams },
  };
}

export function uploadInvoiceByInvoiceId(
  invoiceId: string,
  body: {
    doc: string;
  }
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_UPLOAD_INVOICE_BY_INVOICE_ID,
    type: "POST",
    url: `/invoices/${invoiceId}/doc`,
    body,
  };
}

export function downloadInvoiceByInvoiceId(
  invoiceId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_DOWNLOAD_INVOICE_BY_INVOICE_ID,
    type: "GET",
    url: `/invoices/${invoiceId}/doc`,
  };
}
