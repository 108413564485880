import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getApplicationById,
  getApplicationDocsById,
} from "../../actions/application.action";
import { getEnterpriseById } from "../../actions/enterprise.action";
import { getUserById } from "../../actions/user.action";
import {
  RetailODApplicationDetails,
  TermLoanApplicationDetails,
  UserDetails,
} from "../../dto";
import { EnterpriseDetails } from "../../dto/enterprise.dto";
import useScreenName from "../../hooks/useScreenName";
import dispatch from "../../middleware";
import { ApplicantType, BaseApplication } from "../../models";
import { ReduxState } from "../../reducers";
import ApplicationCard from "./applicationCard";
import TermLoanStatusChangeCard from "./statusChangeCard";
import ENachSummary from "./eNachSummary";
import { getUserSelfieDataUrl, isLoadingActive } from "../../utils";
import UserCreditReport from "../user-details/individual/credit-report";
import DocumentList from "./documentList";
import BankStatements from "../bank-statements";
import { BANK_STATEMENT, USER_ID_MISSING_ERROR_MESSAGE } from "../../constants";
import ApplicationDetails from "./applicationDetails";

export default function ApplicationDetailsScreen() {
  useScreenName("Applications");
  const { appId }: { appId: string } = useParams();
  const storeDispatch = useDispatch();
  const applicationDetails:
    | TermLoanApplicationDetails
    | RetailODApplicationDetails
    | null = useSelector(
    (state: ReduxState) => state.application.singleApplication
  );
  const loadingQueue = useSelector(
    (state: ReduxState) => state.application.loadingQueue
  );
  const [userDetails, setUserDetails] = useState<
    UserDetails | EnterpriseDetails | null
  >(null);
  const [selfieDataUrl, setSelfieDataUrl] = useState<string | null>(null);
  const applicationDocList = useSelector(
    (state: ReduxState) => state.application.docs
  );
  const userDocList = useSelector(
    (state: ReduxState) => state.user.singleUserDocList
  );
  const monthlyAnalysis = useSelector(
    (state: ReduxState) => state.application.monthlyAnalysis
  );
  const isBankStatementsExists = useMemo(
    () => applicationDocList.includes(BANK_STATEMENT),
    [applicationDocList]
  );
  const [errorMessage, setErrorMessage] = useState("");

  // INFO: hack to get value
  // TODO: change interface types
  const tempVal: Record<string, any> = { ...applicationDetails };
  const amount = tempVal?.maxAmount ?? tempVal?.approvedAmount ?? "0";

  async function fetchData(appId: string) {
    try {
      const resp: BaseApplication = await dispatch(
        storeDispatch,
        getApplicationById(appId)
      );
      const isEnterprise =
        resp.applicantType === ApplicantType.ENTERPRISE || !!resp.enterpriseId;
      if (isEnterprise) {
        if (!resp.enterpriseId) return;
        const enterprise: EnterpriseDetails = await dispatch(
          storeDispatch,
          getEnterpriseById(resp.enterpriseId)
        );
        setUserDetails(enterprise);
      } else {
        if (!resp.userId) return;
        const user: UserDetails = await dispatch(
          storeDispatch,
          getUserById(resp.userId)
        );
        setUserDetails(user);
        setUserSelfie(user._id);
      }
    } catch (e) {
      setErrorMessage(e?.message);
    }
  }

  async function setUserSelfie(userId: string) {
    const dataUrl = await getUserSelfieDataUrl(storeDispatch, userId);
    if (!dataUrl) return;
    setSelfieDataUrl(dataUrl);
  }

  useEffect(() => {
    fetchData(appId);
    dispatch(storeDispatch, getApplicationDocsById(appId));
    return () => {
      // TODO: Clear application state on REDUX
    };
  }, [appId]);
  return (
    <div className="row" style={{ margin: "0" }}>
      <div className="col-12 mb-4">
        <ApplicationCard
          isLoading={isLoadingActive(loadingQueue)}
          appId={appId}
          applicationDetails={applicationDetails}
          userDetails={userDetails}
          errorMessage={errorMessage}
          selfieDataUrl={selfieDataUrl}
        />
      </div>
      <div className="col-12 mb-4">
        <ApplicationDetails
          isLoading={isLoadingActive(loadingQueue)}
          applicationDetails={applicationDetails}
          errorMessage={errorMessage}
        />
      </div>
      {applicationDetails?.applicationType === "TERM_LOAN_APP" && (
        <div className="col-12 mb-4">
          <TermLoanStatusChangeCard
            applicationId={appId}
            status={applicationDetails?.status?.data}
            amount={amount}
          />
        </div>
      )}
      <div className="col-12 mb-4">
        <ENachSummary appId={appId} isLoading={isLoadingActive(loadingQueue)} />
      </div>
      {userDetails && (
        <div className="col-12 mb-4">
          <DocumentList
            appId={appId}
            userId={userDetails._id}
            applicationDocList={applicationDocList}
            userDocList={userDocList}
            isLoading={isLoadingActive(loadingQueue)}
          />
        </div>
      )}
      {applicationDetails?.applicationType === "TERM_LOAN_APP" &&
        userDetails && (
          <div className="col-12 mb-4">
            <h2 className="user-page-title mb-4">Credit Report</h2>
            {/* TODO: remove userDetails assertion */}
            <UserCreditReport userDetails={userDetails as UserDetails} />
          </div>
        )}
      <div className="col-12 mb-4">
        <h2 className="user-page-title mb-4">Bank Statements</h2>
        {userDetails?._id && (
          <BankStatements
            userId={userDetails._id}
            appId={appId}
            storeDispatch={storeDispatch}
            applicantType={
              applicationDetails?.applicantType ?? ApplicantType.INDIVIDUAL
            }
            isBankStatementsExists={isBankStatementsExists}
            loading={isLoadingActive(loadingQueue)}
            monthlyAnalysis={monthlyAnalysis}
          />
        )}
        {!userDetails?._id && (
          <h4 className="text-center my-4">{USER_ID_MISSING_ERROR_MESSAGE}</h4>
        )}
      </div>
    </div>
  );
}
