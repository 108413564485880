import React, {
  ChangeEvent,
  FunctionComponent,
  SVGProps,
  useState,
} from "react";
import "./styles.css";
import { ReactComponent as FilePdfIcon } from "../../images/file-pdf.svg";
import { ReactComponent as FileJpgIcon } from "../../images/file-jpg.svg";
import { ReactComponent as FilePngIcon } from "../../images/file-png.svg";
import { ReactComponent as FileTypeIcon } from "../../images/file.svg";
import { ReactComponent as FileLockIcon } from "../../images/file-lock.svg";
import Button from "../button";
import { InputField } from "../input-functions";
import { FileType, PasswordFile } from "../../models";

//TODO: fix type definition
const fileIcons: Partial<
  Record<FileType, FunctionComponent<SVGProps<SVGSVGElement>>>
> = {
  "application/pdf": FilePdfIcon,
  "image/jpeg": FileJpgIcon,
  "image/png": FilePngIcon,
};  
type FileCardProps = {
  title: string;
  subtitle: string;
  fileName?: string;
  type?: string;
  hasCloseIcon?: boolean;
  hasPassword?: boolean;
  onRemove?: Function;
  onPasswordInput?: ({ name, type, password }: PasswordFile) => void;
};

export default function FileCard({
  title,
  subtitle,
  fileName,
  onRemove,
  type = FileType.ANY,
  hasCloseIcon = true,
  hasPassword = false,
  onPasswordInput,
}: FileCardProps) {
  const [isPasswordFieldVisible, setIsPasswordFieldVisible] =
    useState<boolean>(false);
  //TODO: fix type assertion
  const FileIcon = fileIcons[type as FileType] ?? FileTypeIcon;
  function handleOnClick() {
    onRemove?.();
  }
  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    if (!fileName || !onPasswordInput) return;
    const password = event.currentTarget.value;
    if (!password) return;
    onPasswordInput({ name: fileName, type, password });
  }
  return (
    <div className="mb-3">
      <div className="file-card">
        <div className="file-card-type-icon">
          <FileIcon width={16} height={16} />
        </div>
        <div>
          <div className="file-card-title">{title}</div>
          <small className="file-card-subtitle">{subtitle}</small>
        </div>
        {hasCloseIcon && (
          <div className="ml-auto">
            <Button
              type="button"
              size="xs"
              iconOnly={true}
              variant="info"
              onClick={handleOnClick}
            >
              <span className="file-card-close-icon">&times;</span>
            </Button>
          </div>
        )}
      </div>
      {hasPassword && (
        <div className="d-flex justify-content-center">
          <Button
            variant="blue-text"
            size="xs"
            onClick={() => {
              setIsPasswordFieldVisible(true);
            }}
          >
            <FileLockIcon width={20} height={20} />
            <span>Is your file passworrd protected?</span>
          </Button>
        </div>
      )}
      {hasPassword && isPasswordFieldVisible && (
        <InputField
          type="text"
          label="Enter your file password"
          onChange={handlePasswordChange}
        />
      )}
    </div>
  );
}
