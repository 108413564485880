import {Actions} from "../actions/ui.action";

export interface UIState {
    screenName: string;
    toastDetails: {
        bgColor: string;
        msg: string;
    }
}

const defaultState: UIState = {
    screenName: "",
    toastDetails: {
        bgColor: "",
        msg: "",
    },
};

export default function UIStateReducer(state = defaultState, action: any) : UIState {
    switch (action.type) {
        case Actions.SET_SCREEN_NAME:
            return {
                ...state,
                screenName: action.payload.screenName,
            };
        case Actions.SHOW_NOTIFIER:
            return {
                ...state,
                toastDetails: action.payload,
            };
        case Actions.HIDE_NOTIFIER:
            return {
                ...state,
                toastDetails: {
                    bgColor: "",
                    msg: "",
                },
            };
        default:
            return state
    }
}