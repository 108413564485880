const CustomRegex = {
  BANK_ACCOUNT_NUMBER: "^[0-9]{9,18}$",
  IFSC: "^[A-Z]{4}0[A-Z0-9]{6}$",
  PAN: "^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
  PINCODE: "^[0-9]{6}$",
  EMAIL: "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$",
  INDIA_MOBILE_WITHOUT_CODE: "^([6-9]{1})([0-9]{9})$",
  DOB: "^((19|20)[0-9][0-9])(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$",
  DATE: "^((20)[0-9][0-9])(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$",
  VOTER_ID: "^([a-zA-Z]){3}([0-9]){7}?$",
  AADHAAR_NUMBER: "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
  PASSPORT_NUMBER: "^[A-PR-WYa-pr-wy][1-9][0-9]{5}[1-9]$",
  GST_NUMBER: "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
  CIN: "^[LU][0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$",
  STRING_WITH_SINGLE_SPACES_IN_BETWEEN: "^[A-Za-z]+([ ]([A-Za-z]+))*$",
  ADDRESS_LINE: "^[a-zA-Z0-9-.:,/ ]{1,200}$",
  DRIVING_LICENCE_REGEX: "^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$",
};

export default CustomRegex;
