import { Actions } from "../actions/agreement.action";
import { addToLoadingQueue, removeFromLoadingQueue } from "../utils";
import {
  Agreement,
  AgreementCount,
  AgreementFilter,
  AgreementSignOtpResponse,
} from "./../dto/agreement.dto";

export interface AgreementState {
  loadingQueue: string[];
  agreements: Array<Agreement>;
  filters: Array<AgreementFilter>;
  otpRequest: AgreementSignOtpResponse | null;
  count: AgreementCount | null;
  countToStatusMap: Record<string, AgreementCount | null>;
}

const DEFAULT_STATE: AgreementState = {
  loadingQueue: [],
  agreements: [],
  filters: [],
  otpRequest: null,
  count: null,
  countToStatusMap: {},
};

export default function AgreementStateReducer(
  state: AgreementState = DEFAULT_STATE,
  action: any
): AgreementState {
  switch (action.type) {
    case Actions.REQUEST_GET_AGREEMENTS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_AGREEMENTS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        agreements: action.payload,
      };
    case `${Actions.REQUEST_GET_AGREEMENTS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        agreements: [],
      };
    case Actions.REQUEST_GET_AGREEMENTS_COUNT:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_AGREEMENTS_COUNT}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        count: action.payload,
        countToStatusMap: Object.assign({}, state.countToStatusMap, {
          [action.metadata?.agreementType]: action.payload,
        }),
      };
    case `${Actions.REQUEST_GET_AGREEMENTS_COUNT}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        count: null,
      };
    case Actions.REQUEST_GET_AGREEMENT_FILTERS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_AGREEMENT_FILTERS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        filters: action.payload,
      };
    case `${Actions.REQUEST_GET_AGREEMENT_FILTERS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        filters: [],
      };
    case `${Actions.REQUEST_INITIATE_OTP_SIGNING}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        otpRequest: action.payload,
      };
    case `${Actions.REQUEST_INITIATE_OTP_SIGNING}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        otpRequest: null,
      };
    case Actions.REQUEST_GET_AGREEMENT_DOC_BY_ID:
    case Actions.REQUEST_SIGN_AGREEMENT:
    case Actions.REQUEST_BULK_SIGN_AGREEMENT:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_AGREEMENT_DOC_BY_ID}_SUCCESS`:
    case `${Actions.REQUEST_GET_AGREEMENT_DOC_BY_ID}_FAILURE`:
    case `${Actions.REQUEST_SIGN_AGREEMENT}_FAILURE`:
    case `${Actions.REQUEST_BULK_SIGN_AGREEMENT}_SUCCESS`:
    case `${Actions.REQUEST_BULK_SIGN_AGREEMENT}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_SIGN_AGREEMENT}_SUCCESS`:
    case `${Actions.REQUEST_BULK_SIGN_AGREEMENT}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        otpRequest: null,
      };
    default:
      return state;
  }
}
