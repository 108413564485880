import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getApplicationsBySearch,
  getApplicationSearchFilters,
  getCommercialOdApplicationsBySearch,
  getPaginationApplicationCount,
  getWCTLApplicationBySearch,
} from "../../actions/application.action";
import Pagination from "../../components/pagination";
import {
  CommercialODApplicationDetails,
  RetailODApplicationDetails,
  TermLoanApplicationDetails,
} from "../../dto";
import useScreenName from "../../hooks/useScreenName";
import dispatch from "../../middleware";
import { ReduxState } from "../../reducers";
import Table, { TableColumns } from "../../components/table";
import {
  ApplicationStatusType,
  ApplicationType,
  RoutePaths,
  SearchFilter,
} from "../../models";
import Badge from "../../components/badge";
import {
  clearFormData,
  dateToDDMMYYYY,
  downloadCSV,
  getPaginationCount,
  isLoadingActive,
  splitKeyWithSpace,
} from "../../utils";
import MultiSelect, {
  convertFilterArrayToObject,
  convertFilterObjectToArray,
  MultiSelectOption,
} from "../../components/multi-select";
import Tabs, { TabItem } from "../../components/tabs";
import useQueryParams from "../../hooks/useQueryParams";
import DateRange from "../../components/date-range";
import { SearchBox } from "../../components/input-functions";

const tabItems: TabItem<ApplicationType>[] = [
  {
    label: "Term Loan Application",
    value: "TERM_LOAN_APP",
  },
  {
    label: "Retail OD Application",
    value: "RETAIL_OD_APP",
  },
  {
    label: "Commercial OD Application",
    value: "COMMERCIAL_OD_APP",
  },
  {
    label: "WCTL",
    value: "WORKING_CAPITAL_TERM_LOAN_APP",
  },
];

const applicationTypeToRoutePathMap: Record<ApplicationType, RoutePaths> = {
  COMMERCIAL_OD_APP: RoutePaths.COMMERCIAL_LOAN_APPLICATION,
  WORKING_CAPITAL_TERM_LOAN_APP: RoutePaths.WCTL_LOAN_APPLICATION,
  RETAIL_OD_APP: RoutePaths.APPLICATIONS,
  TERM_LOAN_APP: RoutePaths.APPLICATIONS,
};

// Need to render `approvedAmount` field only for TERM_LOAN, thus used the conditional spreading
function getColumns(type: ApplicationType): TableColumns[] {
  if (type === "COMMERCIAL_OD_APP") {
    return [
      { field: "_id", label: "Application ID" },
      { field: "name", label: "Name" },
      { field: "createdAt", label: "Date Of Application" },
      { field: "irpa", label: "IRPA" },
      { field: "pf", label: "PF" },
      { field: "sanctionedAmount", label: "Sanctioned Amount" },
      { field: "status", label: "Status" },
    ];
  }

  if (type === "WORKING_CAPITAL_TERM_LOAN_APP") {
    return [
      { field: "_id", label: "Application ID" },
      { field: "productId", label: "Product ID" },
      { field: "irpa", label: "IRPA" },
      { field: "pf", label: "PF" },
      {
        field: "approvedAmount",
        label: "Approved Amount",
      },
      { field: "moratoriumInMonths", label: "MIM" },
      { field: "status", label: "status" },
    ];
  }

  return [
    { field: "_id", label: "Application ID" },
    { field: "userName", label: "User Name" },
    { field: "employerName", label: "Employer Name" },
    { field: "createdAt", label: "Date Of Application" },
    ...(type === "TERM_LOAN_APP"
      ? [{ field: "approvedAmount", label: "Amount" }]
      : [{ field: "maxAmount", label: "Max Amount" }]),
    { field: "irpa", label: "IRPA" },
    { field: "pf", label: "PF" },
    ...(type === "TERM_LOAN_APP"
      ? [
          {
            field: "tenureInMonths",
            label: "Tenure",
            // INFO: `as any` is to resolve the TS issue
            // TODO: Figure out proper fix
            style: { textAlign: "center" as any },
          },
        ]
      : []),
    { field: "status", label: "Status" },
  ];
}

type SearchQueryType = {
  applicationType: ApplicationType;
  pageNo: number;
  searchText: string;
  numberOfEntitiesPerPage: number;
  status: string[];
  source: string[];
  startDate: string;
  endDate: string;
};

const DEFAULT_SEARCH_QUERY_STATE: SearchQueryType = {
  applicationType: "TERM_LOAN_APP",
  pageNo: 1,
  searchText: "",
  numberOfEntitiesPerPage: 10,
  status: [],
  source: [],
  startDate: "",
  endDate: "",
};

const APPLICATION_TYPE_TO_PAGINATION_COUNT_KEY_MAP: Record<
  Exclude<ApplicationType, "WORKING_CAPITAL_TERM_LOAN_APP">,
  string
> = {
  TERM_LOAN_APP: "termLoanApplicationCount",
  RETAIL_OD_APP: "retailOdApplicationCount",
  COMMERCIAL_OD_APP: "commercialOdApplicationCount",
};

export default function ApplicationsScreen() {
  useScreenName("Applications");
  const storeDispatch = useDispatch();

  const applications:
    | TermLoanApplicationDetails[]
    | RetailODApplicationDetails[] = useSelector(
    (state: ReduxState) => state.application.applications
  );
  const paginationCount: Record<string, number | null> = useSelector(
    (state: ReduxState) => state.application.paginationCount
  );
  const applicationSearchFilters: Record<ApplicationType, SearchFilter[]> =
    useSelector((state: ReduxState) => state.application.searchFilters);
  const loadingQueue = useSelector(
    (state: ReduxState) => state.application.loadingQueue
  );

  const [
    {
      applicationType,
      pageNo,
      searchText,
      numberOfEntitiesPerPage,
      status,
      source,
      startDate,
      endDate,
    },
    setSearchQuery,
  ] = useQueryParams<SearchQueryType>(DEFAULT_SEARCH_QUERY_STATE);

  const filterOptions = useMemo(() => {
    const filters = applicationSearchFilters[applicationType];
    if (!Array.isArray(filters)) return [];
    return filters.reduce(
      (accum, group) => [
        ...accum,
        ...group.values.map((value) => ({
          group: group.name,
          value,
          label: splitKeyWithSpace(value),
        })),
      ],
      [] as MultiSelectOption[]
    );
  }, [applicationSearchFilters, applicationType]);

  useEffect(() => {
    const filters = applicationSearchFilters[applicationType];
    if (!Array.isArray(filters)) return;
    if (filters.length > 0) return;
    dispatch(storeDispatch, getApplicationSearchFilters(applicationType));
  }, [applicationType]);

  useEffect(() => {
    if (applicationType === "COMMERCIAL_OD_APP") {
      dispatch(
        storeDispatch,
        getCommercialOdApplicationsBySearch(
          clearFormData({
            numberOfEntitiesPerPage,
            pageNo,
            searchText,
            applicationType,
            status,
            source,
            startDate,
            endDate,
          })
        )
      );
      return;
    }

    if (applicationType === "WORKING_CAPITAL_TERM_LOAN_APP") {
      dispatch(
        storeDispatch,
        getWCTLApplicationBySearch(
          clearFormData({
            numberOfEntitiesPerPage,
            pageNo,
            applicationType,
            status,
            source,
          })
        )
      );
      return;
    }

    dispatch(
      storeDispatch,
      getApplicationsBySearch(
        clearFormData({
          numberOfEntitiesPerPage,
          pageNo,
          searchText,
          applicationType,
          status,
          source,
          startDate,
          endDate,
        })
      )
    );
  }, [
    pageNo,
    searchText,
    applicationType,
    numberOfEntitiesPerPage,
    status,
    source,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (
      applicationType === "COMMERCIAL_OD_APP" ||
      applicationType === "WORKING_CAPITAL_TERM_LOAN_APP"
    )
      return;
    dispatch(
      storeDispatch,
      getPaginationApplicationCount(
        clearFormData({
          applicationType,
          searchText,
          status,
          source,
          startDate,
          endDate,
        })
      )
    );
  }, [applicationType, searchText, status, source, startDate, endDate]);

  function handleSearch(searchText: string) {
    setSearchQuery((prevState) => ({ ...prevState, searchText, pageNo: 1 }));
  }

  function handleFilterChanges(options: MultiSelectOption[]) {
    const filterObject = convertFilterArrayToObject(options);
    setSearchQuery((prevState) => ({
      ...prevState,
      ...{ status: [], source: [] },
      ...filterObject,
      pageNo: 1,
    }));
  }

  //INFO: empty string is allowed
  function handleDateChange(fieldName: string, val: string) {
    setSearchQuery((prevState) => ({ ...prevState, [fieldName]: val }));
  }

  function getStatusBadge(status: ApplicationStatusType) {
    if (status === "APPROVED") return <Badge type="success">Approved</Badge>;
    if (status === "REJECTED") return <Badge type="reject">Rejected</Badge>;
    return (
      <Badge type="pending" capitalize>
        {splitKeyWithSpace(status).toLowerCase()}
      </Badge>
    );
  }
  function getIdLink(path: RoutePaths, id?: string) {
    if (!id) return;
    return (
      <Link to={`${path}/${id}`} className="table-link">
        {id}
      </Link>
    );
  }
  function getEmployeeNameAsLink(userId?: string, userName?: string) {
    if (!userName || !userId) return "-";
    return (
      <Link to={`users/individual/${userId}`} className="table-link">
        {userName}
      </Link>
    );
  }

  function getTableData(
    applications: (TermLoanApplicationDetails | RetailODApplicationDetails)[]
  ) {
    const path = applicationTypeToRoutePathMap[applicationType];
    return applications.map(
      ({
        _id,
        user,
        createdAt,
        approvedAmount,
        maxAmount,
        productId,
        moratoriumInMonths,
        irpa,
        entitySnapshot,
        pf,
        source,
        tenureInMonths,
        status,
        sanctionedAmount,
      }: (
        | TermLoanApplicationDetails
        | RetailODApplicationDetails
        | CommercialODApplicationDetails
      ) & {
        [x: string]: any;
      }) => ({
        _id: getIdLink(path, _id),
        name: entitySnapshot?.snapshot?.name ?? "-",
        userName: getEmployeeNameAsLink(user?._id, user?.employeeName),
        employerName: user?.employerName ?? "-",
        createdAt: createdAt ? dateToDDMMYYYY(createdAt) : "-",
        maxAmount: maxAmount || "-",
        approvedAmount: approvedAmount || "-",
        productId,
        moratoriumInMonths,
        irpa,
        pf,
        tenureInMonths,
        source: splitKeyWithSpace(source) || "-",
        status: getStatusBadge(
          typeof status === "string" ? status : status.data
        ),
        sanctionedAmount: sanctionedAmount,
      })
    );
  }
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const rows = applications.map(
      (
        application: (
          | TermLoanApplicationDetails
          | RetailODApplicationDetails
        ) & {
          [x: string]: any;
        }
      ) => ({
        "User Id": application.user?._id || "NA",
        "User Name": application.user?.employeeName || "NA",
        Status: application.status?.data || "NA",
        Amount: application.approvedAmount || application.maxAmount,
        Tenure: application?.tenureInMonths || "NA",
        PF: application?.pf || "NA",
      })
    );
    //INFO: undefined is passed to create columns labels from rows keys
    downloadCSV(rows, undefined, "applications-report");
  }
  return (
    <>
      <div className="dashboard-container p-3">
        <Tabs
          items={tabItems}
          disable={isLoadingActive(loadingQueue)}
          value={applicationType}
          onChange={(value) => {
            setSearchQuery({
              ...DEFAULT_SEARCH_QUERY_STATE,
              applicationType: value,
            });
          }}
        />
      </div>
      <div className="dashboard-container">
        {applicationType === "COMMERCIAL_OD_APP" ||
        applicationType === "WORKING_CAPITAL_TERM_LOAN_APP" ? null : (
          <div className="d-flex flex-lg-row flex-column align-items-lg-end mb-4">
            <div className="search-input-user mr-lg-3 mb-4 mb-lg-0">
              <MultiSelect
                label="Filter"
                options={filterOptions}
                groupped
                value={convertFilterObjectToArray({ source, status })}
                onChange={handleFilterChanges}
              />
            </div>
            <SearchBox
              value={searchText}
              label="Search"
              placeholder="Search by User Name"
              onSubmit={handleSearch}
            />
            <form
              className="d-flex flex-lg-row flex-column align-items-lg-end"
              onSubmit={(e) => handleSubmit(e)}
            >
              <DateRange
                startDate={startDate}
                endDate={endDate}
                handleDateChange={handleDateChange}
              />
              <div className="col-md-auto px-0 px-lg-3">
                <button
                  type="submit"
                  className="rf-btn rf-btn-primary rf-btn-md border-0"
                  disabled={applications.length < 1}
                >
                  Download
                </button>
              </div>
            </form>
          </div>
        )}
        <Table
          loading={isLoadingActive(loadingQueue)}
          columns={getColumns(applicationType)}
          data={getTableData(applications)}
        />
      </div>
      <Pagination
        currentPage={pageNo}
        totalEntries={getPaginationCount(
          paginationCount[
            APPLICATION_TYPE_TO_PAGINATION_COUNT_KEY_MAP[
              applicationType as keyof typeof APPLICATION_TYPE_TO_PAGINATION_COUNT_KEY_MAP
            ]
          ],
          numberOfEntitiesPerPage
        )}
        numberOfEntriesPerPage={numberOfEntitiesPerPage}
        onPageChange={(pageNo) =>
          setSearchQuery((prevState) => ({
            ...prevState,
            pageNo,
          }))
        }
        onPerPageCountChange={(countPerPage) =>
          setSearchQuery((prevState) => ({
            ...prevState,
            numberOfEntitiesPerPage: countPerPage,
            pageNo: 1,
          }))
        }
      />
    </>
  );
}
