import DatafieldSection from "../../../components/datafield-section";
import { WCTLoanDetail } from "../../../dto";
import { FieldLabel, LoanType } from "../../../models";
import { splitKeyWithSpace } from "../../../utils";

interface WCTLoanSummaryProps {
  loanId: string;
  loanDetails: WCTLoanDetail;
}

const leftLabelsForLoanSummary: Record<
  Exclude<LoanType, "TERM_LOAN" | "RETAIL_OD" | "COMMERCIAL_OD">,
  FieldLabel[]
> = {
  WORKING_CAPITAL_TERM_LOAN: [
    { key: "loanType", label: "Loan Type :", formatMethod: splitKeyWithSpace },
    { key: "approvedAmount", label: "Approved Amount :" },
    { key: "irpa", label: "Interest Rate :", suffix: "% PA" },
    { key: "pf", label: "Processing Fee :", prefix: "Rs " },
    { key: "pfPostGst", label: "Processing Fee With GST :", prefix: "Rs " },
    { key: "tenureInMonths", label: "Tenure (Months) :" },
  ],
};

export default function WCTLoanSummary({
  loanDetails,
  loanId,
}: WCTLoanSummaryProps) {
  return (
    <div>
      <DatafieldSection
        title="Loan Summary"
        labels={[
          {
            items:
              leftLabelsForLoanSummary[
                loanDetails.loanType as keyof typeof leftLabelsForLoanSummary
              ],
          },
        ]}
        data={loanDetails}
      />
    </div>
  );
}
