import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllProducts } from "../../actions/product.action";
import Avatar from "../../components/avatar";
import Button from "../../components/button";
import { InputField, Range, Select } from "../../components/input-functions";
import {
  AllProducts,
  RetailODApplicationDetails,
  RetailODProduct,
  TermLoanApplicationDetails,
  TermLoanProduct,
  UserDetails,
} from "../../dto";
import dispatch from "../../middleware";
import {
  ApplicantType,
  NewODApplication,
  NewTermApplication,
} from "../../models";
import { ReduxState } from "../../reducers";
import {
  getDefaultBankAccount,
  getProductById,
  getProductByNameAndVersion,
  getProductNames,
  getProductTypeFromApplication,
  getProductVersions,
  isUserAnEnterprise,
} from "./helpers";
import { ReactComponent as TickIcon } from "../../images/tick.svg";
import Alert from "../../components/alert";
import {
  addReailODApplication,
  addTermLoanApplication,
  getApplicationByUserId,
  getApplicationByEnterpriseId,
  updateTermLoanApplication,
  updateReailODApplication,
  getApplicationById,
} from "../../actions/application.action";
import { clearFormData, splitKeyWithSpace, rangeToString } from "../../utils";
import DefaultUserAvatar from "../../images/default-user-avatar.svg";
import { EnterpriseDetails } from "../../dto/enterprise.dto";
import DatafieldSection, {
  DatafieldLabel,
} from "../../components/datafield-section";

function getInitialFormData(
  application?: TermLoanApplicationDetails | RetailODApplicationDetails | null
) {
  return {
    approvedAmount:
      (application as TermLoanApplicationDetails)?.approvedAmount || "",
    tenureInMonths:
      (application as TermLoanApplicationDetails)?.tenureInMonths || "",
    irpa: (application as TermLoanApplicationDetails)?.irpa || "",
    pf: (application as TermLoanApplicationDetails)?.pf || "",
    maxAmount: (application as RetailODApplicationDetails)?.maxAmount || "",
    maxValidMonths:
      (application as RetailODApplicationDetails)?.maxValidMonths || "",
    source: (application as RetailODApplicationDetails)?.source || "",
  };
}

interface CreateOrEditApplicationFormProps {
  selfieUrl: string | null;
  userDetails: UserDetails | EnterpriseDetails;
  applicationData?:
    | TermLoanApplicationDetails
    | RetailODApplicationDetails
    | null;
}

type ProductTypeKey = "termLoanProducts" | "retailOdProducts";

export default function CreateOrEditApplicationForm({
  selfieUrl,
  userDetails,
  applicationData,
}: CreateOrEditApplicationFormProps) {
  const isEditing = !!applicationData;

  const storeDispatch = useDispatch();
  const [productType, setProductType] = useState(
    () => getProductTypeFromApplication(applicationData) || ""
  );
  const [selectedProduct, setSelectedProduct] = useState({
    productName: "",
    productVersion: "",
  });
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState<{ [x: string]: any }>(() =>
    getInitialFormData(applicationData)
  );

  const applicantType = isUserAnEnterprise(userDetails._id)
    ? ApplicantType.ENTERPRISE
    : ApplicantType.INDIVIDUAL;

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const value = e.target.value;
    const inputMode = e.target.inputMode;
    if (inputMode === "numeric" && Number.isNaN(Number(value))) return;
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: value,
    }));
  }

  const lenderSources: string[] = useSelector(
    (state: ReduxState) => state.admin.lenderSources
  );
  const products: AllProducts = useSelector(
    (state: ReduxState) => state.product.products
  );
  const selectedProductDetails = useMemo(
    () =>
      getProductByNameAndVersion(
        products[productType as ProductTypeKey],
        selectedProduct.productName,
        selectedProduct.productVersion
      ),
    [productType, selectedProduct]
  );

  useEffect(() => {
    dispatch(storeDispatch, getAllProducts());
  }, []);

  useEffect(() => {
    if (isEditing) {
      const product = getProductById(
        products[productType as ProductTypeKey],
        applicationData?.productId
      );
      if (!product) return;
      setSelectedProduct({
        productName: product.productName,
        productVersion: String(product.productVersion),
      });
    }
  }, [products]);

  // This effect will make sure formData obj has initial state of product data when product selection is done
  // TODO: Separate formData state for each Products, right now they share same state
  useEffect(() => {
    if (!selectedProductDetails || isEditing) return;
    setFormData((prevFormData) => ({
      ...prevFormData,
      approvedAmount: selectedProductDetails.amountRange?.min || "",
      tenureInMonths:
        (selectedProductDetails as TermLoanProduct).tenureInMonthsRange?.min ||
        "",
      // Don't need to set default value other than `termLoanProducts`
      irpa:
        productType === "termLoanProducts"
          ? selectedProductDetails.irpaRange?.min || ""
          : "",
      pf:
        productType === "termLoanProducts"
          ? selectedProductDetails.pfAmountInRange?.min || ""
          : "",
    }));
  }, [selectedProduct]);

  async function handleSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    if (
      productType !== "termLoanProducts" &&
      productType !== "retailOdProducts"
    )
      return;
    try {
      setIsSubmitLoading(true);
      if (productType === "termLoanProducts") {
        const payload: NewTermApplication = {
          entityId: userDetails._id,
          productName: selectedProduct.productName,
          productVersion: parseInt(selectedProduct.productVersion),
          approvedAmount: parseInt(formData.approvedAmount),
          tenureInMonths: parseInt(formData.tenureInMonths),
          irpa: parseFloat(formData.irpa),
          pf: parseFloat(formData.pf),
          source: formData.source,
          applicantType,
        };
        if (!!applicationData) {
          const { approvedAmount, irpa, tenureInMonths, pf } = payload;
          await dispatch(
            storeDispatch,
            updateTermLoanApplication({
              appId: applicationData._id,
              approvedAmount,
              irpa,
              tenureInMonths,
              pf,
            })
          );
          setSuccessMessage(
            `Term Loan Application # ${applicationData._id} updated successfully`
          );
        } else {
          const response = await dispatch(
            storeDispatch,
            addTermLoanApplication(clearFormData(payload))
          );
          setSuccessMessage(
            `Term Loan Application # ${response?._id} created successfully`
          );
        }
      }
      if (productType === "retailOdProducts") {
        const payload: NewODApplication = {
          entityId: userDetails._id,
          productName: selectedProduct.productName,
          productVersion: parseInt(selectedProduct.productVersion),
          maxAmount: parseInt(formData.maxAmount),
          maxValidMonths: parseInt(formData.maxValidMonths),
          irpa: parseFloat(formData.irpa),
          pf: parseFloat(formData.pf),
          source: formData.source,
          applicantType,
        };
        if (!!applicationData) {
          const { maxAmount, irpa, maxValidMonths, pf } = payload;
          await dispatch(
            storeDispatch,
            updateReailODApplication({
              appId: applicationData._id,
              maxAmount,
              irpa,
              maxValidMonths,
              pf,
            })
          );
          setSuccessMessage(
            `Retail OD Application # ${applicationData._id} updated successfully`
          );
        } else {
          const response = await dispatch(
            storeDispatch,
            addReailODApplication(clearFormData(payload))
          );
          setSuccessMessage(
            `Retail OD Application # ${response?._id} created successfully`
          );
        }
      }
    } catch (error) {
      setErrorMessage(error.message || "Something went wrong, Try again later");
    } finally {
      if (!!applicationData) {
        dispatch(storeDispatch, getApplicationById(applicationData._id));
      } else {
        dispatch(
          storeDispatch,
          applicantType === ApplicantType.ENTERPRISE
            ? getApplicationByEnterpriseId(userDetails._id)
            : getApplicationByUserId(userDetails._id)
        );
      }
      setIsSubmitLoading(false);
    }
  }

  function handleProductChange(e: ChangeEvent<HTMLSelectElement>) {
    setSelectedProduct((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  const labels: DatafieldLabel[] = [
    {
      items: [
        { key: "name.data", label: "Name :" },
        { key: "_id", label: "User ID :" },
        {
          key:
            applicantType === ApplicantType.INDIVIDUAL
              ? "pan.data.pan"
              : "pan.data",
          label: "PAN :",
        },
        {
          key:
            applicantType === ApplicantType.INDIVIDUAL
              ? "bankAccountInfo"
              : "bankAccountsInfo",
          label: "Account Number :",
          formatMethod: (val) =>
            getDefaultBankAccount(val)?.data.accountNumber ?? "-",
        },
        {
          key:
            applicantType === ApplicantType.INDIVIDUAL
              ? "bankAccountInfo"
              : "bankAccountsInfo",
          label: "IFSC :",
          formatMethod: (val) => getDefaultBankAccount(val)?.data.ifsc ?? "-",
        },
      ],
    },
  ];

  function renderProductField(label: string, value?: string | number) {
    return (
      <p className="fs-14">
        <span className="text-thin">{label}</span>: {value}
      </p>
    );
  }
  function renderProductNameSelection(productType: ProductTypeKey) {
    if (!productType) return;
    return (
      <Select
        name="productName"
        className="mb-2"
        onChange={handleProductChange}
        placeholder="Select Product"
        value={selectedProduct.productName}
        options={getProductNames(products[productType]).map((product) => ({
          label: product,
          value: product,
        }))}
        disabled={isEditing}
      />
    );
  }
  function renderProductVersionSelection(
    productType: ProductTypeKey,
    productName?: string
  ) {
    if (!productType) return;
    if (!productName) return;
    return (
      <Select
        name="productVersion"
        onChange={handleProductChange}
        placeholder="Select Product Version"
        value={selectedProduct.productVersion}
        options={getProductVersions(products[productType], productName).map(
          (version) => ({
            label: version,
            value: version,
          })
        )}
        disabled={isEditing}
      />
    );
  }
  function renderNewApplicationForm(
    productType: ProductTypeKey,
    product: TermLoanProduct | RetailODProduct | null
  ) {
    if (!product) return;
    // Intialize the state with min value in form render
    const ButtonElem = (
      <Button type="submit" loading={isSubmitLoading}>
        {isEditing ? "Update" : "Create"} application
      </Button>
    );
    if (productType === "termLoanProducts") {
      return (
        <>
          <Range
            name="approvedAmount"
            label="Approve Amount :"
            {...(product as TermLoanProduct).amountRange}
            value={formData.approvedAmount}
            onChange={handleChange}
            required
          />
          <Range
            name="tenureInMonths"
            label="Tenure (months) :"
            {...(product as TermLoanProduct).tenureInMonthsRange}
            value={formData.tenureInMonths}
            onChange={handleChange}
            required
          />
          <Range
            name="irpa"
            label="Interest rate per annum (%) :"
            {...(product as TermLoanProduct).irpaRange}
            step="0.01"
            value={formData.irpa}
            onChange={handleChange}
            required
          />
          <Range
            name="pf"
            label="PF :"
            {...(product as TermLoanProduct).pfAmountInRange}
            step="0.01"
            value={formData.pf}
            onChange={handleChange}
            required
          />
          {ButtonElem}
        </>
      );
    }
    if (productType === "retailOdProducts") {
      return (
        <>
          <InputField
            label="Max Amount"
            type="text"
            inputMode="numeric"
            name="maxAmount"
            placeholder="Max Amount"
            {...(product as RetailODProduct).amountRange}
            value={formData.maxAmount}
            onChange={handleChange}
          />
          <InputField
            label="Max Validity (months)"
            type="text"
            inputMode="numeric"
            name="maxValidMonths"
            placeholder="Max Validity"
            min={1}
            max={(product as RetailODProduct).numberOfMonthsValid}
            value={formData.maxValidMonths}
            onChange={handleChange}
          />
          <InputField
            label="Interest rate per annum (%)"
            type="text"
            inputMode="numeric"
            name="irpa"
            placeholder="5.6"
            {...(product as RetailODProduct).irpaRange}
            step="0.01"
            value={formData.irpa}
            onChange={handleChange}
            required
          />
          <InputField
            label="PF (Rs)"
            type="text"
            inputMode="numeric"
            name="pf"
            placeholder="20.05"
            {...(product as RetailODProduct).pfAmountInRange}
            step="0.01"
            value={formData.pf}
            onChange={handleChange}
            required
          />
          {ButtonElem}
        </>
      );
    }
  }
  function renderProductDetails(
    productType: ProductTypeKey,
    product: TermLoanProduct | RetailODProduct | null
  ) {
    if (!product) return;
    if (productType === "termLoanProducts") {
      return (
        <>
          <h5 className="mt-3">{(product as TermLoanProduct).productName}</h5>
          {renderProductField("Type", (product as TermLoanProduct).productType)}
          {renderProductField(
            "Eligible Amount",
            rangeToString((product as TermLoanProduct).amountRange, {
              prefix: "Rs",
            })
          )}
          {renderProductField(
            "Rate of Interest (PA)",
            rangeToString((product as TermLoanProduct).irpaRange, {
              suffix: "%",
            })
          )}
          {renderProductField(
            "Term",
            rangeToString((product as TermLoanProduct).tenureInMonthsRange, {
              suffix: "months",
            })
          )}
          {renderProductField(
            "PF (Amount)",
            rangeToString((product as RetailODProduct).pfAmountInRange, {
              prefix: "Rs",
            })
          )}
        </>
      );
    }
    if (productType === "retailOdProducts") {
      return (
        <>
          <h5 className="mt-3">{(product as RetailODProduct).productName}</h5>
          {renderProductField("Type", (product as RetailODProduct).productType)}
          {renderProductField(
            "Maximum Valid (months)",
            (product as RetailODProduct).numberOfMonthsValid
          )}
          {renderProductField(
            "Eligible Amount",
            rangeToString((product as RetailODProduct).amountRange, {
              prefix: "Rs",
            })
          )}
          {renderProductField(
            "Rate of Interest",
            rangeToString((product as RetailODProduct).irpaRange, {
              suffix: "%",
            })
          )}
          {renderProductField(
            "PF (Amount)",
            rangeToString((product as RetailODProduct).pfAmountInRange, {
              prefix: "Rs",
            })
          )}
          {renderProductField(
            "PF (percentage)",
            rangeToString((product as RetailODProduct).pfAmountInRange, {
              suffix: "%",
            })
          )}
          {renderProductField(
            "Number of Months Valid",
            (product as RetailODProduct).numberOfMonthsValid
          )}
          {renderProductField(
            "Fee per Transaction (Amount)",
            rangeToString((product as RetailODProduct).feeAmountPerTxn, {
              prefix: "Rs",
            })
          )}
          {renderProductField(
            "Fee per Transaction (percentage)",
            rangeToString((product as RetailODProduct).feePctPerTxn, {
              suffix: "%",
            })
          )}
        </>
      );
    }
  }
  if (successMessage) {
    return (
      <div className="d-flex flex-column align-items-center">
        <TickIcon color="#5BC97C" />
        <p style={{ marginTop: "20px", marginBottom: "0" }}>{successMessage}</p>
      </div>
    );
  }
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="d-flex flex-row align-items-center user-avatar-section">
            <Avatar
              displayName={""}
              border="1px solid #fff"
              displayPhotoUrl={selfieUrl || DefaultUserAvatar}
              size={100}
            />
          </div>
          <DatafieldSection labels={labels} data={userDetails} />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-7">
          <form onSubmit={handleSubmit}>
            <Alert
              type="danger"
              message={errorMessage}
              canDismiss={true}
              onDismissClick={() => setErrorMessage("")}
            />
            <Select
              name="source"
              placeholder="Select the source"
              className="mb-2"
              options={lenderSources.map((source) => ({
                label: splitKeyWithSpace(source),
                value: source,
              }))}
              value={formData.source}
              onChange={handleChange}
              disabled={isEditing}
              required
            />
            <Select
              name="loanType"
              className="mb-2"
              onChange={(e) => setProductType(e.target.value)}
              placeholder="Select Loan Type"
              options={[
                { value: "termLoanProducts", label: "Term Loan" },
                { value: "retailOdProducts", label: "OD Loan" },
              ]}
              value={productType}
              disabled={isEditing}
            />
            {renderProductNameSelection(productType as ProductTypeKey)}
            {renderProductVersionSelection(
              productType as ProductTypeKey,
              selectedProduct.productName
            )}
            {renderNewApplicationForm(
              productType as ProductTypeKey,
              selectedProductDetails
            )}
          </form>
        </div>
        <div className="col-md-5">
          {renderProductDetails(
            productType as ProductTypeKey,
            selectedProductDetails
          )}
        </div>
      </div>
    </>
  );
}
