import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  clearEnachState,
  getEnachByApplicationId,
  initiateEnachByApplicationId,
} from "../../actions/application.action";
import Alert from "../../components/alert";
import Button from "../../components/button";
import DatafieldSection from "../../components/datafield-section";
import { EnachSubscriptionDto } from "../../dto";
import dispatch from "../../middleware";
import { ReduxState } from "../../reducers";
import { dateToDDMMYYYYHHMMAP, splitKeyWithSpace } from "../../utils";
import { ReactComponent as LoaderIcon } from "../../images/loader.svg";

function renderAnchor(url: any) {
  return (
    <a href={url} target="_black" rel="noopener noreferrer">
      {url}
    </a>
  );
}

interface ENachSummaryProps {
  isLoading: boolean;
  appId: string;
}

export default function ENachSummary({ isLoading, appId }: ENachSummaryProps) {
  const storeDispatch = useDispatch();
  const eNachSubscription: EnachSubscriptionDto = useSelector(
    (state: ReduxState) => state.application.eNachSubscription
  );

  const [errorMessage, setErrorMessage] = useState("");

  async function fetchEnachSubscription(appId: string) {
    try {
      await dispatch(storeDispatch, getEnachByApplicationId(appId));
    } catch (error) {
      setErrorMessage(error?.message);
    }
  }
  async function initiateEnachSubscription() {
    try {
      setErrorMessage("");
      await dispatch(storeDispatch, initiateEnachByApplicationId(appId));
    } catch (error) {
      setErrorMessage(error?.message);
    }
  }

  useEffect(() => {
    fetchEnachSubscription(appId);

    return () => {
      dispatch(storeDispatch, clearEnachState());
    };
  }, [appId]);

  if (isLoading) return <LoaderIcon />;

  if (!eNachSubscription) {
    return (
      <>
        <h2 className="user-page-title mb-4">eNACH Summary</h2>
        <div className="dashboard-container">
          <Alert
            canDismiss
            onDismissClick={() => setErrorMessage("")}
            type="danger"
            message={errorMessage}
          />
          <Button onClick={initiateEnachSubscription}>Initiate eNACH</Button>
        </div>
      </>
    );
  }

  return (
    <>
      <h2 className="user-page-title mb-4">eNACH Summary</h2>
      <div className="dashboard-container">
        <Alert
          canDismiss
          onDismissClick={() => setErrorMessage("")}
          type="danger"
          message={errorMessage}
        />
        <DatafieldSection
          labels={[
            {
              items: [
                {
                  key: "status",
                  label: "Status",
                  formatMethod: splitKeyWithSpace,
                },
                { key: "authLink", label: "Link", formatMethod: renderAnchor },
                {
                  key: "createdAt",
                  label: "Created At",
                  formatMethod: dateToDDMMYYYYHHMMAP,
                },
              ],
            },
          ]}
          data={eNachSubscription}
        />
      </div>
    </>
  );
}
