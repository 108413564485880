import { useEffect, useState } from "react";
import Table, { TableColumns } from "../../components/table";
import { useDispatch } from "react-redux";
import dispatch from "../../middleware";
import { getTermLoanRepaymentByLoanId } from "../../actions/loan.action";
import { useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import { dateToDDMMYYYY, isLoadingActive } from "../../utils";
import Modal from "../../components/modal";
import { TermLoanRepayments } from "../../dto";

interface TermLoanRepaymentProps {
  loanId: string;
}

const TERM_LOAN_REPAYMENT_TABLE_COLUMNS: TableColumns[] = [
  {
    label: "Repaid Date",
    field: "transactedAt",
    style: { textAlign: "center" },
  },
  { label: "Repaid Amount", field: "amount", style: { textAlign: "center" } },
];

function getTableDataForRepayment(
  termLoanRepayment: TermLoanRepayments[] | null
) {
  if (!termLoanRepayment) return [];

  return termLoanRepayment.map(({ _id, amount, transactedAt }) => ({
    _id,
    transactedAt: dateToDDMMYYYY(transactedAt),
    amount: amount.toFixed(2),
  }));
}

export default function TermLoanRepayment({ loanId }: TermLoanRepaymentProps) {
  const storeDispatch = useDispatch();
  const loadingQueue = useSelector(
    (state: ReduxState) => state.loan.loadingQueue
  );
  const termLoanRepayment: TermLoanRepayments[] | null = useSelector(
    (state: ReduxState) => state.loan.termLoanRepayments
  );
  const isLoading = isLoadingActive(loadingQueue);
  const [isErrorModalActive, setIsErrorModalActive] = useState<{
    active: boolean;
    title: string;
    message: string;
  }>({ active: false, title: "", message: "" });

  async function fetchTermLoanRepayment() {
    try {
      await dispatch(storeDispatch, getTermLoanRepaymentByLoanId(loanId));
    } catch (error: any) {
      setIsErrorModalActive({
        active: true,
        title: "Repayments",
        message: error?.message ?? "Unable to load repayments",
      });
    }
  }

  const tableDataForRepayment = getTableDataForRepayment(termLoanRepayment);

  useEffect(() => {
    fetchTermLoanRepayment();
  }, [loanId]);

  return (
    <section>
      <h3 className="user-page-title mb-4">Repayments</h3>
      <Table
        loading={isLoading}
        columns={TERM_LOAN_REPAYMENT_TABLE_COLUMNS}
        data={tableDataForRepayment}
      />
      <Modal
        open={isErrorModalActive.active}
        onClose={() =>
          setIsErrorModalActive((prevState) => ({
            ...prevState,
            active: false,
          }))
        }
        hasCancelButton={false}
        hasSubmitButton={false}
        children={<h4>{isErrorModalActive.message}</h4>}
      />
    </section>
  );
}
