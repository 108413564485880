import { NavLink } from "react-router-dom";
import { NavItemProps, SvgIconType } from "../../models";
import "./styles.css";

function renderIcon(Icon?: SvgIconType) {
  if (!Icon) return;
  return <Icon className="mr-3" />;
}

interface TabmenuItem extends Omit<NavItemProps, "path"> {
  path: string;
}

export default function Tabmenu({ items }: { items: TabmenuItem[] }) {
  return (
    <div className="rf-nav-tab-wrapper">
      <ul className="rf-nav-tab">
        {items.map(({ name, path, exact = false, Icon }, index) => (
          <li className="rf-nav-tab-item" key={index}>
            <NavLink
              to={path}
              className="rf-nav-tab-link d-flex flex-row align-items-center justify-content-center"
              exact={exact}
            >
              {renderIcon(Icon)}
              <span>{name}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
