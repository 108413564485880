import React, { ChangeEvent, FormEvent, useCallback, useState } from "react";
import { ReactComponent as TickIcon } from "../../../images/tick.svg";
import Alert from "../../../components/alert";
import { HorizontalInputField } from "../../../components/input-functions";
import Button from "../../../components/button";
import { calculateGST } from "../helpers";
import { CreateInvoiceDto, InvoiceResponse } from "../../../dto/invoice.dto";
import { createCommercialODInvoiceEntry } from "../../../actions/loan.action";
import dispatch from "../../../middleware";
import { useDispatch } from "react-redux";
import { GST_RATE_FOR_LOAN_PROCESSING_CHARGE } from "./constants";

interface CommercialODInvoiceProps {
  loanId: string;
}

interface FormDataType {
  interestCharge: number;
  penalCharge: number;
  processingCharge: number;
  processingChargeGst: number;
  totalProcessingCharge: number;
  transactionCharge: number;
  transactionChargeGst: number;
  totalTransactionCharge: number;
  escrowCharge: number;
  escrowChargeGst: number;
  totalEscrowCharge: number;
  totalCharge: number;
  totalGst: number;
  total: number;
}

const DEFAULT_FORM_DATA: FormDataType = {
  interestCharge: 0,
  penalCharge: 0,
  processingCharge: 0,
  processingChargeGst: 0,
  totalProcessingCharge: 0,
  transactionCharge: 0,
  transactionChargeGst: 0,
  totalTransactionCharge: 0,
  escrowCharge: 0,
  escrowChargeGst: 0,
  totalEscrowCharge: 0,
  totalCharge: 0,
  totalGst: 0,
  total: 0,
};

function calculateTotals(formData: FormDataType): FormDataType {
  const {
    processingCharge,
    transactionCharge,
    escrowCharge,
    interestCharge,
    penalCharge,
  } = formData;

  const processingChargeGst = calculateGST(
    processingCharge,
    GST_RATE_FOR_LOAN_PROCESSING_CHARGE
  );
  const transactionChargeGst = calculateGST(
    transactionCharge,
    GST_RATE_FOR_LOAN_PROCESSING_CHARGE
  );
  const escrowChargeGst = calculateGST(
    escrowCharge,
    GST_RATE_FOR_LOAN_PROCESSING_CHARGE
  );

  const totalProcessingCharge = processingCharge + processingChargeGst;
  const totalTransactionCharge = transactionCharge + transactionChargeGst;
  const totalEscrowCharge = escrowCharge + escrowChargeGst;
  const totalCharge =
    interestCharge +
    penalCharge +
    processingCharge +
    transactionCharge +
    escrowCharge;
  const totalGst = processingChargeGst + transactionChargeGst + escrowChargeGst;
  const total = totalCharge + totalGst;

  return {
    ...formData,
    processingChargeGst: Number(processingChargeGst.toFixed(2)),
    totalProcessingCharge: Number(totalProcessingCharge.toFixed(2)),
    transactionChargeGst: Number(transactionChargeGst.toFixed(2)),
    totalTransactionCharge: Number(totalTransactionCharge.toFixed(2)),
    escrowChargeGst: Number(escrowChargeGst.toFixed(2)),
    totalEscrowCharge: Number(totalEscrowCharge.toFixed(2)),
    totalCharge: Number(totalCharge.toFixed(2)),
    totalGst: Number(totalGst.toFixed(2)),
    total: Number(total.toFixed(2)),
  };
}

function createPayload(formData: FormDataType): CreateInvoiceDto {
  const {
    interestCharge,
    penalCharge,
    processingCharge,
    processingChargeGst,
    transactionCharge,
    transactionChargeGst,
    escrowCharge,
    escrowChargeGst,
  } = formData;

  return {
    interestCharge: { amount: interestCharge },
    penalCharge: { amount: penalCharge },
    transactionCharge: {
      amount: transactionCharge,
      gstOnAmount: transactionChargeGst,
    },
    escrowCharge: { amount: escrowCharge, gstOnAmount: escrowChargeGst },
    processingCharge: {
      amount: processingCharge,
      gstOnAmount: processingChargeGst,
    },
  };
}

export default function CommercialODInvoice({
  loanId,
}: CommercialODInvoiceProps) {
  const storeDisptach = useDispatch();
  const [formData, setFormData] = useState<FormDataType>(DEFAULT_FORM_DATA);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value, inputMode } = e.target;
      const numericValue = Number(value);

      if (inputMode === "numeric" && isNaN(numericValue)) return;

      const updatedFormData = {
        ...formData,
        [name]: numericValue,
      };

      const updatedFormDataWithTotals = calculateTotals(updatedFormData);
      setFormData(updatedFormDataWithTotals);
    },
    [formData]
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!loanId) return;
      const payload = createPayload(formData);
      const response: InvoiceResponse = await dispatch(
        storeDisptach,
        createCommercialODInvoiceEntry(loanId, { ...payload })
      );
      setSuccessMessage(
        `Invoice created successfully with Invoice ID # ${response._id}`
      );
    } catch (error) {
      setErrorMessage(
        error.message || "Something went wrong. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (successMessage) {
    return (
      <div className="d-flex flex-column align-items-center">
        <TickIcon color="#5BC97C" />
        <p style={{ marginTop: "20px", marginBottom: "0" }}>{successMessage}</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      {errorMessage && (
        <Alert
          type="danger"
          message={errorMessage}
          canDismiss
          onDismissClick={() => setErrorMessage(null)}
        />
      )}

      <HorizontalInputField
        label="Interest Charge:"
        type="text"
        inputMode="numeric"
        name="interestCharge"
        value={formData.interestCharge}
        onChange={handleChange}
        required
      />

      <HorizontalInputField
        label="Penal Charge:"
        type="text"
        inputMode="numeric"
        name="penalCharge"
        value={formData.penalCharge}
        onChange={handleChange}
        required
      />

      <HorizontalInputField
        label="Processing Charges:"
        type="text"
        inputMode="numeric"
        name="processingCharge"
        value={formData.processingCharge}
        onChange={handleChange}
        required
      />

      <HorizontalInputField
        label="Processing Charge GST:"
        type="text"
        inputMode="numeric"
        name="processingChargeGst"
        value={formData.processingChargeGst}
        disabled
        required
      />

      <HorizontalInputField
        label="Total Processing Charge:"
        type="text"
        inputMode="numeric"
        name="totalProcessingCharge"
        value={formData.totalProcessingCharge}
        disabled
        required
      />

      <HorizontalInputField
        label="Transaction Charge:"
        type="text"
        inputMode="numeric"
        name="transactionCharge"
        value={formData.transactionCharge}
        onChange={handleChange}
        required
      />

      <HorizontalInputField
        label="Transaction Charge GST:"
        type="text"
        inputMode="numeric"
        name="transactionChargeGst"
        value={formData.transactionChargeGst}
        disabled
        required
      />

      <HorizontalInputField
        label="Total Transaction Charge:"
        type="text"
        inputMode="numeric"
        name="totalTransactionCharge"
        value={formData.totalTransactionCharge}
        disabled
        required
      />

      <HorizontalInputField
        label="Escrow Charge:"
        type="text"
        inputMode="numeric"
        name="escrowCharge"
        value={formData.escrowCharge}
        onChange={handleChange}
        required
      />

      <HorizontalInputField
        label="Escrow Charge GST:"
        type="text"
        inputMode="numeric"
        name="escrowChargeGst"
        value={formData.escrowChargeGst}
        disabled
        required
      />

      <HorizontalInputField
        label="Total Escrow Charge:"
        type="text"
        inputMode="numeric"
        name="totalEscrowCharge"
        value={formData.totalEscrowCharge}
        disabled
        required
      />

      <HorizontalInputField
        label="Total Charge:"
        type="text"
        inputMode="numeric"
        name="totalCharge"
        value={formData.totalCharge}
        disabled
        required
      />

      <HorizontalInputField
        label="Total GST:"
        type="text"
        inputMode="numeric"
        name="totalGst"
        value={formData.totalGst}
        disabled
        required
      />

      <HorizontalInputField
        label="Total:"
        type="text"
        inputMode="numeric"
        name="total"
        value={formData.total}
        disabled
        required
      />

      <div className="mt-5 d-flex">
        <Button type="submit" loading={isLoading}>
          Create Invoice Entry
        </Button>
      </div>
    </form>
  );
}
