import {
  DocumentInfo,
  ReviewMetadata,
  UploadDocType,
  UserAddress,
} from "../models";

export enum EnterpriseType {
  COMPANY = "COMPANY",
  PARTNERSHIP = "PARTNERSHIP",
  HUF_BUSINESS = "HUF_BUSINESS",
  LIMITED_LIABILITY_PARTNERSHIP = "LIMITED_LIABILITY_PARTNERSHIP",
}

export enum EnterpriseNature {
  PRIVATE_LIMITED = "PRIVATE_LIMITED",
  PUBLIC_LIMITED = "PUBLIC_LIMITED",
  ONE_PERSON_COMPANY = "ONE_PERSON_COMPANY",
  GOVERNMENT = "GOVERNMENT",
  PUBLIC_SECTOR_UNDERTAKING = "PUBLIC_SECTOR_UNDERTAKING",
  NON_PROFIT_ORGANIZATION = "NON_PROFIT_ORGANIZATION",
}

export enum BankAccountType {
  CASHCREDIT_ACCOUNT = "CASHCREDIT_ACCOUNT",
  OVERDRAFT_ACCOUNT = "OVERDRAFT_ACCOUNT",
  CURRENT_ACCOUNT = "CURRENT_ACCOUNT",
}

export enum AssessmentAgencyEnum {
  EQUIFAX = "EQUIFAX",
  TRANSUNION = "TRANSUNION",
  CRIF = "CRIF",
  EXPERIAN = "EXPERIAN",
}

export enum EnterpriseBankAccountTypeEnum {
  ESCROW = "ESCROW",
  CURRENT = "CURRENT",
}

export enum BusinessCategoryEnum {
  MSME = "MSME",
  SME = "SME",
  MICRO = "MICRO",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  OTHERS = "OTHERS",
}

export enum IndustryTypeEnum {
  MANUFACTURING = "MANUFACTURING",
  DISTRIBUTION = "DISTRIBUTION",
  WHOLESALE = "WHOLESALE",
  TRADING = "TRADING",
  BROKING = "BROKING",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  IMPORTING = "IMPORTING",
  EXPORTING = "EXPORTING",
  AGRICULTURE = "AGRICULTURE",
  DEALERS = "DEALERS",
  OTHERS = "OTHERS",
}

export enum EnterpriseRelationshipTypeEnum {
  BUSINESS_ENTITY_REGISTERED_IN_INDIA = "BUSINESS_ENTITY_REGISTERED_IN_INDIA",
  RESIDENT_INDIAN_INDIVIDUAL = "RESIDENT_INDIAN_INDIVIDUAL",
  BUSINESS_ENTITY_REGISTERED_OUTSIDE_INDIA = "BUSINESS_ENTITY_REGISTERED_OUTSIDE_INDIA",
  FOREIGN_NON_RESIDENT_INDIAN_INDIVIDUAL = "FOREIGN_NON_RESIDENT_INDIAN_INDIVIDUAL",
}

export enum EnterpriseRelationshipEnum {
  SHAREHOLDER = "SHAREHOLDER",
  HOLDING_COMPANY = "HOLDING_COMPANY",
  SUBSIDIARY_COMPANY = "SUBSIDIARY_COMPANY",
  PROPRIETOR = "PROPRIETOR",
  PARTNER = "PARTNER",
  TRUSTEE = "TRUSTEE",
  PROMOTER_DIRECTOR = "PROMOTER_DIRECTOR",
  NOMINEE_DIRECTOR = "NOMINEE_DIRECTOR",
  INDEPENDENT_DIRECTOR = "INDEPENDENT_DIRECTOR",
  DIRECTOR_SINCE_RESIGNED = "DIRECTOR_SINCE_RESIGNED",
  INDIVIDUAL_MEMBER_OF_SHG = "INDIVIDUAL_MEMBER_OF_SHG",
  OTHER_DIRECTOR = "OTHER_DIRECTOR",
  OTHERS = "OTHERS",
  KARTA = "KARTA",
}

export enum LegalConstitutionEnum {
  PRIVATE_LIMITED = "PRIVATE_LIMITED",
  PUBLIC_LIMITED = "PUBLIC_LIMITED",
  BUSINESS_ENTITIES_CREATED_BY_STATUTE = "BUSINESS_ENTITIES_CREATED_BY_STATUTE",
  PROPRIETORSHIP = "PROPRIETORSHIP",
  PARTNERSHIP = "PARTNERSHIP",
  TRUST = "TRUST",
  HINDU_UNDIVIDED_FAMILY = "HINDU_UNDIVIDED_FAMILY",
  CO_OPERATIVE_SOCIETY = "CO_OPERATIVE_SOCIETY",
  ASSOCIATION_OF_PERSONS = "ASSOCIATION_OF_PERSONS",
  GOVERNMENT = "GOVERNMENT",
  SELF_HELP_GROUP = "SELF_HELP_GROUP",
}

export enum EnterpriseStatus {
  PENDING = "PENDING",
  VERIFIED = "VERIFIED",
  REJECTED = "REJECTED",
  EXPIRED = "EXPIRED",
}

export enum EnterpriseLocationTypeEnum {
  REGISTERED_OFFICE_REQUIRED = "REGISTERED_OFFICE_REQUIRED",
  BRANCH_OFFICE = "BRANCH_OFFICE",
  WAREHOUSE = "WAREHOUSE",
  FACTORY_ADDRESS = "FACTORY_ADDRESS",
  OTHERS = "OTHERS",
  MORTGAGE_PROPERTY_ADDRESS = "MORTGAGE_PROPERTY_ADDRESS",
}

export enum SingleEnterpriseApplicationStatus {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  IN_PROGRESS = "IN_PROGRESS",
}

interface EnterpriseAddress {
  localityInfo: string;
  locationType: EnterpriseLocationTypeEnum;
  pincode: string;
  state: string;
}

interface EnterpriseBankAccount {
  accountNumber: string;
  accountType: EnterpriseBankAccountTypeEnum;
  ifsc: string;
  primary: boolean;
}
export interface EnterpriseOfficeAddress {
  officeInfo: string;
  localityInfo: string;
  pinCode: string;
  city: string;
  state: string;
}

export interface EnterpriseBankDetails {
  bankAccountType: BankAccountType;
  accountNumber: string;
  ifsc: string;
}

export interface UpdateEnterpriseBankDetails {
  bankAccountType: BankAccountType | "";
  accountNumber: string;
  ifsc: string;
}

export interface CoApplicantAddress {
  houseInfo: string;
  localityInfo: string;
  pinCode: string;
  city: string;
  state: string;
}

export interface NewEnterpriseCoApplicant {
  coApplicantId?: string;
  name: string;
  fatherName: string;
  gender: string;
  designation: string;
  address: CoApplicantAddress;
  dob: string;
  pan: string;
  phone: string;
}

export interface NewEnterprise {
  name: string;
  phone: string;
  email: string;
  enterpriseType: EnterpriseType;
  enterpriseNature: EnterpriseNature;
  cin: string;
  incorporationDate: string;
  pan: string;
  gstNumber: string;
  officeAddress: EnterpriseOfficeAddress;
  bankAccountInfo: EnterpriseBankDetails;
  coApplicantsInfo: NewEnterpriseCoApplicant[];
}

export interface UpdateEnterprise {
  enterpriseId: string;
  name: string;
  phone: string;
  email: string;
  enterpriseType: EnterpriseType | "";
  enterpriseNature: EnterpriseNature | "";
  cin: string;
  incorporationDate: string;
  pan: string;
  gstNumber: string;
  officeAddress: EnterpriseOfficeAddress;
  bankAccountInfo: UpdateEnterpriseBankDetails;
  coApplicantsInfo: NewEnterpriseCoApplicant[];
}

export interface UpdateEnterpriseV1 {
  name: string;
  clientRefId: string;
  legalConstitution: LegalConstitutionEnum | "";
  businessCategory: BusinessCategoryEnum | "";
  industryType: IndustryTypeEnum | "";
  assessmentAgency: AssessmentAgencyEnum | "";
  pan: string;
  address: EnterpriseAddress;
  bankAccountInfo: {
    accountNumber: string;
    accountType: EnterpriseBankAccountTypeEnum;
    ifsc: string;
  };
  relationshipInfo: {
    relationType: EnterpriseRelationshipTypeEnum | string;
    relationship: EnterpriseRelationshipEnum | string;
    metadata: Record<string, any>;
  };
  virtualPaymentAccount?: {
    ifsc?: string;
    accountNumber?: string;
  };
  gstin: string;
}

export interface UpdateWCTLApplication {
  irpa: number;
  pf: number;
  approvedAmount: number;
  tenureInMonths: number;
  moratoriumInMonths: number;
}

export interface StoreEnterpriseDoc {
  file: string;
  documentName: string;
  enterpriseId: string;
  contentType: UploadDocType;
  documentType?: string;
  documentCategory?: string[];
}

export interface EnterpriseCoApplicant {
  _id: string;
  name: {
    data: string;
    reviewMetadata: ReviewMetadata;
  };
  fatherName: {
    data: string;
    reviewMetadata: ReviewMetadata;
  };
  gender: {
    data: string;
    reviewMetadata: ReviewMetadata;
  };
  designation: {
    data: string;
    reviewMetadata: ReviewMetadata;
  };
  address: UserAddress;
  dob: {
    data: string;
    reviewMetadata: ReviewMetadata;
  };
  pan: {
    data: string;
    reviewMetadata: ReviewMetadata;
  };
  phone: {
    data: {
      number: string;
      countryCode: string;
    };
    reviewMetadata: ReviewMetadata;
  };
}

export interface EnterpriseDetailsV1 {
  _id: string;
  address: EnterpriseAddress;
  assessmentAgency: AssessmentAgencyEnum;
  bankAccountInfo: {
    accountNumber: string;
    accountType: EnterpriseBankAccountTypeEnum;
    ifsc: string;
  };
  businessCategory: BusinessCategoryEnum;
  clientId: string;
  createdAt: Date;
  industryType: IndustryTypeEnum;
  legalConstitution: LegalConstitutionEnum;
  status: EnterpriseStatus;
  pan: string;
  lenderId: string;
  name: string;
  relationshipInfo: {
    relationType: EnterpriseRelationshipTypeEnum;
    relationship: EnterpriseRelationshipEnum;
    metadata: object;
  };
  virtualPaymentAccount?: {
    ifsc?: string;
    accountNumber?: string;
  };
  gstin: string;
}

export interface SingleEnterpriseV1Loans {
  loans: [
    {
      _id: string;
      irpa: number;
      sanctionedAmount: number;
    }
  ];
}

export interface SingleEnterpriseV1Applications {
  applications: [
    {
      _id: string;
      status: SingleEnterpriseApplicationStatus;
      sanctionedAmount: number;
    }
  ];
}

export interface EnterpriseDetails {
  _id: string;
  name?: {
    data: string;
    reviewMetadata: ReviewMetadata;
  };
  phone?: {
    data: {
      number: string;
      countryCode: string;
    };
    reviewMetadata: ReviewMetadata;
  };
  email?: {
    data: string;
    reviewMetadata: ReviewMetadata;
  };
  documents?: DocumentInfo[];
  lenderId?: string;
  status: {
    data: EnterpriseStatus;
    comment?: string;
    updatedAt?: string;
  };
  enterpriseType?: EnterpriseType;
  enterpriseNature?: EnterpriseNature;
  cin?: {
    data: string;
    reviewMetadata: ReviewMetadata;
  };
  incorporationDate?: {
    data: string;
    reviewMetadata: ReviewMetadata;
  };
  pan?: {
    data: string;
    reviewMetadata: ReviewMetadata;
  };
  gstNumber?: {
    data: string;
    reviewMetadata: ReviewMetadata;
  };
  officeAddress?: {
    data: EnterpriseOfficeAddress;
    reviewMetadata: ReviewMetadata;
  };
  bankAccountsInfo?: Array<{
    data: EnterpriseBankDetails & { primary: boolean };
    reviewMetadata: ReviewMetadata;
  }>;
  coApplicantsInfo?: Array<EnterpriseCoApplicant & { _id: string }>;
  createdAt: string;
  updatedAt: string;
}

export interface EnterpriseSearchFilterDto {
  searchText?: string;
  status?: string[];
}

export interface EnterpriseSearchDto extends EnterpriseSearchFilterDto {
  pageNo: number;
  numberOfEntitiesPerPage: number;
}
