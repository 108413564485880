import { MiddlewareAction } from "../middleware";
import { AddNewProduct } from "../models";

export enum Actions {
  REQUEST_GET_ALL_PRODUCTS = "REQUEST_GET_ALL_PRODUCTS",
  REQUEST_GET_PRODUCT_BY_NAME_AND_VERSION = "REQUEST_GET_PRODUCT_BY_NAME_AND_VERSION",
  REQUEST_ADD_PRODUCT = "REQUEST_ADD_PRODUCT"
}

export function getAllProducts(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_ALL_PRODUCTS,
    type: "GET",
    url: "/product/all-products",
  };
}

export function getProductsByNameAndVersion(
  productName: string,
  productVersion: number
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_PRODUCT_BY_NAME_AND_VERSION,
    type: "GET",
    url: `/product?productName=${productName}&productVersion=${productVersion}`,
  };
}

export function addProduct(addProductObj: AddNewProduct): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_PRODUCT,
    type: "POST",
    url: "/product",
    body: addProductObj
  }
}
