import { ReactNode, CSSProperties, useState, useMemo } from "react";
import { ReactComponent as LoaderIcon } from "../../images/loader.svg";
import Button from "../button";
import { Checkbox } from "../checkbox";
import CheckboxGroup from "../checkbox/checkbox-group";
import "./tableStyles.css";

export interface TableColumns {
  field: string;
  label: string;
  style?: CSSProperties;
}

//TODO: add rows type <T>
export interface TableProps {
  loading?: boolean;
  hasRowSelect?: boolean;
  columns: TableColumns[];
  data: any[];
  selectedItems?: string[];
  keyField?: string;
  children?: ReactNode;
  rowsHiddenFromBottom?: number;
  parentClass?: string;
  isRowCheckable?: (record: object) => boolean;
  onSelectChange?: (newState: Array<any>) => void;
}

function getNotDataFoundText(colSpan: number) {
  return (
    <tr>
      <td colSpan={colSpan} style={{ textAlign: "center" }}>
        <h5>No data found</h5>
      </td>
    </tr>
  );
}

function getLoadingIcon(colSpan: number) {
  return (
    <tr>
      <td colSpan={colSpan} style={{ textAlign: "center" }}>
        <LoaderIcon />
      </td>
    </tr>
  );
}

function renderDataRow(columns: TableColumns[], data: any) {
  return columns.map((column) => (
    <td key={column.field} className="align-middle" style={column.style}>
      {data[column.field]}
    </td>
  ));
}

export default function Table({
  hasRowSelect = false,
  loading = false,
  columns,
  data,
  keyField,
  rowsHiddenFromBottom = 0,
  parentClass = "",
  selectedItems = [],
  isRowCheckable,
  onSelectChange,
}: TableProps) {
  const [isSomeRowsAreHidden, setIsSomeRowsAreHidden] = useState<boolean>(true);
  const hasHiddenRows = rowsHiddenFromBottom > 0;
  const filteredData = useMemo(() => {
    if (
      !isSomeRowsAreHidden ||
      rowsHiddenFromBottom <= 0 ||
      rowsHiddenFromBottom > data.length
    )
      return data;
    return data.slice(0, rowsHiddenFromBottom);
  }, [rowsHiddenFromBottom, data, isSomeRowsAreHidden]);

  function handleSelectAllRows() {
    if (!!selectedItems.length) return onSelectChange?.([]);
    const items = data
      .filter((row) => isRowCheckable?.(row))
      .map((row) => row[keyField ?? ""]);
    onSelectChange?.(items);
  }

  return (
    <div
      className={`table-comp table-responsive ${
        parentClass ? parentClass : ""
      }`}
    >
      <table className="table table-borderless">
        <thead>
          <tr>
            {hasRowSelect && (
              <th className="align-middle">
                <Checkbox
                  size="sm"
                  value="SELECT_ALL"
                  aria-label="Select all rows"
                  checked={selectedItems.length > 0}
                  indeterminate={selectedItems.length !== data.length}
                  onChange={handleSelectAllRows}
                  disabled={data.length < 1}
                />
              </th>
            )}
            {columns.map((column) => (
              <th key={column.field} className="align-middle" style={column.style}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading && getLoadingIcon(columns.length + 1)}
          {!loading &&
            data.length < 1 &&
            getNotDataFoundText(columns.length + 1)}
          {!loading && (
            <CheckboxGroup value={selectedItems} onChange={onSelectChange}>
              {filteredData.map((eachData, index) => (
                <tr key={keyField ? eachData[keyField] : index}>
                  {hasRowSelect && (
                    <td className="align-middle">
                      <Checkbox
                        size="sm"
                        value={keyField ? eachData[keyField] : ""}
                        aria-label={`Select row by ${keyField ?? index}`}
                        disabled={!isRowCheckable?.(eachData) ?? false}
                      />
                    </td>
                  )}
                  {renderDataRow(columns, eachData)}
                </tr>
              ))}
            </CheckboxGroup>
          )}
        </tbody>
        {hasHiddenRows && (
          <tfoot>
            <tr>
              <td colSpan={100}>
                <Button
                  onClick={() => setIsSomeRowsAreHidden((prev) => !prev)}
                  variant="text"
                  wide
                >
                  {`${
                    isSomeRowsAreHidden ? "Show" : "Hide"
                  } Additional Information`}
                </Button>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
}
