import { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/button";
import Table from "../../components/table";
import Modal from "../../components/modal";
import AddDisbursalForm from "./addDisbursalForm";
import { TermLoanDetails, UserDetails } from "../../dto";
import { dateToDDMMYYYY } from "../../utils";
import { DisbursalDetails, RoutePaths } from "../../models";
import { EnterpriseDetails } from "../../dto/enterprise.dto";

interface LoanDisbursalProps {
  loanDetails: TermLoanDetails;
  userDetails: UserDetails | EnterpriseDetails | null;
}

export default function LoanDisbursal({ loanDetails, userDetails }: LoanDisbursalProps) {
  const history = useHistory();
  const [isModalActive, setIsModalActive] = useState(false);

  const columns = [
    { field: "date", label: "Date" },
    { field: "transactionRefNo", label: "Transfer Advice" },
    { field: "disbursedAmount", label: "Amount" },
    { field: "processingFees", label: "Processing Fees" },
    { field: "bankAccountNo", label: "Bank A/c Number" },
    { field: "ifscCode", label: "IFSC" },
    { field: "bankName", label: "Bank Name" },
  ];
  function getTableData(disbursalDetails?: DisbursalDetails) {
    if (!disbursalDetails) return [];
    return [
      {
        ...loanDetails.disbursalDetails,
        date: loanDetails.disbursalDetails?.date && dateToDDMMYYYY(loanDetails.disbursalDetails?.date),
      }
    ]
  }
  function handleNext() {
    history.replace(`${RoutePaths.LOANS}/${loanDetails._id}`);
  }
  return (
    <div className="mt-4">
      <div className="d-flex flex-md-row flex-column align-items-center justify-content-between mb-4">
        <h3 className="user-page-title mb-4">Disbursal Details</h3>
        <Button
          onClick={() => setIsModalActive(true)}
          disabled={!!loanDetails.disbursalDetails}
        >
          Record Disbursal
        </Button>
      </div>
      <Table columns={columns} data={getTableData(loanDetails.disbursalDetails)} />
      <div className="text-center">
        <Button variant="success" disabled={!loanDetails.disbursalDetails} onClick={handleNext}>Next</Button>
      </div>
      <Modal
        open={isModalActive}
        title="Disbursal Details"
        onClose={() => setIsModalActive(false)}
        hasCancelButton={false}
        hasSubmitButton={false}
      >
        <AddDisbursalForm loanDetails={loanDetails} userDetails={userDetails} />
      </Modal>
    </div>
  );
}
