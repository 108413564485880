import { useState } from "react";
import { Select } from "../input-functions";
import "./styles.css";

const DEFAULT_NUMER_OF_ENTRIES_PER_PAGE = 10;
const LIST_OF_COUNT_PER_PAGE = [10, 50, 100];

export function calculatePageCount(
  total: number | undefined,
  perPageCount?: number
) {
  if (!total || !perPageCount) return 0;
  return Math.ceil(total / perPageCount);
}

function getRelativeEntryNumber(
  total: number,
  page: number,
  perPageCount: number
) {
  const start = perPageCount * (page - 1) + 1;
  const end = start + perPageCount - 1;
  const endCount = end > total ? total : end;
  return `${start} - ${endCount} of ${total}`;
}

interface PaginationProps {
  currentPage: number;
  totalEntries: number;
  numberOfEntriesPerPage?: number;
  onPageChange?: (pageNumber: number) => void;
  onPerPageCountChange?: (numberOfEntriesPerPage: number) => void;
  previousText?: string;
  nextText?: string;
}

export default function Pagination({
  currentPage,
  totalEntries,
  numberOfEntriesPerPage = DEFAULT_NUMER_OF_ENTRIES_PER_PAGE,
  onPageChange,
  onPerPageCountChange,
  previousText = "<",
  nextText = ">",
}: PaginationProps) {
  const totalPageCount = calculatePageCount(
    totalEntries,
    numberOfEntriesPerPage
  );
  const [jumpTo, setJumpTo] = useState("");

  function handleChange(pageNo: number | string) {
    if (typeof pageNo === "string") return;
    if (onPageChange) {
      onPageChange(pageNo);
    }
  }

  function handleForward() {
    handleChange(currentPage + 1);
  }
  function handleBackward() {
    handleChange(currentPage - 1);
  }

  function handleJumpToSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!jumpTo) return;
    const jumpToPageNumber = Number(jumpTo);
    handleChange(jumpToPageNumber);
    setJumpTo("");
  }

  if (totalPageCount < 1) return null;

  return (
    <nav
      aria-labelledby="Table Pagination"
      className="rf-pagination d-flex flex-lg-row flex-column align-items-center justify-content-lg-between"
    >
      <div className="d-flex flex-sm-row flex-column align-items-center mb-2 mb-lg-0">
        <ul className="rf-pagination__list mb-2 mb-sm-0">
          <li className="rf-pagination__item">
            <button
              className="rf-pagination__btn--action"
              onClick={handleBackward}
              disabled={currentPage === 1}
            >
              {previousText}
            </button>
          </li>
          <div className="rf-pagination__relative-entry-count">
            {getRelativeEntryNumber(
              totalEntries,
              currentPage,
              numberOfEntriesPerPage
            )}
          </div>
          <li className="rf-pagination__item">
            <button
              className="rf-pagination__btn--action"
              onClick={handleForward}
              disabled={currentPage === totalPageCount}
            >
              {nextText}
            </button>
          </li>
        </ul>
        <form className="rf-pagination__jump-to" onSubmit={handleJumpToSubmit}>
          <span className="rf-pagination__jump-to--text">Jump to page</span>
          <input
            name="jumpTo"
            type="number"
            className="rf-pagination__jump-to--input"
            min={1}
            max={totalPageCount}
            value={jumpTo}
            onChange={(e) => setJumpTo(e.target.value)}
            required
          />
        </form>
      </div>
      <div
        className="d-flex align-items-center"
        style={{ whiteSpace: "nowrap" }}
      >
        <span>Show</span>
        <Select
          className="rf-pagination__per-page-selector mx-2"
          options={LIST_OF_COUNT_PER_PAGE.map((count) => ({
            label: count,
            value: count,
          }))}
          value={numberOfEntriesPerPage}
          onChange={(e) => {
            if (onPerPageCountChange) {
              onPerPageCountChange(Number(e.target.value));
            }
          }}
        />
        <span>per page</span>
      </div>
    </nav>
  );
}
