import { useState } from "react";
import Button from "../../../components/button";
import { ReactComponent as DownloadIcon } from "../../../images/download.svg";
import { CommercialODTransactionStatement } from "../../../dto";
import Table from "../../../components/table";
import { dateToYYYYMMDD, downloadCSV } from "../../../utils";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../components/confirm-dialog";
import {
  validateAddChargeInterestFields,
  validateAddTDSCreditAndRepaymentFields,
  vlidateAddDrawdownFields,
} from "../helpers";
import {
  AddTransactionChargeInterest,
  AddTransactionTDSCreditAndRepayment,
  AddTransactionWithdrawal,
} from "../../../models";
import dispatch from "../../../middleware";
import { useDispatch } from "react-redux";
import {
  addCommercialODTransactionChargeInterest,
  addCommercialODTransactionPayment,
  addCommercialODTransactionTDSCredit,
  addCommercialODTransactionWithdrawal,
} from "../../../actions/loan.action";

interface TransactionStatementProps {
  transactionStatements: CommercialODTransactionStatement[] | null;
  isLoanLoading: boolean;
  loanId: string;
  onSuccess: () => void;
}

export default function TransactionStatement({
  transactionStatements,
  isLoanLoading,
  loanId,
  onSuccess,
}: TransactionStatementProps) {
  const storeDispatch = useDispatch();
  const [isModalActive, setIsModalActive] = useState({
    payment: false,
    withdrawal: false,
    tdsCredit: false,
    chargeInterest: false,
  });
  const [errorMessage, setErrorMessage] = useState("");

  const transactionStatementColumn = [
    { field: "date", label: "Date (YYMMDD)" },
    { field: "transactionId", label: "Transaction ID" },
    { field: "type", label: "Type" },
    { field: "amount", label: "Amount" },
  ];

  function convertDateTimeToPayloadFormat(originalDateTime: string): string {
    return String(new Date(originalDateTime));
  }

  function getTransactionType(
    transactionStatement: CommercialODTransactionStatement
  ) {
    if (
      transactionStatement.transactionType === "DEBIT" &&
      transactionStatement.feeType === "INTEREST_CHARGE"
    )
      return "INTEREST_CHARGE";
    return transactionStatement.transactionType;
  }

  function getTableData(
    transactionStatements: CommercialODTransactionStatement[] | null
  ) {
    if (!transactionStatements) return [];
    return transactionStatements.map((transactionStatement) => ({
      date: dateToYYYYMMDD(transactionStatement.transactedAt),
      transactionId: transactionStatement._id,
      type: getTransactionType(transactionStatement),
      amount: transactionStatement.amount,
    }));
  }

  function handleDownloadButtonClick() {
    downloadCSV(
      getTableData(transactionStatements),
      transactionStatementColumn,
      `${loanId}_Transaction_Statement`
    );
  }

  async function handleWithdrawalDialog(
    type: ConfirmDialogType,
    fieldData: any
  ) {
    vlidateAddDrawdownFields(fieldData);
    try {
      const payload: AddTransactionWithdrawal = {
        utr: fieldData.utr,
        amount: parseFloat(fieldData.amount),
        onDate: convertDateTimeToPayloadFormat(fieldData.onDate),
      };

      const response = await dispatch(
        storeDispatch,
        addCommercialODTransactionWithdrawal(loanId, payload)
      );

      if (response) {
        onSuccess();
      }

      return {
        message: `Withdrawal of Rs ${fieldData.amount} for Loan # ${loanId} added successfully with Transaction ID: ${response?._id}`,
      };
    } catch (error) {
      setErrorMessage(error?.message);
    }
  }

  async function handleChargeInterestDialog(
    type: ConfirmDialogType,
    fieldData: any
  ) {
    validateAddChargeInterestFields(fieldData);
    try {
      const payload: AddTransactionChargeInterest = {
        onDate: convertDateTimeToPayloadFormat(fieldData.onDate),
      };

      const response = await dispatch(
        storeDispatch,
        addCommercialODTransactionChargeInterest(loanId, payload.onDate)
      );
      if (response) {
        onSuccess();
      }

      return {
        message: `Interest Charge added for # Loan Id ${loanId} on Date ${fieldData.onDate} with Transaction ID: ${response?._id}`,
      };
    } catch (error) {
      return new Error(error);
    }
  }

  async function handleTdsCreditDialog(
    type: ConfirmDialogType,
    fieldData: any
  ) {
    validateAddTDSCreditAndRepaymentFields(fieldData);
    try {
      const payload: AddTransactionTDSCreditAndRepayment = {
        amount: parseFloat(fieldData.amount),
        paymentRefId: fieldData.paymentRefId,
        source: fieldData.source,
        paidAt: convertDateTimeToPayloadFormat(fieldData.paidAt),
      };
      const response = await dispatch(
        storeDispatch,
        addCommercialODTransactionTDSCredit(loanId, payload)
      );

      if (response) {
        onSuccess();
      }

      return {
        message: `TDS Credit added for the # Loan ${loanId} added successfully with Transaction ID: ${response?._id}`,
      };
    } catch (error) {
      setErrorMessage(error?.message);
    }
  }

  async function handlePaymentDialog(type: ConfirmDialogType, fieldData: any) {
    validateAddTDSCreditAndRepaymentFields(fieldData);
    try {
      const payload: AddTransactionTDSCreditAndRepayment = {
        amount: parseFloat(fieldData.amount),
        paymentRefId: fieldData.paymentRefId,
        source: fieldData.source,
        paidAt: convertDateTimeToPayloadFormat(fieldData.paidAt),
      };

      const response = await dispatch(
        storeDispatch,
        addCommercialODTransactionPayment(loanId, payload)
      );

      if (response) {
        onSuccess();
      }

      return {
        message: `Payment of Rs ${fieldData.amount} for Loan # ${loanId} added successfully with Transaction ID: ${response?._id}`,
      };
    } catch (error) {
      setErrorMessage(error?.message);
    }
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h3 className="user-page-title ">Transaction Statement</h3>
        <div className="rf-btn-group">
          <Button
            onClick={() =>
              setIsModalActive((prevState) => ({
                ...prevState,
                tdsCredit: true,
              }))
            }
          >
            TDS Credit
          </Button>
          <Button
            onClick={() =>
              setIsModalActive((prevState) => ({
                ...prevState,
                chargeInterest: true,
              }))
            }
          >
            Charge Interest
          </Button>
          <Button
            onClick={() =>
              setIsModalActive((prvState) => ({
                ...prvState,
                withdrawal: true,
              }))
            }
          >
            Add Drawdown
          </Button>
          <Button
            onClick={() =>
              setIsModalActive((prevState) => ({ ...prevState, payment: true }))
            }
          >
            Add Repayment
          </Button>
          <Button variant="info" title="Download">
            <DownloadIcon
              onClick={handleDownloadButtonClick}
              height="25"
              width="25"
              color="var(--font-color)"
            />
          </Button>
        </div>
      </div>
      <div className="my-4">
        <Table
          loading={isLoanLoading}
          columns={transactionStatementColumn}
          data={getTableData(transactionStatements)}
        />
        <ConfirmDialog
          active={isModalActive.withdrawal}
          type="success"
          title="Add Drawdown"
          message="Please fill the below details to add Drawdown"
          onCancel={() =>
            setIsModalActive((prevState) => ({
              ...prevState,
              withdrawal: false,
            }))
          }
          onSubmit={handleWithdrawalDialog}
          submitButtonText="Add"
          cancelButtonText="Cancel"
          inputFields={[
            {
              name: "utr",
              label: "Payment Ref ID",
              type: "text",
              placeholder: "payment ref id",
              required: true,
            },
            {
              name: "amount",
              label: "Amount",
              type: "text",
              inputMode: "numeric",
              placeholder: "amount",
              required: true,
              numberToWordRequired: true,
            },
            {
              name: "onDate",
              label: "On Date",
              type: "datetime-local",
              placeholder: "date",
              required: true,
            },
          ]}
        />
        <ConfirmDialog
          active={isModalActive.tdsCredit}
          type="success"
          title="Add TDS Credit"
          message="Please fill the below details to add TDS Credit"
          onCancel={() =>
            setIsModalActive((prevState) => ({
              ...prevState,
              tdsCredit: false,
            }))
          }
          onSubmit={handleTdsCreditDialog}
          submitButtonText="Add"
          cancelButtonText="Cancel"
          inputFields={[
            {
              name: "amount",
              label: "Amount",
              type: "text",
              inputMode: "numeric",
              placeholder: "amount",
              required: true,
              numberToWordRequired: true,
            },
            {
              name: "paymentRefId",
              label: "Payment Ref ID",
              type: "text",
              placeholder: "Payment Ref ID",
              required: true,
            },
            {
              name: "source",
              label: "Source",
              type: "text",
              value: "INVOICE",
              placeholder: "Source",
              required: true,
            },
            {
              name: "paidAt",
              label: "Paid At",
              type: "datetime-local",
              placeholder: "Paid At",
              required: true,
            },
          ]}
        />
        <ConfirmDialog
          active={isModalActive.chargeInterest}
          type="success"
          title="Add Charge Interest"
          message="Please fill the below details to add Charge Interest"
          onCancel={() =>
            setIsModalActive((prevState) => ({
              ...prevState,
              chargeInterest: false,
            }))
          }
          onSubmit={handleChargeInterestDialog}
          submitButtonText="Add"
          cancelButtonText="Cancel"
          inputFields={[
            {
              name: "onDate",
              label: "On Date",
              type: "datetime-local",
              placeholder: "On Date",
              required: true,
            },
          ]}
        />
        <ConfirmDialog
          active={isModalActive.payment}
          type="success"
          title="Add Repayment"
          message="Please fill the below details to add Repayment"
          onCancel={() =>
            setIsModalActive((prevState) => ({
              ...prevState,
              payment: false,
            }))
          }
          onSubmit={handlePaymentDialog}
          submitButtonText="Add"
          cancelButtonText="Cancel"
          inputFields={[
            {
              name: "amount",
              label: "Amount",
              type: "text",
              inputMode: "numeric",
              placeholder: "amount",
              required: true,
              numberToWordRequired: true,
            },
            {
              name: "paymentRefId",
              label: "Payment Ref ID",
              type: "text",
              placeholder: "Payment Ref ID",
              required: true,
            },
            {
              name: "source",
              label: "Source",
              type: "text",
              placeholder: "Source",
              required: true,
            },
            {
              name: "paidAt",
              label: "Paid At",
              type: "datetime-local",
              placeholder: "Paid At",
              required: true,
            },
          ]}
        />
      </div>
    </>
  );
}
