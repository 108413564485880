import { ReactNode, useEffect, useState } from "react";
import {
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Link,
  Redirect,
} from "react-router-dom";
import useScreenName from "../../hooks/useScreenName";
import Avatar from "../../components/avatar";
import DefaultUserAvatar from "../../images/default-user-avatar.svg";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import {
  RetailODDetails,
  RetailOdLedgerInfoDto,
  TermLoanDetails,
  TermLoanLedgerInfoDto,
  UserDetails,
} from "../../dto";
import dispatch from "../../middleware";
import {
  getLoanById,
  getRetailOdLedgerInfoByLoanId,
  getTermLoanLedgerInfoByLoanId,
} from "../../actions/loan.action";
import { ReactComponent as LoaderIcon } from "../../images/loader.svg";
import { getUserById } from "../../actions/user.action";
import { ApplicantType, LoanStatusEnum, RoutePaths } from "../../models";
import LoanSummary from "./loanSummary";
import LoanRepaymentSchedule from "./loanRepaymentSchedule";
import LoanDisbursal from "./loanDisbursal";
import RetailOdTransaction from "./retailOdTransaction";
import { getUserSelfieDataUrl, splitKeyWithSpace } from "../../utils";
import { getApplicationDoc } from "../../actions/application.action";
import { getEnterpriseById } from "../../actions/enterprise.action";
import { EnterpriseDetails } from "../../dto/enterprise.dto";
import TermLoanRepayment from "./termLoanRepayment";

export default function LoanDetailsScreen() {
  useScreenName("Loans");
  const { loanId }: { loanId: string } = useParams();
  const { path } = useRouteMatch();
  const storeDispatch = useDispatch();
  const loanDetails: TermLoanDetails | RetailODDetails | null = useSelector(
    (state: ReduxState) => state.loan.singleLoan
  );
  const individualUserDetails: UserDetails | null = useSelector(
    (state: ReduxState) => state.user.singleUser
  );
  const enterpriseDetails: EnterpriseDetails | null = useSelector(
    (state: ReduxState) => state.enterprise.singleEnterprise
  );
  const termLoanLedgerInfo: TermLoanLedgerInfoDto | null = useSelector(
    (state: ReduxState) => state.loan.termLoanLedgerInfo
  );
  const retailOdLedgerInfo: RetailOdLedgerInfoDto | null = useSelector(
    (state: ReduxState) => state.loan.retailOdLedgerInfo
  );

  const userDetails = loanDetails?.enterpriseId
    ? enterpriseDetails
    : individualUserDetails;

  const [isLoading, setIsLoading] = useState(true);
  const [agreementDocumentData, setAgreementDocumentData] = useState({
    body: "",
    type: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [selfieDataUrl, setSelfieDataUrl] = useState<string | null>(null);

  async function fetchAgreementDocument(
    entityId: string | undefined,
    applicationId: string,
    applicantType: ApplicantType
  ) {
    if (!entityId) return;
    try {
      const resp = await dispatch(
        storeDispatch,
        getApplicationDoc({
          entityId,
          appId: applicationId,
          // INFO: It's a hack for now, Ideally docName should come from backend
          documentName: "SIGNED_LENDER_AGREEMENT",
          applicantType,
        })
      );
      setAgreementDocumentData(resp);
    } catch (error) {
      setAgreementDocumentData({ body: "", type: "" });
    }
  }

  async function fetchLoanDetails(loanIdToBeFetched: string) {
    try {
      if (loanDetails?._id === loanIdToBeFetched) {
        const entityId = loanDetails?.userId ?? loanDetails?.enterpriseId;
        // INFO: If `applicantType` missing then it's an individual user (old entries)
        const applicantType =
          loanDetails.applicantType || ApplicantType.INDIVIDUAL;
        fetchAgreementDocument(entityId, loanDetails.appId, applicantType);
        setUserSelfie(loanDetails.userId);
        return;
      }
      const resp: TermLoanDetails | RetailODDetails = await dispatch(
        storeDispatch,
        getLoanById(loanIdToBeFetched)
      );
      if (!!resp.enterpriseId && resp.enterpriseId !== enterpriseDetails?._id) {
        await dispatch(storeDispatch, getEnterpriseById(resp.enterpriseId));
      }
      if (!!resp.userId) {
        await dispatch(storeDispatch, getUserById(resp.userId));
      }
      const entityId = resp?.userId ?? resp?.enterpriseId;
      // INFO: If `applicantType` missing then it's an individual user (old entries)
      const applicantType = resp?.applicantType || ApplicantType.INDIVIDUAL;
      fetchAgreementDocument(entityId, resp.appId, applicantType);
      setUserSelfie(resp.userId);
    } catch (error) {
      setErrorMessage(error?.message);
    } finally {
      setIsLoading(false);
    }
  }
  async function setUserSelfie(userId?: string) {
    const dataUrl = await getUserSelfieDataUrl(storeDispatch, userId);
    if (!dataUrl) return;
    setSelfieDataUrl(dataUrl);
  }

  async function fetchLedgerInfo() {
    try {
      loanDetails?.loanType === "TERM_LOAN"
        ? await dispatch(storeDispatch, getTermLoanLedgerInfoByLoanId(loanId))
        : await dispatch(storeDispatch, getRetailOdLedgerInfoByLoanId(loanId));
    } catch (error: any) {
      setErrorMessage(error?.message);
    }
  }

  useEffect(() => {
    fetchLoanDetails(loanId);
  }, [loanId]);

  function renderHorizontalField(
    left: string | ReactNode,
    right: string | ReactNode
  ) {
    return (
      <p className="d-flex flex-row align-items-start">
        <span className="user-details-item-left text-thin">{left}</span>
        <span className="user-details-item-right">{right}</span>
      </p>
    );
  }

  function renderAgreementDownloadLink(docData: {
    body: string;
    type: string;
  }) {
    if (!docData.body || !docData.type) return;
    return (
      <div className="d-flex flex-row-reverse">
        <a
          href={`data:${docData.type};base64,${docData.body}`}
          className="btn rf-btn rf-btn-primary rf-btn-sm"
          download="Agreement"
        >
          Download Agreement
        </a>
      </div>
    );
  }
  function renderUserIdLink(loan: TermLoanDetails | RetailODDetails | null) {
    if (!loan) return;
    const { userId, enterpriseId } = loan;
    const entityId = enterpriseId ?? userId;
    if (!entityId) return;
    return (
      <Link
        to={`${
          enterpriseId
            ? RoutePaths.ENTERPRISE_USERS
            : RoutePaths.INDIVIDUAL_USERS
        }/${entityId}`}
      >
        {entityId}
      </Link>
    );
  }

  if (errorMessage) {
    return <h4 className="text-center">{errorMessage}</h4>;
  }
  return (
    <div className="dashboard-container">
      {isLoading || !loanDetails ? (
        <LoaderIcon />
      ) : (
        <>
          {renderAgreementDownloadLink(agreementDocumentData)}
          <div className="row align-items-center justify-content-between my-3">
            <div className="col d-flex align-items-center">
              <Avatar
                displayName={userDetails?.name?.data}
                border="1px solid #fff"
                displayPhotoUrl={selfieDataUrl || DefaultUserAvatar}
                size={100}
              />
              <div className="ml-4">
                <h3 className="user-page-title mb-1">
                  {userDetails?.name?.data}
                </h3>
                <p className="user-page-subtitle">
                  <span className="text-thin">User Id:</span>{" "}
                  {renderUserIdLink(loanDetails)}
                </p>
              </div>
            </div>
            <div className="col">
              {renderHorizontalField(
                "Application ID :",
                <Link to={`${RoutePaths.APPLICATIONS}/${loanDetails.appId}`}>
                  {loanDetails.appId}
                </Link>
              )}
              {renderHorizontalField("Loan ID :", loanDetails?._id)}
              {renderHorizontalField(
                "Source :",
                splitKeyWithSpace((userDetails as UserDetails)?.source)
              )}
            </div>
          </div>
          <LoanSummary
            loanId={loanId}
            onMount={fetchLedgerInfo}
            loanDetails={loanDetails}
            termLoanLedgerInfo={termLoanLedgerInfo}
            retailOdLedgerInfo={retailOdLedgerInfo}
          />
          {loanDetails.loanType === "TERM_LOAN" && (
            <>
              <LoanRepaymentSchedule
                loanId={loanId}
                disbursed={
                  loanDetails.status !== LoanStatusEnum.DISBURSAL_PENDING
                }
              />
              <div className="mt-3">
                <TermLoanRepayment loanId={loanId} />
              </div>
              <Switch>
                <Route path={`${path}/disbursal`}>
                  <LoanDisbursal
                    loanDetails={loanDetails as TermLoanDetails}
                    userDetails={userDetails}
                  />
                </Route>
                {loanDetails.status === LoanStatusEnum.DISBURSAL_PENDING && (
                  <Redirect to={`${path}/disbursal`} />
                )}
              </Switch>
            </>
          )}
          {loanDetails.loanType === "RETAIL_OD" && (
            <RetailOdTransaction
              loanId={loanId}
              onSuccess={() => dispatch(storeDispatch, getLoanById(loanId))}
            />
          )}
        </>
      )}
    </div>
  );
}
