import { useDispatch } from "react-redux";
import { SingleWCTLApplication } from "../../../dto";
import { ReactComponent as LoaderIcon } from "../../../images/loader.svg";
import { useState } from "react";
import DatafieldSection from "../../../components/datafield-section";
import Button from "../../../components/button";
import { ApplicationStatusType } from "../../../models";
import Badge from "../../../components/badge";
import { splitKeyWithSpace } from "../../../utils";
import { ConfirmDialogType } from "../../../components/confirm-dialog";
import dispatch from "../../../middleware";
import { updateWctlApplicationStatus } from "../../../actions/application.action";
import Modal from "../../../components/modal";
import { ReactComponent as PencilIcon } from "../../../images/pencil.svg";
import EditWCTLApplication from "./editWctlApplication";
import { ApproveOrRejectApplication } from "./approveOrRejectApplication";

interface WCTLApplicationCardProps {
  isLoading: boolean;
  appId: string;
  wctlApplicationDetails: SingleWCTLApplication | null;
  onSuccess: () => Promise<any>;
}

const BasicLabels = [
  { key: "_id", label: "Application ID :" },
  { key: "clientId", label: "Client ID: " },
  { key: "lenderId", label: "Lender ID:" },
];

function getPayload(status: "APPROVED" | "REJECTED", date?: string) {
  return {
    status,
    ...(date && { approvedOrRejectedDate: new Date(date) }),
  };
}

function getStatusBadge(status: ApplicationStatusType) {
  if (status === "APPROVED") return <Badge type="success">Approved</Badge>;
  if (status === "REJECTED") return <Badge type="reject">Rejected</Badge>;
  return (
    <Badge type="pending" capitalize>
      {splitKeyWithSpace(status).toLowerCase()}
    </Badge>
  );
}

export default function WCTLApplicationCard({
  isLoading,
  appId,
  wctlApplicationDetails,
  onSuccess,
}: WCTLApplicationCardProps) {
  const storeDispatch = useDispatch();
  const [isModalActive, setIsModalActive] = useState({
    edit: false,
    approveOrReject: false,
  });

  async function handleUpdateStatus(cxt: {
    type: ConfirmDialogType;
    date?: string;
  }) {
    const { type, date } = cxt;
    const status = type === "success" ? "APPROVED" : "REJECTED";
    try {
      const payload = getPayload(status, date);
      const response = await dispatch(
        storeDispatch,
        updateWctlApplicationStatus({ applicationId: appId, body: payload })
      );
      if (!response) return;
      setIsModalActive({
        edit: false,
        approveOrReject: false,
      });
      onSuccess();
    } catch (_) {}
  }

  function getFinalStatusOrAllowStatusChange(status: ApplicationStatusType) {
    if (!status) return null;
    if (status === "APPROVED" || status === "REJECTED") {
      return getStatusBadge(status);
    }
    return (
      <div>
        <Button
          onClick={() =>
            setIsModalActive((prevState) => ({ ...prevState, edit: true }))
          }
          variant="blue-outline"
        >
          <PencilIcon height="15" width="15" />
          Edit
        </Button>
        <Button
          onClick={() =>
            setIsModalActive((prevState) => ({
              ...prevState,
              approveOrReject: true,
            }))
          }
          style={{ marginLeft: "16px" }}
        >
          Approve/Reject
        </Button>
      </div>
    );
  }

  if (!wctlApplicationDetails) return null;

  if (isLoading) {
    return <LoaderIcon />;
  }
  return (
    <>
      <div className="bg-white d-flex dashboard-container">
        <div className="flex-grow-1 mr-3">
          <DatafieldSection
            labels={[{ items: BasicLabels }]}
            data={wctlApplicationDetails}
          />
        </div>
        <div className="mb-auto ml-auto">
          {getFinalStatusOrAllowStatusChange(wctlApplicationDetails.status)}
        </div>
      </div>

      <Modal
        open={isModalActive.approveOrReject}
        title="Approve/Reject Application"
        size="large"
        hasSubmitButton={false}
        hasCancelButton={false}
        onClose={() =>
          setIsModalActive((prevState) => ({
            ...prevState,
            approveOrReject: false,
          }))
        }
        children={
          <ApproveOrRejectApplication onApproveOrReject={handleUpdateStatus} />
        }
      />
      <Modal
        open={isModalActive.edit}
        title="Update Application"
        size="large"
        hasSubmitButton={false}
        hasCancelButton={false}
        onClose={() => {
          setIsModalActive((prevState) => ({ ...prevState, edit: false }));
          onSuccess();
        }}
        children={<EditWCTLApplication appId={appId} />}
      />
    </>
  );
}
