import { UserDocType } from "../../../dto";
import { FileType, UserDocCategory } from "../../../models";

export const USER_DOC_TYPES: Record<
  UserDocCategory | "LOAN_APPLICATION_DOCS" | "OTHERS",
  UserDocType[]
> = {
  [UserDocCategory.IDENTITY_PROOF]: [
    {
      doc: "PAN_FRONT",
      acceptedFileTypes: [
        FileType.IMG_JPEG,
        FileType.IMG_PNG,
        FileType.APP_PDF,
      ],
    },
    {
      doc: "PAN_BACK",
      acceptedFileTypes: [
        FileType.IMG_JPEG,
        FileType.IMG_PNG,
        FileType.APP_PDF,
      ],
    },
    {
      doc: "SELFIE",
      acceptedFileTypes: [FileType.IMG_JPEG, FileType.IMG_PNG],
    },
  ],
  [UserDocCategory.ADDRESS_PROOF]: [
    {
      doc: "DRIVING_LICENSE_FRONT",
      acceptedFileTypes: [
        FileType.IMG_JPEG,
        FileType.IMG_PNG,
        FileType.APP_PDF,
      ],
    },
    {
      doc: "DRIVING_LICENSE_BACK",
      acceptedFileTypes: [
        FileType.IMG_JPEG,
        FileType.IMG_PNG,
        FileType.APP_PDF,
      ],
    },
    {
      doc: "VOTER_ID_FRONT",
      acceptedFileTypes: [
        FileType.IMG_JPEG,
        FileType.IMG_PNG,
        FileType.APP_PDF,
      ],
    },
    {
      doc: "VOTER_ID_BACK",
      acceptedFileTypes: [
        FileType.IMG_JPEG,
        FileType.IMG_PNG,
        FileType.APP_PDF,
      ],
    },
    {
      doc: "HR_LETTER",
      acceptedFileTypes: [
        FileType.IMG_JPEG,
        FileType.IMG_PNG,
        FileType.APP_PDF,
      ],
    },
    {
      doc: "RENTAL_AGREEMENT",
      acceptedFileTypes: [FileType.APP_PDF],
    },
    {
      doc: "EKYC", // TODO: Check for aadhaar doc name, what about upload?
      acceptedFileTypes: [FileType.ANY],
    },
  ],
  LOAN_APPLICATION_DOCS: [
    {
      doc: "LOAN_AGREEMENT",
      acceptedFileTypes: [FileType.APP_PDF],
    },
    {
      doc: "PAYSLIP",
      acceptedFileTypes: [
        FileType.IMG_JPEG,
        FileType.IMG_PNG,
        FileType.APP_PDF,
      ],
    },
    {
      doc: "SIGNED_LENDER_AGREEMENT",
      acceptedFileTypes: [FileType.APP_PDF],
    },
  ],
  OTHERS: [],
};
