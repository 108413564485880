import { BrowserRouter as Router } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoute from "./routes/PublicRoute";
import "./styles.css";

function App() {
  const isLoggedIn: boolean = useSelector(
    (state: any) => state.login.isLoggedIn
  );
  return (
    <Router>
      {isLoggedIn ? <PrivateRoutes /> : <PublicRoute />}
    </Router>
  );
}

export default App;
