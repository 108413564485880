import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import dispatch from "../../../middleware";
import {
  getCommercialOdApplicationById,
  updateCommercialOdApplicationStatus,
} from "../../../actions/application.action";
import DatafieldSection from "../../../components/datafield-section";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../reducers";
import { ApplicationStatusType } from "../../../models";
import Badge from "../../../components/badge";
import { splitKeyWithSpace } from "../../../utils";
import Button from "../../../components/button";
import Alert from "../../../components/alert";
import Modal from "../../../components/modal";
import EditCommercialOdApplication from "./editApplication";
import CommercialODApplicationDocumentUpload from "./documents";

export default function SingleCommercialLoanOdApplication() {
  const storeDispatch = useDispatch();
  const { appId }: { appId: string } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [isEditModalActive, setEditModal] = useState(false);
  const singleCommercialOdApplication = useSelector(
    (state: ReduxState) => state.application.singleCommercialOdApplication
  );

  const BasicLabels = [
    { key: "entitySnapshot.snapshot.name", label: "Name :" },
    { key: "_id", label: "Application ID :" },
    { key: "lenderId", label: "Lender ID :" },
  ];
  const ApplicationDetailsLabels = [
    { key: "sanctionedAmount", label: "Sanctioned Amount :" },
    { key: "irpa", label: "IRPA :" },
    { key: "pf", label: "PF :" },
    { key: "pfPostGst", label: "PF (Post GST) :" },
  ];

  function getStatusBadge(status: ApplicationStatusType) {
    if (status === "APPROVED") return <Badge type="success">Approved</Badge>;
    if (status === "REJECTED") return <Badge type="reject">Rejected</Badge>;
    return (
      <Badge type="pending" capitalize>
        {splitKeyWithSpace(status).toLowerCase()}
      </Badge>
    );
  }

  async function handleApplicationStatusChange(
    toStatus: ApplicationStatusType
  ) {
    setErrorMessage("");
    try {
      await dispatch(
        storeDispatch,
        updateCommercialOdApplicationStatus(appId, toStatus)
      );
      await dispatch(storeDispatch, getCommercialOdApplicationById(appId));
    } catch (e) {
      setErrorMessage(e?.message);
    }
  }

  function renderFinalStatusOrAllowStatusChange(status: ApplicationStatusType) {
    if (!status) return null;
    if (status === "APPROVED" || status === "REJECTED")
      return getStatusBadge(singleCommercialOdApplication?.status);
    return (
      <div className="d-flex justify-content-end">
        <Button variant="blue-outline" onClick={() => setEditModal(true)}>
          Edit
        </Button>
        <Button
          variant="danger-outline"
          onClick={() => handleApplicationStatusChange("REJECTED")}
        >
          Reject
        </Button>
        <Button
          variant="success-outline"
          onClick={() => handleApplicationStatusChange("APPROVED")}
        >
          Approve
        </Button>
      </div>
    );
  }

  useEffect(() => {
    if (!appId) return;
    dispatch(storeDispatch, getCommercialOdApplicationById(appId));
  }, []);
  return (
    <div>
      <div className="dashboard-container">
        <Alert
          type="danger"
          message={errorMessage}
          canDismiss
          onDismissClick={() => setErrorMessage("")}
        />
        <div className="row">
          <div className="col-6">
            <DatafieldSection
              labels={[{ items: BasicLabels }]}
              data={singleCommercialOdApplication}
            />
          </div>
          <div className="col-6">
            {renderFinalStatusOrAllowStatusChange(
              singleCommercialOdApplication?.status
            )}
          </div>
        </div>
      </div>

      <h2 className="user-page-title my-4">Application Details</h2>
      <div className="dashboard-container">
        <DatafieldSection
          labels={[{ items: ApplicationDetailsLabels }]}
          data={singleCommercialOdApplication}
        />
      </div>

      <h2 className="user-page-title my-4">Upload Documents</h2>
      <div className="dashboard-container">
        <CommercialODApplicationDocumentUpload appId={appId} />
      </div>
      <Modal
        open={isEditModalActive}
        onClose={() => setEditModal(false)}
        hasSubmitButton={false}
        hasCancelButton={false}
      >
        <EditCommercialOdApplication
          applicationId={appId}
          data={{
            pfPostGst: singleCommercialOdApplication?.pfPostGst,
            sanctionedAmount: singleCommercialOdApplication?.sanctionedAmount,
            drawdownRepaymentPeriodInDays:
              singleCommercialOdApplication?.drawdownRepaymentPeriodInDays,
            interestRepaymentPeriodInDays:
              singleCommercialOdApplication?.interestRepaymentPeriodInDays,
            irpa: singleCommercialOdApplication?.irpa,
          }}
        />
      </Modal>
    </div>
  );
}
