import { ChangeEvent, FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateReport } from "../../actions/report.action";
import Alert from "../../components/alert";
import {
  GroupInputField,
  HorizontalSelect,
  SelectOption,
} from "../../components/input-functions";
import { GenerateReport, ReportType } from "../../dto/report.dto";
import dispatch from "../../middleware";
import {
  convertLocalDateToUtcWithoutTimeOffset,
  dateToInputDate,
  getTodayDateForInputField,
  offsetDateByDays,
  splitKeyWithSpace,
} from "../../utils";
import { ReactComponent as TickIcon } from "../../images/tick.svg";
import Button from "../../components/button";
import { ReduxState } from "../../reducers";
import { ReportPeriod } from "../../models";

const MAX_ACCOUNTING_REPORT_PERIOD_RANGE_IN_DAYS = 31;

const REPORT_PERIODS: SelectOption[] = [
  { label: "Today", value: ReportPeriod.TODAY },
  { label: "Last 7 days", value: ReportPeriod.LAST_7_DAYS },
  { label: "Last 15 days", value: ReportPeriod.LAST_15_DAYS },
  { label: "Last month", value: ReportPeriod.LAST_MONTH },
  { label: "Custom Date Range", value: ReportPeriod.CUSTOM },
]

const REPORT_PERIODS_OFFSET_IN_DAYS: Record<ReportPeriod, number> = {
  [ReportPeriod.TODAY]: -1, 
  [ReportPeriod.LAST_7_DAYS]: -7 * 1, 
  [ReportPeriod.LAST_15_DAYS]: -15 * 1, 
  [ReportPeriod.LAST_MONTH]: -30 * 1, 
  [ReportPeriod.CUSTOM]: 0,
}

function calculateMaxToDate(
  fromDate: string,
  maxRangeAllowedInDays: number | null
) {
  const todayDate = new Date();
  if (maxRangeAllowedInDays === null || !fromDate)
    return dateToInputDate(todayDate);
  const date = new Date(fromDate);
  const maxDate = offsetDateByDays(maxRangeAllowedInDays - 1, date);
  if (maxDate > todayDate) return dateToInputDate(todayDate);
  return dateToInputDate(maxDate);
}

interface GenerateReportFormProps {
    onSuccess?: () => void;
}

export default function GenerateReportForm({ onSuccess }: GenerateReportFormProps) {
  const storeDispatch = useDispatch();
  const isLoading = useSelector((state: ReduxState) => state.report.loading);
  const [reportPeriod, setReportPeriod] = useState<ReportPeriod>();
  const [formData, setFormData] = useState<GenerateReport>({
    reportType: ReportType.ACCOUNTING_REPORT,
    fromDate: "",
    toDate: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleReportPeriodChange(e: ChangeEvent<HTMLSelectElement>) {
    const selectedReportPeriod = e.target.value as ReportPeriod;
    setReportPeriod(selectedReportPeriod);
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!reportPeriod) return;
    try {
      let startDate: Date;
      let endDate: Date;
      const { reportType, fromDate, toDate } = formData;
      if (reportPeriod === ReportPeriod.CUSTOM) {

        startDate = new Date(fromDate);
        endDate = new Date(toDate);
      } else if (reportPeriod === ReportPeriod.LAST_MONTH) {

        const lastMonthDate = new Date();
        lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

        startDate = new Date(
          lastMonthDate.getFullYear(),
          lastMonthDate.getMonth(),
          1
        );
        endDate = new Date(
          lastMonthDate.getFullYear(),
          lastMonthDate.getMonth() + 1,
          0
        );
      } else if (reportPeriod === ReportPeriod.TODAY) {
        startDate = new Date();
        endDate = new Date();

      } else {

        const offsetFromToday = -1;
        startDate = offsetDateByDays(
          REPORT_PERIODS_OFFSET_IN_DAYS[reportPeriod] + offsetFromToday
        );
        endDate = offsetDateByDays(offsetFromToday);
      }

      startDate.setHours(0, 0, 0, 0);
      if (reportPeriod !== ReportPeriod.TODAY) {
        // HACK: Offset timezone (23:59 hr - 5:30 hr = 18:29 hr)
        endDate.setHours(18, 29, 59, 999);
      }

      const payload: GenerateReport = {
        reportType,
        // INFO: For the backend to work properly have to convert local time to UTC without time offset
        fromDate: convertLocalDateToUtcWithoutTimeOffset(startDate).toISOString(),
        toDate: convertLocalDateToUtcWithoutTimeOffset(endDate).toISOString(),
      };
      await dispatch(storeDispatch, generateReport(payload));
      setSuccessMessage("Report generated successfully");
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      setErrorMessage(error.message || "Something went wrong, Try again later");
    }
  }
  
  if (successMessage) {
    return (
      <div className="d-flex flex-column align-items-center">
        <TickIcon color="#5BC97C" />
        <p style={{ marginTop: "20px", marginBottom: "0" }}>{successMessage}</p>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <Alert
        type="danger"
        message={errorMessage}
        canDismiss
        onDismissClick={() => setErrorMessage("")}
      />
      <HorizontalSelect
        label="Report Type:"
        name="reportType"
        style={{ backgroundColor: "#fff" }}
        value={formData.reportType}
        onChange={handleChange}
        placeholder="Select the Report Type"
        options={Object.keys(ReportType).map((type) => ({
          label: splitKeyWithSpace(type),
          value: type,
        }))}
        required
      />
      <HorizontalSelect
        label="Report Period:"
        name="reportPeriod"
        style={{ backgroundColor: "#fff" }}
        value={reportPeriod}
        onChange={handleReportPeriodChange}
        placeholder="Select a Period"
        options={REPORT_PERIODS}
        required
      />
      {reportPeriod === ReportPeriod.CUSTOM && (
        <div className="form-group row">
          <label htmlFor="address" className="col-sm-3 col-form-label">
            Custom Date Range:
          </label>
          <div className="col-sm-9">
            <GroupInputField
              className="mb-3"
              onChange={handleChange}
              fields={[
                {
                  element: "input",
                  type: "date",
                  placeholder: "Select from date",
                  name: "fromDate",
                  value: formData.fromDate,
                  required: true,
                  max: getTodayDateForInputField(),
                },
                {
                  element: "input",
                  type: "date",
                  placeholder: "Select to date",
                  name: "toDate",
                  value: formData.toDate,
                  required: true,
                  disabled: !formData.fromDate,
                  max: calculateMaxToDate(
                    formData.fromDate,
                    formData.reportType === ReportType.ACCOUNTING_REPORT
                      ? MAX_ACCOUNTING_REPORT_PERIOD_RANGE_IN_DAYS
                      : null
                  ),
                  min: formData.fromDate,
                },
              ]}
            />
          </div>
        </div>
      )}
      <Button type="submit" loading={isLoading}>
        Generate
      </Button>
    </form>
  );
}
