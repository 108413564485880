import { SingleWCTLApplication } from "../../../dto";
import { ApplicationType, FieldLabel } from "../../../models";
import { ReactComponent as LoaderIcon } from "../../../images/loader.svg";
import DatafieldSection from "../../../components/datafield-section";

interface WCTLApplicationDetailsProps {
  isLoading: boolean;
  wctlApplicationDetails: SingleWCTLApplication | null;
}

const labels: Record<
  Exclude<
    ApplicationType,
    "TERM_LOAN_APP" | "RETAIL_OD_APP" | "COMMERCIAL_OD_APP"
  >,
  FieldLabel[]
> = {
  WORKING_CAPITAL_TERM_LOAN_APP: [
    { key: "approvedAmount", label: "Maximum Amount :", prefix: "Rs" },
    { key: "irpa", label: "Interest rate per annum :", suffix: "%" },
    { key: "pf", label: "Processing Fee:", prefix: "Rs" },
    { key: "tenureInMonths", label: "Tenure in Months :", suffix: "months" },
    {
      key: "moratoriumInMonths",
      label: "Moratorium in Months :",
      suffix: "months",
    },
  ],
};

export function WCTLApplicationDetails({
  isLoading,
  wctlApplicationDetails,
}: WCTLApplicationDetailsProps) {
  if (isLoading) return <LoaderIcon />;

  return (
    <>
      <h2 className="user-page-title mb-4">Application Details</h2>
      <div className="dashboard-container">
        <DatafieldSection
          labels={[{ items: labels["WORKING_CAPITAL_TERM_LOAN_APP"] }]}
          data={wctlApplicationDetails}
        />
      </div>
    </>
  );
}
