import { useSelector } from "react-redux";
import Box from "../../../components/box";
import Button from "../../../components/button";
import { CkycDataDto, CkycDocDto } from "../../../dto/ckyc.dto";
import { FileType } from "../../../models";
import { ReduxState } from "../../../reducers";
import { getDataUrlFromDocTypeAndBody } from "../../../utils";
import { ReactComponent as DownloadIcon } from "../../../images/download.svg";
import { ReactComponent as EyeIcon } from "../../../images/eye.svg";
import { useMemo } from "react";
import CustomAlert from "../../../components/custom-alert";

const docKeys = ["aadhaarDoc", "panDoc", "voterIdDoc", "drivingLicenseDoc"];
const DOC_KEYS_WITH_LABEL = [
  {
    key: "aadhaarDoc",
    label: "Aadhaar :",
  },
  {
    key: "panDoc",
    label: "PAN :",
  },
  {
    key: "voterIdDoc",
    label: "Voter ID :",
  },
  {
    key: "drivingLicenseDoc",
    label: "DL :",
  },
];

interface CkycDocsProps {
  onShowDocumentClick: (doc?: { body: string; type: FileType }) => void;
}

export default function CkycDocs({ onShowDocumentClick }: CkycDocsProps) {
  const ckycDetails: CkycDataDto | null = useSelector(
    (state: ReduxState) => state.user.ckycDetailsV1
  );

  function getDocumentData(
    docKeyName: string
  ): { body: string; type: FileType } | null {
    if (!docKeys.includes(docKeyName) || !ckycDetails) return null;
    const doc = ckycDetails[docKeyName as keyof CkycDataDto] as CkycDocDto;
    if (!doc) return null;
    return {
      body: doc.b64Image,
      type: (doc.extension ?? FileType.IMG_JPEG) as FileType,
    };
  }

  const hasAnyCkycDoc = useMemo(
    () => DOC_KEYS_WITH_LABEL.some(({ key }) => !!getDocumentData(key)),
    [ckycDetails]
  );

  if (!ckycDetails) return null;

  return (
    <Box header={<h3 className="user-page-title">CKYC Documents</h3>}>
      {!hasAnyCkycDoc && (
        <CustomAlert variant="warning">No documents available</CustomAlert>
      )}
      {hasAnyCkycDoc &&
        DOC_KEYS_WITH_LABEL.map(({ key, label }) => {
          const documentData = getDocumentData(key);
          if (!documentData) return null;
          return (
            <div className="row align-items-center my-4" key={key}>
              <div className="col-2">{label}</div>
              <div className="col">
                <Button
                  variant="info"
                  title="View Document"
                  onClick={() => onShowDocumentClick(documentData)}
                >
                  <EyeIcon />
                  View
                </Button>
                <a
                  className="btn rf-btn rf-btn-info rf-btn-sm"
                  href={getDataUrlFromDocTypeAndBody(
                    documentData.type,
                    documentData.body
                  )}
                  title="Download"
                  download={key}
                >
                  <DownloadIcon color="var(--font-color)" />
                </a>
              </div>
            </div>
          );
        })}
    </Box>
  );
}
