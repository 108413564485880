import { SingleWCTLApplication } from "../../dto";
import { UpdateWCTLApplication } from "../../dto/enterprise.dto";
import {
  ApplicationStatusInfo,
  ApplicationType,
  TermLoanApplicationStatus,
} from "../../models";

const TERM_LOAN_APPLICATION_EDITABLE_STATUSES: string[] = [
  TermLoanApplicationStatus.IN_PROGRESS,
  TermLoanApplicationStatus.USER_DOC_UPLOAD_PENDING,
  TermLoanApplicationStatus.AGREEMENT_PENDING,
  TermLoanApplicationStatus.ENACH_PENDING,
  TermLoanApplicationStatus.VERIFICATION_PENDING,
];

export function isApplicationInEditableState(
  applicationType?: ApplicationType,
  status?: ApplicationStatusInfo
): boolean {
  if (!applicationType || !status?.data) return false;
  if (applicationType === "TERM_LOAN_APP") {
    return TERM_LOAN_APPLICATION_EDITABLE_STATUSES.includes(status.data);
  }
  // INFO: Fallback for OD loan application
  return status.data === "IN_PROGRESS";
}

export const isInProgress = (status?: ApplicationStatusInfo) =>
  status?.data === "IN_PROGRESS";

export const getDefaultFormDataForWctlApplication = (
  wctlApplicationDetails: SingleWCTLApplication | null
): (() => UpdateWCTLApplication) => {
  return () => ({
    irpa: wctlApplicationDetails?.irpa || 0,
    approvedAmount: wctlApplicationDetails?.approvedAmount || 0,
    pf: wctlApplicationDetails?.pf || 0,
    tenureInMonths: wctlApplicationDetails?.tenureInMonths || 0,
    moratoriumInMonths: wctlApplicationDetails?.moratoriumInMonths || 0,
  });
};
