import { Switch, Route } from "react-router-dom";
import Login from "../../containers/login";
import ForgotPassword from "../../containers/login/forgotPassword";


export default function PublicRoute() {
  return (
    <Switch>
      <Route path="/forget-password" exact component={ForgotPassword} />
      <Route path="/" component={Login} />
    </Switch>
  );
}
