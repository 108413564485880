import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dispatch from "../../../middleware";
import { useDispatch } from "react-redux";
import {
  getCommercialLoanById,
  getCommercialODLoanStatementByLoanId,
  getCommercialODTransactionStatementByLoanId,
} from "../../../actions/loan.action";
import { ReactComponent as LoaderIcon } from "../../../images/loader.svg";
import { ReduxState } from "../../../reducers";
import { useSelector } from "react-redux";
import { dateToYYYYMMDD, isLoadingActive } from "../../../utils";
import {
  CommercialLoanDetails,
  CommercialODLoanStatement,
  CommercialODTransactionStatement,
} from "../../../dto";
import LoanSummary from "./commercialLoanSummary";
import LoanStatement from "./commercialLoanStatement";
import LoanDetails from "./commercialLoanDetails";
import TransactionStatement from "./commercialTransactionStatement";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import CommercialODInvoice from "./commercialOdInvoice";

export default function CommercialODLoan() {
  const { loanId }: { loanId: string } = useParams();
  const storeDispatch = useDispatch();
  const [isAddInvoiceModalActive, setIsAddInvoiceModalActive] = useState(false);

  const loadingQueue = useSelector(
    (state: ReduxState) => state.loan.loadingQueue
  );
  const commercialLoanDetails: CommercialLoanDetails | null = useSelector(
    (state: ReduxState) => state.loan.singleCommercialLoan
  );
  const loanStatementDetails: CommercialODLoanStatement[] | null = useSelector(
    (state: ReduxState) => state.loan.commercialODLoanStatement
  );
  const transactionStatements: CommercialODTransactionStatement[] | null =
    useSelector(
      (state: ReduxState) => state.loan.commercialODTransactionStatement
    );

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function fetchLoanDetails(loanIdToBeFetched: string) {
    setIsLoading(true);
    try {
      await dispatch(storeDispatch, getCommercialLoanById(loanIdToBeFetched));
    } catch (error) {
      setErrorMessage(error?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchLoanStatement(loanIdToBeFetched: string, asOf: Date) {
    setIsLoading(true);
    try {
      if (!loanIdToBeFetched) return;
      await dispatch(
        storeDispatch,
        getCommercialODLoanStatementByLoanId(
          loanIdToBeFetched,
          dateToYYYYMMDD(asOf)
        )
      );
    } catch (error) {
      setErrorMessage(error?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchTransactionStatement(loanIdToBeFetched: string) {
    setIsLoading(true);
    try {
      if (!loanIdToBeFetched) return;
      await dispatch(
        storeDispatch,
        getCommercialODTransactionStatementByLoanId(loanIdToBeFetched)
      );
    } catch (error) {
      setErrorMessage(error?.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchLoanDetails(loanId);
    fetchLoanStatement(loanId, new Date());
    fetchTransactionStatement(loanId);
  }, []);

  if (errorMessage) {
    return <h4 className="text-center">{errorMessage}</h4>;
  }

  return (
    <>
      <div className="d-flex mb-4 justify-content-end">
        <Button
          onClick={() => setIsAddInvoiceModalActive(true)}
          title="Add Invoice"
          size="md"
        >
          Add Invoice Entry
        </Button>
      </div>
      <div className="dashboard-container">
        {isLoading ? (
          <LoaderIcon />
        ) : (
          <>
            <LoanDetails commercialLoanDetails={commercialLoanDetails} />
            <LoanSummary commercialLoanDetails={commercialLoanDetails} />
          </>
        )}
      </div>

      <div className="dashboard-container mt-4">
        <TransactionStatement
          onSuccess={() =>
            dispatch(
              storeDispatch,
              getCommercialODTransactionStatementByLoanId(loanId)
            )
          }
          transactionStatements={transactionStatements}
          isLoanLoading={isLoadingActive(loadingQueue)}
          loanId={loanId}
        />
      </div>

      <div className="dashboard-container mt-4">
        <LoanStatement
          loanStatementDetails={loanStatementDetails}
          isLoanLoading={isLoadingActive(loadingQueue)}
          loanId={loanId}
        />
      </div>
      <Modal
        open={isAddInvoiceModalActive}
        title="Add Invoice Entry"
        size="large"
        hasSubmitButton={false}
        hasCancelButton={false}
        onClose={() => setIsAddInvoiceModalActive(false)}
      >
        <CommercialODInvoice loanId={loanId} />
      </Modal>
    </>
  );
}
