import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getApplicationById,
  updateTermApplicationStatus,
} from "../../actions/application.action";
import Alert from "../../components/alert";
import Button from "../../components/button";
import {
  HorizontalSelect,
  HorizontalInputField,
} from "../../components/input-functions";
import Modal from "../../components/modal";
import {
  STATUS_CHANGE_MODAL_MESSGE,
  STATUS_CHANGE_MODAL_THRESHOLD_AMOUNT,
} from "../../constants";
import dispatch from "../../middleware";
import { TermLoanApplicationStatus } from "../../models";
import { getTodayDateForInputField, splitKeyWithSpace } from "../../utils";

interface TermLoanStatusChangeCardProps {
  status: string;
  amount?: string;
  applicationId: string;
}

export default function TermLoanStatusChangeCard({
  status,
  applicationId,
  amount = "0",
}: TermLoanStatusChangeCardProps) {
  const storeDispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [toStatus, setToStatus] = useState("");
  const [statusChangeMetadata, setStatusChangeMetadata] = useState<
    Record<string, string>
  >({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalVisible, toggleModal] = useState<boolean>(false);
  const updateToStatusRef = useRef<string>("");
  const modalTitle = STATUS_CHANGE_MODAL_MESSGE.replace("<amount>", amount);

  async function handleStatusUpdate() {
    setIsLoading(true);
    setErrorMessage("");
    try {
      await dispatch(
        storeDispatch,
        updateTermApplicationStatus(
          applicationId,
          toStatus,
          statusChangeMetadata
        )
      );
      await dispatch(storeDispatch, getApplicationById(applicationId));
    } catch (e) {
      setErrorMessage(e?.message);
    } finally {
      setIsLoading(false);
      setToStatus("");
    }
  }

  function handleMetadataChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;
    setStatusChangeMetadata((current) => ({ ...current, [name]: value }));
  }

  async function handleToStatusChange(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    const { value } = event.currentTarget;

    if (isStatusChangeFromVerificationToAgreementPending(value)) {
      updateToStatusRef.current = value;
      toggleModal(true);
      return;
    }
    setToStatus(value);
  }

  function handleCloseModal() {
    toggleModal(false);
    setToStatus("");
  }

  function handleSubmitModal() {
    setToStatus(updateToStatusRef.current);
    updateToStatusRef.current = "";
    toggleModal(false);
  }

  function isStatusChangeFromVerificationToAgreementPending(
    nextStatus: string
  ) {
    const isAmountGreaterThanThreshold =
      parseInt(amount, 10) >= STATUS_CHANGE_MODAL_THRESHOLD_AMOUNT;
    const isCurrentStatusVerificationPending =
      status == TermLoanApplicationStatus.VERIFICATION_PENDING;
    const isNextStatusAgreementPending =
      nextStatus == TermLoanApplicationStatus.AGREEMENT_PENDING;
    return (
      isAmountGreaterThanThreshold &&
      isCurrentStatusVerificationPending &&
      isNextStatusAgreementPending
    );
  }

  useEffect(() => {
    if (toStatus === TermLoanApplicationStatus.APPROVED) {
      setStatusChangeMetadata({ emiStartDate: getTodayDateForInputField() });
    } else {
      setStatusChangeMetadata({});
    }
  }, [toStatus]);

  return (
    <>
      <h2 className="user-page-title mb-4">Status</h2>
      <div className="dashboard-container">
        <Alert
          type="danger"
          message={errorMessage}
          canDismiss
          onDismissClick={() => setErrorMessage("")}
        />
        <HorizontalInputField
          label="Current Status"
          value={status || "NA"}
          disabled
        />
        <HorizontalSelect
          label="Update To"
          placeholder="Please select a status"
          options={Object.values(TermLoanApplicationStatus).map((value) => ({
            label: splitKeyWithSpace(value),
            value,
          }))}
          onChange={handleToStatusChange}
          disabled={isLoading}
          value={toStatus}
        />
        {toStatus === TermLoanApplicationStatus.APPROVED && (
          <HorizontalInputField
            type="date"
            name="emiStartDate"
            label="EMI Start Date"
            value={statusChangeMetadata["emiStartDate"]}
            onChange={handleMetadataChange}
          />
        )}
        <Button
          loading={isLoading}
          disabled={!toStatus}
          onClick={handleStatusUpdate}
        >
          Update
        </Button>
      </div>
      <Modal
        open={isModalVisible}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        submitButtonText="Yes, Proceed"
        cancelButtonText="No"
        title={modalTitle}
      ></Modal>
    </>
  );
}
