import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useScreenName from "../../../hooks/useScreenName";
import dispatch from "../../../middleware";
import {
  getWCTLoanDetails,
  getWCTLRepaymentSchedule,
} from "../../../actions/loan.action";
import { useDispatch } from "react-redux";
import { WCTLLoanRepaymentSchedule, WCTLoanDetail } from "../../../dto";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../reducers";
import { dateToDDMMYYYY, downloadCSV, isLoadingActive } from "../../../utils";
import { ReactComponent as Loader } from "../../../images/loader.svg";
import WCTLoanSummary from "./wctLoanSummary";
import { WCTLRepaymentSchedule } from "./wctlRepaymentSchedule";
import { TableColumns } from "../../../components/table";

function getStatus({
  repaymentPendingAmount,
  interest,
  principal,
}: {
  repaymentPendingAmount: number;
  interest: number;
  principal: number;
}): string {
  const totalAmount = principal + interest;

  if (repaymentPendingAmount === 0) return "Repaid";
  if (repaymentPendingAmount === totalAmount) return "Due";
  if (repaymentPendingAmount < totalAmount) return "Partially Paid";
  return "";
}

const COLUMN_FOR_WCTL_REPAYMENT_SCHEDULE: TableColumns[] = [
  { field: "emiNumber", label: "EMI No" },
  { field: "dueAt", label: "Date" },
  { field: "principal", label: "Principal" },
  { field: "interest", label: "Interest" },
  { field: "totalAmount", label: "EMI" },
  { field: "status", label: "Status" },
  { field: "repaidAmount", label: "Repaid Amount" },
];

const getTableDataForRepaymentSchedule = (
  wctlLoanRepaymentSchedule: WCTLLoanRepaymentSchedule[]
) => {
  return wctlLoanRepaymentSchedule.map(
    ({ dueAt, repaymentPendingAmount, interest, loanId, principal }, idx) => ({
      emiNumber: idx + 1,
      dueAt: dateToDDMMYYYY(dueAt),
      principal,
      interest,
      totalAmount: (principal + interest).toFixed(2),
      status: getStatus({ repaymentPendingAmount, interest, principal }),
      repaidAmount: principal + interest - repaymentPendingAmount,
    })
  );
};

export default function WCTLoanDetailsScreen() {
  useScreenName("Loans");
  const { loanId }: { loanId: string } = useParams();
  const storeDispatch = useDispatch();
  const loadingQueue = useSelector(
    (state: ReduxState) => state.loan.loadingQueue
  );
  const wctLoanDetails: WCTLoanDetail | null = useSelector(
    (state: ReduxState) => state.loan.wctlLoan
  );
  const wctlLoanRepaymentSchedule: WCTLLoanRepaymentSchedule[] = useSelector(
    (state: ReduxState) => state.loan.wctlLoanRepaymentSchedule
  );
  const isLoading = isLoadingActive(loadingQueue);
  const [errorMessage, setErrorMessage] = useState("");

  async function fetchWCTLoanDetails() {
    try {
      await dispatch(storeDispatch, getWCTLoanDetails(loanId));
    } catch (error) {
      setErrorMessage(error?.message);
    }
  }

  async function fetchWCTLRepaymentScheduleDetails() {
    try {
      await dispatch(storeDispatch, getWCTLRepaymentSchedule(loanId));
    } catch (error) {
      setErrorMessage(error?.message);
    }
  }

  function handleDownloadRepaymentSchedule() {
    downloadCSV(
      getTableDataForRepaymentSchedule(wctlLoanRepaymentSchedule),
      COLUMN_FOR_WCTL_REPAYMENT_SCHEDULE,
      `${loanId}_Repayment_Schedule`
    );
  }

  useEffect(() => {
    fetchWCTLoanDetails();
    fetchWCTLRepaymentScheduleDetails();
  }, [loanId]);

  if (!wctLoanDetails) return null;

  if (isLoading || !wctLoanDetails)
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Loader />
      </div>
    );

  if (errorMessage) {
    return <h4 className="text-center">{errorMessage}</h4>;
  }

  return (
    <>
      <div className="dashboard-container">
        <WCTLoanSummary loanId={loanId} loanDetails={wctLoanDetails} />
      </div>
      <div className="mt-4 dashboard-container">
        <WCTLRepaymentSchedule
          loanId={loanId}
          onDownloadClick={handleDownloadRepaymentSchedule}
          isLoading={isLoading}
          columns={COLUMN_FOR_WCTL_REPAYMENT_SCHEDULE}
          data={getTableDataForRepaymentSchedule(wctlLoanRepaymentSchedule)}
        />
      </div>
    </>
  );
}
