import { Actions } from "../actions/enterprise.action";
import { UserDocType } from "../dto";
import {
  EnterpriseDetails,
  EnterpriseDetailsV1,
  SingleEnterpriseV1Applications,
  SingleEnterpriseV1Loans,
} from "../dto/enterprise.dto";
import { SearchFilter } from "../models";

export interface EnterpriseState {
  loading: boolean;
  enterprises: EnterpriseDetails[];
  singleEnterprise: EnterpriseDetails | null;
  searchFilters: SearchFilter[];
  paginationCount: number | null;
  docTypes: UserDocType[];
  enterprisesV1: EnterpriseDetailsV1[];
  singleEnterpriseV1: EnterpriseDetailsV1 | null;
  singleEnterpriseV1Loans: SingleEnterpriseV1Loans | null;
  singleEnterpriseV1Applications: SingleEnterpriseV1Applications | null;
}

const defaultState: EnterpriseState = {
  loading: false,
  enterprises: [],
  singleEnterprise: null,
  searchFilters: [],
  paginationCount: null,
  docTypes: [],
  enterprisesV1: [],
  singleEnterpriseV1: null,
  singleEnterpriseV1Loans: null,
  singleEnterpriseV1Applications: null,
};

export default function EnterpriseStateReducer(
  state = defaultState,
  action: any
): EnterpriseState {
  switch (action.type) {
    case Actions.REQUEST_GET_PAGINATED_ENTERPRISES:
    case Actions.REQUEST_GET_ENTERPRISES_BY_SEARCH:
    case Actions.REQUEST_GET_ENTERPRISE_BY_ID_V1:
    case Actions.REQUEST_GET_ALL_ENTERPRISES_V1:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_GET_PAGINATED_ENTERPRISES}_SUCCESS`:
    case `${Actions.REQUEST_GET_ENTERPRISES_BY_SEARCH}_SUCCESS`:
      return {
        ...state,
        loading: false,
        enterprises: action?.payload,
      };
    case `${Actions.REQUEST_GET_PAGINATED_ENTERPRISES}_FAILURE`:
    case `${Actions.REQUEST_GET_ENTERPRISES_BY_SEARCH}_FAILURE`:
      return {
        ...state,
        loading: false,
        enterprises: [],
      };
    case Actions.REQUEST_GET_ENTERPRISE_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_GET_ENTERPRISE_BY_ID}_SUCCESS`:
      return {
        ...state,
        loading: false,
        singleEnterprise: action?.payload,
      };
    case `${Actions.REQUEST_GET_ENTERPRISE_BY_ID}_FAILURE`:
      return {
        ...state,
        loading: false,
        singleEnterprise: null,
      };
    case `${Actions.REQUEST_GET_ALL_ENTERPRISES_V1}_SUCCESS`:
      return {
        ...state,
        loading: false,
        enterprisesV1: action?.payload,
      };
    case `${Actions.REQUEST_GET_ALL_ENTERPRISES_V1}_FAILURE`:
      return {
        ...state,
        loading: false,
        enterprisesV1: [],
      };
    case `${Actions.REQUEST_GET_ENTERPRISE_BY_ID_V1}_SUCCESS`:
      return {
        ...state,
        loading: false,
        singleEnterpriseV1: action?.payload,
      };
    case `${Actions.REQUEST_GET_ENTERPRISE_BY_ID_V1}_FAILURE`:
      return {
        ...state,
        loading: false,
        singleEnterpriseV1: null,
      };
    case Actions.REQUEST_ADD_ENTERPRISE:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_ADD_ENTERPRISE}_SUCCESS`: {
      const prevStateEnterprises = state.enterprises;
      prevStateEnterprises.unshift(action?.payload);
      return {
        ...state,
        loading: false,
        enterprises: [...prevStateEnterprises],
      };
    }
    case `${Actions.REQUEST_ADD_ENTERPRISE}_FAILURE`:
      return {
        ...state,
        loading: false,
      };
    case Actions.REQUEST_UPDATE_ENTERPRISE:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_UPDATE_ENTERPRISE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        singleEnterprise: action?.payload,
      };
    case `${Actions.REQUEST_UPDATE_ENTERPRISE}_FAILURE`:
      return {
        ...state,
        loading: false,
      };
    case Actions.REQUEST_UPDATE_ENTERPRISE_STATUS:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_UPDATE_ENTERPRISE_STATUS}_SUCCESS`:
      return {
        ...state,
        loading: false,
        singleEnterprise: action?.payload,
      };
    case `${Actions.REQUEST_UPDATE_ENTERPRISE_STATUS}_FAILURE`:
      return {
        ...state,
        loading: false,
      };
    case Actions.REQUEST_UPDATE_ENTERPRISE_COAPPLICANTS:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_UPDATE_ENTERPRISE_COAPPLICANTS}_SUCCESS`:
    case `${Actions.REQUEST_UPDATE_ENTERPRISE_COAPPLICANTS}_FAILURE`:
      return {
        ...state,
        loading: false,
      };
    case Actions.REQUEST_STORE_DOC_OF_ENTERPRISE:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_STORE_DOC_OF_ENTERPRISE}_SUCCESS`:
      return {
        ...state,
        loading: false,
      };
    case `${Actions.REQUEST_STORE_DOC_OF_ENTERPRISE}_FAILURE`:
      return {
        ...state,
        loading: false,
      };
    case Actions.REQUEST_GET_DOC_OF_ENTERPRISE:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_GET_DOC_OF_ENTERPRISE}_SUCCESS`: {
      const prevState = state;
      if (prevState.singleEnterprise) {
        prevState.singleEnterprise.documents = Array.isArray(action?.payload)
          ? action?.payload
          : [];
      }
      return {
        ...prevState,
        loading: false,
      };
    }
    case `${Actions.REQUEST_GET_DOC_OF_ENTERPRISE}_FAILURE`:
      return {
        ...state,
        loading: false,
      };
    case Actions.REQUEST_GET_ENTERPRISE_DOC_TYPES:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_GET_ENTERPRISE_DOC_TYPES}_SUCCESS`:
      return {
        ...state,
        loading: false,
        docTypes: action?.payload,
      };
    case `${Actions.REQUEST_GET_ENTERPRISE_DOC_TYPES}_FAILURE`:
      return {
        ...state,
        loading: false,
        docTypes: [],
      };
    case Actions.REQUEST_GET_ENTERPRISE_SEARCH_FILTERS:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_GET_ENTERPRISE_SEARCH_FILTERS}_SUCCESS`:
      return {
        ...state,
        loading: false,
        searchFilters: action?.payload?.filters || [],
      };
    case `${Actions.REQUEST_GET_ENTERPRISE_SEARCH_FILTERS}_FAILURE`:
      return {
        ...state,
        loading: false,
        searchFilters: [],
      };
    case Actions.REQUEST_GET_ENTERPRISES_BY_SEARCH:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_GET_ENTERPRISES_BY_SEARCH}_SUCCESS`:
      return {
        ...state,
        loading: false,
        enterprises: action?.payload,
      };
    case `${Actions.REQUEST_GET_ENTERPRISES_BY_SEARCH}_FAILURE`:
      return {
        ...state,
        loading: false,
        enterprises: [],
      };
    case Actions.REQUEST_GET_PAGINATED_ENTERPRISES_COUNT:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_GET_PAGINATED_ENTERPRISES_COUNT}_SUCCESS`:
      return {
        ...state,
        loading: false,
        paginationCount: action?.payload?.enterpriseUsersCount,
      };
    case `${Actions.REQUEST_GET_PAGINATED_ENTERPRISES_COUNT}_FAILURE`:
      return {
        ...state,
        loading: false,
        paginationCount: null,
      };
    case Actions.REQUEST_SINGLE_ENTERPRISE_LOAN_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_SINGLE_ENTERPRISE_LOAN_BY_ID}_SUCCESS`:
      return {
        ...state,
        loading: false,
        singleEnterpriseV1Loans: action?.payload,
      };
    case `${Actions.REQUEST_SINGLE_ENTERPRISE_LOAN_BY_ID}_FAILURE`:
      return {
        ...state,
        loading: false,
        singleEnterpriseV1Loans: null,
      };
    case Actions.REQUEST_SINGLE_ENTERPRISE_APPLICATION_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_SINGLE_ENTERPRISE_APPLICATION_BY_ID}_SUCCESS`:
      return {
        ...state,
        loading: false,
        singleEnterpriseV1Applications: action?.payload,
      };
    case `${Actions.REQUEST_SINGLE_ENTERPRISE_APPLICATION_BY_ID}_FAILURE`:
      return {
        ...state,
        loading: false,
        singleEnterpriseV1Applications: null,
      };
    default:
      return state;
  }
}
