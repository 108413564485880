import { Actions } from "../actions/product.action";
import { AllProducts, RetailODProduct, TermLoanProduct } from "../dto";

export interface ProductState {
  loading: boolean;
  products: AllProducts;
  singleProduct: TermLoanProduct | RetailODProduct | null;
}

const defaultState: ProductState = {
  loading: false,
  products: {
    termLoanProducts: [],
    retailOdProducts: [],
  },
  singleProduct: null,
};

export default function ProductStateReducer(
  state: ProductState = defaultState,
  action: any
): ProductState {
  switch (action.type) {
    case Actions.REQUEST_GET_ALL_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_GET_ALL_PRODUCTS}_SUCCESS`:
      return {
        ...state,
        loading: false,
        products: action?.payload,
      };
    case `${Actions.REQUEST_GET_ALL_PRODUCTS}_FAILURE`:
      return {
        ...state,
        loading: false,
        products: {
          termLoanProducts: [],
          retailOdProducts: [],
        },
      };
    case Actions.REQUEST_GET_PRODUCT_BY_NAME_AND_VERSION:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_GET_PRODUCT_BY_NAME_AND_VERSION}_SUCCESS`:
      return {
        ...state,
        loading: false,
        singleProduct: action?.payload,
      };
    case `${Actions.REQUEST_GET_PRODUCT_BY_NAME_AND_VERSION}_FAILURE`:
      return {
        ...state,
        loading: false,
        singleProduct: null,
      };
    default:
      return state;
  }
}
