import Button from "../../components/button";
import Table, { TableColumns } from "../../components/table";
import { InvoiceDetails } from "../../dto/invoice.dto";
import { ReactComponent as DownloadIcon } from "../../images/download.svg";
import { InvoiceStatusType } from "../../models";
import { splitKeyWithSpace } from "../../utils";
import {
  getFormattedAddress,
  getTotalAmount,
  getTotalCharge,
  getTotalGst,
} from "./helper";

interface InvoiceTableProps {
  loading: boolean;
  invoices: InvoiceDetails[];
  columns: TableColumns[];
  statusBadge: (status: InvoiceStatusType) => JSX.Element | null;
  onUploadClick: (invoiceId: string) => void;
  onDownloadInvoice: (invoiceId: string) => Promise<void>;
}

interface InvoiceIDWithDownloadIconProps {
  id: string;
  onDownloadInvoice: (invoiceId: string) => Promise<void>;
}

interface UploadButtonProps {
  onUploadClick: () => void;
}

function getTableData({
  invoices,
  statusBadge,
  onUploadClick,
  onDownloadInvoice,
}: {
  invoices: InvoiceDetails[];
  statusBadge: (status: InvoiceStatusType) => JSX.Element | null;
  onUploadClick: (invoiceId: string) => void;
  onDownloadInvoice: (invoiceId: string) => Promise<void>;
}) {
  return invoices.map(
    ({ _id, name, address, amountBreakup, invoiceAttachmentExists }) => ({
      key: _id,
      id: (
        <InvoiceIDWithDownloadIcon
          id={_id}
          onDownloadInvoice={onDownloadInvoice}
        />
      ),
      name: splitKeyWithSpace(name),
      address: getFormattedAddress(address),
      totalCharge: getTotalCharge(amountBreakup),
      totalGst: getTotalGst(amountBreakup),
      totalAmount: getTotalAmount(amountBreakup),
      status: statusBadge(
        invoiceAttachmentExists === false ? "PENDING" : "SUCCESS"
      ),
      fileUpload: <UploadButton onUploadClick={() => onUploadClick(_id)} />,
    })
  );
}

export default function InvoiceTable({
  invoices,
  columns,
  loading,
  statusBadge,
  onUploadClick,
  onDownloadInvoice,
}: InvoiceTableProps) {
  return (
    <div className="dashboard-container">
      <Table
        loading={loading}
        data={getTableData({
          invoices,
          statusBadge,
          onUploadClick,
          onDownloadInvoice,
        })}
        columns={columns}
      />
    </div>
  );
}

function InvoiceIDWithDownloadIcon({
  id,
  onDownloadInvoice,
}: InvoiceIDWithDownloadIconProps) {
  return (
    <div className="d-flex align-items-center">
      <span className="mr-1">{id}</span>
      <DownloadIcon
        onClick={() => onDownloadInvoice(id)}
        style={{ cursor: "pointer", color: "#0057b7" }}
      />
    </div>
  );
}

function UploadButton({ onUploadClick }: UploadButtonProps) {
  return (
    <Button
      onClick={onUploadClick}
      title="Upload"
      size="sm"
      variant="blue-outline"
    >
      Upload
    </Button>
  );
}
