import { ChangeEvent, FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../reducers";
import { getDefaultFormDataForWctlApplication } from "../utils";
import Alert from "../../../components/alert";
import { HorizontalInputField } from "../../../components/input-functions";
import Button from "../../../components/button";
import { UpdateWCTLApplication } from "../../../dto/enterprise.dto";
import { useDispatch } from "react-redux";
import dispatch from "../../../middleware";
import { updateWCTLApplication } from "../../../actions/loan.action";
import { ReactComponent as TickIcon } from "../../../images/tick.svg";

interface EditWCTLApplicationProps {
  appId: string;
}

function getFormattedPayload(
  formData: UpdateWCTLApplication
): UpdateWCTLApplication {
  return {
    irpa: Number(formData.irpa),
    pf: Number(formData.pf),
    approvedAmount: Number(formData.approvedAmount),
    tenureInMonths: Number(formData.tenureInMonths),
    moratoriumInMonths: Number(formData.moratoriumInMonths),
  };
}

export default function EditWCTLApplication({
  appId,
}: EditWCTLApplicationProps) {
  const storeDispatch = useDispatch();
  const wctlApplicationDetails = useSelector(
    (state: ReduxState) => state.application.singleWCTLApplication
  );
  const [formData, setFormData] = useState(
    getDefaultFormDataForWctlApplication(wctlApplicationDetails)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    transformValue?: (value: string) => void
  ) => {
    const value = e.target.value;
    const inputMode = e.target.inputMode;
    if (inputMode === "numeric" && Number.isNaN(Number(value))) return;

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: transformValue ? transformValue(value) : value,
    }));
  };

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsLoading(true);
    try {
      const payload: UpdateWCTLApplication = getFormattedPayload(formData);
      const response = await dispatch(
        storeDispatch,
        updateWCTLApplication({ appId, body: payload })
      );

      if (!response) return;

      setSuccessMessage(
        `Application updated successfully with ID # ${response?._id}`
      );
    } catch (error: any) {
      setErrorMessage(error.message || "Something went wrong, Try again later");
    } finally {
      setIsLoading(false);
    }
  }

  if (successMessage) {
    return (
      <div className="d-flex flex-column align-items-center">
        <TickIcon color="#5BC97C" />
        <p style={{ marginTop: "20px", marginBottom: "0" }}>{successMessage}</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Alert
        type="danger"
        message={errorMessage}
        canDismiss
        onDismissClick={() => setErrorMessage("")}
      />
      <HorizontalInputField
        label="IRPA"
        type="text"
        inputMode="numeric"
        name="irpa"
        value={formData.irpa}
        onChange={handleChange}
        required
      />
      <HorizontalInputField
        label="Pf"
        type="text"
        inputMode="numeric"
        name="pf"
        value={formData.pf}
        onChange={handleChange}
        required
      />
      <HorizontalInputField
        label="Approved Amount"
        type="text"
        inputMode="numeric"
        name="approvedAmount"
        value={formData.approvedAmount}
        onChange={handleChange}
        required
      />
      <HorizontalInputField
        label="Tenure In Months"
        type="text"
        inputMode="numeric"
        name="tenureInMonths"
        value={formData.tenureInMonths}
        onChange={handleChange}
        required
      />
      <HorizontalInputField
        label="Moratorium In Months"
        type="text"
        inputMode="numeric"
        name="moratoriumInMonths"
        value={formData.moratoriumInMonths}
        onChange={handleChange}
        required
      />

      <div className="mt-4">
        <Button type="submit" loading={isLoading}>
          Update
        </Button>
      </div>
    </form>
  );
}
