import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import dispatch from "../../middleware";
import { UserDetails } from "../../dto";
import { ReduxState } from "../../reducers";
import { useDispatch } from "react-redux";
import {
  getPaginationUserCount,
  getUserBySearch,
  getUserSearchFilters,
} from "../../actions/user.action";
import Table from "../../components/table";
import { RoutePaths, SearchFilter, UserStatus } from "../../models";
import Badge from "../../components/badge";
import Pagination from "../../components/pagination";
import useScreenName from "../../hooks/useScreenName";
import Button from "../../components/button";
import Modal from "../../components/modal";
import { SearchBox } from "../../components/input-functions";
import CreateUserForm from "./createUserForm";
import { renderDataFieldsValue } from "../user-details/helpers";
import MultiSelect, {
  convertFilterArrayToObject,
  convertFilterObjectToArray,
  MultiSelectOption,
} from "../../components/multi-select";
import {
  clearFormData,
  getPaginationCount,
  isLoadingActive,
  splitKeyWithSpace,
} from "../../utils";
import useQueryParams from "../../hooks/useQueryParams";

const columns = [
  { field: "_id", label: "User ID" },
  { field: "name", label: "Name" },
  { field: "phone", label: "Phone Number" },
  { field: "pan", label: "PAN" },
  { field: "source", label: "Source" },
  { field: "status", label: "Status" },
];

type SearchQueryType = {
  pageNo: number;
  searchText: string;
  numberOfEntitiesPerPage: number;
  status: string[];
  source: string[];
};

const DEFAULT_SEARCH_QUERY_STATE: SearchQueryType = {
  pageNo: 1,
  searchText: "",
  numberOfEntitiesPerPage: 10,
  status: [],
  source: [],
};

export default function UserScreen() {
  useScreenName("Users / Individual");
  const storeDispatch = useDispatch();
  const users: UserDetails[] = useSelector(
    (state: ReduxState) => state.user.users
  );
  const paginationCount: number | null = useSelector(
    (state: ReduxState) => state.user.paginationCount
  );
  const userSearchFilters: SearchFilter[] = useSelector(
    (state: ReduxState) => state.user.searchFilters
  );
  const loadingQueue = useSelector(
    (state: ReduxState) => state.user.loadingQueue
  );
  const [isModalActive, setIsModalActive] = useState(false);

  const [
    { pageNo, searchText, numberOfEntitiesPerPage, status, source },
    setSearchQuery,
  ] = useQueryParams<SearchQueryType>(DEFAULT_SEARCH_QUERY_STATE);

  const filterOptions = useMemo(
    () =>
      userSearchFilters.reduce(
        (accum, group) => [
          ...accum,
          ...group.values.map((value) => ({
            group: group.name,
            value,
            label: splitKeyWithSpace(value),
          })),
        ],
        [] as MultiSelectOption[]
      ),
    [userSearchFilters]
  );

  useEffect(() => {
    if (userSearchFilters.length > 0) return;
    dispatch(storeDispatch, getUserSearchFilters());
  }, []);

  useEffect(() => {
    dispatch(
      storeDispatch,
      getUserBySearch(
        clearFormData({
          numberOfEntitiesPerPage,
          pageNo,
          searchText,
          status,
          source,
        })
      )
    );
  }, [pageNo, searchText, numberOfEntitiesPerPage, status, source]);

  useEffect(() => {
    dispatch(
      storeDispatch,
      getPaginationUserCount(clearFormData({ searchText, status, source }))
    );
  }, [searchText, status, source]);

  function handleSearch(searchText: string) {
    setSearchQuery((prevState) => ({ ...prevState, searchText, pageNo: 1 }));
  }

  function handleFilterChanges(options: MultiSelectOption[]) {
    const filterObject = convertFilterArrayToObject(options);
    setSearchQuery((prevState) => ({
      ...prevState,
      ...{ status: [], source: [] },
      ...filterObject,
      pageNo: 1,
    }));
  }

  function getStatusBadge(status: UserStatus) {
    if (status === "VERIFIED") return <Badge type="success">Verified</Badge>;
    if (status === "PENDING") return <Badge type="pending">Pending</Badge>;
    if (status === "REJECTED") return <Badge type="reject">Rejected</Badge>;
    return "";
  }
  function getIdLink(id: string) {
    return (
      <Link to={`${RoutePaths.INDIVIDUAL_USERS}/${id}`} className="table-link">
        {id}
      </Link>
    );
  }

  function getTableData(userArr: UserDetails[]) {
    return userArr.map((eachUser) => {
      const { _id, name, status, source } = eachUser;
      return {
        _id: getIdLink(_id),
        name: name?.data,
        phone: renderDataFieldsValue("phone", eachUser),
        pan: renderDataFieldsValue("pan", eachUser),
        source: splitKeyWithSpace(source) || "-",
        status: getStatusBadge(status),
      };
    });
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-4">
        <Button
          size="sm"
          onClick={() => setIsModalActive((prevState) => !prevState)}
        >
          Add User
        </Button>
      </div>
      <div className="dashboard-container">
        <div className="d-flex flex-lg-row flex-column align-items-lg-end mb-4">
          <div className="search-input-user mr-lg-3 mb-4 mb-lg-0">
            <MultiSelect
              label="Filter"
              options={filterOptions}
              groupped
              value={convertFilterObjectToArray({ source, status })}
              onChange={handleFilterChanges}
            />
          </div>
          <SearchBox
            value={searchText}
            label="Search"
            placeholder="Search by Name, Email or Phone"
            onSubmit={handleSearch}
          />
        </div>
        <Table
          loading={isLoadingActive(loadingQueue)}
          columns={columns}
          data={getTableData(users)}
        />
      </div>
      <Pagination
        currentPage={pageNo}
        totalEntries={getPaginationCount(
          paginationCount,
          numberOfEntitiesPerPage
        )}
        numberOfEntriesPerPage={numberOfEntitiesPerPage}
        onPageChange={(pageNo) =>
          setSearchQuery((prevState) => ({
            ...prevState,
            pageNo,
          }))
        }
        onPerPageCountChange={(countPerPage) =>
          setSearchQuery((prevState) => ({
            ...prevState,
            numberOfEntitiesPerPage: countPerPage,
            pageNo: 1,
          }))
        }
      />
      <Modal
        open={isModalActive}
        title="Add User"
        onClose={() => setIsModalActive((prevState) => !prevState)}
        hasCancelButton={false}
        hasSubmitButton={false}
      >
        <CreateUserForm />
      </Modal>
    </>
  );
}
