import DatafieldSection from "../../../components/datafield-section";
import { CommercialLoanDetails } from "../../../dto";
import { FieldLabel } from "../../../models";
import { splitKeyWithSpace } from "../../../utils";
import { refactorCommercialLoanData } from "../helpers";

interface LoanSummaryProps {
  commercialLoanDetails: CommercialLoanDetails | null;
}

export default function LoanSummary({
  commercialLoanDetails,
}: LoanSummaryProps) {
  const leftLabelsForLoanSummary: Record<"COMMERCIAL_OD", FieldLabel[]> = {
    COMMERCIAL_OD: [
      {
        key: "loanType",
        label: "Loan Type :",
        formatMethod: splitKeyWithSpace,
      },
      { key: "sanctionedAmount", label: "Sanctioned Amount :", prefix: "Rs " },
      { key: "irpa", label: "Interest Rate :", suffix: "% PA" },
      {
        key: "drawdownRepaymentPeriodInDays",
        label: "Drawdown Repayment Period In Days :",
      },
      {
        key: "interestRepaymentPeriodInDays",
        label: "Interests Repayment Period In Days :",
      },
    ],
  };

  const rightLabelsForLoanSummary: Record<"COMMERCIAL_OD", FieldLabel[]> = {
    COMMERCIAL_OD: [
      {
        key: "totalDrawdownAmount",
        label: "Total Drawdown :",
        prefix: " Rs",
      },
      {
        key: "totalOutstandingAmount",
        label: "Total Outstanding :",
        prefix: " Rs",
      },
    ],
  };

  return (
    <>
      <DatafieldSection
        title="Loan Summary"
        labels={[
          { items: leftLabelsForLoanSummary["COMMERCIAL_OD"] },
          { items: rightLabelsForLoanSummary["COMMERCIAL_OD"] },
        ]}
        data={refactorCommercialLoanData(commercialLoanDetails)}
      />
    </>
  );
}
