import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setScreenName } from "../actions/ui.action";
import dispatch from "../middleware";

const APP_TITLE = "LMS Portal";
export default function useScreenName(screenName: string) {
    const storeDispatch = useDispatch();
    function setAppTitle(screenName: string) {
        if (screenName === "") {
            window.document.title = APP_TITLE;
            return;
        }
        window.document.title = `${screenName} - ${APP_TITLE}`;
    }
    useEffect(() => {
        dispatch(storeDispatch, setScreenName(screenName));
        setAppTitle(screenName);
        return () => {
            dispatch(storeDispatch, setScreenName(""));
            setAppTitle("");
        }
    }, [])
}
