import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useRouteMatch, Switch, Route } from "react-router-dom";
import {
  clearUser,
  getUserById,
  clearCkycData,
  getUserVkycDetails,
} from "../../../actions/user.action";
import { UserDetails, VkycDetails } from "../../../dto";
import dispatch from "../../../middleware";
import { ReduxState } from "../../../reducers";
import useScreenName from "../../../hooks/useScreenName";
import "../userDetailsStyles.css";
import Tabmenu from "../../../components/tabmenu";
import { ReactComponent as UserInfoIcon } from "../../../images/document_text.svg";
import { ReactComponent as UserDocIcon } from "../../../images/document_file.svg";
import { ReactComponent as UserReportIcon } from "../../../images/document_report.svg";
import UserInformation from "./userInformation";
import UserDocuments from "../documents";
import UserCreditReport from "./credit-report";
import { getUserSelfieDataUrl, isLoadingActive } from "../../../utils";

export default function SingleUserScreen() {
  useScreenName("Users / Individual");
  const { userId } = useParams();
  const { url: pathUrl, path } = useRouteMatch();
  const storeDispatch = useDispatch();
  const userDetails: UserDetails | null = useSelector(
    (state: ReduxState) => state.user.singleUser
  );
  const vkycDetails: VkycDetails | null = useSelector(
    (state: ReduxState) => state.user.vkycDetails
  );
  const loadingQueue = useSelector(
    (state: ReduxState) => state.user.loadingQueue
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [selfieDataUrl, setSelfieDataUrl] = useState<string | null>(null);

  async function setUserSelfie() {
    const dataUrl = await getUserSelfieDataUrl(storeDispatch, userId);
    if (!dataUrl) return;
    setSelfieDataUrl(dataUrl);
  }

  async function fetchUserVkycDetails() {
    try {
      await dispatch(storeDispatch, getUserVkycDetails(userId));
    } catch (e: any) {
      setErrorMessage(e?.message);
    }
  }

  useEffect(() => {
    dispatch(storeDispatch, getUserById(userId))
      .then(setUserSelfie)
      .catch((e) => setErrorMessage(e?.message));

    fetchUserVkycDetails();

    return () => {
      dispatch(storeDispatch, clearUser());
      dispatch(storeDispatch, clearCkycData());
    };
  }, [userId]);
  if (errorMessage) {
    return <h4 className="text-center">{errorMessage}</h4>;
  }
  if (!userDetails || userDetails._id !== userId) {
    return <h4 className="text-center">Loading...</h4>;
  }
  return (
    <>
      <Tabmenu
        items={[
          {
            name: "Information",
            path: `${pathUrl}`,
            exact: true,
            Icon: UserInfoIcon,
          },
          {
            name: "Documents",
            path: `${pathUrl}/documents`,
            Icon: UserDocIcon,
          },
          {
            name: "Credit Report",
            path: `${pathUrl}/credit-report`,
            Icon: UserReportIcon,
          },
        ]}
      />
      <Switch>
        <Route path={`${path}/documents`}>
          <UserDocuments userDetails={userDetails} />
        </Route>
        <Route path={`${path}/credit-report`}>
          <UserCreditReport userDetails={userDetails} />
        </Route>
        <Route path={`${path}/`}>
          <UserInformation
            seflieUrl={selfieDataUrl}
            userDetails={userDetails}
            vkycDetails={vkycDetails}
            isLoading={isLoadingActive(loadingQueue)}
          />
        </Route>
      </Switch>
    </>
  );
}
