import { CreditReportScoreDetails } from "../../../../models";
import { dateToDDMMYYYY, isEmpty } from "../../../../utils";
import { UserDetails } from "../../../../dto";

export function getCreditScore(scores?: CreditReportScoreDetails[]) {
  if (!scores || !scores.length) return "-";
  return scores[0].score;
}

export function formatDateFields(
  objArr: Record<string, any>[] = [],
  dateFields: string[]
) {
  return objArr.map((eachObj) => {
    const tempObj = { ...eachObj };
    dateFields.forEach((dateField) => {
      if (tempObj[dateField]) {
        tempObj[dateField] = dateToDDMMYYYY(tempObj[dateField]);
      }
    });
    return tempObj;
  });
}

export function convertDateToReadableFormat(date: Date) {
  return new Date(date).toDateString();
}

export function getMissingCreditReportEssentails(userDetails: UserDetails) {
  const missing = [];

  if (isEmpty(userDetails.name?.data)) {
    missing.push("Name");
  }
  if (isEmpty(userDetails.pan?.data?.pan)) {
    missing.push("Pan");
  }
  const isPhoneNumberMissing =
    userDetails.phone?.data && isEmpty(Object.values(userDetails.phone?.data));
  if (isPhoneNumberMissing) {
    missing.push("PhoneNumber");
  }
  const isAddressMissing =
    userDetails.address?.data &&
    isEmpty(Object.values(userDetails.address.data));
  if (isAddressMissing) {
    missing.push("Address");
  }

  return missing;
}
