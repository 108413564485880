import { useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";

interface QueryParamMap {
  [paramName: string]: string | string[] | number;
}
type SetQuery<T> = T | ((prevState: T) => T);
type Dispatch<T> = (value: T) => void;

export default function useQueryParams<QPMap extends QueryParamMap>(
  defaultState: QPMap,
  config?: { historyAction: "push" | "replace" }
): [QPMap, Dispatch<SetQuery<QPMap>>] {
  const { historyAction = "push" } = config || {};
  const location = useLocation();
  const history = useHistory();

  const value = useMemo(() => {
    const paramsObj = queryString.parse(location.search, {
      parseNumbers: true,
      arrayFormat: "bracket",
    });
    // TODO: Fix merging string & string[]
    const updatedValue = { ...defaultState, ...paramsObj };
    return updatedValue;
  }, [location.search]);

  function setValue(newValue: SetQuery<QPMap>) {
    const newValueToBeUsed =
      newValue instanceof Function ? newValue(value) : newValue;

    const searchQuery = queryString.stringify(newValueToBeUsed, { arrayFormat: "bracket" });

    history[historyAction]({
      ...location,
      search: `?${searchQuery}`,
    });
  }

  return [value, setValue];
}
