import { BankStatementFile } from ".";
import Button from "../../components/button";
import FileCard from "../../components/file-card";
import { PasswordFile } from "../../models";
import { formatBytes } from "../../utils";

type BankStatementFileCardProps = {
  files: BankStatementFile[];
  onPasswordChange: ({ name, type, password }: PasswordFile) => void;
  onRemove: (file: BankStatementFile) => void;
  onRemoveAllFiles: () => void;
};

export default function BankStatementFileCard({
  files,
  onPasswordChange,
  onRemove,
  onRemoveAllFiles,
}: BankStatementFileCardProps) {
  if (files.length < 1) return null;
  return (
    <div className="file-card-container">
      <div className="mb-3 d-flex justify-content-end">
        <Button
          type="button"
          size="sm"
          variant="danger-text"
          onClick={onRemoveAllFiles}
        >
          Remove all files
        </Button>
      </div>
      {/** INFO: All Files have unqiue name and type  */}
      {files.map(({ file }) => (
        <FileCard
          key={`${file.name}_${file.type}_${file.size}}`}
          title={file.name}
          subtitle={formatBytes(file.size)}
          type={file.type}
          fileName={file.name}
          hasPassword
          hasCloseIcon
          onPasswordInput={onPasswordChange}
          onRemove={() => onRemove({ file })}
        />
      ))}
    </div>
  );
}
