import { getTodayDateForInputField, isEmptyString } from "../../utils";

type DateRangeProps = {
  startDate: string;
  endDate: string;
  handleDateChange: (fieldName: string, val: string) => void;
};

export default function DateRange({
  startDate,
  endDate,
  handleDateChange,
}: DateRangeProps) {
  return (
    <div className="d-flex flex-lg-row flex-column align-items-lg-end">
      <div className="col-md-auto px-0 px-lg-3">
        <div className="form-signin-label-group mb-lg-0">
          <input
            type="date"
            id="fromDate"
            className="form-control input-login"
            placeholder="From Date"
            value={startDate}
            onChange={(e) => handleDateChange("startDate", e.target.value)}
            max={getTodayDateForInputField()}
            required
          />
          <label htmlFor="fromDate">From Date</label>
        </div>
      </div>
      <div className="col-md-auto px-0 px-lg-3">
        <div className="form-signin-label-group mb-lg-0">
          <input
            type="date"
            id="toDate"
            className="form-control input-login"
            placeholder="To Date"
            value={endDate}
            onChange={(e) => handleDateChange("endDate", e.target.value)}
            disabled={isEmptyString(startDate)}
            min={startDate}
            max={getTodayDateForInputField()}
            required
          />
          <label htmlFor="toDate">To Date</label>
        </div>
      </div>
    </div>
  );
}
