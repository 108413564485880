import { useState } from "react";
import { CommercialODApplicationDocsEnum, FileType } from "../../../../models";
import { useDispatch } from "react-redux";
import dispatch from "../../../../middleware";
import {
  getCommercialODDocument,
  uploadCommercilODApplicationDocument,
} from "../../../../actions/application.action";
import Modal from "../../../../components/modal";
import { downloadFileFromBase64, splitKeyWithSpace } from "../../../../utils";
import DocumentUpload from "../../../../components/document-upload";
import CommercialODApplicationDocumentList from "./commercialODApplicationDocumentLists";
import { CommercialODDoc } from "../../../../dto/commercial.dto";

interface CommercialODApplicationDocumentUploadProps {
  appId: string;
}

export default function CommercialODApplicationDocumentUpload({
  appId,
}: CommercialODApplicationDocumentUploadProps) {
  const storeDispatch = useDispatch();
  const [uploadModal, setUploadModal] = useState<{
    active: boolean;
    docName: string;
  }>({ active: false, docName: "" });
  const [errorModal, setErrorModal] = useState<{
    title: string;
    message: string;
    active: boolean;
  }>({ title: "", message: "", active: false });
  const [isDocumentDownloading, setIsDocumentDownloading] = useState<{
    [docName: string]: boolean;
  }>({});

  const DOCUMENTS = [
    {
      name: CommercialODApplicationDocsEnum.ITR,
      downloadDocument: handleDownloadDocument,
      uploadDocument: handleUploadDocument,
    },
    {
      name: CommercialODApplicationDocsEnum.BANK_STATEMENT,
      downloadDocument: handleDownloadDocument,
      uploadDocument: handleUploadDocument,
    },
    {
      name: CommercialODApplicationDocsEnum.DEBTOR_DETAILS,
      downloadDocument: handleDownloadDocument,
      uploadDocument: handleUploadDocument,
    },
    {
      name: CommercialODApplicationDocsEnum.FINANCIAL_STATEMENTS,
      downloadDocument: handleDownloadDocument,
      uploadDocument: handleUploadDocument,
    },
    {
      name: CommercialODApplicationDocsEnum.GST_DOCUMENTS,
      downloadDocument: handleDownloadDocument,
      uploadDocument: handleUploadDocument,
    },
    {
      name: CommercialODApplicationDocsEnum.INCORPORATION_CERTIFICATE,
      downloadDocument: handleDownloadDocument,
      uploadDocument: handleUploadDocument,
    },
    {
      name: CommercialODApplicationDocsEnum.LOAN_DETAILS_WITH_REPAYMENT_SCHEDULES,
      downloadDocument: handleDownloadDocument,
      uploadDocument: handleUploadDocument,
    },
    {
      name: CommercialODApplicationDocsEnum.OD_STATEMENT,
      downloadDocument: handleDownloadDocument,
      uploadDocument: handleUploadDocument,
    },
    {
      name: CommercialODApplicationDocsEnum.PAYROLL_DETAILS,
      downloadDocument: handleDownloadDocument,
      uploadDocument: handleUploadDocument,
    },
    {
      name: CommercialODApplicationDocsEnum.PROVISIONAL_OF_FINANCIALS,
      downloadDocument: handleDownloadDocument,
      uploadDocument: handleUploadDocument,
    },
    {
      name: CommercialODApplicationDocsEnum.SIGNED_LENDER_AGREEMENT,
      downloadDocument: handleDownloadDocument,
      uploadDocument: handleUploadDocument,
    },
  ];

  async function handleDownloadDocument(docName: string) {
    setIsDocumentDownloading((prevState) => ({
      ...prevState,
      [docName]: true,
    }));
    try {
      const { type, body }: CommercialODDoc = await dispatch(
        storeDispatch,
        getCommercialODDocument(appId, docName)
      );

      downloadFileFromBase64({
        base64: body,
        fileName: docName,
        fileType: type,
      });
    } catch (error: any) {
      setErrorModal({
        title: "Invoice List",
        message: error?.message ?? "Something Went Wrong!!",
        active: true,
      });
    } finally {
      setIsDocumentDownloading((prevState) => ({
        ...prevState,
        [docName]: false,
      }));
    }
  }

  function handleUploadDocument(docName: string) {
    setUploadModal({ active: true, docName });
  }

  async function handleFileUpload(fileInB64: string, fileType: string) {
    const payload = {
      file: fileInB64,
      documentName: uploadModal.docName,
      contentType: fileType,
      password: "",
    };

    await dispatch(
      storeDispatch,
      uploadCommercilODApplicationDocument(appId, payload)
    );
  }

  return (
    <>
      <CommercialODApplicationDocumentList
        documents={DOCUMENTS}
        isDocDownloading={isDocumentDownloading}
      />
      <Modal
        open={uploadModal.active}
        title={`Upload ${splitKeyWithSpace(uploadModal.docName)}`}
        onClose={() =>
          setUploadModal((prevState) => ({ ...prevState, active: false }))
        }
        hasCancelButton={false}
        hasSubmitButton={false}
      >
        <DocumentUpload
          accepts={[FileType.APP_PDF, FileType.IMG_PNG]}
          onFileSubmit={handleFileUpload}
        />
      </Modal>
      <Modal
        open={errorModal.active}
        title={errorModal.title}
        onClose={() =>
          setErrorModal((prevError) => ({ ...prevError, active: false }))
        }
        hasCancelButton={false}
        hasSubmitButton={false}
      >
        {errorModal.message}
      </Modal>
    </>
  );
}
