import React, { useEffect, useState } from "react";
import { isEmail } from "../../utils";
import { sendResetPwEmail } from "../../actions/login.action";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import { showNotifier } from "../../actions/ui.action";
import { NotifierBgColor } from "../../models";
import dispatch from "../../middleware";
import { Link } from "react-router-dom";
import Logo from "../../images/lms-logo.svg";

function handleForgotPassword(email: string, storeDispatch: any) {
  if (isEmail(email)) {
    sendResetPwEmail(email, storeDispatch);
  }
}

export default function ForgotPassword() {
  const [email, updateEmail] = useState("");
  const firebaseResponse = useSelector(
    (state: ReduxState) => state.login.firebaseResponse
  );
  const loading: boolean = useSelector(
    (state: ReduxState) => state.login.loading
  );
  const storeDispatch = useDispatch();

  useEffect(() => {
    const bgColor =
      firebaseResponse.type === "SUCCESS"
        ? NotifierBgColor.SUCCESS
        : NotifierBgColor.ERROR;
    dispatch(
      storeDispatch,
      showNotifier(bgColor, firebaseResponse.response.message)
    );
  }, [firebaseResponse.type !== ""]);
  return (
    <div className="auth-wrapper">
      <div className="auth-card">
        <div className="text-center">
          <img src={Logo} alt="Logo" className="logo-login mb-md-5 mb-4" />
        </div>
        <div className={"text-center"}>
          <p className={"subheading mb-1 fs-24"}>Forgot your password</p>
          <p className={"mb-5 secondary-color"}>
            Just enter the email address that you have used to create an
            account. We will send a reset link.
          </p>
        </div>
        <div className="forgot-pw-form">
          <div className="form-signin-label-group">
            <input
              type="email"
              id="inputEmail"
              className="form-control input-login"
              placeholder="steve.jobs@mail.com"
              value={email}
              onChange={(e) => updateEmail(e.target.value)}
            />
            <label htmlFor="inputEmail">Email address</label>
          </div>
          <button
            className={"btn btn2 btn-block font mt-3"}
            onClick={() => handleForgotPassword(email, storeDispatch)}
          >
            {loading ? "Please wait..." : "Request Link"}
          </button>

          <p className="text-center mt-5">
            <Link to={"/?signIn=true"}>
              <small className=" grey-text font-size-small">
                {"<- Back to"}
              </small>
              <small className="primarycolor font-weight-bold font-14 ml-2">
                {"Sign In "}
              </small>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
