import { ChangeEvent, useState } from "react";
import Alert, { AlertType } from "../alert";
import Button from "../button";
import { getMimeTypeOfUploadedFile, isIphoneDevice } from "../../utils";
import { FileType } from "../../models";
import { ReactComponent as UploadCloudIcon } from "../../images/upload_cloud.svg";

const fileTypeAndNameMap: Record<FileType, string> = {
  [FileType.IMG_JPEG]: "JPG/JPEG",
  [FileType.IMG_PNG]: "PNG",
  [FileType.IMG_GIF]: "GIF",
  [FileType.APP_PDF]: "PDF",
  [FileType.APP_JSON]: "JSON",
  [FileType.TEXT_JSON]: "JSON",
  [FileType.ZIP]: "ZIP",
  [FileType.ANY]: "ANY",
  [FileType.UNKNOWN]: "",
};

interface DocumentUploadProps {
  onFileSubmit: (fileInB64: string, contentType: string) => Promise<any>;
  accepts?: FileType[];
}

export default function DocumentUpload({
  onFileSubmit,
  accepts = [FileType.ANY],
}: DocumentUploadProps) {
  const [isLoading, setIsloading] = useState(false);
  const [b64File, setB64File] = useState({ file: "", type: "" });
  const [selectedFileName, setSelectedFileName] = useState("");
  const [uploadStatus, setUploadStatus] = useState<{
    type: AlertType;
    message: string;
  }>({ type: "danger", message: "" });

  async function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files && e.target.files.item(0);
    if (!file) return;
    try {
      const fileType = await getMimeTypeOfUploadedFile(file, isIphoneDevice());
      if (
        accepts.includes(FileType.ANY) ||
        accepts.includes(fileType as FileType)
      ) {
        setSelectedFileName(file.name);
        const fr = new FileReader();
        fr.onload = (event) => {
          const b64Response = window.btoa(event.target?.result as string);
          setB64File({ file: b64Response, type: fileType });
        };
        fr.readAsBinaryString(file);
      } else {
        setSelectedFileName("");
        setB64File({ file: "", type: "" });
        throw new Error(
          `Please select ${accepts
            .map((val) => fileTypeAndNameMap[val])
            .join(", ")} files only`
        );
      }
    } catch (error) {
      setUploadStatus({ type: "danger", message: error.message });
    }
  }

  async function handleSubmit() {
    try {
      setIsloading(true);
      await onFileSubmit(b64File.file, b64File.type);
      setUploadStatus({
        type: "success",
        message: "File upload successful",
      });
      setSelectedFileName("");
      setB64File({ file: "", type: "" });
    } catch (error) {
      setUploadStatus({ type: "danger", message: error.message });
    } finally {
      setIsloading(false);
    }
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <Alert
        type={uploadStatus.type}
        message={uploadStatus.message}
        canDismiss={true}
        onDismissClick={() =>
          setUploadStatus((prevState) => ({ ...prevState, message: "" }))
        }
      />
      <div className="drop-zone my-4">
        <span>
          <UploadCloudIcon className="mb-2" />
        </span>
        <div className="rf-file-input">
          <label>
            <p className="rf-file-name text-center">{selectedFileName}</p>
            <div className="d-flex justify-content-center">
              <input
                type="file"
                id="file"
                className="rf-file w-75"
                onChange={handleFileChange}
                accept={accepts.join()}
              />
            </div>
          </label>
        </div>
        <small className="mt-3">{`${accepts
          .map((val) => fileTypeAndNameMap[val])
          .join(", ")} files are supported`}</small>
      </div>
      <Button
        variant="primary"
        size="lg"
        style={{ width: "100%", maxWidth: "350px" }}
        disabled={isLoading || b64File.file === ""}
        loading={isLoading}
        onClick={handleSubmit}
      >
        Upload
      </Button>
    </div>
  );
}
