import { ReactNode } from "react";
import "./badgeStyles.css";

interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  type: "success" | "pending" | "reject";
  capitalize?: boolean;
  children?: ReactNode;
}

export default function Badge({
  type = "success",
  capitalize = false,
  children,
  ...props
}: BadgeProps) {
  return (
    <span
      className={`badge rf-badge rf-badge-${type} ${
        capitalize ? "capitalize" : ""
      }`}
      {...props}
    >
      {children}
    </span>
  );
}
