import { ChangeEvent, FormEvent, useState } from "react";
import { InputField } from "../../components/input-functions";
import Button from "../../components/button";
import { useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import { changePassword } from "../../actions/login.action";
import { useDispatch } from "react-redux";
import Alert, { AlertType } from "../../components/alert";

interface StatusMessage {
  type: AlertType;
  message: string;
}

export default function ChangePasswordForm() {
  const [formData, setFormData] = useState({
    currentPass: "",
    newPass: "",
    newPassAgain: "",
  });
  const [statusMessage, setStatusMessage] = useState<StatusMessage>({
    type: "success",
    message: "",
  });
  const storeDispatch = useDispatch();
  const isLoading: boolean = useSelector(
    (state: ReduxState) => state.login.loading
  );

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [e.target.name]: e.target.value,
    }));
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    setStatusMessage((prevState) => ({ ...prevState, message: "" }));
    e.preventDefault();
    if (formData.newPass !== formData.newPassAgain) {
      setStatusMessage({
        type: "danger",
        message: "Both the new password entered doesn't match!",
      });
      return;
    }
    try {
      const { message } = await changePassword(
        formData.currentPass,
        formData.newPass,
        storeDispatch
      );
      setStatusMessage({ type: "success", message });
      setFormData({
        currentPass: "",
        newPass: "",
        newPassAgain: "",
      });
    } catch (error) {
      setStatusMessage({
        type: "danger",
        message: error.message || "Something went wrong",
      });
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <Alert
        type={statusMessage.type}
        message={statusMessage.message}
        canDismiss={true}
        onDismissClick={() =>
          setStatusMessage((prevState) => ({ ...prevState, message: "" }))
        }
      />
      <InputField
        label="Current Password"
        type="password"
        name="currentPass"
        placeholder="********"
        value={formData.currentPass}
        onChange={handleChange}
        required
      />
      <InputField
        label="New Password"
        type="password"
        name="newPass"
        placeholder="********"
        value={formData.newPass}
        onChange={handleChange}
        required
      />
      <InputField
        label="Re-enter New Password"
        type="password"
        name="newPassAgain"
        placeholder="********"
        value={formData.newPassAgain}
        onChange={handleChange}
        required
      />
      <Button loading={isLoading}>Submit</Button>
    </form>
  );
}
