import { SelectOption } from "../../components/input-functions";
import { RefundsDto } from "../../dto/refunds.dto";
import { RefundApprovalStatus } from "../../models";
import { splitKeyWithSpace } from "../../utils";

export function getFilterOptions(): SelectOption[] {
  return Object.keys(RefundApprovalStatus).map((value) => ({
    label: splitKeyWithSpace(value),
    value: value,
  }));
}

export function checkIsBulkApproval(selectedItems: number) {
  return selectedItems > 1;
}

export function getAllRefundsReferenceIdByStatus(
  refunds: RefundsDto[],
  refundApprovalStatus: keyof typeof RefundApprovalStatus
) {
  return refunds
    .filter((refund) => refund.status === refundApprovalStatus)
    .map((refund) => refund.referenceId);
}
