import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import dispatch, { MiddlewareAction } from "../middleware";
import { ReduxState } from "../reducers";
import { isRetryExceeded } from "../utils";
import useUpdateEffect from "./useUpdateEffect";

type UsePollingProps<T> = {
  action: MiddlewareAction;
  selector(state: ReduxState): T;
  maxRetries: number;
  retryIntervalInSeconds: number;
};

export function usePolling<T>({
  action,
  selector,
  maxRetries,
  retryIntervalInSeconds,
}: UsePollingProps<T>) {
  const storeDispatch = useDispatch();
  const retryCount = useRef<number>(0);
  const status = useSelector(selector);
  const [isPolling, togglePolling] = useState(false);

  function startPolling(): void {
    togglePolling(true);
  }

  useUpdateEffect(() => {
    if (!isPolling) return;
    //INFO: surfaced from useAutoRefresh
    const intervalId = window.setInterval(() => {
      const isOnline = window.navigator.onLine;
      if (
        !isOnline ||
        isRetryExceeded(retryCount.current, maxRetries) ||
        status
      ) {
        retryCount.current = 0;
        window.clearInterval(intervalId);
        togglePolling(false);
        return;
      }

      dispatch(storeDispatch, action).catch(() => {
        window.clearInterval(intervalId);
        togglePolling(false);
      });
      retryCount.current++;
    }, retryIntervalInSeconds * 1000);

    return () => {
      retryCount.current = 0;
      window.clearInterval(intervalId);
      togglePolling(false);
    };
  }, [isPolling, status]);

  return startPolling;
}
