import { combineReducers } from "redux";
import UIStateReducer, { UIState } from "./ui.reducer";
import LoginStateReducer, { LoginState } from "./login.reducer";
import AdminStateReducer, { AdminState } from "./admin.reducer";
import UserStateReducer, { UserState } from "./user.reducer";
import ApplicationStateReducer, {
  ApplicationState,
} from "./application.reducer";
import LoanStateReducer, { LoanState } from "./loan.reducer";
import ProductStateReducer, { ProductState } from "./product.reducer";
import EnterpriseStateReducer, { EnterpriseState } from "./enterprise.reducer";
import ReportStateReducer, { ReportState } from "./report.reducer";
import AgreementStateReducer, { AgreementState } from "./agreement.reducer";
import InvoiceStateReducer, { InvoiceState } from "./invoice.reducer";
import RefundsReducer, { RefundsState } from "./refunds.reducer";

export interface ReduxState {
  ui: UIState;
  login: LoginState;
  admin: AdminState;
  user: UserState;
  enterprise: EnterpriseState;
  application: ApplicationState;
  loan: LoanState;
  product: ProductState;
  report: ReportState;
  agreeement: AgreementState;
  invoice: InvoiceState;
  refunds: RefundsState;
}

export default combineReducers<ReduxState>({
  ui: UIStateReducer,
  login: LoginStateReducer,
  admin: AdminStateReducer,
  user: UserStateReducer,
  enterprise: EnterpriseStateReducer,
  application: ApplicationStateReducer,
  loan: LoanStateReducer,
  product: ProductStateReducer,
  report: ReportStateReducer,
  agreeement: AgreementStateReducer,
  invoice: InvoiceStateReducer,
  refunds: RefundsReducer,
});
