import { useState, useEffect, ChangeEvent } from "react";
import { CreateWctlApplication } from "../../models";
import { useDispatch } from "react-redux";
import { EnterpriseDetailsV1 } from "../../dto/enterprise.dto";
import dispatch from "../../middleware";
import { createWctlApplication } from "../../actions/application.action";

interface UseWctlApplicationFormReturnType {
  formData: CreateWctlApplication;
  errorMessage: string;
  isFormValid: boolean;
  handleChange: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    transformValue?: (value: string) => void
  ) => void;
  handleSubmit: () => Promise<{
    success: boolean;
    message: any;
  } | null>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

const DEFAULT_FORM_DATA: CreateWctlApplication = {
  enterpriseId: "",
  irpa: 0,
  approvedAmount: 0,
  moratoriumInMonths: 0,
  pf: 0,
  productId: "",
  tenureInMonths: 0,
};

function getPayloadForCreateApplication(
  formData: CreateWctlApplication,
  enterpriseId: string
): CreateWctlApplication {
  return {
    productId: formData.productId,
    approvedAmount: Number(formData.approvedAmount),
    pf: Number(formData.pf),
    irpa: Number(formData.irpa),
    tenureInMonths: Number(formData.tenureInMonths),
    moratoriumInMonths: Number(formData.moratoriumInMonths),
    enterpriseId,
    ...(formData.applicationDate && {
      applicationDate: formData.applicationDate,
    }),
  };
}

export function useWctlApplicationForm(
  singleEnterprise: EnterpriseDetailsV1
): UseWctlApplicationFormReturnType {
  const storeDispatch = useDispatch();
  const [formData, setFormData] =
    useState<CreateWctlApplication>(DEFAULT_FORM_DATA);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    transformValue?: (value: string) => void
  ) => {
    const value = e.target.value;
    const inputMode = e.target.inputMode;
    if (inputMode === "numeric" && Number.isNaN(Number(value))) return;

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: transformValue ? transformValue(value) : value,
    }));
  };

  const validateForm = (data: CreateWctlApplication) => {
    const requiredFields: Array<keyof CreateWctlApplication> = [
      "productId",
      "irpa",
      "pf",
      "approvedAmount",
      "tenureInMonths",
      "moratoriumInMonths",
    ];

    return requiredFields.every((field) => data[field]);
  };

  useEffect(() => {
    setIsFormValid(validateForm(formData));
  }, [formData]);

  const handleSubmit = async () => {
    try {
      const payload: CreateWctlApplication = getPayloadForCreateApplication(
        formData,
        singleEnterprise._id
      );
      const response = await dispatch(
        storeDispatch,
        createWctlApplication(payload)
      );
      if (!response) return null;

      setFormData(DEFAULT_FORM_DATA);
      const message = `WCTL Application created with _id ${response._id}`;
      return { success: true, message };
    } catch (error: any) {
      setErrorMessage(error?.message);
      return { success: false, message: error?.message };
    }
  };

  return {
    formData,
    errorMessage,
    isFormValid,
    handleChange,
    handleSubmit,
    setErrorMessage,
  };
}
