import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useScreenName from "../../../hooks/useScreenName";
import { useDispatch } from "react-redux";
import dispatch from "../../../middleware";
import { getWCTLApplicationById } from "../../../actions/application.action";
import { SingleWCTLApplication } from "../../../dto";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../reducers";
import { isLoadingActive } from "../../../utils";
import WCTLApplicationCard from "./wctlApplicationCard";
import { WCTLApplicationDetails } from "./wctlApplicationDetails";

export function WCTLApplicationDetailsScreen() {
  useScreenName("WCTL Applications");
  const { appId }: { appId: string } = useParams();
  const storeDispatch = useDispatch();
  const wctlApplication: SingleWCTLApplication | null = useSelector(
    (state: ReduxState) => state.application.singleWCTLApplication
  );
  const loadingQueue = useSelector(
    (state: ReduxState) => state.application.loadingQueue
  );
  const isLoading = isLoadingActive(loadingQueue);

  useEffect(() => {
    dispatch(storeDispatch, getWCTLApplicationById(appId));
  }, [appId]);

  return (
    <div className="row" style={{ margin: "0" }}>
      <div className="col-12 mb-4">
        <WCTLApplicationCard
          isLoading={isLoading}
          appId={appId}
          wctlApplicationDetails={wctlApplication}
          onSuccess={() =>
            dispatch(storeDispatch, getWCTLApplicationById(appId))
          }
        />
      </div>
      <div className="col-12 mb-4">
        <WCTLApplicationDetails
          isLoading={isLoading}
          wctlApplicationDetails={wctlApplication}
        />
      </div>
    </div>
  );
}
