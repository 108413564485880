import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  getLoansByEnterpriseId,
  getLoansByUserId,
} from "../../actions/loan.action";
import Button from "../../components/button";
import Table from "../../components/table";
import { RetailODDetails, TermLoanDetails, UserLoanDetails } from "../../dto";
import dispatch from "../../middleware";
import { LoanType, RoutePaths } from "../../models";
import { ReduxState } from "../../reducers";
import { isUserAnEnterprise } from "./helpers";
import { isLoadingActive } from "../../utils";

interface UserLoansProps {
  userId: string;
}

export default function UserLoans({ userId }: UserLoansProps) {
  const storeDispatch = useDispatch();
  const loadingQueue = useSelector(
    (state: ReduxState) => state.loan.loadingQueue
  );
  const loans: UserLoanDetails = useSelector(
    (state: ReduxState) => state.loan.userLoans
  );

  const isEnterprise = isUserAnEnterprise(userId);

  useEffect(() => {
    dispatch(
      storeDispatch,
      isEnterprise ? getLoansByEnterpriseId(userId) : getLoansByUserId(userId)
    );
  }, []);
  const termLoanColumns = [
    { field: "_id", label: "Loan ID" },
    { field: "appId", label: "Application ID" },
    { field: "loanType", label: "Loan Type" },
    { field: "irpa", label: "IRPA (%)" },
    { field: "pf", label: "PF" },
    { field: "approvedAmount", label: "Approved Amount" },
    { field: "tenureInMonths", label: "Tenure (months)" },
    // { field: "action", label: "" },
  ];
  const retailODColumns = [
    { field: "_id", label: "Loan ID" },
    { field: "appId", label: "Application ID" },
    { field: "loanType", label: "Loan Type" },
    { field: "irpa", label: "IRPA (%)" },
    { field: "pf", label: "PF" },
    { field: "maxAmount", label: "Max Amount" },
    // { field: "action", label: "" },
  ];
  function getIdLink(path: RoutePaths, id: string) {
    return (
      <Link to={`${path}/${id}`} className="table-link">
        {id}
      </Link>
    );
  }
  function getLoanType(type: LoanType) {
    if (type === "RETAIL_OD") return "Retail OD";
    if (type === "TERM_LOAN") return "Term Loan";
    return "";
  }
  function getTableDataForTerm(loans: TermLoanDetails[]) {
    return loans.map(
      ({ _id, appId, loanType, irpa, pf, approvedAmount, tenureInMonths }) => ({
        _id: getIdLink(RoutePaths.LOANS, _id),
        appId: getIdLink(RoutePaths.APPLICATIONS, appId),
        loanType: getLoanType(loanType),
        irpa,
        pf,
        approvedAmount,
        tenureInMonths,
        action: <Button variant="info">View</Button>,
      })
    );
  }
  function getTableDataForOD(loans: RetailODDetails[]) {
    return loans.map(({ _id, appId, loanType, irpa, pf, maxAmount }) => ({
      _id: getIdLink(RoutePaths.LOANS, _id),
      appId: getIdLink(RoutePaths.APPLICATIONS, appId),
      loanType: getLoanType(loanType),
      irpa,
      pf,
      maxAmount,
      action: <Button variant="info">View</Button>,
    }));
  }
  return (
    <>
      <div className="d-flex flex-row justify-content-between mb-4">
        <div className="user-page-title">Retail ODs</div>
      </div>
      <Table
        loading={isLoadingActive(loadingQueue)}
        columns={retailODColumns}
        data={getTableDataForOD(loans.retailODs)}
      />
      <div className="mb-4">
        <h3 className="user-page-title">Term Loans</h3>
      </div>
      <Table
        loading={isLoadingActive(loadingQueue)}
        columns={termLoanColumns}
        data={getTableDataForTerm(loans.termLoans)}
      />
    </>
  );
}
