import { useDispatch } from "react-redux";
import DocumentUpload from "../../components/document-upload";
import { FileType } from "../../models";
import dispatch from "../../middleware";
import { uploadInvoiceByInvoiceId } from "../../actions/invoice.action";
import { useState } from "react";
import Alert from "../../components/alert";

interface UploadFileProps {
  invoiceId: string;
  onSuccess: () => Promise<any>;
}

export default function UploadInvoice({
  invoiceId,
  onSuccess,
}: UploadFileProps) {
  const storeDispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  async function handleFileUpload(fileInB64: string, fileType: string) {
    if (!invoiceId) return;
    try {
      const payload = {
        doc: fileInB64,
      };

      await dispatch(
        storeDispatch,
        uploadInvoiceByInvoiceId(invoiceId, payload)
      );

      onSuccess();
    } catch (error: any) {
      setErrorMessage(error.message || "Something went wrong, Try again later");
    }
  }

  return (
    <>
      <Alert
        type="danger"
        message={errorMessage}
        canDismiss
        onDismissClick={() => setErrorMessage("")}
      />
      <DocumentUpload
        accepts={[FileType.APP_PDF]}
        onFileSubmit={handleFileUpload}
      />
    </>
  );
}
