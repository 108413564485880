import { ChangeEvent, FormEvent, useState } from "react";
import { addWCTLoanDisbursal } from "../../../models";
import { getTodayDateForInputField } from "../../../utils";
import Alert from "../../../components/alert";
import { InputField } from "../../../components/input-functions";
import Button from "../../../components/button";
import { ReactComponent as TickIcon } from "../../../images/green-tick.svg";
import CustomRegex from "../../../constants/customRegex";

interface WCTLAddDisbursalProps {
  errMessage: string;
  successMessage: string;
  onDismissClick: () => void;
  onFormSubmit: (formData: addWCTLoanDisbursal) => void;
}

const DEFAULT_ADD_DISBURSAL_FORM_DATA: addWCTLoanDisbursal = {
  date: getTodayDateForInputField(),
  disbursedAmount: 0,
  transactionRefNo: "",
  bankAccountNo: "",
  ifscCode: "",
};

export function WCTLAddDisbursal({
  onDismissClick,
  errMessage,
  onFormSubmit,
  successMessage,
}: WCTLAddDisbursalProps) {
  const [formData, setFormData] = useState(DEFAULT_ADD_DISBURSAL_FORM_DATA);

  function handleChange(
    e: ChangeEvent<HTMLInputElement>,
    transformValue?: (value: string) => void
  ) {
    const value = e.target.value;
    const inputMode = e.target.inputMode;
    if (inputMode === "numeric" && Number.isNaN(Number(value))) return;

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: transformValue ? transformValue(value) : value,
    }));
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    onFormSubmit(formData);
  }

  if (successMessage) {
    return (
      <div className="d-flex flex-column align-items-center ">
        <TickIcon width={48} height={48} />
        <p className="mt-4">{successMessage}</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Alert
        type="danger"
        message={errMessage}
        canDismiss={true}
        onDismissClick={onDismissClick}
      />

      <InputField
        name="date"
        label="Date"
        type="date"
        value={formData.date}
        onChange={handleChange}
        placeholder="Enter Date"
        required
      />
      <InputField
        name="disbursedAmount"
        label="Disbursed Amount"
        type="text"
        inputMode="numeric"
        value={formData.disbursedAmount}
        onChange={handleChange}
        placeholder="Enter Disbursed Amount"
        required
      />
      <InputField
        name="transactionRefNo"
        label="transactionRefNo"
        type="text"
        value={formData.transactionRefNo}
        onChange={handleChange}
        placeholder="Enter Transaction Ref No"
        required
      />
      <InputField
        name="bankAccountNo"
        label="Bank Account No"
        type="text"
        value={formData.bankAccountNo}
        onChange={handleChange}
        placeholder="Enter Bank Account No"
        required
      />
      <InputField
        name="ifscCode"
        label="IFSC Code"
        type="text"
        value={formData.ifscCode}
        onChange={handleChange}
        placeholder="Enter IFSC Code"
        pattern={CustomRegex.IFSC}
        required
      />
      <div className="mt-4">
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </div>
    </form>
  );
}
