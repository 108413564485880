import axios from "axios";
import { getIdToken } from "../actions/login.action";
import { baseUrl } from "../config";
import { constructErrorMessage } from "../utils";

export const idTokenName = "__lmsToken";
export let token: string = "";

export interface MiddlewareAction {
  actionName: string;
  body?: object;
  type?: string;
  url?: string;
  authType?: string;
  token?: string;
  queryParams?: Record<string, number | string | string[] | undefined>;
  metadata?: Record<string, any>;
}

export function saveToken(idToken: string) {
  token = idToken;
  window.localStorage.setItem(idTokenName, idToken);
}
export function getToken() {
  return window.localStorage.getItem(idTokenName);
}

function getHeader(base64Str: string, authType?: string) {
  return {
    "Content-Type": "application/json",
    Authorization: `${authType ? authType : "Bearer"} ${base64Str}`,
  };
}
export function turnOnDnd(localStorageName: string, value: string) {
  window.localStorage.setItem(localStorageName, value);
}
export function isDndOn(localStorageName: string, value: string) {
  return window.localStorage.getItem(localStorageName) === value;
}
export async function getImage(doc_type: string, data: object, url: string) {
  const response = await axios.post(baseUrl + url, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
    responseType: "blob",
  });
  return Promise.resolve(response);
}
export default async function dispatch(
  dispatch: any,
  actionDetails: MiddlewareAction
) {
  let splitActionName = actionDetails.actionName.split("_");
  dispatch({
    type: actionDetails.actionName,
    payload: actionDetails.body,
    metadata: actionDetails.metadata,
  });
  if (splitActionName[0] === "REQUEST") {
    try {
      const idToken = actionDetails.token
        ? actionDetails.token
        : await getIdToken();
      let response: any = undefined;
      const axiosConfig = {
        headers: getHeader(idToken, actionDetails.authType),
        timeout: 30000,
        params: actionDetails.queryParams,
      };
      switch (actionDetails.type) {
        case "GET":
          response = await axios.get(
            `${baseUrl}${actionDetails.url}`,
            axiosConfig
          );
          break;
        case "POST":
          response = await axios.post(
            `${baseUrl}${actionDetails.url}`,
            actionDetails.body,
            axiosConfig
          );
          break;
        case "PATCH":
          response = await axios.patch(
            `${baseUrl}${actionDetails.url}`,
            actionDetails.body,
            axiosConfig
          );
          break;
        case "DELETE":
          response = await axios.delete(
            `${baseUrl}${actionDetails.url}`,
            axiosConfig
          );
          break;
      }
      if (
        response &&
        (response.status === 200 ||
          response.status === 201 ||
          response.status === 204)
      ) {
        dispatch({
          type: `${actionDetails.actionName}_SUCCESS`,
          payload: response.data,
          metadata: actionDetails.metadata,
        });
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: `${actionDetails.actionName}_FAILURE`,
        payload: error.data,
        metadata: actionDetails.metadata,
      });
      throw new Error(constructErrorMessage(error?.response?.data), {
        cause: error?.response?.data,
      });
    }
  }
}
