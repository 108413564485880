import DatafieldSection from "../../components/datafield-section";
import {
  RetailODApplicationDetails,
  TermLoanApplicationDetails,
} from "../../dto";
import { ReactComponent as LoaderIcon } from "../../images/loader.svg";
import { ApplicationType, FieldLabel } from "../../models";
import { splitKeyWithSpace } from "../../utils";

type ApplicationDetailsProps = {
  isLoading: boolean;
  applicationDetails:
    | TermLoanApplicationDetails
    | RetailODApplicationDetails
    | null;
  errorMessage: string;
};

const labels: Record<ApplicationType, FieldLabel[]> = {
  TERM_LOAN_APP: [
    {
      key: "applicationType",
      label: "Application Type :",
      formatMethod: splitKeyWithSpace,
    },
    { key: "approvedAmount", label: "Approved Amount :", prefix: "Rs" },
    { key: "irpa", label: "Interest rate per annum:", suffix: "%" },
    { key: "pf", label: "Processing Fee:", prefix: "Rs" },
    { key: "tenureInMonths", label: "Tenure :", suffix: "months" },
  ],
  RETAIL_OD_APP: [
    {
      key: "applicationType",
      label: "Application Type :",
      formatMethod: splitKeyWithSpace,
    },
    { key: "maxAmount", label: "Maximum Amount :", prefix: "Rs" },
    { key: "irpa", label: "Interest rate per annum :", suffix: "%" },
    { key: "pf", label: "Processing Fee:", prefix: "Rs" },
    { key: "maxValidMonths", label: "Maximum Validity :", suffix: "months" },
  ],
  COMMERCIAL_OD_APP: [
    {
      key: "applicationType",
      label: "Application Type :",
      formatMethod: splitKeyWithSpace,
    },
    { key: "maxAmount", label: "Maximum Amount :", prefix: "Rs" },
    { key: "irpa", label: "Interest rate per annum :", suffix: "%" },
    { key: "pf", label: "Processing Fee:", prefix: "Rs" },
    { key: "maxValidMonths", label: "Maximum Validity :", suffix: "months" },
  ],
  WORKING_CAPITAL_TERM_LOAN_APP: [
    {
      key: "applicationType",
      label: "Application Type :",
      formatMethod: splitKeyWithSpace,
    },
    { key: "maxAmount", label: "Maximum Amount :", prefix: "Rs" },
    { key: "irpa", label: "Interest rate per annum :", suffix: "%" },
    { key: "pf", label: "Processing Fee:", prefix: "Rs" },
    { key: "maxValidMonths", label: "Maximum Validity :", suffix: "months" },
  ],
};

export default function ApplicationDetails({
  isLoading,
  applicationDetails,
  errorMessage,
}: ApplicationDetailsProps) {
  if (isLoading) return <LoaderIcon />;
  if (errorMessage) {
    return <h4 className="text-center">{errorMessage}</h4>;
  }
  if (!applicationDetails?.applicationType) return null;
  return (
    <>
      <h2 className="user-page-title mb-4">Application Details</h2>
      <div className="dashboard-container">
        <DatafieldSection
          labels={[{ items: labels[applicationDetails?.applicationType] }]}
          data={applicationDetails}
        />
      </div>
    </>
  );
}
