import { TabItem } from "../../components/tabs";
import {
  AgreementCount,
  AgreementsSearchQuery,
  AgreementStatus,
  AgreementType,
} from "../../dto/agreement.dto";

export const AgreementsTabItems: TabItem<AgreementType>[] = [
  {
    label: "Credit Line Agreement",
    value: "CREDIT_LINE_AGREEMENT",
  },
  {
    label: "Key Fact Statment",
    value: "KEY_FACT_STATEMENT",
  },
  {
    label: "Term Loan Agreement",
    value: "TERM_LOAN_AGREEMENT",
  },
];

export function getRecordsCountBasedOnStatus(
  count: AgreementCount | null,
  status?: AgreementStatus
): number | null {
  if (!count) return null;
  if (!status || status === "all") return count.total;
  return count[status] ?? count.total;
}

//TODO: make it consistent with backend
// Check with Varun
export const AGREEMENT_TYPE_TO_PAGINATION_COUNT_KEY_MAP: Record<
  AgreementType,
  string
> = {
  CREDIT_LINE_AGREEMENT: "creditLineAgreementCount",
  KEY_FACT_STATEMENT: "keyFactStatementCount",
  TERM_LOAN_AGREEMENT: "termLoanAgreementCount",
};

export function getSignAgreementButtonText(numberOfSelectedItems: number = 0) {
  return !!numberOfSelectedItems ? `Sign (${numberOfSelectedItems})` : "Sign";
}
