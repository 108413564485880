import { FileType } from "../../models";

export const MONTHLY_ANALYSIS_IS_NOT_AVAILABLE =
  "Monthly Analysis Is Not Available";
export const BANK_STATEMENTS_ARE_NOT_AVAILABLE =
  "Bank Statements Are Not Available";
export const ALERT_INFO_INITIAL_STATE = {
  message: "",
  showAlert: false,
  hasError: false,
};

//INFO: No values types present in finbox docs
//INFO: These parameters/labels have to be harcoßded
export const MONTHLY_ANALYSIS_PARAMETERS = [
  {
    key: "cnt_inward_cheque_bounce_credit",
    parameter: "Total No. of Inward Cheque Bounces",
  },
  {
    key: "cnt_outward_cheque_bounce_debit",
    parameter: "Total No. of Outward Cheque Bounces",
  },
  {
    key: "min_eod_balance",
    parameter: "Min EoD balance",
  },
  {
    key: "max_eod_balance",
    parameter: "Max EoD balance",
  },
  {
    key: "total_amount_of_salary",
    parameter: "Total Amount of Salary",
  },
  {
    key: "amt_auto_debit_payment_bounce_credit",
    parameter: "Total Amount of Auto debit bounce",
  },
  {
    key: "amt_emi_debit",
    parameter: "Total Amount Debited through EMI",
  },
  {
    key: "amt_loan_credits",
    parameter: "Total Loan Credits Amount",
  },
  {
    key: "amt_auto_debit_payment_debit",
    parameter: "Total Amount of Auto-Debit Payments",
  },
  {
    key: "amt_bill_payment_debit",
    parameter: "Total Amount of Bill Payments",
  },
  {
    key: "amt_credit_card_bill_debit",
    parameter: "Total Amount Credit Card Bill Debit",
  },
  {
    key: "avg_emi",
    parameter: "Average EMI Transactions Size",
  },
  {
    key: "cnt_loan_credits",
    parameter: "Total Loan Credits Amount",
  },
  {
    key: "cnt_credit_card_bill_debit",
    parameter: "Number of Credit Card Bill Debit",
  },
  {
    key: "cnt_emi_debit",
    parameter: "Number of EMI Transactions",
  },
  {
    key: "number_of_salary_transactions",
    parameter: "Number of Salary Transactions",
  },
];

export const ACCEPTED_FILE_TYPES = [
  FileType.APP_PDF,
  FileType.IMG_JPEG,
  FileType.IMG_PNG,
];

export const ROWS_HIDDEN_FROM_BOTTOM = 5;
export const MAX_RETRIES = 3;
export const RETRY_IN_SECONDS = 3;
