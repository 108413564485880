import React, { useState } from "react";
import Button from "../../../../components/button";
import Modal from "../../../../components/modal";
import CommericalOdNewApplication from "./commericalOdcreateApplication";
import { ReduxState } from "../../../../reducers";
import { useSelector } from "react-redux";
import { EnterpriseDetailsV1 } from "../../../../dto/enterprise.dto";

interface CommercialOdApplicationProps {
  title: string;
  onSuccess?: () => Promise<any>;
}

export default function CommercialOdApplication({
  title,
  onSuccess,
}: CommercialOdApplicationProps) {
  const singleEnterprise: EnterpriseDetailsV1 = useSelector(
    (state: ReduxState) => state.enterprise.singleEnterpriseV1
  );

  const [isModalActive, setIsModalActive] = useState(false);

  return (
    <>
      <div className="dashboard-container d-flex align-items-center justify-content-between">
        <h3 className="user-page-title">{title}</h3>
        <Button size="md" onClick={() => setIsModalActive(true)}>
          Create application
        </Button>
      </div>
      <Modal
        open={isModalActive}
        title="New application"
        size="large"
        hasSubmitButton={false}
        hasCancelButton={false}
        onClose={() => setIsModalActive(false)}
      >
        <CommericalOdNewApplication
          singleEnterprise={singleEnterprise}
          onSuccess={onSuccess}
        />
      </Modal>
    </>
  );
}
