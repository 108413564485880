import { ChangeEvent, useState } from "react";
import Button from "../../../components/button";
import { HorizontalInputField } from "../../../components/input-functions";
import { ConfirmDialogType } from "../../../components/confirm-dialog";

interface ApproveOrRejectApplicationProps {
  onApproveOrReject: (cxt: {
    type: ConfirmDialogType;
    date?: string;
  }) => Promise<void>;
}

export function ApproveOrRejectApplication({
  onApproveOrReject,
}: ApproveOrRejectApplicationProps) {
  const [selectDate, setSelectDate] = useState<string>("");

  function handleDateChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setSelectDate(value);
  }

  return (
    <div>
      <HorizontalInputField
        name="approvedOrRejectedDate"
        label="Date: "
        type="date"
        value={selectDate}
        onChange={handleDateChange}
      />
      <div className="d-flex align-items-center">
        <Button
          onClick={() =>
            onApproveOrReject({ type: "success", date: selectDate })
          }
          style={{ marginRight: "16px" }}
          size="lg"
          variant="success-outline"
        >
          Approve
        </Button>
        <Button
          onClick={() =>
            onApproveOrReject({ type: "reject", date: selectDate })
          }
          size="lg"
          variant="danger-outline"
        >
          Reject
        </Button>
      </div>
    </div>
  );
}
