import { ReactNode } from "react";
import "./styles.css";

export type InfoCardVariants = "success" | "info" | "warning";

interface InfoCardProps {
  variant: InfoCardVariants;
  label: string;
  content: string | ReactNode;
  onClick?: () => void;
}

export default function InfoCard({
  variant,
  label,
  content,
  onClick,
}: InfoCardProps) {
  return (
    <div
      className={`info-card info-card__${variant} ${
        !!onClick ? "clickable" : ""
      }`}
      onClick={onClick}
      role={onClick ? "button" : undefined}
    >
      <div>
        <div className="info-card__label">{label}</div>
        <div className="info-card__content">{content}</div>
      </div>
      {onClick && <span>&#10132;</span>}
    </div>
  );
}
