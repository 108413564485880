import { useState } from "react";

interface UseApplicationSuccessModalReturnType {
  applicationCreatedSuccessModal: {
    active: boolean;
    message: string;
  };
  showSuccessModal: (message: string) => void;
  closeSuccessModal: () => void;
}

export function useApplicationSuccessModal(): UseApplicationSuccessModalReturnType {
  const [applicationCreatedSuccessModal, setApplicationCreatedSuccessModal] =
    useState<{ active: boolean; message: string }>({
      active: false,
      message: "",
    });

  const showSuccessModal = (message: string) => {
    setApplicationCreatedSuccessModal({ active: true, message });
  };

  const closeSuccessModal = () => {
    setApplicationCreatedSuccessModal((prevState) => ({
      ...prevState,
      active: false,
    }));
  };

  return {
    applicationCreatedSuccessModal,
    showSuccessModal,
    closeSuccessModal,
  };
}
