import { ReactNode, useState } from "react";
import Alert from "../../components/alert";
import Button from "../../components/button";
import { InputField } from "../../components/input-functions";
import Modal from "../../components/modal";
import {
  AgreementSignOtpResponse,
  AgreementType,
} from "../../dto/agreement.dto";
import { ReactComponent as FeaturedIcon } from "../../images/featured-icon.svg";
import { ReactComponent as WarnIcon } from "../../images/warn.svg";

const OTP_LENGTH = 6;

function isTerminalStatus(
  otpSignResponse?: {
    success: boolean;
    isOtpError?: boolean;
    message: string;
  } | null
): boolean {
  if (!otpSignResponse) return false;
  if (otpSignResponse.isOtpError === true) return false;
  return true;
}

interface OtpSignProps {
  agreementType: AgreementType;
  otpRequestResponse: AgreementSignOtpResponse | null;
  otpSignResponse: {
    success: boolean;
    isOtpError?: boolean;
    message: string;
  } | null;
  disclaimer?: ReactNode;
  onResendClick: () => void;
  onSubmitClick: (requestId: string, otp: string) => void;
  onClose: () => void;
}

export default function OtpSign({
  agreementType,
  otpRequestResponse,
  otpSignResponse,
  disclaimer,
  onClose,
  onSubmitClick,
  onResendClick,
}: OtpSignProps) {
  const [otp, setOtp] = useState("");

  function handleOtpChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value.trim();
    if (!/^[0-9]*$/.test(value)) return;
    setOtp(value);
  }

  function handleSubmitClick() {
    if (!otpRequestResponse) return;
    onSubmitClick(otpRequestResponse?.requestId, otp);
  }

  if (!otpRequestResponse && !otpSignResponse) return null;

  return (
    <Modal
      open
      onClose={onClose}
      hasCloseIcon={false}
      hasSubmitButton={false}
      hasCancelButton={false}
      removeScrollBarFromBodyWhenOpened={false}
    >
      <div>
        {isTerminalStatus(otpSignResponse) ? (
          !!otpSignResponse && (
            <SignResult {...otpSignResponse} onClose={onClose} />
          )
        ) : (
          <>
            <FeaturedIcon className="mb-3" />
            <h5>
              Enter the OTP for signing the {agreementType.replaceAll("_", " ")}
              (s)
            </h5>
            <p>{otpRequestResponse?.details}</p>
            {otpSignResponse?.isOtpError === true && (
              <Alert type="danger" message={otpSignResponse.message} />
            )}
            {/* TODO: Temp for now, create a separate OTP component later */}
            <InputField
              style={{
                letterSpacing: "2rem",
                width: "100%",
                fontSize: "1.8rem",
                textAlign: "center",
              }}
              maxLength={OTP_LENGTH}
              minLength={OTP_LENGTH}
              inputMode="numeric"
              value={otp}
              onChange={handleOtpChange}
            />
            <p>
              Didn’t get a code?{" "}
              <Button variant="blue-text" size="xs" onClick={onResendClick}>
                Click to resend
              </Button>
            </p>
            {disclaimer}
            <div
              className="d-flex align-items-center mt-4"
              style={{ gap: "1rem" }}
            >
              <Button variant="blue-outline" wide onClick={onClose}>
                Cancel
              </Button>
              <Button
                wide
                style={{ margin: "unset" }}
                type="submit"
                onClick={handleSubmitClick}
                disabled={otp.length !== OTP_LENGTH}
              >
                Confirm
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

function SignResult(props: {
  success: boolean;
  message: string;
  onClose: () => void;
}) {
  const { success, message, onClose } = props;
  if (success) {
    return (
      <>
        <FeaturedIcon className="mb-3" />
        <p>{message}</p>
        <Button wide type="button" onClick={onClose}>
          Done
        </Button>
      </>
    );
  }
  return (
    <>
      <WarnIcon className="mb-3" height={56} width={56} />
      <p>{message}</p>
      <Button wide type="button" onClick={onClose}>
        Ok
      </Button>
    </>
  );
}
