import { UserDetails, VkycDetails } from "../../../dto";
import UserApplications from "../userApplications";
import UserLoans from "../userLoans";
import UserCard from "./userCard";
import { isRejected, isVerified } from "../helpers";
import ProcessUser from "../processUser";
import Box from "../../../components/box";

interface UserInformationProps {
  seflieUrl: string | null;
  userDetails: UserDetails;
  vkycDetails: VkycDetails | null;
  isLoading: boolean;
}

export default function UserInformation({
  seflieUrl,
  userDetails,
  vkycDetails,
  isLoading,
}: UserInformationProps) {
  return (
    <div className="row" style={{ margin: "0" }}>
      <div className="col-md p-0">
        <UserCard
          selfieUrl={seflieUrl}
          loading={isLoading}
          userDetails={userDetails}
          vkycDetails={vkycDetails}
        />
      </div>
      {!isVerified(userDetails.status) && !isRejected(userDetails.status) && (
        <div className="col-12 p-0 mt-4">
          <Box>
            <ProcessUser userData={userDetails} />
          </Box>
        </div>
      )}
      {isVerified(userDetails.status) && (
        <>
          <div className="col-12 p-0 mt-4">
            <Box>
              <UserApplications
                userSelfieUrl={seflieUrl}
                userDetails={userDetails}
              />
            </Box>
          </div>
          <div className="col-12 p-0 mt-4">
            <Box>
              <UserLoans userId={userDetails._id} />
            </Box>
          </div>
        </>
      )}
    </div>
  );
}
