import { useState, useEffect } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { logout } from "../actions/login.action";
import { ReactComponent as LMSLogo } from "../images/lms-logo.svg";
import { ReactComponent as DashboardIcon } from "../images/dashboard.svg";
import { ReactComponent as UsersIcon } from "../images/users.svg";
import { ReactComponent as ApplicationsIcon } from "../images/enquiries.svg";
import { ReactComponent as LoansIcon } from "../images/loan.svg";
import { ReactComponent as ClipBoardIcon } from "../images/clipboard.svg";
import { ReactComponent as LogoutIcon } from "../images/logout.svg";
import { ReactComponent as ListCheckIcon } from "../images/list-checks.svg";
import { ReactComponent as BusinessIcon } from "../images/business.svg";
import { ReactComponent as InvoiceIcon } from "../images/invoice.svg";
import { RoutePaths, NavItemProps } from "../models";
import "./styles.css";

const navItems: NavItemProps[] = [
  {
    name: "Dashboard",
    path: RoutePaths.DASHBOARD,
    exact: true,
    Icon: DashboardIcon,
  },
  {
    name: "Users",
    path: RoutePaths.USERS,
    Icon: UsersIcon,
  },
  {
    name: "Applications",
    path: RoutePaths.APPLICATIONS,
    Icon: ApplicationsIcon,
  },
  {
    name: "Loans",
    path: RoutePaths.LOANS,
    Icon: LoansIcon,
  },
  {
    name: "Reports",
    path: RoutePaths.REPORTS,
    Icon: ClipBoardIcon,
  },
  {
    name: "Agreements",
    path: RoutePaths.AGREEMENTS,
    Icon: ListCheckIcon,
  },
  {
    name: "Enterprise",
    path: RoutePaths.ENTERPRISE_USERS,
    Icon: BusinessIcon,
  },
  // TODO: Commenting the code for now will undo the change on 24 Aug
  // {
  //   name: "Invoice",
  //   path: RoutePaths.INVOICE,
  //   Icon: InvoiceIcon,
  // },
  {
    name: "Portfolio",
    path: RoutePaths.PORTFOLIO,
    Icon: InvoiceIcon,
  },
  {
    name: "Refund Approval",
    path: RoutePaths.REFUND_APPROVAL,
    Icon: InvoiceIcon,
  },
];

function NavItem({ name, path, exact = false, Icon, dropdown }: NavItemProps) {
  const routeMatch = useRouteMatch(path);
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  useEffect(() => {
    if (routeMatch !== null) {
      setIsDropdownActive(true);
    }
  }, [routeMatch]);

  if (dropdown) {
    return (
      <li className="sidenav-link">
        <button
          className="btn btn-link d-flex flex-row align-items-center dropdown-toggle"
          aria-expanded={isDropdownActive}
          onClick={() => setIsDropdownActive((prevState) => !prevState)}
        >
          {Icon && <Icon className="mr-3" />}
          <span className="d-none d-md-block">{name}</span>
        </button>
        <ul
          className={`list-unstyled mt-1 pl-2 collapse${
            isDropdownActive ? " show" : ""
          }`}
        >
          {dropdown.map((navItem, index) => (
            <NavItem key={index} {...navItem} />
          ))}
        </ul>
      </li>
    );
  }
  return (
    <li className="sidenav-link">
      <NavLink
        to={path}
        className="d-flex flex-row align-items-center"
        exact={exact}
        activeClassName="link-active"
      >
        {Icon && <Icon className="mr-3" />}
        <span className="d-none d-md-block">{name}</span>
      </NavLink>
    </li>
  );
}

export default function SideNav() {
  return (
    <nav className={`sidenav d-flex flex-column justify-content-between`}>
      <div className="sidenav-top">
        <div className="sidenav-brand">
          <LMSLogo />
        </div>
        <ul className="list-unstyled sidenav-links">
          {navItems.map((navItem, index) => (
            <NavItem key={index} {...navItem} />
          ))}
        </ul>
      </div>
      <div className="sidenav-bottom">
        <div className="sidenav-link">
          <button
            className="btn btn-link d-flex flex-row align-items-center"
            onClick={logout}
          >
            <LogoutIcon className="mr-3" />
            <span className="d-none d-md-block">Logout</span>
          </button>
        </div>
      </div>
    </nav>
  );
}
