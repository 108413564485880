import { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { updateUserStatus } from "../../actions/user.action";
import Button from "../../components/button";
import { InputField } from "../../components/input-functions";
import { UserDetails } from "../../dto";
import dispatch from "../../middleware";
import {
  ExternalApiType,
  ExternallySourcedBankAccInfo,
  ExternallySourcedPanInfo,
  FieldLabel,
  UserBankInfo,
} from "../../models";
import { getDefaultBankAccount, isUserAnEnterprise, renderDob } from "./helpers";
import { ReactComponent as WarnIcon } from "../../images/warn.svg";
import { ReactComponent as TickIcon } from "../../images/tick.svg";
import Alert from "../../components/alert";
import {
  constructErrorMessage,
  dateToDDMMYYYYHHMMAP,
  getProp,
} from "../../utils";
import { getDetailsFromThirdParty } from "../../actions/admin.action";
import { EnterpriseDetails } from "../../dto/enterprise.dto";
import { updateEnterpriseStatus } from "../../actions/enterprise.action";

interface ProcessUserProps {
  userData: UserDetails | EnterpriseDetails;
  isEnterprise?: boolean;
}

function defaultAccountDetailsMethod(key: string) {
  return function (bankAccountInfos: UserBankInfo[]) {
    const defaultAccount = getDefaultBankAccount(bankAccountInfos);
    if (!defaultAccount) return;
    return getProp(defaultAccount, key);
  };
}

interface ExternalApiData {
  [ExternalApiType.PAN_VERIFICATION]?: ExternallySourcedPanInfo;
  [ExternalApiType.BANK_ACCOUNT_VERIFICATION]?: ExternallySourcedBankAccInfo;
}

export default function ProcessUser({ userData }: ProcessUserProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedActionType, setSelectedActionType] =
    useState<"verify" | "reject" | "">("");
  const [actionComment, setActionComment] = useState("");
  const [isActionCompleted, setIsActionCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [externalApiFetchLoading, setExternalApiFetchLoading] = useState({
    [ExternalApiType.PAN_VERIFICATION]: false,
    [ExternalApiType.BANK_ACCOUNT_VERIFICATION]: false,
  });
  const [externalApiErrorMessage, setExternalApiErrorMessage] = useState({
    [ExternalApiType.PAN_VERIFICATION]: "",
    [ExternalApiType.BANK_ACCOUNT_VERIFICATION]: "",
  });
  const [externalApiData, setExternalApiData] = useState<ExternalApiData>({
    [ExternalApiType.PAN_VERIFICATION]: undefined,
    [ExternalApiType.BANK_ACCOUNT_VERIFICATION]: undefined,
  });

  const storeDispatch = useDispatch();

  const isEnterprise = isUserAnEnterprise(userData._id);

  function fetchExternalDetails(type: ExternalApiType, userId: string) {
    return async function () {
      try {
        setExternalApiFetchLoading((prevState) => ({
          ...prevState,
          [type]: true,
        }));
        const response = await dispatch(
          storeDispatch,
          getDetailsFromThirdParty(type, userId)
        );
        setExternalApiData((prevState) => ({ ...prevState, [type]: response }));
      } catch (error) {
        setExternalApiErrorMessage((prevState) => ({
          ...prevState,
          [type]: constructErrorMessage(error),
        }));
      } finally {
        setExternalApiFetchLoading((prevState) => ({
          ...prevState,
          [type]: false,
        }));
      }
    };
  }

  const labelsForExternalApiData: Record<ExternalApiType, FieldLabel[]> = {
    [ExternalApiType.PAN_VERIFICATION]: [
      { key: "pan", label: "PAN :" },
      { key: "panHolderName", label: "Name :" },
      {
        key: "verifiedAt",
        label: "Fetched At :",
        formatMethod: dateToDDMMYYYYHHMMAP,
      },
      { key: "verifiedFromSource", label: "External Vendor :" },
      { key: "remarks", label: "Remarks :" },
    ],
    [ExternalApiType.BANK_ACCOUNT_VERIFICATION]: [
      { key: "accountNo", label: "Account Number :" },
      { key: "ifsc", label: "IFSC :" },
      { key: "accountHolderName", label: "Name :" },
      {
        key: "verifiedAt",
        label: "Fetched At :",
        formatMethod: dateToDDMMYYYYHHMMAP,
      },
      { key: "verifiedFromSource", label: "External Vendor :" },
      { key: "remarks", label: "Remarks :" },
    ],
  };

  function renderExternalApiData(type: ExternalApiType) {
    // TODO: Need more generic one when other details verification also available
    const buttonText =
      type === ExternalApiType.PAN_VERIFICATION
        ? "Fetch PAN Details"
        : "Fetch Bank A/c";
    return (
      <div className="row">
        <div className="col-md-5 mb-4 mb-md-0">
          <Button
            onClick={fetchExternalDetails(type, userData._id)}
            loading={externalApiFetchLoading[type]}
          >
            {buttonText}
          </Button>
        </div>
        <div className="col">
          <Alert
            type="danger"
            message={externalApiErrorMessage[type]}
            canDismiss
            onDismissClick={() =>
              setExternalApiErrorMessage((prevState) => ({
                ...prevState,
                [type]: "",
              }))
            }
          />
          {renderDataFields(
            labelsForExternalApiData[type],
            externalApiData[type]
          )}
        </div>
      </div>
    );
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!selectedActionType) return;
    const statusChange =
      selectedActionType === "verify" ? "VERIFIED" : "REJECTED";
    const comment = actionComment === "" ? undefined : actionComment;
    try {
      setIsLoading(true);
      await dispatch(
        storeDispatch,
        isEnterprise
          ? updateEnterpriseStatus(userData?._id, statusChange, comment)
          : updateUserStatus(userData?._id, statusChange, comment)
      );
      setIsActionCompleted(true);
    } catch (error) {
      setErrorMessage(
        error?.message || "Something went wrong. Try again later"
      );
    } finally {
      setIsLoading(false);
    }
  }

  const labelsForUserDetails: FieldLabel[] = [
    { key: "_id", label: "User ID :" },
    { key: "name.data", label: "Name :" },
    { key: "dob.data", label: "Date of Birth :", formatMethod: renderDob },
    {
      key: "bankAccountInfo",
      label: "Account Number :",
      formatMethod: defaultAccountDetailsMethod("data.accountNumber"),
    },
    {
      key: "bankAccountInfo",
      label: "IFSC :",
      formatMethod: defaultAccountDetailsMethod("data.ifsc"),
    },
  ];
  function renderDataFields(labels: FieldLabel[], data?: Record<string, any>) {
    if (!data) return <></>;
    function getData(
      label: FieldLabel,
      data?: Record<string, any>
    ): string | number | undefined {
      const renderData =
        typeof label.formatMethod === "function"
          ? label.formatMethod(getProp(data, label.key))
          : getProp(data, label.key);
      if (typeof renderData !== "string" && typeof renderData !== "number")
        return "-";
      if (renderData === "") return "-";
      return renderData;
    }
    return labels.map((eachLabel, index) => (
      <div key={index} className="d-flex flex-row align-items-start">
        <p className="user-details-item-left text-thin w-50">
          {eachLabel.label}
        </p>
        <p className="user-details-item-right w-50 word-break">
          {getData(eachLabel, data)}
        </p>
      </div>
    ));
  }

  function renderPromptScreen(type: "verify" | "reject") {
    return (
      <form onSubmit={handleSubmit}>
        <Alert
          type="danger"
          message={errorMessage}
          canDismiss
          onDismissClick={() => setErrorMessage("")}
        />
        <p>
          Are you sure to {type} <strong>{userData?.name?.data}</strong>?
          <br />
          This is an irreversible process.
        </p>
        <InputField
          type="text"
          name="comment"
          label="Remarks"
          placeholder="Remarks"
          required={type === "reject"}
          onChange={(e) => setActionComment(e.target.value)}
        />
        <div className="d-flex w-50">
          <div className="mr-3 flex-fill">
            <Button
              variant={type === "verify" ? "success" : "danger-fill"}
              loading={isLoading}
              wide
            >
              {type === "verify" ? "Verify" : "Reject"}
            </Button>
          </div>
          <div className="flex-fill">
            <Button
              variant="blue-outline"
              onClick={() => setSelectedActionType("")}
              disabled={isLoading}
              wide
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    );
  }

  if (isActionCompleted) {
    return (
      <div className="d-flex flex-column align-items-center">
        {selectedActionType === "verify" ? (
          <>
            <TickIcon color="#5BC97C" />
            <p style={{ marginTop: "20px", marginBottom: "0" }}>
              <strong>{userData?.name?.data}</strong> # {userData?._id} Verified
              successfully.
            </p>
          </>
        ) : (
          <>
            <WarnIcon />
            <p style={{ marginTop: "20px", marginBottom: "0" }}>
              <strong>{userData?.name?.data}</strong> # {userData?._id}{" "}
              rejected.
            </p>
          </>
        )}
      </div>
    );
  }
  
  return (
    <>
      <h3 className="user-page-title mb-5">User Verification</h3>
      {/* TODO: Remove it properly later */}
      {/* {!isEnterprise && (
        <div className="my-4">
          {renderExternalApiData(ExternalApiType.PAN_VERIFICATION)}
          <hr />
          {renderExternalApiData(ExternalApiType.BANK_ACCOUNT_VERIFICATION)}
        </div>
      )} */}
      {selectedActionType === "verify" || selectedActionType === "reject" ? (
        renderPromptScreen(selectedActionType)
      ) : (
        <>
          <p>
            If the details provided by the user and details here match, please
            click Verify button to verify the user. If any discrepancy was
            found, reject the user by clicking on the Reject button.
          </p>
          <div className="d-flex w-50">
            <div className="mr-3 flex-fill">
              <Button
                onClick={() => setSelectedActionType("verify")}
                wide
              >
                Verify
              </Button>
            </div>
            <div className="flex-fill">
              <Button
                variant="danger-outline"
                onClick={() => setSelectedActionType("reject")}
                wide
              >
                Reject
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
