import { MiddlewareAction } from "../middleware";
import { AdminDetails } from "../dto";
import { ExternalApiType } from "../models";

export enum Actions {
  REQUEST_GET_ADMIN = "REQUEST_GET_ADMIN",
  REQUEST_ADD_ADMIN = "REQUEST_ADD_ADMIN",
  REQUEST_GET_DETAILS_FROM_THIRD_PARTY = "REQUEST_GET_DETAILS_FROM_THIRD_PARTY",
  REQUEST_GET_LENDER_SOURCES = "REQUEST_GET_LENDER_SOURCES",
  REQUEST_GET_SUMMARY_STATS = "REQUEST_GET_SUMMARY_STATS",
  REQUEST_GET_ONE_MONTH_FINANCIAL_STATS = "REQUEST_GET_ONE_MONTH_FINANCIAL_STATS",
  REQUEST_GET_MULTI_MONTH_FINANCIAL_STATS = "REQUEST_GET_MULTI_MONTH_FINANCIAL_STATS",
  REQUEST_GET_COMMERCIAL_OD_FINANCIAL_STATS = "REQUEST_GET_COMMERCIAL_OD_FINANCIAL_STATS",
  REQUEST_GET_PORTFOLIO_STATS = "REQUEST_GET_PORTFOLIO_STATS",
  REQUEST_GET_CLOAING_BALANCE_FINANCIAL_STATS = "REQUEST_GET_CLOAING_BALANCE_FINANCIAL_STATS",
}

export function getAdmin(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_ADMIN,
    type: "GET",
    url: "/admin",
  };
}

export function addAdmin(adminObj: AdminDetails): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_ADMIN,
    type: "POST",
    url: "/admin",
    body: adminObj,
  };
}

export function getDetailsFromThirdParty(
  verificationType: ExternalApiType,
  userId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_DETAILS_FROM_THIRD_PARTY,
    type: "GET",
    url: "/admin/third-party-verification",
    queryParams: { verificationType, userId },
  };
}

export function getLenderSources(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_LENDER_SOURCES,
    type: "GET",
    url: "/admin/sources",
  };
}

export function getSummaryStats(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_SUMMARY_STATS,
    type: "GET",
    url: "/admin/stats/summary",
  };
}

export function getOneMonthFinancialStats(
  month: string,
  year: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_ONE_MONTH_FINANCIAL_STATS,
    type: "GET",
    url: `/admin/stats/one-month-loans?month=${month}&year=${year}`,
  };
}

export function getMultiMonthFinacialStats(
  month: string,
  year: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_MULTI_MONTH_FINANCIAL_STATS,
    type: "GET",
    url: `/admin/stats/multi-month-loans?month=${month}&year=${year}`,
  };
}

export function getCommercialOdFinancialStats(
  month: string,
  year: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_COMMERCIAL_OD_FINANCIAL_STATS,
    type: "GET",
    url: `/admin/stats/commercial-ods?month=${month}&year=${year}`,
  };
}

export function getPortfolioStats(cxt: {
  month: string;
  year: string;
}): MiddlewareAction {
  const { month, year } = cxt;
  return {
    actionName: Actions.REQUEST_GET_PORTFOLIO_STATS,
    type: "GET",
    url: `/admin/stats/portfolio?month=${month}&year=${year}`,
  };
}

export function getClosingBalanceFinancialStats(
  month: string,
  year: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_CLOAING_BALANCE_FINANCIAL_STATS,
    type: "GET",
    url: `/admin/stats/financials-and-balances?month=${month}&year=${year}`,
  };
}
