import {
  ChangeEvent,
  Fragment,
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from "react";
import Alert from "../alert";
import Modal from "../modal";
import { ReactComponent as WarnIcon } from "../../images/warn.svg";
import { ReactComponent as TickIcon } from "../../images/tick.svg";
import { InputField } from "../input-functions";
import { numberToWord } from "../../utils/number-to-word";
import { isValueNumeric } from "../../utils";

export type ConfirmDialogType = "success" | "reject";

interface ConfirmDialogInputField
  extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  type?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  numberToWordRequired?: boolean;
}

interface ConfirmDialogProps {
  active: boolean;
  type?: ConfirmDialogType;
  title: string;
  message: string | ReactNode;
  afterSuccessMessage?: string;
  customErrorMessage?: string;
  onSubmit: (
    type: ConfirmDialogType,
    fieldData: { [x: string]: string }
  ) => Promise<any>;
  onCancel: () => void;
  submitButtonText?: string;
  cancelButtonText?: string;
  primaryActionButton?: "submit" | "cancel";
  needInputFields?: boolean;
  inputFields?: ConfirmDialogInputField[];
}

type StatusType = "INITIATED" | "SUCCESS";

export default function ConfirmDialog({
  active = false,
  type = "success",
  title,
  message,
  afterSuccessMessage,
  customErrorMessage,
  onSubmit,
  onCancel,
  submitButtonText = "Yes",
  cancelButtonText = "No",
  primaryActionButton = "submit",
  inputFields = [],
}: ConfirmDialogProps) {
  // Ref: https://stackoverflow.com/a/27538363
  const initalFieldState = (): { [x: string]: string } =>
    inputFields.reduce(
      (prevObj, currObj) =>
        Object.assign(prevObj, { [currObj.name]: currObj.value || "" }),
      {}
    );
  const [status, setStatus] = useState<StatusType>("INITIATED");
  const [isLoading, setIsLoading] = useState(false);
  const [fieldData, setFieldData] = useState(initalFieldState);
  const [successMessage, setSuccessMessage] = useState(afterSuccessMessage);
  const [errorMessage, setErrorMessage] = useState(customErrorMessage);

  useEffect(() => {
    if (active) {
      setStatus("INITIATED");
      setFieldData(initalFieldState);
      setErrorMessage(undefined);
    }
  }, [active]);

  async function handleSubmit() {
    setStatus("INITIATED");
    setIsLoading(true);
    setErrorMessage(undefined);
    try {
      const response = await onSubmit(type, fieldData);
      console.log(response);
      if (response?.message) {
        setSuccessMessage(response.message);
      }
      setStatus("SUCCESS");
    } catch (error) {
      setErrorMessage(error.message || "Something went wrong. Try again later");
    } finally {
      setIsLoading(false);
    }
  }
  function handleInputFieldChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const inputMode = e.target.inputMode;
    if (inputMode === "numeric" && Number.isNaN(Number(value))) return;
    setFieldData((prevState) => ({
      ...prevState,
      [e.target.name]: value,
    }));
  }
  function renderNumberToWordIfRequired(
    required: boolean,
    value: unknown
  ): ReactNode {
    if (!required) return null;
    const isNumber = isValueNumeric(value);
    if (!isNumber) return null;
    const numberInWord = numberToWord(value);
    if (numberInWord === null) return null;
    return <span>{numberToWord(value)}</span>;
  }
  function renderInputFields(fields: ConfirmDialogInputField[]) {
    return fields.map((eachField, index) => (
      <Fragment key={index}>
        <InputField
          {...eachField}
          value={fieldData[eachField.name]}
          onChange={handleInputFieldChange}
        />
        {renderNumberToWordIfRequired(
          !!eachField.numberToWordRequired,
          fieldData[eachField.name]
        )}
      </Fragment>
    ));
  }
  function renderScreen(status: StatusType) {
    if (status === "SUCCESS")
      return (
        <div className="d-flex flex-column align-items-center">
          {type === "success" && <TickIcon color="#5BC97C" />}
          {type === "reject" && <WarnIcon />}
          <p style={{ marginTop: "20px", marginBottom: "0" }}>
            {successMessage}
          </p>
        </div>
      );
    return (
      <>
        <Alert
          type="danger"
          message={errorMessage}
          canDismiss
          onDismissClick={() => setErrorMessage(undefined)}
        />
        {message}
        {renderInputFields(inputFields)}
      </>
    );
  }
  return (
    <Modal
      open={active}
      onSubmit={primaryActionButton === "submit" ? handleSubmit : onCancel}
      onClose={onCancel}
      onCancel={
        status === "INITIATED" && primaryActionButton === "cancel"
          ? handleSubmit
          : onCancel
      }
      title={status === "INITIATED" ? title : undefined}
      hasCloseIcon={status === "INITIATED"}
      hasSubmitButton={status === "INITIATED"}
      submitButtonText={submitButtonText}
      cancelButtonText={status === "INITIATED" ? cancelButtonText : "Ok"}
      hasSubmitButtonLoading={primaryActionButton === "submit" && isLoading}
      isCancelButtonLoading={primaryActionButton === "cancel" && isLoading}
      description="Confirmation dialog box"
    >
      {renderScreen(status)}
    </Modal>
  );
}
