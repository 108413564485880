import { Actions } from "../actions/report.action";
import { ReportDetails } from "../dto/report.dto";

export interface ReportState {
  loading: boolean;
  reportList: ReportDetails[];
}

const defaultState: ReportState = {
  loading: false,
  reportList: [],
};

export default function reportReducer(
  state = defaultState,
  action: any
): ReportState {
  switch (action.type) {
    case Actions.REQUEST_GET_REPORT_LIST:
    case Actions.REQUEST_GENERATE_REPORT:
    case Actions.REQUEST_DELETE_REPORT_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case `${Actions.REQUEST_GET_REPORT_LIST}_SUCCESS`:
      return {
        ...state,
        loading: false,
        reportList: action?.payload,
      };
    case `${Actions.REQUEST_GET_REPORT_LIST}_FAILURE`:
      return {
        ...state,
        loading: false,
        reportList: [],
      };
    case `${Actions.REQUEST_DELETE_REPORT_BY_ID}_SUCCESS`: {
      const reportId = action?.metadata?.reportId;
      const reportIndex = state.reportList.findIndex(
        (report) => report.reportId === reportId
      );
      if (reportIndex < 0) return {
          ...state,
          loading: false,
      };
      const tempReportList = [...state.reportList];
      tempReportList.splice(reportIndex, 1);
      return {
        ...state,
        loading: false,
        reportList: tempReportList,
      };
    }
    case `${Actions.REQUEST_DELETE_REPORT_BY_ID}_FAILURE`:
    case `${Actions.REQUEST_GENERATE_REPORT}_SUCCESS`:
    case `${Actions.REQUEST_GENERATE_REPORT}_FAILURE`:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
