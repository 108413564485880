import Button from "../../components/button";
import Table from "../../components/table";
import { BANK_STATEMENT } from "../../constants";
import { MonthlyAnalysisStatementsFields } from "../../models";
import { downloadCSV } from "../../utils";
import {
  BANK_STATEMENTS_ARE_NOT_AVAILABLE,
  MONTHLY_ANALYSIS_IS_NOT_AVAILABLE,
  ROWS_HIDDEN_FROM_BOTTOM,
} from "./constants";
import { formatMonthlyAnalysis } from "./helpers";

type MonthlyAnalysisProps = {
  isBankStatementsExists: boolean;
  isMonthlyAnalysisCompleted: boolean;
  monthlyAnalysis: MonthlyAnalysisStatementsFields;
  loading: boolean;
};
function getTableRowsAndColumns(
  monthlyAnalysis: Record<string, Record<string, unknown>>
) {
  try {
    return formatMonthlyAnalysis(monthlyAnalysis);
  } catch (e: any) {
    //TODO: add error logging here
    return { rows: [], columns: [] };
  }
}

export function MonthlyAnalysis({
  isBankStatementsExists,
  isMonthlyAnalysisCompleted,
  monthlyAnalysis,
  loading,
}: MonthlyAnalysisProps) {
  if (!isBankStatementsExists)
    return (
      <h5 className="text-center my-4">{BANK_STATEMENTS_ARE_NOT_AVAILABLE}</h5>
    );
  if (!isMonthlyAnalysisCompleted)
    return (
      <h5 className="text-center my-4">{MONTHLY_ANALYSIS_IS_NOT_AVAILABLE}</h5>
    );
  const { rows, columns } = getTableRowsAndColumns(monthlyAnalysis);

  function onDownloadReport() {
    downloadCSV(rows, columns, `${BANK_STATEMENT}_MONTHLY_ANALYSIS`);
  }
  if (rows.length < 1)
    return (
      <h5 className="text-center my-4">{MONTHLY_ANALYSIS_IS_NOT_AVAILABLE}</h5>
    );

  return (
    <>
      <Table
        rowsHiddenFromBottom={ROWS_HIDDEN_FROM_BOTTOM}
        loading={loading}
        parentClass="bank_statements_table"
        columns={columns}
        data={rows}
      />
      <div className="px-3 mb-3">
        <Button
          type="submit"
          size="md"
          variant="blue-outline"
          wide={true}
          onClick={onDownloadReport}
        >
          Download Data
        </Button>
      </div>
    </>
  );
}
