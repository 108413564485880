import { AgreementsSearchQuery } from "../dto/agreement.dto";
import { MiddlewareAction } from "../middleware";

export enum Actions {
  REQUEST_GET_AGREEMENTS = "REQUEST_GET_AGREEMENTS",
  REQUEST_GET_AGREEMENTS_COUNT = "REQUEST_GET_AGREEMENTS_COUNT",
  REQUEST_GET_AGREEMENT_FILTERS = "REQUEST_GET_AGREEMENT_FILTERS",
  REQUEST_GET_AGREEMENT_DOC_BY_ID = "REQUEST_GET_AGREEMENT_DOC_BY_ID",
  REQUEST_INITIATE_OTP_SIGNING = "REQUEST_INITIATE_OTP_SIGNING",
  REQUEST_SIGN_AGREEMENT = "REQUEST_SIGN_AGREEMENT",
  REQUEST_BULK_SIGN_AGREEMENT = "REQUEST_BULK_SIGN_AGREEMENT",
}

export function getAgreements(body: AgreementsSearchQuery): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_AGREEMENTS,
    url: "/v1/agreement",
    type: "GET",
    queryParams: body,
  };
}

export function getAgreementsCount(agreementType: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_AGREEMENTS_COUNT,
    url: "/v1/agreement/counts",
    type: "GET",
    queryParams: { agreementType },
    metadata: { agreementType }
  };
}

export function getAgreementDocById(id: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_AGREEMENT_DOC_BY_ID,
    url: `/v1/agreement/${id}/user-doc`,
    type: "GET",
  };
}

export function getAgreementFilters(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_AGREEMENT_FILTERS,
    url: "/v1/agreement/filter",
    type: "GET",
  };
}

export function initiateOtpForSigning(agreementType: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_INITIATE_OTP_SIGNING,
    url: "/v1/agreement/sign/initiate",
    type: "GET",
    queryParams: { agreementType }
  };
}

export function signAgreement(body: {
  requestId: string;
  otp: string;
  agreementType: string;
  agreementIds: string[];
}): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_SIGN_AGREEMENT,
    url: "/v1/agreement/sign/verify",
    type: "GET",
    queryParams: body,
  };
}

export function bulkSignAgreement(body: {
  requestId: string;
  otp: string;
  agreementType: string;
}): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_BULK_SIGN_AGREEMENT,
    url: "/v1/agreement/sign/bulk-verify",
    type: "GET",
    queryParams: body,
  };
}
