import { useCallback, useEffect, useRef, useState } from "react";
import "./styles.css";
import Button from "../../components/button";
import CustomAlert from "../../components/custom-alert";
import DropZone from "../../components/drop-zone";
import Modal from "../../components/modal";
import dispatch from "../../middleware";
import {
  ApplicantType,
  MonthlyAnalysis as MonthlyAnalysisType,
  PasswordFile,
} from "../../models";
import {
  getAnalyeButtonText,
  getNewFiles,
  getUploadButtonText,
  getUploadedBankStatements,
  postBankStatements,
} from "./helpers";
import { MonthlyAnalysis } from "./statementsMonthlyAnalysis";
import BankStatementFileCard from "./statementsFileCards";
import {
  getApplicationDocsById,
  getBankStatementsMonthlyAnalysis,
} from "../../actions/application.action";
import { debounce } from "../../utils";
import {
  ALERT_INFO_INITIAL_STATE,
  MAX_RETRIES,
  RETRY_IN_SECONDS,
} from "./constants";
import BankStatementPreviewer from "./statementsPreviewer";
import { usePolling } from "../../hooks/usePolling";
import { ReduxState } from "../../reducers";

type BankStatementsProps = {
  appId: string;
  applicantType: ApplicantType;
  loading: boolean;
  isBankStatementsExists: boolean;
  userId: string;
  monthlyAnalysis: MonthlyAnalysisType;
  storeDispatch: any;
};

type ModalInfo = {
  isModalActive: boolean;
  title: string;
  removeAll?: boolean;
  fileName?: string;
  fileType?: string;
};

type AlertInfo = {
  message: string;
  showAlert: boolean;
  hasError?: boolean;
};

export interface BankStatementFile {
  file: File;
  metadata?: Record<string, unknown>;
}

export interface IdentifierFile {
  name: string;
  type: string;
}

export default function BankStatements({
  appId,
  storeDispatch,
  isBankStatementsExists,
  loading,
  userId,
  monthlyAnalysis,
  applicantType,
}: BankStatementsProps) {
  const [files, setFiles] = useState<Array<BankStatementFile>>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [documentPreviewKey, setDocumentPreviewKey] = useState<number>(() =>
    Date.now()
  );
  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    isModalActive: false,
    title: "",
  });
  const [alertInfo, setAlertInfo] = useState<AlertInfo>(
    ALERT_INFO_INITIAL_STATE
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const debouncedOnPasswordChange = useCallback(debounce(onPasswordChange), []);
  const startPolling = usePolling<boolean>({
    action: getBankStatementsMonthlyAnalysis(appId),
    selector: (state: ReduxState) =>
      state.application?.monthlyAnalysis?.progress?.at(-1)?.status ===
      "completed",
    maxRetries: MAX_RETRIES,
    retryIntervalInSeconds: RETRY_IN_SECONDS,
  });
  const isMonthlyAnalysisCompleted =
    monthlyAnalysis?.progress?.at(-1)?.status === "completed";

  async function updateFiles(
    nextFiles: BankStatementFile[],
    isExistingFiles: boolean = false
  ) {
    try {
      const prevFileNamesAndTypes = files.map(({ file }) => ({
        name: file.name,
        type: file.type,
      }));
      const newFiles = await getNewFiles(prevFileNamesAndTypes, nextFiles);
      if (newFiles.length < 1) {
        setAlertInfoToInitialState();
        return;
      }
      //TODO: fix this to handle multiple files
      if (isExistingFiles) {
        setFiles([...newFiles]);
      } else {
        setIsUploading(true);
        setFiles((files) => [...files, ...newFiles]);
      }
      //INFO: Helpful when admin uploads and removes file,
      //INFO: then again uploads same file
      if (inputRef.current) {
        inputRef.current.value = "";
      }
      setAlertInfoToInitialState();
    } catch (e: any) {
      setAlertInfo({
        message: e?.message ?? "Something went wrong",
        showAlert: true,
        hasError: true,
      });
    }
  }

  function clearAllFiles() {
    setFiles([]);
  }

  function clearFileByNameAndType({
    name,
    type,
  }: {
    name: string;
    type: string;
  }) {
    setFiles((prevFiles) =>
      prevFiles.filter(({ file }) => file.name != name && file.type != type)
    );
  }

  function handleModalSubmit() {
    if (modalInfo.removeAll) {
      clearAllFiles();
    } else if (modalInfo.fileName && modalInfo.fileType) {
      clearFileByNameAndType({
        name: modalInfo.fileName,
        type: modalInfo.fileType,
      });
    }
    setModalInfo((prevModalInfo) => ({
      ...prevModalInfo,
      isModalActive: false,
      title: "",
    }));
  }

  async function mergeUploadedBankStatementsWithFiles() {
    try {
      if (!isBankStatementsExists) return;
      const files = await getUploadedBankStatements({
        userId,
        appId,
        applicantType,
        storeDispatch,
      });
      if (files.length < 1) return;
      updateFiles(files, true);
    } catch (e: any) {
      setAlertInfo({
        message: e?.message ?? "Something went wrong",
        showAlert: true,
        hasError: true,
      });
    }
  }

  function onRemove({ file }: BankStatementFile) {
    setModalInfo((prevModalInfo: any) => ({
      ...prevModalInfo,
      isModalActive: true,
      title: `Are you sure want to remove ${file.name}?`,
      fileName: file.name,
      fileType: file.type,
      removeAll: false,
    }));
  }

  function onRemoveAllFiles() {
    setModalInfo((prevModalInfo) => ({
      ...prevModalInfo,
      isModalActive: true,
      title: "Are you sure want to remove all the files?",
      removeAll: true,
    }));
  }

  function onPasswordChange({ name, type, password }: PasswordFile) {
    setFiles((prevFiles) =>
      prevFiles.map(({ file, metadata }) =>
        file.name != name && file.type != type
          ? { file, metadata }
          : { file, metadata: { ...metadata, password: password } }
      )
    );
  }

  async function getMonthlyAnalysis() {
    try {
      if (!isBankStatementsExists) return;
      await dispatch(storeDispatch, getBankStatementsMonthlyAnalysis(appId));
    } catch (e: any) {
      setAlertInfo({
        showAlert: true,
        message: e?.message ?? "Something went wrong",
        hasError: true,
      });
    }
  }

  async function pollMonthlyAnalysis() {
    try {
      if (!isBankStatementsExists) return;
      startPolling();
    } catch (e: any) {
      setAlertInfo({
        showAlert: true,
        message: e?.message ?? "Something went wrong",
        hasError: true,
      });
    }
  }

  async function uploadBankStatements() {
    try {
      await postBankStatements({ files, appId, storeDispatch });
      setIsUploading(false);
      //INFO: to update list of docs available
      await dispatch(storeDispatch, getApplicationDocsById(appId));
      mergeUploadedBankStatementsWithFiles();
      setDocumentPreviewKey(() => Date.now());
    } catch (e: any) {
      setAlertInfo({
        message: e?.message ?? "Something went wrong",
        showAlert: true,
        hasError: true,
      });
    }
  }

  function handleFilesChange(files: File[]) {
    const simpleFiles = files.map((file) => ({ file }));
    updateFiles(simpleFiles);
  }

  function setAlertInfoToInitialState() {
    setAlertInfo(ALERT_INFO_INITIAL_STATE);
  }

  useEffect(() => {
    mergeUploadedBankStatementsWithFiles();
    getMonthlyAnalysis();
  }, []);

  return (
    <>
      <div className="bank_statements_layout dashboard-container">
        <div>
          <DropZone
            onFilesChange={handleFilesChange}
            disabled={files.length == 1}
            ref={inputRef}
          />
          {/**TODO: add variant for info */}
          {alertInfo.showAlert && (
            <div className="mb-3">
              <CustomAlert
                fullWidth={true}
                variant={alertInfo.hasError ? "danger" : "success"}
              >
                {alertInfo.message}
              </CustomAlert>
            </div>
          )}
          <BankStatementFileCard
            files={files}
            onPasswordChange={debouncedOnPasswordChange}
            onRemove={onRemove}
            onRemoveAllFiles={onRemoveAllFiles}
          />
          <div className="px-3 mb-3">
            {isUploading && (
              <Button
                type="submit"
                size="md"
                wide={true}
                loading={loading}
                disabled={files.length < 1}
                onClick={uploadBankStatements}
              >
                {getUploadButtonText(loading)}
              </Button>
            )}
            {!isUploading && (
              <Button
                type="submit"
                size="md"
                wide={true}
                loading={loading}
                disabled={files.length < 1}
                onClick={pollMonthlyAnalysis}
              >
                {getAnalyeButtonText(isMonthlyAnalysisCompleted, loading)}
              </Button>
            )}
          </div>
          <MonthlyAnalysis
            isBankStatementsExists={isBankStatementsExists}
            isMonthlyAnalysisCompleted={isMonthlyAnalysisCompleted}
            monthlyAnalysis={monthlyAnalysis?.monthly_analysis ?? {}}
            loading={loading}
          />
        </div>
        <div>
          <BankStatementPreviewer
            isBankStatementsExists={isBankStatementsExists}
            userId={userId}
            appId={appId}
            applicantType={applicantType}
            documentPreviewKey={documentPreviewKey}
          />
        </div>
      </div>
      <Modal
        title={modalInfo.title}
        centered={true}
        size="medium"
        open={modalInfo.isModalActive}
        cancelButtonText="No"
        closeOnBackdropClick={true}
        onClose={() => {
          setModalInfo((prevModalInfo) => ({
            ...prevModalInfo,
            isModalActive: false,
            title: "",
          }));
        }}
        submitButtonText={"Yes, remove"}
        onSubmit={handleModalSubmit}
      ></Modal>
    </>
  );
}
