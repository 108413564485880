export enum Actions {
    SET_SCREEN_NAME = "SET_SCREEN_NAME",
    SHOW_NOTIFIER = "SHOW_NOTIFIER",
    HIDE_NOTIFIER = "HIDE_NOTIFIER",
}
export function setScreenName(screenName: string) {
    return {actionName: Actions.SET_SCREEN_NAME, body: {screenName}};
}
export function showNotifier(bgColor: string, msg: string) {
    return {actionName: Actions.SHOW_NOTIFIER, body: {bgColor, msg}};
}
export function hideNotifier() {
    return {actionName: Actions.HIDE_NOTIFIER};
}