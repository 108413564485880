export enum Env {
  LOCAL = "LOCAL",
  UAT = "UAT",
  PROD = "PROD",
}
enum WebUrl {
  LOCAL = "http://localhost:3000",
  UAT = "https://uat.lms.refyne.co.in",
  PROD = "https://lms.refyne.co.in",
}
enum ApiUrl {
  LOCAL = "http://localhost:8080",
  UAT = "https://uat-lms-api.refyne.co.in",
  PROD = "https://lms-api.refyne.co.in",
}
const firebaseConfigUAT = {
  apiKey: "AIzaSyCHEdZyGnD-Y8OGqhseqzXKGo6ZGetVjT4",
  authDomain: "lms-uat-8e120.firebaseapp.com",
  projectId: "lms-uat-8e120",
  storageBucket: "lms-uat-8e120.appspot.com",
  messagingSenderId: "632513207004",
  appId: "1:632513207004:web:26e61e231136bf0dcc3219",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyDM_CQsW94MRdEKPM5Y73j5X6GypA9XSc8",
  authDomain: "lms-prod-12ec2.firebaseapp.com",
  projectId: "lms-prod-12ec2",
  storageBucket: "lms-prod-12ec2.appspot.com",
  messagingSenderId: "423601001581",
  appId: "1:423601001581:web:3e37acac2c12909a3d5b64",
  measurementId: "G-1MPXV31826",
};
let FirebaseConfig: Record<Env, any> = {
  LOCAL: firebaseConfigUAT,
  UAT: firebaseConfigUAT,
  PROD: firebaseConfigProd,
};
const processEnv =
  process.env.REACT_APP_API_ENV === "PROD" ||
  process.env.REACT_APP_API_ENV === "LOCAL"
    ? process.env.REACT_APP_API_ENV
    : "UAT";
export const env: Env = Env[processEnv];
export const baseUrl = ApiUrl[env];
export const webUrl = WebUrl[env];
export const firebaseConfig = FirebaseConfig[env];
