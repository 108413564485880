import Badge from "../../../components/badge";
import Button from "../../../components/button";
import {
  EnterpriseDetailsV1,
  EnterpriseStatus,
} from "../../../dto/enterprise.dto";
import { ReactComponent as BusinessIcon } from "../../../images/business.svg";
import { ReactComponent as LoaderIcon } from "../../../images/loader.svg";
import { convertDateToReadableFormat } from "../../user-details/individual/credit-report/helpers";

interface BasicInformationProps {
  enterprise: EnterpriseDetailsV1;
  isLoading: boolean;
  onUserVerify: (id: string) => void;
  onEditClick: () => void;
}

export default function BasicInformation({
  enterprise,
  isLoading,
  onUserVerify,
  onEditClick,
}: BasicInformationProps) {
  function getEnterpriseStatusBadge(status: EnterpriseStatus) {
    if (status === EnterpriseStatus.VERIFIED)
      return <Badge type="success">Verified</Badge>;
    if (status === EnterpriseStatus.REJECTED)
      return <Badge type="reject">Rejected</Badge>;
    if (status === EnterpriseStatus.PENDING)
      return <Badge type="pending">Pending</Badge>;
    return "";
  }

  if (isLoading) return <LoaderIcon />;
  if (!enterprise) return null;

  return (
    <div className="dashboard-container">
      <div className="d-flex flex-sm-row flex-column-reverse align-items-start justify-content-between">
        <div className="d-flex flex-row align-items-center">
          <div className="rounded-circle mr-4 shadow-sm p-4 text-center">
            <BusinessIcon color="var(--clr-brand-default)" />
          </div>
          <div>
            <h3 className="enterprise-title text-uppercase">
              {enterprise.name}
            </h3>
            <p className="enterprise-page-subtitle mb-0">
              <span className="text-thin">Enterprise id: </span>
              {enterprise._id}
            </p>
            <p className="enterprise-page-subtitle mb-0">
              <span className="text-thin">Lender Id: </span>
              {enterprise.lenderId}
            </p>
            <p className="enterprise-page-subtitle mb-0">
              <span className="text-thin">Source: </span>
              {enterprise.clientId}
            </p>
            <p className="enterprise-page-subtitle mb-0">
              <span className="text-thin">Created at: </span>
              {convertDateToReadableFormat(enterprise.createdAt)}
            </p>
          </div>
        </div>
        <div className="w-25">
          {getEnterpriseStatusBadge(enterprise.status)}
          {enterprise.status !== EnterpriseStatus.VERIFIED && (
            <Button
              variant="blue-outline"
              onClick={() => onUserVerify(enterprise._id)}
              style={{ width: "100%", marginTop: 8 }}
            >
              Verify
            </Button>
          )}
          <Button
            variant="blue-outline"
            onClick={onEditClick}
            style={{ width: "100%", marginTop: 8 }}
          >
            Edit
          </Button>
        </div>
      </div>
    </div>
  );
}
