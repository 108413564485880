import { ChangeEvent, FormEvent, useState } from "react";
import { AddWCTLRepayment } from "../../../models";
import { getTodayDateForInputField } from "../../../utils";
import Alert from "../../../components/alert";
import { InputField } from "../../../components/input-functions";
import Button from "../../../components/button";
import { ReactComponent as TickIcon } from "../../../images/green-tick.svg";

interface WCTLAddRepaymentProps {
  successMessage: string;
  errMessage: string;
  onDismissClick: () => void;
  onFormSubmit: (formData: AddWCTLRepayment) => void;
}

const DEFAULT_FORM_DATA_FOR_REPAYMENT: AddWCTLRepayment = {
  amount: 0,
  paidAt: getTodayDateForInputField(),
  source: "",
  paymentRefId: "",
};

export function WCTLAddRepayment({
  onFormSubmit,
  errMessage,
  onDismissClick,
  successMessage,
}: WCTLAddRepaymentProps) {
  const [formData, setFormData] = useState(DEFAULT_FORM_DATA_FOR_REPAYMENT);

  function handleChange(
    e: ChangeEvent<HTMLInputElement>,
    transformValue?: (value: string) => void
  ) {
    const value = e.target.value;
    const inputMode = e.target.inputMode;
    if (inputMode === "numeric" && Number.isNaN(Number(value))) return;

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: transformValue ? transformValue(value) : value,
    }));
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    onFormSubmit(formData);
  }

  if (successMessage) {
    return (
      <div className="d-flex flex-column align-items-center ">
        <TickIcon width={48} height={48} />
        <p className="mt-4">{successMessage}</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Alert
        type="danger"
        message={errMessage}
        canDismiss={true}
        onDismissClick={onDismissClick}
      />
      <InputField
        name="amount"
        label="Amount"
        type="text"
        inputMode="numeric"
        value={formData.amount}
        onChange={handleChange}
        placeholder="Enter Amount"
        required
      />
      <InputField
        name="paymentRefId"
        label="Payment Ref Id"
        type="text"
        value={formData.paymentRefId}
        onChange={handleChange}
        placeholder="Enter Payment Ref Id"
        required
      />
      <InputField
        name="source"
        label="Source"
        type="text"
        value={formData.source}
        onChange={handleChange}
        placeholder="Enter Source"
        required
      />
      <InputField
        name="paidAt"
        label="Paid At"
        type="date"
        value={formData.paidAt}
        onChange={handleChange}
        placeholder="Enter Paid At"
        required
      />
      <div className="mt-4">
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </div>
    </form>
  );
}
