import {FieldName, IValidation} from "../../models";
import {isEmail} from "../../utils";
import {Link} from "react-router-dom";

export function validateLogin(email: string, password: string, reEnteredPw: string, isSignIn: boolean): IValidation {
    const validation: IValidation = {
        status: false,
        msg: "",
        type: "",
    };
    if (!isEmail(email)) {
        validation.msg = "Invalid";
        validation.type = FieldName.EMAIL;
    } else if (password.trim() === "") {
        validation.msg = "Invalid";
        validation.type = FieldName.PASSWORD;
    } else if (!isSignIn && reEnteredPw.trim() === "") {
        validation.msg = "Invalid";
        validation.type = FieldName.RE_ENTER_PW;
    } else if (!isSignIn && password !== reEnteredPw) {
        validation.msg = "Password did not match";
        validation.type = FieldName.RE_ENTER_PW;
    } else {
        validation.status = true;
    }
    return validation;
}

export function renderSignUpText(updateIsSignIn: (val: boolean) => void, isSignIn: boolean) {
    return (
        <p className="py-md-3 py-2 text-center m-0 login-line-height">
            <small className="pr-2 primary-color font-size-small">
                {isSignIn ? "First time here?" : "Already an user?"}
            </small>
            <small className="primarycolor font-weight-bold font-size-small cursor-pointer" onClick={() => updateIsSignIn(!isSignIn)}>
                {isSignIn ? "Sign up" : "Sign in"}
            </small>
        </p>
    )
}
export function renderSubmit(handleSubmit: (e: any) => void, isSignIn: boolean, loading: boolean) {
    return (
        <button className="btn btn2 d-block w-100" onClick={handleSubmit} type="submit">
            {loading ? "Please wait..." : isSignIn ? "Sign in" : "Create an Account"}
        </button>
    )
}

export function renderForgetPassword() {
    return (
        <div className="login-line-height text-center mt-3">
            <Link className="clickable primarycolor font-size-small" to={"/forget-password"}>
                Forgot your password?
            </Link>
        </div>
    )
}
export function renderEmail(email: string, handleChange: (fieldName: string, value: string | number) => void, errorMsg: string) {
    return (
        <div className="form-signin-label-group">
            <input type="email" id="inputEmail" className="form-control input-login"
                   placeholder="Email address" value={email}
                   onChange={(e) => handleChange(FieldName.EMAIL, e.target.value)}
            />
            {errorMsg.trim() !== "" && <div className={"login-error-text"}>{errorMsg}</div>}
            <label htmlFor="inputEmail">
                Email address
            </label>
        </div>
    )
}
export function renderPassword(password: string, handleChange: (fieldName: string, value: string | number) => void, label: string, fieldName: string, errorMsg: string) {
    return (
        <div className="form-signin-label-group">
            <input type="password" id="inputPassword" className="form-control input-login"
                   placeholder="Password" value={password}
                   onChange={(e) => handleChange(fieldName, e.target.value)}
            />
            <label htmlFor="inputPassword">{label}</label>
            {errorMsg.trim() !== "a" && <div className={"login-error-text"}>{errorMsg}</div>}
        </div>
    )
}
export function renderFirebaseResponse(errorMsg: string) {
    return (
        <div className={"login-error-text text-center firebase-error mb-3"}>
            {errorMsg}
        </div>
    )
}