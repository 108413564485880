import { useSelector } from "react-redux";
import Avatar from "../components/avatar";
import { ReduxState } from "../reducers";
import { RoutePaths } from "../models";

export default function TopBar() {
  const adminDetails = useSelector((state: ReduxState) => state.admin.data);
  const screenName = useSelector((state: ReduxState) => state.ui.screenName);

  return (
    <header className="topbar">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="topbar-left">{screenName}</div>
        <div className="topbar-right d-flex flex-row align-items-center">
          <span className="topbar-username">{adminDetails?.name}</span>
          <Avatar
            displayName={adminDetails?.name}
            size={45}
            clickable={true}
            linkTo={RoutePaths.PROFILE}
          />
        </div>
      </div>
    </header>
  );
}
