import {
  EnterpriseSearchDto,
  EnterpriseSearchFilterDto,
  NewEnterprise,
  NewEnterpriseCoApplicant,
  StoreEnterpriseDoc,
  UpdateEnterprise,
  UpdateEnterpriseV1,
} from "../dto/enterprise.dto";
import { MiddlewareAction } from "../middleware";

export enum Actions {
  REQUEST_ADD_ENTERPRISE = "REQUEST_ADD_ENTERPRISE",
  REQUEST_UPDATE_ENTERPRISE = "REQUEST_UPDATE_ENTERPRISE",
  REQUEST_UPDATE_ENTERPRISE_COAPPLICANTS = "REQUEST_UPDATE_ENTERPRISE_COAPPLICANTS",
  REQUEST_GET_ENTERPRISE_BY_ID = "REQUEST_GET_ENTERPRISE_BY_ID",
  REQUEST_GET_PAGINATED_ENTERPRISES = "REQUEST_GET_PAGINATED_ENTERPRISES",
  REQUEST_GET_PAGINATED_ENTERPRISES_COUNT = "REQUEST_GET_PAGINATED_ENTERPRISES_COUNT",
  REQUEST_STORE_DOC_OF_ENTERPRISE = "REQUEST_STORE_DOC_OF_ENTERPRISE",
  REQUEST_GET_DOC_OF_ENTERPRISE = "REQUEST_GET_DOC_OF_ENTERPRISE",
  REQUEST_UPDATE_ENTERPRISE_STATUS = "REQUEST_UPDATE_ENTERPRISE_STATUS",
  REQUEST_GET_ENTERPRISE_DOC_TYPES = "REQUEST_GET_ENTERPRISE_DOC_TYPES",
  REQUEST_GET_ENTERPRISE_SEARCH_FILTERS = "REQUEST_GET_ENTERPRISE_SEARCH_FILTERS",
  REQUEST_GET_ENTERPRISES_BY_SEARCH = "REQUEST_GET_ENTERPRISES_BY_SEARCH",
  REQUEST_GET_ENTERPRISE_BY_ID_V1 = "REQUEST_GET_ENTERPRISE_BY_ID_V1",
  REQUEST_GET_ALL_ENTERPRISES_V1 = "REQUEST_GET_ALL_ENTERPRISES_V1",
  REQUEST_VERIFY_ENTERPRISE = "REQUEST_VERIFY_ENTERPRISE",
  REQUEST_UPDATE_ENTERPRISE_BY_ID = "REQUEST_UPDATE_ENTERPRISE_BY_ID",
  REQUEST_SINGLE_ENTERPRISE_LOAN_BY_ID = "REQUEST_SINGLE_ENTERPRISE_LOAN_BY_ID",
  REQUEST_SINGLE_ENTERPRISE_APPLICATION_BY_ID = "REQUEST_SINGLE_ENTERPRISE_APPLICATION_BY_ID",
}

export function getAllEnterprisesV1(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_ALL_ENTERPRISES_V1,
    type: "GET",
    url: "/v1/enterprises",
  };
}

export function getEnterpriseByIdV1(enterpriseId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_ENTERPRISE_BY_ID_V1,
    type: "GET",
    url: `/v1/enterprises/${enterpriseId}`,
  };
}

export function getSingleEnterpriseLoanById(
  enterpriseId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_SINGLE_ENTERPRISE_LOAN_BY_ID,
    type: "GET",
    url: `/v1/enterprises/${enterpriseId}/loans`,
  };
}

export function getSingleEnterpriseApplicationById(
  enterpriseId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_SINGLE_ENTERPRISE_APPLICATION_BY_ID,
    type: "GET",
    url: `/v1/enterprises/${enterpriseId}/applications`,
  };
}

export function addEnterpriseV1(payload: UpdateEnterpriseV1): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_ENTERPRISE,
    type: "POST",
    url: "/v1/enterprises",
    body: payload,
  };
}

export function verifyEnterprise(id: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_VERIFY_ENTERPRISE,
    type: "PATCH",
    url: `/v1/enterprises/${id}/status`,
    body: {
      status: "VERIFIED",
      reason: "Verifying status",
    },
  };
}

//Below are old APIs

export function getEnterpriseById(enterpriseId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_ENTERPRISE_BY_ID,
    type: "GET",
    url: "/enterprise",
    queryParams: { enterpriseId },
  };
}

export function getEnterpriseSearchFilters(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_ENTERPRISE_SEARCH_FILTERS,
    type: "GET",
    url: "/enterprise/search-filters",
  };
}

export function getEnterpriseBySearch({
  pageNo,
  numberOfEntitiesPerPage,
  searchText,
  status,
}: EnterpriseSearchDto): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_ENTERPRISES_BY_SEARCH,
    type: "GET",
    url: "/enterprise/search",
    queryParams: { pageNo, numberOfEntitiesPerPage, searchText, status },
  };
}

export function getPaginationEnterpriseCount(
  payload: EnterpriseSearchFilterDto
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_PAGINATED_ENTERPRISES_COUNT,
    type: "GET",
    url: "/enterprise/pagination-count",
    queryParams: { ...payload },
  };
}

export function getPaginatedEnterprises(
  pageNo: number,
  numberOfEntitiesPerPage: number,
  lenderId?: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_PAGINATED_ENTERPRISES,
    type: "POST",
    url: "/enterprise/paginated-enterprise",
    body: { pageNo, numberOfEntitiesPerPage, lenderId },
  };
}

export function addEnterprise(payload: NewEnterprise): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_ENTERPRISE,
    type: "POST",
    url: "/enterprise",
    body: payload,
  };
}

export function updateEnterprise(payload: UpdateEnterprise): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_UPDATE_ENTERPRISE,
    type: "PATCH",
    url: "/enterprise",
    body: payload,
  };
}

export function updateEnterpriseById(
  paylod: UpdateEnterpriseV1,
  enterpriseId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_UPDATE_ENTERPRISE_BY_ID,
    type: "PATCH",
    url: `/v1/enterprises/${enterpriseId}`,
    body: paylod,
  };
}

export function updateEnterpriseCoApplicants(
  enterpriseId: string,
  coApplicant: NewEnterpriseCoApplicant
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_UPDATE_ENTERPRISE_COAPPLICANTS,
    type: "PATCH",
    url: "/enterprise/update-co-applicant",
    body: {
      enterpriseId,
      ...coApplicant,
    },
  };
}

export function storeEnterpriseDoc(
  payload: StoreEnterpriseDoc
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_STORE_DOC_OF_ENTERPRISE,
    type: "POST",
    url: "/enterprise/store-doc",
    body: payload,
  };
}

export function getEnterpriseDoc(
  enterpriseId: string,
  documentName: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_DOC_OF_ENTERPRISE,
    type: "POST",
    url: "/enterprise/get-doc",
    body: { enterpriseId, documentName },
  };
}

export function updateEnterpriseStatus(
  enterpriseId: string,
  status: string,
  comment?: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_UPDATE_ENTERPRISE_STATUS,
    type: "POST",
    url: "/enterprise/update-status",
    body: { enterpriseId, status, comment },
  };
}

export function getEnterpriseDocTypes(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_ENTERPRISE_DOC_TYPES,
    type: "GET",
    url: "/enterprise/doc-types",
  };
}
