import Button from "../../../components/button";
import Table from "../../../components/table";
import { Agreement } from "../../../dto/agreement.dto";

interface AgreementsTableProps {
  userAgreements: Array<Agreement>;
  downloadFn: (agreementId: string) => void;
}

export default function AgreementsTable({
  userAgreements,
  downloadFn,
}: AgreementsTableProps) {
  return (
    <div className="dashboard-container">
      <h4>Agreements</h4>
      <Table
        columns={[
          { field: "entityId", label: "Entity ID" },
          { field: "agreementType", label: "Agreement type" },
          {
            field: "createdAt",
            label: "Created on",
          },
          {
            field: "download",
            label: "Download",
            style: { textAlign: "center" },
          },
        ]}
        data={userAgreements.map((el) => {
          return {
            ...el,
            download: (
              <Button
                variant="blue"
                onClick={() => {
                  downloadFn(el._id);
                }}
              >
                <span className="text__underline">Download</span>
              </Button>
            ),
          };
        })}
      />
    </div>
  );
}
