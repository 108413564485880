import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "../../../../components/avatar";
import {
  getCreditDetailsByUserId,
  getOrFetchCreditDetailsByUserId,
} from "../../../../actions/user.action";
import { CreditDetails, UserDetails } from "../../../../dto";
import dispatch from "../../../../middleware";
import { ReduxState } from "../../../../reducers";
import DefaultUserAvatar from "../../../../images/default-user-avatar.svg";
import { ReactComponent as LoaderIcon } from "../../../../images/loader.svg";
import DatafieldSection from "../../../../components/datafield-section";
import Table from "../../../../components/table";
import Button from "../../../../components/button";
import {
  formatDateFields,
  getMissingCreditReportEssentails,
  getCreditScore,
} from "./helpers";
import {
  ACCOUNT_DETAILS_LABELS,
  ADDRESS_TABLE_COLUMNS,
  CREDIT_REPORT_SUMMARY_LABELS,
  ENQUIRY_SUMMARY_LABELS,
  ENQUIRY_TABLE_COLUMNS,
  PERSONAL_INFO_LABELS,
} from "./constants";
import { isLoadingActive } from "../../../../utils";

interface UserCreditReportProps {
  userDetails: UserDetails;
}

export default function UserCreditReport({
  userDetails,
}: UserCreditReportProps) {
  const storeDispatch = useDispatch();
  const creditDetails: CreditDetails[] = useSelector(
    (state: ReduxState) => state.user.creditDetails
  );
  const loadingQueue = useSelector(
    (state: ReduxState) => state.user.loadingQueue
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isEnquiryDetailsShowing, setIsEnquiryDetailsShowing] = useState(false);
  const [isAccountDetailsShowing, setIsAccountDetailsShowing] = useState(false);

  const creditReportData = creditDetails[0] || {};
  const creditReportMissingEssentials =
    getMissingCreditReportEssentails(userDetails);

  async function fetchCreditDetails(
    userId: string,
    forceFetch: boolean = false
  ) {
    try {
      if (!forceFetch && creditReportData?.userId === userId) return;
      const resp: CreditDetails[] = await dispatch(
        storeDispatch,
        getCreditDetailsByUserId(userId)
      );
      if (resp.length < 1) {
        setErrorMessage("Credit Report not found");
      }
    } catch (error) {
      setErrorMessage(error?.message);
    }
  }
  useEffect(() => {
    fetchCreditDetails(userDetails._id);
  }, []);
  async function fetchOrGetCreditReport() {
    const userId = userDetails._id;
    setErrorMessage("");
    try {
      const resp: CreditDetails[] = await dispatch(
        storeDispatch,
        getOrFetchCreditDetailsByUserId(userId)
      );
      if (resp.length < 1) {
        setErrorMessage("Credit Report not found");
      }
    } catch (error) {
      setErrorMessage(error?.message);
    }
  }
  if (!!creditReportMissingEssentials.length)
    return (
      <h5 className="text-center my-4">
        {`${creditReportMissingEssentials.join(
          " or "
        )} for the user is missing. Please add the corresponding details to fetch the report.`}
      </h5>
    );
  return (
    <div className="dashboard-container">
      <div className="d-flex flex-row-reverse">
        <Button
          onClick={fetchOrGetCreditReport}
          disabled={isLoadingActive(loadingQueue)}
        >
          Fetch Credit Report
        </Button>
      </div>
      {isLoadingActive(loadingQueue) ? (
        <h4 className="text-center my-4">
          <LoaderIcon />
        </h4>
      ) : errorMessage || creditReportData.bureauError ? (
        <h4 className="text-center my-4">
          {creditReportData.bureauError?.ErrorDesc ?? errorMessage}
        </h4>
      ) : (
        <>
          <div className="d-flex flex-sm-row flex-column-reverse align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center">
              <Avatar
                displayName={userDetails?.name?.data}
                border="1px solid #fff"
                displayPhotoUrl={DefaultUserAvatar}
                size={100}
              />
              <div className="ml-4">
                <h3 className="user-page-title mb-1">
                  {userDetails?.name?.data}
                </h3>
                <p className="user-page-subtitle">
                  <span className="text-thin">User Id:</span> {userDetails?._id}
                </p>
              </div>
            </div>
            <p className="d-flex flex-row align-items-start">
              <span className="user-details-item-left text-thin">
                Equifax Score :
              </span>
              <span className="user-details-item-right">
                {getCreditScore(creditReportData.scoreDetails)}
              </span>
            </p>
          </div>
          <DatafieldSection
            className="my-4"
            labels={PERSONAL_INFO_LABELS}
            data={creditReportData}
          />
          <div className="my-4">
            <h3 className="user-page-title mb-4">Consumer Address</h3>
            <Table
              data={formatDateFields(creditReportData.addressInfo, [
                "reportedDate",
              ])}
              columns={ADDRESS_TABLE_COLUMNS}
            />
          </div>
          <div className="d-flex flex-md-row flex-column justify-content-between align-items-center">
            <h3 className="user-page-title">Enquiries Summary</h3>
            <button
              className="btn btn-link"
              onClick={() => setIsEnquiryDetailsShowing((prev) => !prev)}
            >
              {isEnquiryDetailsShowing ? "Hide" : "Show"} Detailed View
            </button>
          </div>
          <DatafieldSection
            className="my-4"
            labels={ENQUIRY_SUMMARY_LABELS}
            data={creditReportData.enquiriesSummary}
          />
          {isEnquiryDetailsShowing && (
            <Table
              data={formatDateFields(creditReportData.enquiries, [
                "enquiryDate",
              ])}
              columns={ENQUIRY_TABLE_COLUMNS}
            />
          )}
          <div className="d-flex flex-md-row flex-column justify-content-between align-items-center">
            <h3 className="user-page-title">Credit Report Summary</h3>
            <button
              className="btn btn-link"
              onClick={() => setIsAccountDetailsShowing((prev) => !prev)}
            >
              {isAccountDetailsShowing ? "Hide" : "Show"} Detailed View
            </button>
          </div>
          <DatafieldSection
            className="my-4"
            labels={CREDIT_REPORT_SUMMARY_LABELS}
            data={creditReportData.accountsSummary}
          />
          {isAccountDetailsShowing &&
            creditReportData.accounts?.map((accountDetail, index) => (
              <div className="border my-3 p-3" key={index}>
                <DatafieldSection
                  labels={ACCOUNT_DETAILS_LABELS}
                  data={accountDetail}
                />
              </div>
            ))}
        </>
      )}
    </div>
  );
}
