import { ReactNode } from "react";
import "./styles.css";

interface BoxProps {
  header?: ReactNode;
  className?: string;
  children: ReactNode;
}

export default function Box({ header, className, children }: BoxProps) {
  return (
    <div className={`rf-box ${className || ""}`}>
      {header && <div className="rf-box-header">{header}</div>}
      <div className="rf-box-content">{children}</div>
    </div>
  );
}
