import { ApplicationSearchDto, ApplicationSearchFilterDto } from "../dto";
import { MiddlewareAction } from "../middleware";
import {
  ApplicantType,
  ApplicationStatusType,
  ApplicationType,
  CreateWctlApplication,
  NewCommercialODApplication,
  NewODApplication,
  NewTermApplication,
  StoreApplicationDoc,
  UpdateApplicationStatus,
  UpdateCommercialApplication,
  UpdateOdApplication,
  UpdateTermApplication,
} from "../models";

export enum Actions {
  REQUEST_ADD_TERM_LOAN_APPLICATION = "REQUEST_ADD_TERM_LOAN_APPLICATION",
  REQUEST_UPDATE_TERM_LOAN_APPLICATION = "REQUEST_UPDATE_TERM_LOAN_APPLICATION",
  REQUEST_UPDATE_COMMERCIAL_OD_APPLICATION = "REQUEST_UPDATE_COMMERCIAL_OD_APPLICATION",
  REQUEST_ADD_RETAIL_OD_APPLICATION = "REQUEST_ADD_RETAIL_OD_APPLICATION",
  REQUEST_UPDATE_RETAIL_OD_APPLICATION = "REQUEST_UPDATE_RETAIL_OD_APPLICATION",
  REQUEST_GET_APPLICATION_BY_ID = "REQUEST_GET_APPLICATION_BY_ID",
  REQUEST_GET_COMMERCIAL_OD_APPLICATION_BY_ID = "REQUEST_GET_COMMERCIAL_OD_APPLICATION_BY_ID",
  REQUEST_GET_PAGINATED_APPLICATIONS = "REQUEST_GET_PAGINATED_APPLICATIONS",
  REQUEST_STORE_DOC_OF_APPLICATION = "REQUEST_STORE_DOC_OF_APPLICATION",
  REQUEST_GET_DOC_OF_APPLICATION = "REQUEST_GET_DOC_OF_APPLICATION",
  REQUEST_UPDATE_APPLICATION_STATUS = "REQUEST_UPDATE_APPLICATION_STATUS",
  REQUEST_GET_APPLICATIONS_BY_USER_ID = "REQUEST_GET_APPLICATIONS_BY_USER_ID",
  REQUEST_GET_APPLICATIONS_BY_ENTERPRISE_ID = "REQUEST_GET_APPLICATIONS_BY_ENTERPRISE_ID",
  REQUEST_GET_APPLICATION_COUNT = "REQUEST_GET_APPLICATION_COUNT",
  REQUEST_GET_APPLICATION_PAGINATION_COUNT = "REQUEST_GET_APPLICATION_PAGINATION_COUNT",
  REQUEST_GET_APPLICATION_DOC_TYPES = "REQUEST_GET_APPLICATION_DOC_TYPES",
  REQUEST_GET_APPLICATION_SEARCH_FILTERS = "REQUEST_GET_APPLICATION_SEARCH_FILTERS",
  REQUEST_GET_COMMERCIAL_OD_APPLICATIONS = "REQUEST_GET_COMMERCIAL_OD_APPLICATIONS",
  REQUEST_GET_APPLICATIONS_BY_SEARCH = "REQUEST_GET_APPLICATIONS_BY_SEARCH",
  REQUEST_GET_APPLICATION_DOCS_BY_ID = "REQUEST_GET_APPLICATION_DOCS_BY_ID",
  REQUEST_UPDATE_TERM_APPLICATION_STATUS_BY_ID = "REQUEST_UPDATE_TERM_APPLICATION_STATUS_BY_ID",
  REQUEST_UPDATE_COMMERCIAL_OD_APPLICATION_STATUS_BY_ID = "REQUEST_UPDATE_COMMERCIAL_OD_APPLICATION_STATUS_BY_ID",
  REQUEST_GET_ENACH_BY_APPLICATION_ID = "REQUEST_GET_ENACH_BY_APPLICATION_ID",
  REQUEST_INITIATE_ENACH_BY_APPLICATION_ID = "REQUEST_INITIATE_ENACH_BY_APPLICATION_ID",
  CLEAR_ENACH_STATE = "CLEAR_ENACH_STATE",
  REQUEST_BANK_STATEMENT_MONTHLY_ANALYSIS = "REQUEST_BANK_STATEMENT_MONTHLY_ANALYSIS",
  REQUEST_BANK_STATEMENT_PROCESS = "REQUEST_BANK_STATEMENT_PROCESS",
  REQUEST_ADD_COMMERCIAL_OD_APPLICATION = "REQUEST_ADD_COMMERCIAL_OD_APPLICATION",
  REQUEST_UPLOAD_COMMERCIAL_OD_APPLICATION_DOCUMENTS = "REQUEST_UPLOAD_COMMERCIAL_OD_APPLICATION_DOCUMENTS",
  REQUEST_DOWNLOAD_COMMERCIAL_OD_DOCUMENT = "REQUEST_DOWNLOAD_COMMERCIAL_OD_DOCUMENT",
  REQUEST_CREATE_WCTL_APPLICATION = "REQUEST_CREATE_WCTL_APPLICATION",
  REQUEST_GET_WCTL_APPLICATIONS = "REQUEST_GET_WCTL_APPLICATIONS",
  REQUEST_GET_WCTL_APPLICATION_BY_ID = "REQUEST_GET_WCTL_APPLICATION_BY_ID",
  REQUEST_UPDATE_WCTL_APPLICATION_STATUS = "REQUEST_UPDATE_WCTL_APPLICATION_STATUS",
}

export function getApplicationById(appId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_APPLICATION_BY_ID,
    type: "GET",
    url: `/application?appId=${appId}`,
  };
}

export function getCommercialOdApplicationById(
  appId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_COMMERCIAL_OD_APPLICATION_BY_ID,
    type: "GET",
    url: `/v1/applications/commercial-ods/${appId}`,
  };
}

export function getApplicationDocsById(appId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_APPLICATION_DOCS_BY_ID,
    type: "GET",
    url: `/application/${appId}/docs`,
  };
}

export function createCommercialOdApplication(
  newCommercialOdApplication: NewCommercialODApplication
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_COMMERCIAL_OD_APPLICATION,
    type: "POST",
    url: "/v1/applications/commercial-ods",
    body: newCommercialOdApplication,
  };
}

export function addTermLoanApplication(
  newTermApplicationObj: NewTermApplication
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_TERM_LOAN_APPLICATION,
    type: "POST",
    url: "/application/term-loan-application",
    body: newTermApplicationObj,
  };
}

export function updateTermLoanApplication(
  payload: UpdateTermApplication
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_UPDATE_TERM_LOAN_APPLICATION,
    type: "PATCH",
    url: "/application/term-loan-application",
    body: payload,
  };
}

export function updateCommercialOdApplication(
  applicationId: string,
  payload: UpdateCommercialApplication
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_UPDATE_COMMERCIAL_OD_APPLICATION,
    type: "PATCH",
    url: `/v1/applications/commercial-ods/${applicationId}`,
    body: payload,
  };
}

export function addReailODApplication(
  newODApplicationObj: NewODApplication
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_RETAIL_OD_APPLICATION,
    type: "POST",
    url: "/application/retail-od-loan-application",
    body: newODApplicationObj,
  };
}

export function updateReailODApplication(
  payload: UpdateOdApplication
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_UPDATE_RETAIL_OD_APPLICATION,
    type: "PATCH",
    url: "/application/retail-od-loan-application",
    body: payload,
  };
}

export function updateApplicationStatus(
  updateApplicationStatusObj: UpdateApplicationStatus
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_UPDATE_APPLICATION_STATUS,
    type: "POST",
    url: "/application/update-status",
    body: updateApplicationStatusObj,
  };
}

export function storeApplicationDoc(
  storeApplicationDocObj: StoreApplicationDoc
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_STORE_DOC_OF_APPLICATION,
    type: "POST",
    url: "/application/store-doc",
    body: storeApplicationDocObj,
  };
}

export function getApplicationDoc(payload: {
  entityId: string;
  applicantType: ApplicantType;
  appId: string;
  documentName: string;
}): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_DOC_OF_APPLICATION,
    type: "POST",
    url: "/application/get-doc",
    body: payload,
  };
}

export function getApplicationByUserId(userId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_APPLICATIONS_BY_USER_ID,
    type: "GET",
    url: `/application/user-applications?userId=${userId}`,
  };
}

export function getApplicationByEnterpriseId(
  enterpriseId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_APPLICATIONS_BY_ENTERPRISE_ID,
    type: "GET",
    url: "/application/enterprise-applications",
    queryParams: { enterpriseId },
  };
}

export function getApplicationCount(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_APPLICATION_COUNT,
    type: "GET",
    url: "/application/applications-count",
  };
}

export function getApplicationDocTypes(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_APPLICATION_DOC_TYPES,
    type: "GET",
    url: "/application/doc-types",
  };
}

export function getApplicationSearchFilters(
  applicationType: ApplicationType
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_APPLICATION_SEARCH_FILTERS,
    type: "GET",
    url: "/application/search-filters",
    queryParams: { applicationType },
    metadata: { applicationType },
  };
}

export function getCommercialOdApplicationsBySearch(
  payload: ApplicationSearchDto
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_COMMERCIAL_OD_APPLICATIONS,
    type: "GET",
    url: "/v1/applications/commercial-ods",
    queryParams: {
      status: payload.status,
      pageNumber: payload.pageNo,
      pageSize: payload.numberOfEntitiesPerPage,
    },
  };
}

export function getApplicationsBySearch(
  payload: ApplicationSearchDto
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_APPLICATIONS_BY_SEARCH,
    type: "GET",
    url: "/application/search",
    queryParams: { ...payload },
  };
}

export function getPaginationApplicationCount(
  payload: ApplicationSearchFilterDto
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_APPLICATION_PAGINATION_COUNT,
    type: "GET",
    url: "/application/pagination-count",
    queryParams: { ...payload },
  };
}

export function updateTermApplicationStatus(
  appId: string,
  toStatus: string,
  metadata: { emiStartDate?: string } = {}
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_UPDATE_TERM_APPLICATION_STATUS_BY_ID,
    type: "POST",
    url: "/term-loan-application/status",
    body: { appId, toStatus, ...metadata },
  };
}

export function updateCommercialOdApplicationStatus(
  applicationId: string,
  toStatus: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_UPDATE_COMMERCIAL_OD_APPLICATION_STATUS_BY_ID,
    type: "PATCH",
    url: `/v1/applications/commercial-ods/${applicationId}/status`,
    body: { status: toStatus },
  };
}

export function initiateEnachByApplicationId(
  applicationId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_INITIATE_ENACH_BY_APPLICATION_ID,
    type: "POST",
    url: "/eNACH/initiate",
    body: { applicationId },
  };
}

export function getEnachByApplicationId(
  applicationId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_ENACH_BY_APPLICATION_ID,
    type: "GET",
    url: "/eNACH",
    queryParams: { applicationId },
  };
}

export function getCommercialODDocument(
  applicationId: string,
  documentName: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_DOWNLOAD_COMMERCIAL_OD_DOCUMENT,
    type: "GET",
    queryParams: { documentName },
    url: `/v1/applications/commercial-ods/${applicationId}/documents`,
  };
}

export function uploadCommercilODApplicationDocument(
  applicationId: string,
  body: {
    file: string;
    documentName: string;
    contentType: string;
    password: string;
  }
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_UPLOAD_COMMERCIAL_OD_APPLICATION_DOCUMENTS,
    url: `/v1/applications/commercial-ods/${applicationId}/documents`,
    type: "POST",
    body,
  };
}

export function clearEnachState(): MiddlewareAction {
  return {
    actionName: Actions.CLEAR_ENACH_STATE,
  };
}

export function getBankStatementsMonthlyAnalysis(
  appId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_BANK_STATEMENT_MONTHLY_ANALYSIS,
    type: "GET",
    url: `/application/${appId}/bank-statement/monthly-analysis`,
  };
}

export function getBankStatementsEntityId(appId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_BANK_STATEMENT_PROCESS,
    type: "GET",
    url: `/application/${appId}/bank-statement/process`,
  };
}

export function createWctlApplication(
  body: CreateWctlApplication
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_CREATE_WCTL_APPLICATION,
    type: "POST",
    url: "/v1/applications/working-capital-term-loans",
    body,
  };
}

export function getWCTLApplicationBySearch(
  payload: ApplicationSearchDto
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_WCTL_APPLICATIONS,
    type: "GET",
    url: "/v1/applications/working-capital-term-loans",
    queryParams: {
      status: payload.status,
      pageNumber: payload.pageNo,
      pageSize: payload.numberOfEntitiesPerPage,
    },
  };
}

export function getWCTLApplicationById(
  applicationId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_WCTL_APPLICATION_BY_ID,
    type: "GET",
    url: `/v1/applications/working-capital-term-loans/${applicationId}`,
  };
}

export function updateWctlApplicationStatus(cxt: {
  applicationId: string;
  body: {
    status: ApplicationStatusType;
    approvedOrRejectedDate?: Date;
  };
}): MiddlewareAction {
  const { applicationId, body } = cxt;
  return {
    actionName: Actions.REQUEST_UPDATE_WCTL_APPLICATION_STATUS,
    type: "PATCH",
    url: `/v1/applications/working-capital-term-loans/${applicationId}/status`,
    body,
  };
}
