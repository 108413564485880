import "./styles.css";
import { ReactComponent as ChevronTopGreenIcon } from "../../images/chevron-top-green.svg.svg";
import { ReactComponent as ChevronBottomOrageIcon } from "../../images/chevron-bottom-orange.svg";

export type DeltaIndicatorType = "increment" | "decrement";

function renderIcon(type: DeltaIndicatorType) {
  if (type === "increment") return <ChevronTopGreenIcon />;
  if (type === "decrement") return <ChevronBottomOrageIcon />;
  return null;
}

interface DeltaIndicatorChipProps {
  type: DeltaIndicatorType;
  value: string;
}

export default function DeltaIndicatorChip({
  type,
  value,
}: DeltaIndicatorChipProps) {
  return (
    <div className={`delta-indicator-chip delta-indicator-chip__${type}`}>
      <span className="mr-2">{value}</span>
      {renderIcon(type)}
    </div>
  );
}
