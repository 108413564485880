import DocumentPreview from "../../components/document-preview";
import { BANK_STATEMENT } from "../../constants";
import { ApplicantType } from "../../models";
import { BANK_STATEMENTS_ARE_NOT_AVAILABLE } from "./constants";
import { getDocFetchAction } from "./helpers";

type BankStatementPreviewerProps = {
  isBankStatementsExists: boolean;
  documentPreviewKey: number;
  userId: string;
  appId: string;
  applicantType: ApplicantType;
};

export default function BankStatementPreviewer(context: BankStatementPreviewerProps) {
  const {
    isBankStatementsExists,
    documentPreviewKey,
    userId,
    appId,
    applicantType,
  } = context;
  if (!isBankStatementsExists) {
    return (
      <h5 className="text-center my-4">{BANK_STATEMENTS_ARE_NOT_AVAILABLE}</h5>
    );
  }
  return (
    <DocumentPreview
      key={documentPreviewKey}
      action={getDocFetchAction({
        entityId: userId,
        appId,
        applicantType,
      })}
      fileName={BANK_STATEMENT}
      parentClass="document-previewer"
    />
  );
}
