import { useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "../../components/avatar";
import Button from "../../components/button";
import { AdminDetails } from "../../dto";
import { ReduxState } from "../../reducers";
import ChangePasswordForm from "./changePasswordForm";

export default function ProfileScreen() {
  const admin: AdminDetails | null = useSelector(
    (state: ReduxState) => state.admin.data
  );
  const clientId: string = useSelector(
    (state: ReduxState) => state.admin.data?.clientId
  );
  const [passwordChangeFormVisible, setPasswordChangeFormVisible] =
    useState(false);
  if (admin === null) {
    return <h4 className="text-center">Admin not found</h4>;
  }
  return (
    <div className="dashboard-container">
      <div className="d-flex flex-row position-relative">
        <div className="mr-3">
          <Avatar displayName={admin?.name} size={50} />
        </div>
        <div className="flex-fill">
          <h5>{admin?.name}</h5>
          <p>{admin?.email}</p>
          <div className="my-4">
            <p className="d-flex flex-row align-items-start">
              <span className="user-details-item-left text-thin">
                Admin ID :
              </span>
              <span className="user-details-item-right">{admin?._id}</span>
            </p>
            <p className="d-flex flex-row align-items-start">
              <span className="user-details-item-left text-thin">
                Lender ID :
              </span>
              <span className="user-details-item-right">{admin?.lenderId}</span>
            </p>
            <p className="d-flex flex-row align-items-start">
              <span className="user-details-item-left text-thin">
                Client ID :
              </span>
              <span className="user-details-item-right">{clientId}</span>
            </p>
          </div>
          <hr />
          <Button
            variant="blue"
            onClick={() =>
              setPasswordChangeFormVisible((prevState) => !prevState)
            }
          >
            Change Password
          </Button>
          {passwordChangeFormVisible && (
            <div style={{ maxWidth: "400px" }}>
              <ChangePasswordForm />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
