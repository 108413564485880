import { useEffect, useMemo, useState } from "react";
import Avatar from "../../../components/avatar";
import { ReactComponent as LoaderIcon } from "../../../images/loader.svg";
import { useParams, Link } from "react-router-dom";
import Button from "../../../components/button";
import { ReactComponent as PencilIcon } from "../../../images/pencil.svg";
import { UserDetails, UserDocType, VkycDetails } from "../../../dto";
import {
  getUserDocTypeLabels,
  isRejected,
  isVerified,
  renderDob,
  getDefaultBankAccount,
  getLatestWorkInfo,
  formatDate,
} from "../helpers";
import Modal from "../../../components/modal";
import { useSelector } from "react-redux";
import Badge from "../../../components/badge";
import { ExternalApiType, RoutePaths, UserStatus } from "../../../models";
import EditUserForm from "./editUserForm";
import DefaultUserAvatar from "../../../images/default-user-avatar.svg";
import { ReduxState } from "../../../reducers";
import DatafieldSection, {
  DatafieldLabel,
} from "../../../components/datafield-section";
import { dateToDDMMYYYY, splitKeyWithSpace } from "../../../utils";
import VerifyUserDataFromExternalSource from "./verifyUserDataFromExternalSource";
import dispatch from "../../../middleware";
import { useDispatch } from "react-redux";
import { getDetailsFromThirdParty } from "../../../actions/admin.action";
import Box from "../../../components/box";
import {
  getCkycDataByUserId,
  getV1CkycDataByUserId,
} from "../../../actions/user.action";
import Ckyc from "./ckyc";

interface UserCardProps {
  selfieUrl: string | null;
  userDetails: UserDetails;
  vkycDetails: VkycDetails | null;
  loading: boolean;
}

export default function UserCard({
  selfieUrl,
  userDetails,
  vkycDetails,
  loading,
}: UserCardProps) {
  const storeDispatch = useDispatch();
  const { userId } = useParams();
  const [isModalActive, setIsModalActive] = useState({
    edit: false,
  });
  const [isPanDataFetched, setisPanDataFetched] = useState(false);
  const ckycDetailsV1 = useSelector(
    (state: ReduxState) => state.user.ckycDetailsV1
  );

  // Need to rething about this method, till then keep it commented
  // const isVerifyButtonEnabled = useMemo(() => isVerifyEnabled(userDetails), [userDetails]);

  const userDocTypes: UserDocType[] = useSelector(
    (state: ReduxState) => state.user.docTypes
  );
  const userDocTypeLabels = useMemo(
    () => getUserDocTypeLabels(userDocTypes),
    [userDocTypes]
  );

  const selfieDetailLabels: DatafieldLabel[] = [
    {
      items: [
        { key: "status", label: "Status: ", formatMethod: () => "VERIFIED" },
      ],
    },
    {
      items: [
        {
          key: "pan.reviewMetadata.lastVerifiedAt",
          label: "Added on:",
          formatMethod: formatDate,
        },
      ],
    },
  ];

  const panDetailLabels: DatafieldLabel[] = [
    {
      items: [
        { key: "pan.data.pan", label: "PAN Number" },
        { key: "", label: "Source:", formatMethod: () => "NSDL" },
      ],
    },
    {
      items: [
        {
          key: "",
          label: "PAN Holder Name:",
          formatMethod: () => userDetails?.name?.data,
        },
      ],
    },
  ];

  const personalDetailLabels: DatafieldLabel[] = [
    {
      items: [
        { key: "name.data", label: "Name :" },
        { key: "parentOrSpouseName.data", label: "Parent/Spouse Name :" },
      ],
    },
    {
      items: [
        { key: "dob.data", label: "Date of Birth :", formatMethod: renderDob },
        { key: "gender", label: "Gender :" },
      ],
    },
  ];

  const communicationDetailLabels: DatafieldLabel[] = [
    {
      items: [
        {
          key: "address.data",
          label: "Address :",
          formatMethod: (val) =>
            (val?.houseInfo ?? "-") + ", " + (val?.localityInfo ?? ""),
        },
        { key: "address.data.city", label: "City :" },
        { key: "address.data.state", label: "State :" },
        { key: "address.data.pinCode", label: "PIN Code :" },
        { key: "", label: "Country :", formatMethod: () => "India" },
      ],
    },
    {
      items: [
        { key: "email.data", label: "Email :" },
        {
          key: "phone",
          label: "Mobile :",
          formatMethod: (phone) =>
            phone?.data.countryCode && phone?.data.number
              ? `${phone?.data.countryCode}-${phone?.data.number}`
              : "-",
        },
        {
          key: "drivingLicenseNumber.data.drivingLicenseNumber",
          label: "DL Number :",
        },
        { key: "voterIdNumber.data.voterIdNumber", label: "Voter ID :" },
        { key: "passportNumber.data.passportNumber", label: "Passport No :" },
      ],
    },
  ];

  const vkycVerificationTypeDetailsLabels: DatafieldLabel[] = [
    {
      items: [
        // TODO: Get the type value sorted
        // { label: "Type: ", key: "", formatMethod: () => "CKYC" },
        { label: "Name: ", key: "proofOfIdentity.name" },
      ],
    },
    // TODO: For now we don't want to show Id number
    // {
    //   items: [{ label: "Id number: ", key: "" }],
    // },
  ];

  const vkycAdressDetails: DatafieldLabel[] = [
    {
      items: [
        { label: "Address: ", key: "proofOfAddress.house" },
        { label: "State: ", key: "proofOfAddress.state" },
        { label: "City: ", key: "proofOfAddress.city" },
      ],
    },
    {
      items: [
        {
          label: "DOB: ",
          key: "proofOfIdentity.dob",
          formatMethod: (dob) => dateToDDMMYYYY(dob),
        },
        { label: "Pincode: ", key: "proofOfAddress.pincode" },
        { label: "Country: ", key: "proofOfAddress.country" },
      ],
    },
  ];

  const vkycVerificationDetails: DatafieldLabel[] = [
    {
      items: [
        {
          label: "Verified At: ",
          key: "agentReviewDetails.decisionedAt",
          formatMethod: (decisionedAt) => dateToDDMMYYYY(decisionedAt),
        },
        {
          label: "Latitudes & Longitudes (Captured during video KYC):",
          key: "location",
          formatMethod: (location) => {
            return (
              <div>
                <span className="mr-2">{`${location?.latitude}, ${location?.longitude}`}</span>
                <a
                  style={{ textDecoration: "none" }}
                  href={`https://www.google.com/maps?q=${location?.latitude},${location?.longitude}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View in map
                </a>
              </div>
            );
          },
        },
      ],
    },
    {
      items: [
        { label: "Verified by: ", key: "agentReviewDetails.agentDetails.name" },
      ],
    },
  ];

  const workDetailLabels: DatafieldLabel[] = [
    {
      items: [
        {
          label: "Employment Type :",
          key: "data.employmentType",
          formatMethod: splitKeyWithSpace,
        },
        {
          label: "Employer Name :",
          key: "data.employerName",
        },
        {
          label: "Company Type :",
          key: "data.companyType",
          formatMethod: splitKeyWithSpace,
        },
        {
          label: "Work Address :",
          key: "data.workAddress.addressLine",
        },
        { key: "data.workAddress.city", label: "City :" },
        { key: "data.workAddress.state", label: "State :" },
        { key: "data.workAddress.pinCode", label: "PIN Code :" },
        {
          key: "data.workAddress.country",
          label: "Country :",
        },
      ],
    },
    {
      items: [
        {
          label: "Designation :",
          key: "data.designation",
        },
        {
          label: "Current Monthly Salary :",
          key: "data.currentMonthlySalary",
          prefix: "Rs",
        },
        {
          label: "Work Email :",
          key: "data.workEmail",
        },
        {
          label: "Work Mobile :",
          key: "data.workMobile",
        },
      ],
    },
  ];

  const bankDetailLabels: DatafieldLabel[] = [
    {
      items: [
        {
          key: "bankAccountInfo",
          label: "Bank A/c Number :",
          formatMethod: (val) =>
            getDefaultBankAccount(val)?.data.accountNumber ?? "-",
        },
      ],
    },
    {
      items: [
        {
          key: "bankAccountInfo",
          label: "IFSC :",
          formatMethod: (val) => getDefaultBankAccount(val)?.data.ifsc ?? "-",
        },
      ],
    },
  ];

  async function fetchCkyc() {
    return dispatch(storeDispatch, getCkycDataByUserId(userDetails._id));
  }

  async function fetchCkycDetailsV1() {
    return dispatch(storeDispatch, getV1CkycDataByUserId(userDetails._id));
  }

  function getStatusBadge(status: UserStatus) {
    if (status === "VERIFIED") return <Badge type="success">Verified</Badge>;
    if (status === "REJECTED") return <Badge type="reject">Rejected</Badge>;
    return "";
  }

  useEffect(() => {
    fetchCkycDetailsV1();
  }, []);

  return (
    <>
      {loading ? (
        <LoaderIcon />
      ) : (
        <>
          <Box>
            <div className="d-flex flex-sm-row flex-column-reverse align-items-start justify-content-between">
              <div className="d-flex flex-row align-items-center user-avatar-section">
                <Avatar
                  displayName={userDetails?.name?.data}
                  border="1px solid #fff"
                  displayPhotoUrl={selfieUrl || DefaultUserAvatar}
                  size={100}
                />
                <div className="ml-4">
                  <h3 className="user-page-title mb-1">
                    {userDetails?.name?.data}
                  </h3>
                  <p className="user-page-subtitle mb-0">
                    <span className="text-thin">User Id:</span>{" "}
                    {userDetails?._id}
                  </p>
                  <p className="user-page-subtitle mb-0">
                    <span className="text-thin">Client Unique Ref Id:</span>{" "}
                    {userDetails?.clientUniqueRefId || "-"}
                  </p>
                  <p className="user-page-subtitle">
                    <span className="text-thin">Source:</span>{" "}
                    {splitKeyWithSpace(userDetails?.source) || "-"}
                  </p>
                </div>
              </div>
              {!isVerified(userDetails?.status) &&
              !isRejected(userDetails?.status) ? (
                <Button
                  variant="blue-outline"
                  size="sm"
                  onClick={() =>
                    setIsModalActive({
                      edit: true,
                    })
                  }
                >
                  <PencilIcon height="15" width="15" />
                  Edit
                </Button>
              ) : (
                <div className="w-25">
                  {getStatusBadge(userDetails?.status)}
                </div>
              )}
            </div>
          </Box>
          <div className="dashboard-container p-0 details-section">
            <div className="details-proof d-flex align-items-center justify-content-between mb-2">
              <h4 className="ml-3">Identity Proof</h4>
              <Link to={`${RoutePaths.USERS}/individual/${userId}/documents`}>
                <Button variant="blue-outline" size="md">
                  View Documents
                </Button>
              </Link>
            </div>
            <DatafieldSection
              title="Personal details"
              labels={personalDetailLabels}
              data={userDetails}
              withinBox
            />
            <hr className="horizontal-line" />
            <DatafieldSection
              title="Selfie"
              labels={selfieDetailLabels}
              data={userDetails}
              withinBox
            />
            <hr className="horizontal-line" />
            <DatafieldSection
              title="PAN"
              labels={panDetailLabels}
              data={userDetails}
              footer={
                <VerifyUserDataFromExternalSource
                  externalApiType={ExternalApiType.PAN_VERIFICATION}
                  isEnabled={
                    !isRejected(userDetails.status) && !!userDetails.pan?.data
                  }
                  autoFetch={isVerified(userDetails.status)}
                  onFetchClick={(type) =>
                    dispatch(
                      storeDispatch,
                      getDetailsFromThirdParty(type, userDetails._id)
                    )
                  }
                  onSuccess={() => setisPanDataFetched(true)}
                />
              }
              withinBox
            />
          </div>
          {!ckycDetailsV1 && (
            <Ckyc
              isPanDataFetched={isPanDataFetched}
              autoFetchOnMount={!!userDetails?.ckycInfo?.data}
              onFetchClick={fetchCkyc}
            />
          )}
          <div className="dashboard-container p-0 details-section">
            <div className="details-proof d-flex align-items-center justify-content-between mb-2">
              <h4 className="ml-3">Address Proof</h4>
              <Link to={`${RoutePaths.USERS}/individual/${userId}/documents`}>
                <Button variant="blue-outline" size="md">
                  View Documents
                </Button>
              </Link>
            </div>
            <DatafieldSection
              className="my-3"
              title="Address"
              labels={communicationDetailLabels}
              data={userDetails}
              withinBox
            />
          </div>
          <div className="dashboard-container p-0 details-section">
            <div className="details-proof d-flex align-items-center justify-content-between mb-2">
              <h4 className="ml-3">Bank Details</h4>
            </div>
            <DatafieldSection
              title="Bank details"
              labels={bankDetailLabels}
              data={userDetails}
              withinBox
              footer={
                <VerifyUserDataFromExternalSource
                  externalApiType={ExternalApiType.BANK_ACCOUNT_VERIFICATION}
                  isEnabled={
                    !(
                      isVerified(userDetails.status) ||
                      isRejected(userDetails.status)
                    )
                  }
                  onFetchClick={(type) =>
                    dispatch(
                      storeDispatch,
                      getDetailsFromThirdParty(type, userDetails._id)
                    )
                  }
                />
              }
            />
          </div>
          {vkycDetails && (
            <div className="dashboard-container p-0 details-section">
              <div className="details-proof d-flex align-items-center justify-content-between mb-2">
                <h4 className="ml-3">Video KYC details</h4>
                <Link to={`${RoutePaths.USERS}/individual/${userId}/documents`}>
                  <Button variant="blue-outline" size="md">
                    View Documents
                  </Button>
                </Link>
              </div>
              <DatafieldSection
                className="my-3"
                title="Verification type"
                labels={vkycVerificationTypeDetailsLabels}
                data={vkycDetails}
                withinBox
              />
              <hr className="horizontal-line" />
              <DatafieldSection
                className="my-3"
                title="Address"
                labels={vkycAdressDetails}
                data={vkycDetails}
                withinBox
              />
              <hr className="horizontal-line" />
              <div className="p-4">
                <div
                  style={{ border: "1px solid #BAD7FF", borderRadius: "16px" }}
                >
                  <DatafieldSection
                    className="my-3"
                    title="Verification details"
                    labels={vkycVerificationDetails}
                    data={vkycDetails}
                    withinBox
                  />
                </div>
              </div>
            </div>
          )}

          <div className="dashboard-container p-0 details-section">
            <div className="details-proof d-flex align-items-center justify-content-between mb-2">
              <h4 className="ml-3">Work Information Details</h4>
            </div>
            <DatafieldSection
              className="my-3"
              labels={workDetailLabels}
              data={getLatestWorkInfo(userDetails?.workInfo) || {}}
              withinBox
            />
          </div>
        </>
      )}
      <Modal
        open={isModalActive.edit}
        title="Edit User"
        size="large"
        hasSubmitButton={false}
        hasCancelButton={false}
        onClose={() => setIsModalActive({ edit: false })}
      >
        <EditUserForm data={userDetails} kycDocTypes={userDocTypeLabels} />
      </Modal>
    </>
  );
}
