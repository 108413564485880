import { ChangeEvent, FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addEnterpriseV1,
  updateEnterprise,
} from "../../actions/enterprise.action";
import { getCityAndStateByPin } from "../../actions/user.action";
import Button from "../../components/button";
import {
  GroupInputField,
  HorizontalInputField,
  HorizontalSelect,
} from "../../components/input-functions";
import { PinCodeDetails } from "../../dto";
import {
  AssessmentAgencyEnum,
  BusinessCategoryEnum,
  EnterpriseBankAccountTypeEnum,
  EnterpriseDetails,
  EnterpriseDetailsV1,
  EnterpriseLocationTypeEnum,
  EnterpriseRelationshipEnum,
  EnterpriseRelationshipTypeEnum,
  IndustryTypeEnum,
  LegalConstitutionEnum,
  UpdateEnterpriseV1,
} from "../../dto/enterprise.dto";
import dispatch from "../../middleware";
import {
  clearFormData,
  dateInputToString,
  splitKeyWithSpace,
} from "../../utils";
import { getDefaultFormData } from "./helpers";
import { ReactComponent as TickIcon } from "../../images/tick.svg";
import Alert from "../../components/alert";
import CustomRegex from "../../constants/customRegex";
interface AddOrEditEnterpriseFormProps {
  enterpriseData?: EnterpriseDetailsV1;
}

export default function AddOrEditEnterpriseForm({
  enterpriseData,
}: AddOrEditEnterpriseFormProps) {
  const isEditing = !!enterpriseData;
  //TODO: Not adding update feature as of now. Will edit this once added.
  const [formData, setFormData] = useState(getDefaultFormData(enterpriseData));
  const [isLoading, setIsLoading] = useState(false);
  const [pinDataLoading, setPinDataLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const storeDispatch = useDispatch();

  async function fetchPinData(
    pinCode: string,
    key: "address" | "coApplicantAddress" | "relationshipInfo",
    arrayIndex?: number
  ) {
    if (isNaN(Number(pinCode)) || pinCode.length !== 6) return;
    setPinDataLoading(true);
    try {
      const pinData: PinCodeDetails = await dispatch(
        storeDispatch,
        getCityAndStateByPin(pinCode)
      );
      if (key === "address") {
        setFormData((prevState) => ({
          ...prevState,
          address: {
            locationType: prevState.address?.locationType || "",
            localityInfo: prevState.address?.localityInfo || "",
            state: pinData[pinCode]?.state || "",
            pincode: prevState.address?.pincode || "",
          },
        }));
        return;
      }
      if (key === "relationshipInfo") {
        setFormData((prevState) => ({
          ...prevState,
          relationshipInfo: {
            ...prevState.relationshipInfo,
            metadata: {
              ...prevState.relationshipInfo.metadata,
              pincode: pinCode,
              stateOrUnionTerritoryName: pinData[pinCode]?.state || "",
            },
          },
        }));
        return;
      }
    } catch {
      // TODO: do something with the error later
    } finally {
      setPinDataLoading(false);
    }
  }

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    let { name, value } = e.target;
    if (name === "incorporationDate") {
      value = dateInputToString(value);
    }
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleVpaChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      virtualPaymentAccount: {
        ...prevState.virtualPaymentAccount,
        [name]: value === "" ? undefined : value,
      },
    }));
  }

  function handleNestedChange(
    key: "address" | "bankAccountInfo" | "relationshipInfo"
  ) {
    return (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      if (name === "pincode") {
        fetchPinData(value, "address");
      }
      setFormData((prevState) => ({
        ...prevState,
        [key]: { ...prevState[key], [name]: value },
      }));
    };
  }

  function handleRelationshipMetaData(
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    const { name, value } = e.target;
    if (name === "pincode") {
      fetchPinData(value, "relationshipInfo");
    }
    setFormData((prevState) => ({
      ...prevState,
      relationshipInfo: {
        ...prevState.relationshipInfo,
        metadata: {
          ...prevState.relationshipInfo.metadata,
          [name]: value,
        },
      },
    }));
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsLoading(true);
    try {
      let enterpriseResp: EnterpriseDetails;
      if (isEditing) {
        const { ...payload } = formData;
        enterpriseResp = await dispatch(
          storeDispatch,
          updateEnterprise(clearFormData(payload))
        );
      } else {
        enterpriseResp = await dispatch(
          storeDispatch,
          addEnterpriseV1(formData as UpdateEnterpriseV1)
        );
      }
      setSuccessMessage(
        `User ${
          isEditing ? "updated" : "created"
        } successfully with a User ID # ${enterpriseResp?._id}`
      );
    } catch (error: any) {
      setErrorMessage(error.message || "Something went wrong, Try again later");
    } finally {
      setIsLoading(false);
    }
  }

  if (successMessage) {
    return (
      <div className="d-flex flex-column align-items-center">
        <TickIcon color="#5BC97C" />
        <p style={{ marginTop: "20px", marginBottom: "0" }}>{successMessage}</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Alert
        type="danger"
        message={errorMessage}
        canDismiss
        onDismissClick={() => setErrorMessage("")}
      />
      <h3 className="user-page-title user-details-title mb-4">Basic details</h3>
      <HorizontalInputField
        label="Name:"
        type="text"
        name="name"
        value={formData?.name}
        onChange={handleChange}
        required
        pattern={CustomRegex.STRING_WITH_SINGLE_SPACES_IN_BETWEEN}
      />

      <HorizontalInputField
        label="Employer Id:"
        type="text"
        name="clientRefId"
        value={formData?.clientRefId}
        onChange={handleChange}
        required
      />

      <h3 className="user-page-title user-details-title mb-4">
        Enterprise details
      </h3>

      <HorizontalSelect
        label="Legal constitution:"
        name="legalConstitution"
        placeholder="Select legal constitution"
        options={Object.values(LegalConstitutionEnum).map((value) => ({
          label: splitKeyWithSpace(value),
          value,
        }))}
        value={formData?.legalConstitution}
        onChange={handleChange}
        required
      />
      <HorizontalSelect
        label="Assessment agency:"
        name="assessmentAgency"
        placeholder="Select assesment agency"
        options={Object.values(AssessmentAgencyEnum).map((value) => ({
          label: splitKeyWithSpace(value),
          value,
        }))}
        value={formData?.assessmentAgency}
        onChange={handleChange}
        required
      />
      <HorizontalSelect
        label="Business category:"
        name="businessCategory"
        placeholder="Select Business category"
        options={Object.values(BusinessCategoryEnum).map((value) => ({
          label: splitKeyWithSpace(value),
          value,
        }))}
        value={formData?.businessCategory}
        onChange={handleChange}
        required
      />
      <HorizontalSelect
        label="Industry type:"
        name="industryType"
        placeholder="Select industry type"
        options={Object.values(IndustryTypeEnum).map((value) => ({
          label: splitKeyWithSpace(value),
          value,
        }))}
        value={formData?.industryType}
        onChange={handleChange}
        required
      />
      <HorizontalInputField
        label="PAN:"
        type="text"
        name="pan"
        value={formData?.pan}
        onChange={handleChange}
        pattern={CustomRegex.PAN}
        title="E.g. AAACA1234Z"
        required
      />
      <HorizontalInputField
        label="GST Number:"
        type="text"
        name="gstin"
        value={formData?.gstin}
        pattern={CustomRegex.GST_NUMBER}
        onChange={handleChange}
        placeholder="Ex - 22AAAAA0000A1Z5"
        required
      />
      <h3 className="user-page-title user-details-title mb-4">
        Relationship details
      </h3>
      <HorizontalSelect
        label="Relationship:"
        name="relationship"
        placeholder="Select relationship"
        options={Object.values(EnterpriseRelationshipEnum).map((value) => ({
          label: splitKeyWithSpace(value),
          value,
        }))}
        value={formData?.relationshipInfo?.relationship}
        onChange={handleNestedChange("relationshipInfo")}
        required
      />
      <HorizontalSelect
        label="Relationship type:"
        name="relationType"
        placeholder="Select relationship type"
        options={Object.values(EnterpriseRelationshipTypeEnum).map((value) => ({
          label: splitKeyWithSpace(value),
          value,
        }))}
        value={formData?.relationshipInfo?.relationType}
        onChange={handleNestedChange("relationshipInfo")}
        required
      />
      <HorizontalInputField
        label="Date of birth:"
        type="date"
        name="dateOfBirth"
        value={formData?.relationshipInfo?.metadata?.dateOfBirth}
        onChange={handleRelationshipMetaData}
        pattern={CustomRegex.DOB}
        required
      />
      <HorizontalInputField
        label="Relationship PAN:"
        type="text"
        name="pan"
        value={formData?.relationshipInfo?.metadata?.pan}
        onChange={handleRelationshipMetaData}
        pattern={CustomRegex.PAN}
        title="E.g. AAACA1234Z"
        required
      />
      <HorizontalInputField
        label="Address line 1:"
        type="text"
        name="addressLine1"
        value={formData?.relationshipInfo?.metadata?.addressLine1}
        onChange={handleRelationshipMetaData}
        pattern={CustomRegex.ADDRESS_LINE}
        required
      />
      <HorizontalInputField
        label="Pincode:"
        type="text"
        name="pincode"
        value={formData?.relationshipInfo?.metadata?.pincode}
        onChange={handleRelationshipMetaData}
        required
      />
      <HorizontalInputField
        label="State/UT:"
        type="text"
        name="stateOrUnionTerritoryName"
        value={formData?.relationshipInfo?.metadata?.stateOrUnionTerritoryName}
        onChange={handleRelationshipMetaData}
        required
        disabled
      />

      <h3 className="user-page-title user-details-title mb-4">
        Communication details
      </h3>
      <div className="form-group row">
        <label htmlFor="address" className="col-sm-3 col-form-label">
          Address:
        </label>
        <div className="col-sm-9">
          <GroupInputField
            className="mb-3"
            fields={[
              {
                element: "input",
                placeholder: "PIN Code",
                name: "pincode",
                minLength: 6,
                maxLength: 6,
                pattern: CustomRegex.PINCODE,
                value: formData?.address?.pincode,
                required: true,
              },
            ]}
            onChange={handleNestedChange("address")}
          />
          <HorizontalSelect
            label="Location type:"
            name="locationType"
            placeholder="Select location type"
            options={Object.values(EnterpriseLocationTypeEnum).map((value) => ({
              label: splitKeyWithSpace(value),
              value,
            }))}
            value={formData?.address?.locationType}
            onChange={handleNestedChange("address")}
            required
          />
          <GroupInputField
            className="mb-3"
            fields={[
              {
                element: "input",
                placeholder: "Locality info",
                name: "localityInfo",
                value: formData?.address?.localityInfo,
                required: true,
                pattern: CustomRegex.ADDRESS_LINE,
              },
            ]}
            onChange={handleNestedChange("address")}
          />
          <GroupInputField
            className="mb-3"
            fields={[
              {
                element: "input",
                placeholder: "State",
                name: "state",
                value: formData?.address?.state,
                required: true,
              },
              {
                element: "input",
                placeholder: "Country",
                // We support only India now, thus hardcoded
                value: "India",
                disabled: true,
              },
            ]}
            onChange={handleNestedChange("address")}
          />
        </div>
      </div>
      <h3 className="user-page-title user-details-title mb-4">Bank details</h3>
      <HorizontalSelect
        label="Bank account type:"
        name="bankAccountType"
        placeholder="Select Bank Account Type"
        options={Object.values(EnterpriseBankAccountTypeEnum).map((value) => ({
          label: splitKeyWithSpace(value),
          value,
        }))}
        value={formData?.bankAccountInfo?.accountType}
        onChange={handleNestedChange("bankAccountInfo")}
        required
      />
      <HorizontalInputField
        label="Account No:"
        name="accountNumber"
        minLength={9}
        maxLength={18}
        placeholder="Enter bank account number"
        pattern={CustomRegex.BANK_ACCOUNT_NUMBER}
        title="Please enter a valid bank account number"
        value={formData?.bankAccountInfo?.accountNumber}
        onChange={handleNestedChange("bankAccountInfo")}
        required
      />
      <HorizontalInputField
        label="IFSC:"
        name="ifsc"
        minLength={11}
        maxLength={11}
        placeholder="Bank branch IFSC code"
        pattern={CustomRegex.IFSC}
        title="Please enter a valid IFSC code"
        value={formData?.bankAccountInfo?.ifsc}
        onChange={handleNestedChange("bankAccountInfo")}
        required
      />
      <h3 className="user-page-title user-details-title mb-4">
        VPA: NBFC Payment Receivable Account
      </h3>
      <HorizontalInputField
        label="Account Number:"
        type="text"
        name="accountNumber"
        value={formData?.virtualPaymentAccount?.accountNumber}
        onChange={handleVpaChange}
      />
      <HorizontalInputField
        label="IFSC:"
        type="text"
        name="ifsc"
        value={formData?.virtualPaymentAccount?.ifsc}
        onChange={handleVpaChange}
      />
      <div className="mt-4">
        <Button type="submit" loading={isLoading || pinDataLoading}>
          {isEditing ? "Update" : "Submit"}
        </Button>
      </div>
    </form>
  );
}
