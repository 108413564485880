import { ReactNode } from "react";
import "./styles.css";

import { ReactComponent as GreenTickIcon } from "../../images/green-tick.svg";
import { ReactComponent as RedCrossIcon } from "../../images/red-cross.svg";
import { ReactComponent as YellowExclamationIcon } from "../../images/yellow-exclamation.svg";

interface CustomAlertProps {
  icon?: ReactNode;
  variant?: "success" | "danger" | "warning";
  footer?: ReactNode;
  children: ReactNode;
  fullWidth?: boolean;
}

function renderIcon(variant: "success" | "danger" | "warning") {
  if (variant === "success") return <GreenTickIcon />;
  if (variant === "danger") return <RedCrossIcon />;
  if (variant === "warning") return <YellowExclamationIcon />;
  return null;
}

export default function CustomAlert({
  icon,
  variant = "success",
  footer,
  children,
  fullWidth = false,
}: CustomAlertProps) {
  return (
    <div
      className={`custom-alert custom-alert__${variant} ${
        fullWidth ? "custom-alert-full-width" : ""
      }`}
    >
      <div className="custom-alert-body">
        <div className="custom-alert-icon mr-2">
          {icon || renderIcon(variant)}
        </div>
        <span>{children}</span>
      </div>
      {footer && <div className="mt-3">{footer}</div>}
    </div>
  );
}
