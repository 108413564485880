import { ReactNode } from "react";
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import "./styles.css";

interface ComponentProps {
  children: ReactNode;
}

export default function Layout({ children }: ComponentProps) {
  return (
    <div className="page-wrapper">
      <SideNav />
      <div className="content">
        <TopBar />
        <main>{children}</main>
      </div>
    </div>
  );
}
