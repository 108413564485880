import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPortfolioStats } from "../../actions/admin.action";
import dispatch from "../../middleware";
import { useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import { FinancialDataDto } from "../../dto/portfolio.dto";
import Table, { TableColumns } from "../../components/table";
import { getMonthTypeInputValueFromDate } from "./helper";
import Modal from "../../components/modal";
import useScreenName from "../../hooks/useScreenName";
import { isLoadingActive } from "../../utils";

const PORTFOLIO_STATS_COLUMNS: TableColumns[] = [
  { field: "tenure", label: "Tenure" },
  {
    field: "totalDisbursedAmountInCr",
    label: "Total Disbursed Amount (In Cr.)",
  },
  {
    field: "totalOutstandingAmountInCr",
    label: "Outstanding Amount (In Cr.)",
  },
  {
    field: "dpdThirtyPlusOverdueAmountInCr",
    label: "Overdue Amount in DPD 30+ (In Cr.)",
  },
  {
    field: "dpdSixtyPlusOverdueAmountInCr",
    label: "OverDue Amount in DPD 60+ (In Cr.)",
  },
  {
    field: "dpdNinetyPlusOverdueAmountInCr",
    label: "Overdue Amount in DPD 90+ (In Cr.)",
  },
  {
    field: "dpdThirtyPlusOverdueAmountPercentage",
    label: "%Amount in DPD 30+",
  },
  {
    field: "dpdSixtyPlusOverdueAmountPercentage",
    label: "%Amount in DPD 60+",
  },
  {
    field: "dpdNinetyPlusOverdueAmountPercentage",
    label: "%Amount in DPD 90+",
  },
];

const getTableDataForPortfolioStats = (
  portfolioStats: FinancialDataDto | null
) => {
  if (!portfolioStats) return [];
  return Object.entries(portfolioStats).map(([key, value]) => ({
    tenure: key,
    totalDisbursedAmountInCr: value.totalDisbursedAmountInCr ?? "-",
    totalOutstandingAmountInCr: value.totalOutstandingAmountInCr ?? "-",
    dpdThirtyPlusOverdueAmountInCr: value.dpdThirtyPlusOverdueAmountInCr ?? "-",
    dpdSixtyPlusOverdueAmountInCr: value.dpdSixtyPlusOverdueAmountInCr ?? "-",
    dpdNinetyPlusOverdueAmountInCr: value.dpdNinetyPlusOverdueAmountInCr ?? "-",
    dpdThirtyPlusOverdueAmountPercentage:
      value.dpdThirtyPlusOverdueAmountPercentage !== undefined &&
      value.dpdThirtyPlusOverdueAmountPercentage !== null
        ? `${value.dpdThirtyPlusOverdueAmountPercentage}%`
        : "-",
    dpdSixtyPlusOverdueAmountPercentage:
      value.dpdSixtyPlusOverdueAmountPercentage !== undefined &&
      value.dpdSixtyPlusOverdueAmountPercentage !== null
        ? `${value.dpdSixtyPlusOverdueAmountPercentage}%`
        : "-",
    dpdNinetyPlusOverdueAmountPercentage:
      value.dpdNinetyPlusOverdueAmountPercentage !== undefined &&
      value.dpdNinetyPlusOverdueAmountPercentage !== null
        ? `${value.dpdNinetyPlusOverdueAmountPercentage}%`
        : "-",
  }));
};

export default function Portfolio() {
  useScreenName("Portfolio Stats");
  const storeDispatch = useDispatch();
  const loadingQueue = useSelector(
    (state: ReduxState) => state.admin.loadingQueue
  );
  const portfolioStats: FinancialDataDto | null = useSelector(
    (state: ReduxState) => state.admin.portfolioStats
  );
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState<Date | null>(
    new Date()
  );

  const [errorModal, setErrorModal] = useState<{
    title: string;
    message: string;
    active: boolean;
  }>({ title: "", message: "", active: false });

  async function fetchPortfolioStats(date: Date | null) {
    try {
      const monthAndYear = getMonthTypeInputValueFromDate(date);
      if (!monthAndYear) return;
      const [year, month] = monthAndYear.split("-");

      await dispatch(storeDispatch, getPortfolioStats({ month, year }));
    } catch (error: any) {
      setErrorModal({
        title: "Failed to load response",
        message: "Please try again",
        active: true,
      });
    }
  }

  useEffect(() => {
    fetchPortfolioStats(selectedMonthAndYear);
  }, [selectedMonthAndYear]);

  return (
    <>
      <div className="d-flex justify-content-end mb-4">
        <input
          type="month"
          id="formDate"
          className="input-login p-2"
          placeholder="Filters"
          value={getMonthTypeInputValueFromDate(selectedMonthAndYear)}
          onChange={(e) => setSelectedMonthAndYear(e.currentTarget.valueAsDate)}
          required
        />
      </div>
      <div className="dashboard-container">
        <Table
          loading={isLoadingActive(loadingQueue)}
          columns={PORTFOLIO_STATS_COLUMNS}
          data={getTableDataForPortfolioStats(portfolioStats)}
        />
      </div>
      <Modal
        open={errorModal.active}
        title={errorModal.title}
        onClose={() =>
          setErrorModal((prevError) => ({ ...prevError, active: false }))
        }
        hasCancelButton={false}
        hasSubmitButton={false}
      >
        {errorModal.message}
      </Modal>
    </>
  );
}
