import { Actions } from "../actions/loan.action";
import {
  CommercialLoanDetails,
  CommercialODLoanStatement,
  CommercialODTransactionStatement,
  LoanDetails,
  RepaymentScheduleDetails,
  RetailODDetails,
  TermLoanDetails,
  TermLoanRepayments,
  TermLoanLedgerInfoDto,
  UserLoanDetails,
  RetailOdLedgerInfoDto,
  WCTLoanDetail,
  WCTLLoanRepaymentSchedule,
} from "../dto";
import { LoanType, SearchFilter, Transaction } from "../models";
import { addToLoadingQueue, removeFromLoadingQueue } from "../utils";

export interface LoanState {
  loadingQueue: string[];
  loans: TermLoanDetails[] | RetailODDetails[];
  singleLoan: LoanDetails | null;
  singleCommercialLoan: CommercialLoanDetails | null;
  singleLoanRepaymentSchedule: RepaymentScheduleDetails[];
  singleLoanTransactions: Transaction[];
  commercialODLoanStatement: CommercialODLoanStatement[] | null;
  commercialODTransactionStatement: CommercialODTransactionStatement[] | null;
  termLoanRepayments: TermLoanRepayments[] | null;
  userLoans: UserLoanDetails;
  termLoanLedgerInfo: TermLoanLedgerInfoDto | null;
  retailOdLedgerInfo: RetailOdLedgerInfoDto | null;
  wctlLoan: WCTLoanDetail | null;
  wctlLoanRepaymentSchedule: WCTLLoanRepaymentSchedule[];
  paginationCount: {
    termLoanCount: number | null;
    retailODCount: number | null;
  };
  searchFilters: Record<LoanType, SearchFilter[]>;
  count: number | null;
}

const defaultState: LoanState = {
  loadingQueue: [],
  loans: [],
  singleLoan: null,
  singleCommercialLoan: null,
  singleLoanRepaymentSchedule: [],
  singleLoanTransactions: [],
  wctlLoanRepaymentSchedule: [],
  commercialODLoanStatement: null,
  commercialODTransactionStatement: null,
  termLoanRepayments: null,
  termLoanLedgerInfo: null,
  retailOdLedgerInfo: null,
  wctlLoan: null,
  userLoans: {
    retailODs: [],
    termLoans: [],
  },
  searchFilters: {
    TERM_LOAN: [],
    RETAIL_OD: [],
    COMMERCIAL_OD: [],
    WORKING_CAPITAL_TERM_LOAN: [],
  },
  paginationCount: {
    termLoanCount: null,
    retailODCount: null,
  },
  count: null,
};

export default function LoanStateReducer(
  state: LoanState = defaultState,
  action: any
): LoanState {
  switch (action.type) {
    case Actions.REQUEST_GET_PAGINATED_LOANS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_PAGINATED_LOANS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        loans: action?.payload,
      };
    case `${Actions.REQUEST_GET_PAGINATED_LOANS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        loans: [],
      };
    case Actions.REQUEST_GET_LOAN_BY_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_LOAN_BY_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleLoan: action?.payload,
      };
    case `${Actions.REQUEST_GET_LOAN_BY_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleLoan: null,
      };
    case Actions.REQUEST_GET_LOANS_BY_USER_ID:
    case Actions.REQUEST_GET_LOANS_BY_ENTERPRISE_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case Actions.REQUEST_GET_COMMERCIAL_LOAN_BY_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_COMMERCIAL_LOAN_BY_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleCommercialLoan: action?.payload,
      };
    case `${Actions.REQUEST_GET_COMMERCIAL_LOAN_BY_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleCommercialLoan: null,
      };
    case `${Actions.REQUEST_GET_LOANS_BY_USER_ID}_SUCCESS`:
    case `${Actions.REQUEST_GET_LOANS_BY_ENTERPRISE_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        userLoans: action?.payload,
      };
    case `${Actions.REQUEST_GET_LOANS_BY_USER_ID}_FAILURE`:
    case `${Actions.REQUEST_GET_LOANS_BY_ENTERPRISE_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        userLoans: {
          retailODs: [],
          termLoans: [],
        },
      };
    //TODO: Payment and Withdrawal
    case Actions.REQUEST_GET_REPAYMENT_SCHEDULE_BY_LOAN_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_REPAYMENT_SCHEDULE_BY_LOAN_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleLoanRepaymentSchedule: action?.payload,
      };
    case `${Actions.REQUEST_GET_REPAYMENT_SCHEDULE_BY_LOAN_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleLoanRepaymentSchedule: [],
      };
    case Actions.REQUEST_GET_LOAN_COUNT:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_LOAN_COUNT}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        count: action?.payload,
      };
    case `${Actions.REQUEST_GET_LOAN_COUNT}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        count: null,
      };
    case Actions.REQUEST_GET_LOAN_SEARCH_FILTERS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_LOAN_SEARCH_FILTERS}_SUCCESS`: {
      const { loanType } = action?.metadata || {};
      if (!loanType) return state;
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        searchFilters: Object.assign({}, state.searchFilters, {
          [loanType]: action?.payload?.filters || [],
        }),
      };
    }
    case `${Actions.REQUEST_GET_LOAN_SEARCH_FILTERS}_FAILURE`: {
      const { loanType } = action?.metadata || {};
      if (!loanType) return state;
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        searchFilters: Object.assign({}, state.searchFilters, {
          [loanType]: [],
        }),
      };
    }
    case Actions.REQUEST_GET_LOANS_BY_SEARCH:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_LOANS_BY_SEARCH}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        loans: action?.payload,
      };
    case `${Actions.REQUEST_GET_LOANS_BY_SEARCH}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        loans: [],
      };
    case Actions.REQUEST_GET_COMMERICIAL_LOANS_BY_SEARCH:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_COMMERICIAL_LOANS_BY_SEARCH}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        loans: action?.payload?.data ?? [],
      };
    case `${Actions.REQUEST_GET_COMMERICIAL_LOANS_BY_SEARCH}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        loans: [],
      };
    case Actions.REQUEST_GET_WORKING_CAPITAL_TERM_LOAN_BY_SEARCH:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
        loans: [],
      };
    case `${Actions.REQUEST_GET_WORKING_CAPITAL_TERM_LOAN_BY_SEARCH}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        loans: action?.payload?.data ?? [],
      };
    case `${Actions.REQUEST_GET_WORKING_CAPITAL_TERM_LOAN_BY_SEARCH}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        loans: [],
      };
    case Actions.REQUEST_GET_LOAN_PAGINATION_COUNT:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_LOAN_PAGINATION_COUNT}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        paginationCount: {
          ...state.paginationCount,
          ...action?.payload,
        },
      };
    case `${Actions.REQUEST_GET_LOAN_PAGINATION_COUNT}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case Actions.REQUEST_ADD_LOAN_PAYMENT:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_ADD_LOAN_PAYMENT}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleLoanTransactions: [
          ...state.singleLoanTransactions,
          action?.payload,
        ],
      };
    case `${Actions.REQUEST_ADD_LOAN_PAYMENT}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case Actions.REQUEST_ADD_LOAN_WITHDRAWAL:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_ADD_LOAN_WITHDRAWAL}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleLoanTransactions: [
          ...state.singleLoanTransactions,
          action?.payload,
        ],
      };
    case `${Actions.REQUEST_ADD_LOAN_WITHDRAWAL}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case Actions.REQUEST_GET_TRANSACTIONS_BY_LOAN_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_TRANSACTIONS_BY_LOAN_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleLoanTransactions: action?.payload,
      };
    case `${Actions.REQUEST_GET_TRANSACTIONS_BY_LOAN_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleLoanTransactions: [],
      };
    case Actions.REQUEST_GET_COMMERCIAL_LOAN_STATEMENT_BY_LOAN_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_COMMERCIAL_LOAN_STATEMENT_BY_LOAN_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        commercialODLoanStatement: action?.payload,
      };
    case `${Actions.REQUEST_GET_COMMERCIAL_LOAN_STATEMENT_BY_LOAN_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        commercialODLoanStatement: [],
      };

    case Actions.REQUEST_GET_COMMERCIAL_OD_TRANSACTION_STATEMENT:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_COMMERCIAL_OD_TRANSACTION_STATEMENT}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        commercialODTransactionStatement: action?.payload,
      };
    case `${Actions.REQUEST_GET_COMMERCIAL_OD_TRANSACTION_STATEMENT}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        commercialODTransactionStatement: [],
      };
    case Actions.REQUEST_ADD_LOAN_DISBURSAL:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_ADD_LOAN_DISBURSAL}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        singleLoan: action?.payload,
      };
    case `${Actions.REQUEST_ADD_LOAN_DISBURSAL}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };

    case Actions.REQUEST_TERM_LOAN_REPAYMENT_BY_LOAN_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_TERM_LOAN_REPAYMENT_BY_LOAN_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        termLoanRepayments: action?.payload ?? null,
      };
    case `${Actions.REQUEST_TERM_LOAN_REPAYMENT_BY_LOAN_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        termLoanRepayments: null,
      };
    case Actions.REQUEST_GET_TERM_LOAN_LEDGER_INFO_BY_LOAN_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_TERM_LOAN_LEDGER_INFO_BY_LOAN_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        termLoanLedgerInfo: action?.payload ?? null,
      };
    case `${Actions.REQUEST_GET_TERM_LOAN_LEDGER_INFO_BY_LOAN_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        termLoanLedgerInfo: null,
      };
    case Actions.REQUEST_GET_RETAIL_OD_LEDGER_INFO_BY_LOAN_ID:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_RETAIL_OD_LEDGER_INFO_BY_LOAN_ID}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        retailOdLedgerInfo: action?.payload ?? null,
      };
    case `${Actions.REQUEST_GET_RETAIL_OD_LEDGER_INFO_BY_LOAN_ID}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        retailOdLedgerInfo: null,
      };
    case Actions.REQUEST_GET_WCTL_LOAN_DETAILS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_GET_WCTL_LOAN_DETAILS}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        wctlLoan: action?.payload ?? null,
      };
    case `${Actions.REQUEST_GET_WCTL_LOAN_DETAILS}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        wctlLoan: null,
      };
    case Actions.REQUEST_WCTL_REPAYMENT_SCHEDULE:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${Actions.REQUEST_WCTL_REPAYMENT_SCHEDULE}_SUCCESS`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        wctlLoanRepaymentSchedule: action?.payload ?? [],
      };
    case `${Actions.REQUEST_WCTL_REPAYMENT_SCHEDULE}_FAILURE`:
      return {
        ...state,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
        wctlLoanRepaymentSchedule: [],
      };
    default:
      return state;
  }
}
