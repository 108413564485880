import { useState } from "react";
import Button from "../../../components/button";
import Table, { TableColumns } from "../../../components/table";
import { ReactComponent as DownloadIcon } from "../../../images/download.svg";
import { useDispatch } from "react-redux";
import dispatch from "../../../middleware";
import {
  addWCTLoanDisbursal,
  AddWCTLRepayment,
  AddWCTLTdsCredit,
} from "../../../models";
import {
  addWCTLRepayment,
  addWCTLTdsCredit,
  updateWCTLoanDisbursal,
} from "../../../actions/loan.action";
import Modal from "../../../components/modal";
import { WCTLAddRepayment } from "./wctlAddRepayment";
import { WCTLAddDisbursal } from "./wctlAddDisbursalDetails";
import { WCTLAddTdsCredit } from "./wctlAddTdsCredit";

interface WCTLRepaymentScheduleProps {
  loanId: string;
  isLoading: boolean;
  onDownloadClick: () => void;
  columns: TableColumns[];
  data: {
    emiNumber: number;
    dueAt: string;
    principal: number;
    interest: number;
    totalAmount: string;
    status: string;
  }[];
}

export function WCTLRepaymentSchedule({
  loanId,
  isLoading,
  columns,
  data,
  onDownloadClick,
}: WCTLRepaymentScheduleProps) {
  const storeDispatch = useDispatch();
  const [isModalActive, setIsModalActive] = useState<{
    addDisbursal: boolean;
    addTdsCredit: boolean;
    addRepayment: boolean;
  }>({ addDisbursal: false, addTdsCredit: false, addRepayment: false });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  async function handleAddRepaymentSubmit(formDate: AddWCTLRepayment) {
    try {
      const payload: AddWCTLRepayment = {
        amount: parseFloat(String(formDate.amount)),
        paymentRefId: formDate.paymentRefId,
        source: formDate.source,
        paidAt: new Date(formDate.paidAt).toISOString(),
      };

      const response = await dispatch(
        storeDispatch,
        addWCTLRepayment({ loanId, body: payload })
      );

      if (!response) return;
      setSuccessMessage(
        `Repayment of amount ${formDate.amount} added successfully with Txn ID # ${response._id}`
      );
    } catch (err) {
      setErrorMessage(err?.message);
    }
  }

  async function handleAddTdsCreditSubmit(formData: AddWCTLTdsCredit) {
    try {
      const payload: AddWCTLTdsCredit = {
        amount: parseFloat(String(formData.amount)),
        paymentRefId: formData.paymentRefId,
        source: formData.source,
        paidAt: new Date(formData.paidAt).toISOString(),
      };

      const response = await dispatch(
        storeDispatch,
        addWCTLTdsCredit({ loanId, body: payload })
      );

      if (!response) return;
      setSuccessMessage(
        `TDS Credit added successfully with amount ${formData.amount}`
      );
    } catch (err) {
      setErrorMessage(err?.message);
    }
  }

  async function handleAddDisbursalDetailsSubmit(
    formData: addWCTLoanDisbursal
  ) {
    try {
      const payload: addWCTLoanDisbursal = {
        date: new Date(formData.date).toISOString(),
        disbursedAmount: parseFloat(String(formData.disbursedAmount)),
        transactionRefNo: formData.transactionRefNo,
        bankAccountNo: formData.bankAccountNo,
        ifscCode: formData.ifscCode,
      };

      const response = await dispatch(
        storeDispatch,
        updateWCTLoanDisbursal({ loanId, body: payload })
      );

      if (!response) return;
      setSuccessMessage(
        `Disbursal of Rs ${formData.disbursedAmount} for Loan # ${loanId} added successfully with Transaction ID: ${response?._id}`
      );
    } catch (err) {
      setErrorMessage(err?.message);
    }
  }

  return (
    <>
      <div className="mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="user-page-title mb-4">Repayment Schedule</h3>
          <div>
            <Button
              onClick={() =>
                setIsModalActive((prevState) => ({
                  ...prevState,
                  addRepayment: true,
                }))
              }
            >
              Add Repayment
            </Button>
            <Button
              style={{ marginLeft: "16px", marginRight: "16px" }}
              onClick={() =>
                setIsModalActive((prevState) => ({
                  ...prevState,
                  addDisbursal: true,
                }))
              }
            >
              Add Disbursal
            </Button>
            <Button
              onClick={() =>
                setIsModalActive((prevState) => ({
                  ...prevState,
                  addTdsCredit: true,
                }))
              }
            >
              Add Tds Credit
            </Button>
            <Button variant="info" title="Download" onClick={onDownloadClick}>
              <DownloadIcon height="25" width="25" color="var(--font-color)" />
            </Button>
          </div>
        </div>
      </div>
      <Table loading={isLoading} columns={columns} data={data} />
      <Modal
        open={isModalActive.addDisbursal}
        title="Add Disbursal"
        onClose={() => {
          setIsModalActive((prevState) => ({
            ...prevState,
            addDisbursal: false,
          }));
          setErrorMessage("");
        }}
        hasCancelButton={false}
        hasSubmitButton={false}
        children={
          <WCTLAddDisbursal
            errMessage={errorMessage}
            successMessage={successMessage}
            onDismissClick={() => setErrorMessage("")}
            onFormSubmit={handleAddDisbursalDetailsSubmit}
          />
        }
      />
      <Modal
        open={isModalActive.addTdsCredit}
        title="Add Tds Credit"
        onClose={() => {
          setIsModalActive((prevState) => ({
            ...prevState,
            addTdsCredit: false,
          }));
          setErrorMessage("");
        }}
        hasSubmitButton={false}
        hasCancelButton={false}
        children={
          <WCTLAddTdsCredit
            errMessage={errorMessage}
            successMessage={successMessage}
            onDismissClick={() => setErrorMessage("")}
            onFormSubmit={handleAddTdsCreditSubmit}
          />
        }
      />
      <Modal
        open={isModalActive.addRepayment}
        title="Add Repayment"
        onClose={() => {
          setIsModalActive((prevState) => ({
            ...prevState,
            addRepayment: false,
          }));
          setErrorMessage("");
        }}
        hasSubmitButton={false}
        hasCancelButton={false}
        children={
          <WCTLAddRepayment
            errMessage={errorMessage}
            successMessage={successMessage}
            onDismissClick={() => setErrorMessage("")}
            onFormSubmit={handleAddRepaymentSubmit}
          />
        }
      />
    </>
  );
}
