import { Switch, Route, Redirect } from "react-router-dom";
import DashboardScreen from "../../containers/dashboard";
import UserScreen from "../../containers/users";
import SingleUserScreen from "../../containers/user-details/individual";
import ApplicationsScreen from "../../containers/applications";
import ApplicationDetailsScreen from "../../containers/application-details";
import LoanScreen from "../../containers/loans";
import LoanDetailsScreen from "../../containers/loan-details";
import ProfileScreen from "../../containers/profile";
import Layout from "../../layout";
import { RoutePaths } from "../../models";
import ReportsScreen from "../../containers/reports";
import Agreements from "../../containers/agreements";
import EnterpriseUserScreen from "../../containers/enterprise-users";
import SingleEnterpriseScreen from "../../containers/enterprise-users/individual/singleEnterpriseScreen";
import SingleCommercialLoanOdApplication from "../../containers/application-details/commercial-od/singleApplication";
import CommercialODLoan from "../../containers/loan-details/commercial-loan/commercial-loan";
import Invoice from "../../containers/invoice";
import Portfolio from "../../containers/portfolio";
import RefundApproval from "../../containers/refund-approval";
import { WCTLApplicationDetailsScreen } from "../../containers/application-details/wctl";
import WCTLoanDetailsScreen from "../../containers/loan-details/wctl-loan";

export default function PrivateRoutes() {
  return (
    <Layout>
      <Switch>
        <Route path={RoutePaths.DASHBOARD} exact component={DashboardScreen} />
        <Route
          path={RoutePaths.INDIVIDUAL_USERS}
          exact
          component={UserScreen}
        />
        <Route
          path={RoutePaths.ENTERPRISE_USERS}
          exact
          component={EnterpriseUserScreen}
        />
        <Route
          path={`${RoutePaths.ENTERPRISE_USERS}/:id`}
          component={SingleEnterpriseScreen}
        />
        <Route
          path={`${RoutePaths.INDIVIDUAL_USERS}/:userId`}
          component={SingleUserScreen}
        />
        <Route
          path={RoutePaths.APPLICATIONS}
          exact
          component={ApplicationsScreen}
        />
        <Route
          path={`${RoutePaths.APPLICATIONS}/:appId`}
          exact
          component={ApplicationDetailsScreen}
        />
        <Route
          path={`${RoutePaths.COMMERCIAL_LOAN_APPLICATION}/:appId`}
          exact
          component={SingleCommercialLoanOdApplication}
        />
        <Route
          path={`${RoutePaths.WCTL_LOAN_APPLICATION}/:appId`}
          exact
          component={WCTLApplicationDetailsScreen}
        />
        <Route
          path={`${RoutePaths.LOANS}${RoutePaths.WORKING_CAPITAL_TERM_LOAN}/:loanId`}
          exact
          component={WCTLoanDetailsScreen}
        />
        <Route path={RoutePaths.LOANS} exact component={LoanScreen} />
        <Route
          path={`${RoutePaths.LOANS}/commercial/:loanId`}
          component={CommercialODLoan}
        />
        <Route
          path={`${RoutePaths.LOANS}/:loanId`}
          component={LoanDetailsScreen}
        />
        <Route path={RoutePaths.INVOICE} component={Invoice} />
        <Route path={RoutePaths.REPORTS} exact component={ReportsScreen} />
        <Route path={RoutePaths.PROFILE} exact component={ProfileScreen} />
        <Route path={RoutePaths.AGREEMENTS} exact component={Agreements} />
        <Route path={RoutePaths.PORTFOLIO} exact component={Portfolio} />
        <Route
          path={RoutePaths.REFUND_APPROVAL}
          exact
          component={RefundApproval}
        />
        <Redirect from={RoutePaths.USERS} to={RoutePaths.INDIVIDUAL_USERS} />
      </Switch>
    </Layout>
  );
}
