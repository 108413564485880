import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import dispatch from "../../middleware";
import { ReduxState } from "../../reducers";
import { useDispatch } from "react-redux";
import Table from "../../components/table";
import { RoutePaths } from "../../models";
import Badge from "../../components/badge";
import useScreenName from "../../hooks/useScreenName";
import Button from "../../components/button";
import Modal from "../../components/modal";
import { SearchBox } from "../../components/input-functions";
import AddOrEditEnterpriseForm from "./addOrEditEnterpriseForm";
import { getAllEnterprisesV1 } from "../../actions/enterprise.action";
import {
  EnterpriseDetailsV1,
  EnterpriseStatus,
} from "../../dto/enterprise.dto";
import useQueryParams from "../../hooks/useQueryParams";

const columns = [
  { field: "_id", label: "Enterprise ID" },
  { field: "name", label: "Name" },
  { field: "pan", label: "PAN" },
  { field: "status", label: "Status" },
];

type SearchQueryType = {
  pageNo: number;
  searchText: string;
  numberOfEntitiesPerPage: number;
  status: string[];
};

const DEFAULT_SEARCH_QUERY_STATE: SearchQueryType = {
  pageNo: 1,
  searchText: "",
  numberOfEntitiesPerPage: 10,
  status: [],
};

export default function EnterpriseUserScreen() {
  useScreenName("Users / Enterprise");
  const storeDispatch = useDispatch();
  const enterprises: EnterpriseDetailsV1[] = useSelector(
    (state: ReduxState) => state.enterprise.enterprisesV1
  );
  const isLoading = useSelector(
    (state: ReduxState) => state.enterprise.loading
  );
  const [isModalActive, setIsModalActive] = useState(false);

  const [{ searchText }, setSearchQuery] = useQueryParams<SearchQueryType>(
    DEFAULT_SEARCH_QUERY_STATE
  );

  const filteredEnterprises = useMemo(() => {
    return enterprises.filter((enterprise) =>
      enterprise.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, enterprises]);

  useEffect(() => {
    dispatch(storeDispatch, getAllEnterprisesV1());
  }, []);

  function handleSearch(searchText: string) {
    setSearchQuery((prevState) => ({ ...prevState, searchText, pageNo: 1 }));
  }

  function getStatusBadge(status?: EnterpriseStatus) {
    if (status === EnterpriseStatus.VERIFIED)
      return <Badge type="success">Verified</Badge>;
    if (status === EnterpriseStatus.PENDING)
      return <Badge type="pending">Pending</Badge>;
    if (status === EnterpriseStatus.REJECTED)
      return <Badge type="reject">Rejected</Badge>;
    return "";
  }
  function getIdLink(id: string) {
    return (
      <Link to={`${RoutePaths.ENTERPRISE_USERS}/${id}`} className="table-link">
        {id}
      </Link>
    );
  }

  function getTableData(enterpriseArr: EnterpriseDetailsV1[]) {
    return enterpriseArr.map((eachEnterprise) => {
      const { _id, name, pan, status } = eachEnterprise;
      return {
        _id: getIdLink(_id),
        name: name || "-",
        pan: pan || "-",
        status: getStatusBadge(status),
      };
    });
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-4">
        <Button
          size="sm"
          onClick={() => setIsModalActive((prevState) => !prevState)}
        >
          Add Enterprise
        </Button>
      </div>
      <div className="dashboard-container">
        <div className="d-flex flex-lg-row flex-column align-items-lg-end mb-4">
          <SearchBox
            value={searchText}
            label="Search"
            placeholder="Search by Name"
            onSubmit={handleSearch}
          />
        </div>
        <Table
          loading={isLoading}
          columns={columns}
          data={getTableData(filteredEnterprises)}
        />
      </div>
      <Modal
        open={isModalActive}
        title="Add Enterprise"
        onClose={() => setIsModalActive((prevState) => !prevState)}
        size="large"
        hasCancelButton={false}
        hasSubmitButton={false}
      >
        <AddOrEditEnterpriseForm />
      </Modal>
    </>
  );
}
