import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/button";
import Avatar from "../../components/avatar";
import {
  RetailODApplicationDetails,
  TermLoanApplicationDetails,
  UserDetails,
} from "../../dto";
import {
  ApplicationType,
  FieldLabel,
  RoutePaths,
  UpdateApplicationStatus,
} from "../../models";
import { useDispatch } from "react-redux";
import { updateApplicationStatus } from "../../actions/application.action";
import dispatch from "../../middleware";
import { ReactComponent as LoaderIcon } from "../../images/loader.svg";
import { ReactComponent as PencilIcon } from "../../images/pencil.svg";
import { isApplicationInEditableState, isInProgress } from "./utils";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../components/confirm-dialog";
import {
  clearFormData,
  dateToDateString,
  getTodayDateForInputField,
} from "../../utils";
import DefaultUserAvatar from "../../images/default-user-avatar.svg";
import DatafieldSection from "../../components/datafield-section";
import Modal from "../../components/modal";
import CreateOrEditApplicationForm from "../user-details/createApplicationForm";
import { EnterpriseDetails } from "../../dto/enterprise.dto";

interface ApplicationCardProps {
  isLoading: boolean;
  applicationDetails:
    | TermLoanApplicationDetails
    | RetailODApplicationDetails
    | null;
  appId: string;
  userDetails: UserDetails | EnterpriseDetails | null;
  selfieDataUrl: string | null;
  errorMessage: string;
}

export default function ApplicationCard({
  isLoading,
  applicationDetails,
  appId,
  userDetails,
  selfieDataUrl,
  errorMessage,
}: ApplicationCardProps) {
  const storeDispatch = useDispatch();

  const [isModalActive, setIsModalActive] = useState({
    approve: false,
    reject: false,
    edit: false,
  });

  const isEnterprise = !!applicationDetails?.enterpriseId;

  async function handleConfirmDialog(type: ConfirmDialogType, fieldData: any) {
    if (type === "success") {
      if (
        applicationDetails?.applicationType === "TERM_LOAN_APP" &&
        !fieldData?.emiStartDate
      )
        throw new Error("Please enter the EMI start date");
    } else if (type === "reject") {
      if (!fieldData?.reason) {
        throw new Error("Please enter the reason to reject");
      }
    }
    const statusUpdateObj: UpdateApplicationStatus = {
      toStatus: type === "success" ? "APPROVED" : "REJECTED",
      appId,
      reason: fieldData?.reason,
      // INFO: Only for Approval of Term Loan this field will be there
      emiStartDate: fieldData?.emiStartDate
        ? dateToDateString(fieldData?.emiStartDate)
        : undefined,
      comment: fieldData?.reason,
    };
    await dispatch(
      storeDispatch,
      updateApplicationStatus(clearFormData(statusUpdateObj))
    );
    if (type === "success")
      return { message: `Application # ${appId} has been approved` };
    if (type === "reject")
      return { message: `Application # ${appId} has been rejected` };
  }

  const labels: Record<ApplicationType, FieldLabel[]> = {
    TERM_LOAN_APP: [
      { key: "_id", label: "Application ID :" },
      {
        key: isEnterprise ? "enterpriseId" : "userId",
        label: "User ID :",
        formatMethod: (userId) => (
          <Link
            to={`${
              isEnterprise
                ? RoutePaths.ENTERPRISE_USERS
                : RoutePaths.INDIVIDUAL_USERS
            }/${userId}`}
          >
            {userId}
          </Link>
        ),
      },
    ],
    RETAIL_OD_APP: [
      { key: "_id", label: "Application ID :" },
      {
        key: isEnterprise ? "enterpriseId" : "userId",
        label: "User ID :",
        formatMethod: (userId) => (
          <Link
            to={`${
              isEnterprise
                ? RoutePaths.ENTERPRISE_USERS
                : RoutePaths.INDIVIDUAL_USERS
            }/${userId}`}
          >
            {userId}
          </Link>
        ),
      },
    ],
    COMMERCIAL_OD_APP: [
      { key: "_id", label: "Application ID :" },
      {
        key: isEnterprise ? "enterpriseId" : "userId",
        label: "User ID :",
        formatMethod: (userId) => (
          <Link
            to={`${
              isEnterprise
                ? RoutePaths.ENTERPRISE_USERS
                : RoutePaths.INDIVIDUAL_USERS
            }/${userId}`}
          >
            {userId}
          </Link>
        ),
      },
    ],
    WORKING_CAPITAL_TERM_LOAN_APP: [
      { key: "_id", label: "Application ID :" },
      {
        key: isEnterprise ? "enterpriseId" : "userId",
        label: "User ID :",
        formatMethod: (userId) => (
          <Link
            to={`${
              isEnterprise
                ? RoutePaths.ENTERPRISE_USERS
                : RoutePaths.INDIVIDUAL_USERS
            }/${userId}`}
          >
            {userId}
          </Link>
        ),
      },
    ],
  };

  function getConfirmPromptFields(appType?: ApplicationType) {
    return [
      {
        name: "reason",
        label: "Remarks",
        type: "text",
        placeholder: "Remarks",
      },
      ...(appType === "TERM_LOAN_APP"
        ? [
            {
              name: "emiStartDate",
              label: "EMI Start Date",
              type: "date",
              placeholder: "date",
              value: getTodayDateForInputField(),
              required: true,
            },
          ]
        : []),
    ];
  }
  if (errorMessage) {
    return <h4 className="text-center">{errorMessage}</h4>;
  }
  if (isLoading || !userDetails) {
    return <LoaderIcon />;
  }
  return (
    <>
      <div className="bg-white d-flex dashboard-container">
        <div className="d-flex flex-row mr-3">
          <Avatar
            displayName={applicationDetails?.userId || ""}
            border="1px solid #fff"
            displayPhotoUrl={selfieDataUrl || DefaultUserAvatar}
            size={100}
          />
        </div>
        <div className="flex-grow-1 mr-3">
          <h5 className="mb-3">{userDetails.name?.data}</h5>
          {applicationDetails?.applicationType && (
            <DatafieldSection
              labels={[{ items: labels[applicationDetails?.applicationType] }]}
              data={applicationDetails}
            />
          )}
        </div>
        <div className="mb-auto ml-auto">
          {isApplicationInEditableState(
            applicationDetails?.applicationType,
            applicationDetails?.status
          ) && (
            <Button
              variant="blue-outline"
              onClick={() =>
                setIsModalActive((prevState) => ({
                  ...prevState,
                  edit: true,
                }))
              }
            >
              <PencilIcon height="15" width="15" />
              Edit
            </Button>
          )}
          {isInProgress(applicationDetails?.status) && (
            <>
              <Button
                variant="danger-outline"
                onClick={() =>
                  setIsModalActive((prevState) => ({
                    ...prevState,
                    reject: true,
                  }))
                }
              >
                Reject
              </Button>
              <Button
                size="md"
                variant="success-outline"
                onClick={() =>
                  setIsModalActive((prevState) => ({
                    ...prevState,
                    approve: true,
                  }))
                }
              >
                Approve
              </Button>
            </>
          )}
        </div>
      </div>
      {/* TODO: Find a better way to do this in a single modal */}
      <ConfirmDialog
        active={isModalActive.approve}
        type="success"
        title="Approve Application"
        message={`Do you want to approve the application # ${applicationDetails?._id}?`}
        onCancel={() =>
          setIsModalActive((prevState) => ({ ...prevState, approve: false }))
        }
        onSubmit={handleConfirmDialog}
        inputFields={getConfirmPromptFields(
          applicationDetails?.applicationType
        )}
      />
      <ConfirmDialog
        active={isModalActive.reject}
        type="reject"
        title="Reject Application"
        message={`Do you want to reject the application # ${applicationDetails?._id}?`}
        onCancel={() =>
          setIsModalActive((prevState) => ({ ...prevState, reject: false }))
        }
        onSubmit={handleConfirmDialog}
        inputFields={[
          {
            name: "reason",
            label: "Reason for rejection",
            type: "text",
            placeholder: "Reason for rejection",
            required: true,
          },
        ]}
      />
      {!!userDetails && (
        <Modal
          open={isModalActive.edit}
          title="Update application"
          size="large"
          hasSubmitButton={false}
          hasCancelButton={false}
          onClose={() =>
            setIsModalActive((prevState) => ({ ...prevState, edit: false }))
          }
        >
          <CreateOrEditApplicationForm
            selfieUrl={selfieDataUrl}
            userDetails={userDetails}
            applicationData={applicationDetails}
          />
        </Modal>
      )}
    </>
  );
}
