import React, { ReactNode } from "react";
import "./styles.css";

interface CustomBadgeProps {
  children: string;
  rounded?: boolean;
  fullWidth?: boolean;
}

export default function CustomBadge({
  children,
  rounded = true,
  fullWidth = false,
}: CustomBadgeProps) {
  return (
    <div
      className={`custom-badge ${rounded ? "rounded" : ""} ${
        fullWidth ? "full-width" : ""
      }`}
    >
      {children}
    </div>
  );
}
