import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dispatch from "../../middleware";
import { ReactComponent as LoaderIcon } from "../../images/loader.svg";
import { FileType } from "../../models";
import { isEmptyObject } from "../../utils";
import "./styles.css";

interface DocumentPreviewProps {
  action?: Promise<any>;
  fileName?: string;
  parentClass?: string;
}

export default function DocumentPreview({
  action,
  fileName,
  parentClass
}: DocumentPreviewProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [documentData, setDocumentData] = useState<{
    body: string;
    type: string;
    metadata?: Record<string, string>;
  }>({ body: "", type: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const storeDispatch = useDispatch();
  async function handleDocFetch() {
    if (!action) return;
    try {
      setErrorMessage("");
      setIsLoading(true);
      const actionObj = await action;
      const response = await dispatch(storeDispatch, actionObj);
      setDocumentData(response);
    } catch (error: any) {
      setDocumentData({ body: "", type: "" });
      setErrorMessage(error?.message ?? 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    handleDocFetch();
  }, [fileName]);
  if (isLoading) {
    return (
      <div className="text-center my-5">
        <LoaderIcon />
      </div>
    );
  }
  if (!documentData.body || errorMessage) {
    return (
      <h5 className="text-center my-5">
        {errorMessage ? errorMessage : "No data found"}
      </h5>
    );
  }

  const docDataUrl = `data:${documentData.type};base64,${documentData.body}`;

  const DocPreviewElem =
    documentData.type === FileType.IMG_JPEG ||
    documentData.type === FileType.IMG_PNG ? (
      <figure className="document-preview-box">
        <img src={docDataUrl} className="img-fluid" alt="doc" />
      </figure>
    ) : (
      <object
        type={documentData.type}
        data={docDataUrl}
        width="100%"
        height="100%"
        style={{minHeight: "560px"}}
      >
        <h5 className="text-center my-5">
          Your browser cannot display the file.{" "}
          <a href={docDataUrl} download>
            Click here
          </a>{" "}
          to download it
        </h5>
      </object>
    );
  return (
    <div className={`${parentClass ? parentClass : ''}`}>
      {(documentData.metadata && !isEmptyObject(documentData.metadata)) && (
        <div className="my-4">
          <h2 className="user-page-title">Metadata</h2>
          {Object.keys(documentData.metadata).map((key) => (
            <p key={key} className="my-2">
              {key}: {documentData.metadata?.[key]}
            </p>
          ))}
        </div>
      )}
      {DocPreviewElem}
    </div>
  );
}
