import Button from "../../../../components/button";
import { ReactComponent as DownloadIcon } from "../../../../images/download.svg";
import { ReactComponent as UploadIcon } from "../../../../images/upload.svg";

import { CommercialODApplicationDocsEnum } from "../../../../models";

interface CommercialODApplicationDocumentListProps {
  documents: {
    name: CommercialODApplicationDocsEnum;
    downloadDocument: (fileName: string) => void;
    uploadDocument: (docName: string) => void;
  }[];
  isDocDownloading: {
    [docName: string]: boolean;
  };
}

export default function CommercialODApplicationDocumentList({
  documents,
  isDocDownloading,
}: CommercialODApplicationDocumentListProps) {
  return (
    <div>
      {documents.map((document) => {
        const { name, downloadDocument, uploadDocument } = document;
        return (
          <div key={document.name}>
            <div className="d-flex align-items-center justify-content-between">
              <p className="w-50">{name}</p>
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  style={{ marginRight: "30px" }}
                  loading={isDocDownloading[document.name] || false}
                  variant="blue"
                  onClick={() => downloadDocument(name)}
                  iconOnly
                >
                  <DownloadIcon />
                </Button>
                <Button
                  variant="blue"
                  onClick={() => uploadDocument(name)}
                  iconOnly
                >
                  <UploadIcon />
                </Button>
              </div>
            </div>
            <hr />
          </div>
        );
      })}
    </div>
  );
}
