import { ChangeEvent, useEffect, useState } from "react";
import useQueryParams from "../../hooks/useQueryParams";
import useScreenName from "../../hooks/useScreenName";
import { RefundApprovalStatus } from "../../models";
import { useDispatch } from "react-redux";
import dispatch from "../../middleware";
import {
  approveBulkRefunds,
  approveRefunds,
  getRefunds,
} from "../../actions/refund.action";
import { RefundsDto } from "../../dto/refunds.dto";
import { useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import Table, { TableColumns } from "../../components/table";
import { isLoadingActive } from "../../utils";
import Button from "../../components/button";
import { Select } from "../../components/input-functions";
import {
  checkIsBulkApproval,
  getAllRefundsReferenceIdByStatus,
  getFilterOptions,
} from "./helper";
import Modal from "../../components/modal";
import { ReactComponent as Tick } from "../../images/green-tick.svg";
import { formatDate } from "../user-details/helpers";

interface RefundApproveButtonTextProps {
  numberOfSelectedItems: number;
}

interface ApproveRefundsReasonModalContentProps {
  reason: string;
  onReasonChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

type RefundsSearchQueryType = {
  pageNo: number;
  numberOfEntitiesPerPage: number;
  status: RefundApprovalStatus;
};

const DEFAULT_SEARCH_QUERY_STATE: RefundsSearchQueryType = {
  pageNo: 1,
  numberOfEntitiesPerPage: 10,
  status: RefundApprovalStatus.APPROVAL_PENDING,
};

const TABLE_COLUMNS_FOR_REFUND_APPROVAL: TableColumns[] = [
  { label: "Refund Id", field: "referenceId", style: { textAlign: "center" } },
  { label: "User Id", field: "userId", style: { textAlign: "center" } },
  { label: "Amount", field: "amount", style: { textAlign: "center" } },
  { label: "Date", field: "createdAt", style: { textAlign: "center" } },
  {
    label: "Client User Id",
    field: "clientUserId",
    style: { textAlign: "center" },
  },
];

function getTableDataForRefundApproval(data: RefundsDto[] | null) {
  if (!data) return [];
  return data.map(
    ({ _id, amount, clientUserId, createdAt, referenceId, userId }) => ({
      _id,
      referenceId,
      userId,
      amount,
      createdAt: formatDate(createdAt),
      clientUserId,
    })
  );
}

export default function RefundApproval() {
  useScreenName("Approve Refunds");
  const storeDispatch = useDispatch();
  const loadingQueue = useSelector(
    (state: ReduxState) => state.refunds.loadingQueue
  );
  const isLoading = isLoadingActive(loadingQueue);
  const refunds: RefundsDto[] | null = useSelector(
    (state: ReduxState) => state.refunds.refunds
  );

  const [selectedRefundItems, setSelectedRefundItems] = useState<string[]>([]);
  const [enteredReason, setEnteredReason] = useState("");
  const [isRefundsApproveReasonModalOpen, setIsRefundsApproveReasonModalOpen] =
    useState(false);
  const [
    isRefundsApprovedSuccessfullModalOpen,
    setIsRefundsApprovedSuccessfullModalOpen,
  ] = useState(false);
  const [errorModal, setErrorModal] = useState<{
    active: boolean;
    title: string;
    message: string;
  }>({
    active: false,
    title: "",
    message: "",
  });
  const [{ numberOfEntitiesPerPage, pageNo, status }, setSearchQuery] =
    useQueryParams<RefundsSearchQueryType>(DEFAULT_SEARCH_QUERY_STATE);

  const FILTERED_OPTIONS = getFilterOptions();

  async function fetchRefunds() {
    try {
      await dispatch(
        storeDispatch,
        getRefunds({
          pageNumber: pageNo,
          pageSize: numberOfEntitiesPerPage,
          status,
        })
      );
    } catch (error: any) {
      setErrorModal({
        title: "Refunds",
        message: error?.message ?? "Unable to load refunds!",
        active: true,
      });
    }
  }

  function getFormattedBulkApproveRefunds(): {
    referenceId: string;
    reason: string;
  }[] {
    return selectedRefundItems.map((referenceId) => {
      return { referenceId, reason: enteredReason };
    });
  }

  async function handleRefundsApproveSubmit() {
    const isBulkApproval = checkIsBulkApproval(selectedRefundItems.length);
    try {
      isBulkApproval
        ? await dispatch(
            storeDispatch,
            approveBulkRefunds({
              bulkApproveRefunds: getFormattedBulkApproveRefunds(),
            })
          )
        : await dispatch(
            storeDispatch,
            approveRefunds({
              referenceId: selectedRefundItems[0],
              reason: enteredReason,
            })
          );

      setSelectedRefundItems([]);
      setEnteredReason("");
      setIsRefundsApproveReasonModalOpen(false);
      setIsRefundsApprovedSuccessfullModalOpen(true);
    } catch (error: any) {
      setErrorModal({
        title: "Approve Refund",
        message: error?.message ?? "Unable to approve refund!",
        active: true,
      });
    }
  }

  function handleRemarkChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const { value } = e.target;
    setEnteredReason(value);
  }

  async function handleSelectAllRefunds() {
    if (!refunds) return;

    const referenceIds = getAllRefundsReferenceIdByStatus(refunds, status);
    setSelectedRefundItems(referenceIds);
  }

  useEffect(() => {
    fetchRefunds();
  }, [pageNo, numberOfEntitiesPerPage, status]);

  return (
    <div className="dashboard-container">
      <div className="d-flex flex-lg-row flex-column align-items-lg-end mb-4">
        <div className="search-input-user mr-lg-3 mb-4 mb-lg-0">
          <Select
            options={FILTERED_OPTIONS}
            value={status}
            onChange={(e) => {
              const value = e.currentTarget.value.trim();
              setSearchQuery((prev) => ({
                ...prev,
                pageNumber: 1,
                status: value as RefundApprovalStatus,
              }));
            }}
            placeholder="Select Refund Status"
          />
        </div>
        <div className="signin-buttons--container">
          <Button
            size="md"
            disabled={
              !selectedRefundItems.length ||
              status !== RefundApprovalStatus.APPROVAL_PENDING
            }
            onClick={() => setIsRefundsApproveReasonModalOpen(true)}
          >
            <RefundApproveButtonText
              numberOfSelectedItems={selectedRefundItems.length}
            />
          </Button>
          <Button
            size="md"
            disabled={
              !refunds?.length ||
              !!selectedRefundItems.length ||
              status !== RefundApprovalStatus.APPROVAL_PENDING
            }
            onClick={handleSelectAllRefunds}
          >
            Select All
          </Button>
        </div>
      </div>
      <Table
        keyField="referenceId"
        hasRowSelect
        loading={isLoading}
        columns={TABLE_COLUMNS_FOR_REFUND_APPROVAL}
        data={getTableDataForRefundApproval(refunds)}
        selectedItems={selectedRefundItems}
        onSelectChange={setSelectedRefundItems}
        isRowCheckable={(record: any) => record.referenceId !== ""}
      />
      <Modal
        title="Refunds Approve Reason"
        open={isRefundsApproveReasonModalOpen}
        onClose={() => setIsRefundsApproveReasonModalOpen(false)}
        submitButtonText="Approve Refunds"
        onSubmit={handleRefundsApproveSubmit}
        children={
          <ApproveRefundsReasonModalContent
            reason={enteredReason}
            onReasonChange={handleRemarkChange}
          />
        }
      />
      <Modal
        title="Refunds Approved Successfully"
        open={isRefundsApprovedSuccessfullModalOpen}
        onClose={() => {
          setIsRefundsApprovedSuccessfullModalOpen(false);
          fetchRefunds();
        }}
        hasSubmitButton={false}
        children={<RefundsApprovedSuccessfullModalContent />}
      />
      <Modal
        title={errorModal.title}
        open={errorModal.active}
        onClose={() =>
          setErrorModal((prevError) => ({ ...prevError, active: false }))
        }
        hasCancelButton={false}
        hasSubmitButton={false}
        children={<h4>{errorModal.message}</h4>}
      />
    </div>
  );
}

function RefundApproveButtonText({
  numberOfSelectedItems,
}: RefundApproveButtonTextProps) {
  return (
    <span>
      {numberOfSelectedItems > 0
        ? `Approve Refunds (${numberOfSelectedItems})`
        : "Approve Refunds"}
    </span>
  );
}

function ApproveRefundsReasonModalContent({
  reason,
  onReasonChange,
}: ApproveRefundsReasonModalContentProps) {
  return (
    <>
      <h4>Enter Remarks:</h4>
      <textarea
        name="remark"
        id="remark"
        value={reason}
        onChange={onReasonChange}
        style={{
          width: "100%",
          height: "100px",
          maxHeight: "250px",
          resize: "vertical",
          boxSizing: "border-box",
        }}
      />
    </>
  );
}

function RefundsApprovedSuccessfullModalContent() {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center mb-3">
        <Tick width={60} height={60} />
      </div>
      <h4>Refunds are approved successfully!</h4>
    </>
  );
}
