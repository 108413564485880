import { createStore, compose } from "redux";
import rootReducer from "../reducers";
import { Env, env } from "../config";

export default function configureStore(initialState: any) {
  return createStore(
    rootReducer,
    initialState,
    compose(
      env !== Env.PROD
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION__()
        : undefined
    )
  );
}
