import { useEffect } from "react";
import { FileType } from "../../models";
import "./style.css";

function renderDocViewer(docType: FileType | undefined, docUrl?: string) {
  if (docType === FileType.APP_JSON || docType === FileType.TEXT_JSON) {
    return (
      <div className="rf-doc-viewer__doc embedded-view">
        <iframe src={docUrl} frameBorder="0" />
      </div>
    );
  }
  if (docType === FileType.IMG_JPEG || docType === FileType.IMG_PNG) {
    return (
      <figure className="rf-doc-viewer__doc">
        <img src={docUrl} alt="document" />
      </figure>
    );
  }
  if (docType === FileType.APP_PDF) {
    return (
      <div className="rf-doc-viewer__doc embedded-view">
        <object type={docType} data={docUrl} width="100%" height="100%">
          <p className="rf-doc-viewer__fallback-text">
            Your browser cannot display the file.{" "}
            <a href={docUrl} download>
              Click here
            </a>{" "}
            to download it
          </p>
        </object>
      </div>
    );
  }
  return (
    <div className="rf-doc-viewer__doc">
      <p className="rf-doc-viewer__fallback-text">Preview not available</p>
    </div>
  );
}

interface DocumentViewerProps {
  active: boolean;
  docUrl?: string;
  docType?: FileType;
  onClose?: () => void;
}

export default function DocumentViewer({
  active = false,
  docUrl,
  docType,
  onClose,
}: DocumentViewerProps) {
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }
  function hanldeKeyPress(e: KeyboardEvent) {
    if (e.key === "Escape") {
      handleClose();
    }
  }
  useEffect(() => {
    window.document.addEventListener("keyup", hanldeKeyPress);
    return () => {
      window.document.removeEventListener("keyup", hanldeKeyPress);
    };
  }, []);
  return (
    <div
      className="rf-doc-viewer"
      style={active ? { display: "flex" } : undefined}
    >
      <span
        className="rf-doc-viewer__close"
        onClick={handleClose}
        role="button"
      >
        &times;
      </span>
      {renderDocViewer(docType, docUrl)}
    </div>
  );
}
