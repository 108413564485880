import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addLoanPayment,
  getRepaymentScheduleByLoanId,
} from "../../actions/loan.action";
import Badge from "../../components/badge";
import Button from "../../components/button";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../components/confirm-dialog";
import Table from "../../components/table";
import { RepaymentScheduleDetails } from "../../dto";
import dispatch from "../../middleware";
import { AddLoanPayment } from "../../models";
import { ReduxState } from "../../reducers";
import {
  clearFormData,
  dateInputToString,
  dateToDDMMYYYY,
  downloadCSV,
  getTodayDateForInputField,
  isLoadingActive,
} from "../../utils";
import {
  repaymentScheduleSortByEmiNo,
  validateAddPaymentFields,
} from "./helpers";
import { ReactComponent as DownloadIcon } from "../../images/download.svg";

interface LoanRepaymentScheduleProps {
  loanId: string;
  disbursed?: boolean;
  outstandingAmount?: number;
}

export default function LoanRepaymentSchedule({
  loanId,
  disbursed = false,
  outstandingAmount,
}: LoanRepaymentScheduleProps) {
  const storeDispatch = useDispatch();
  const repaymentSchedule: RepaymentScheduleDetails[] = useSelector(
    (state: ReduxState) => state.loan.singleLoanRepaymentSchedule
  );
  const loadingQueue = useSelector(
    (state: ReduxState) => state.loan.loadingQueue
  );
  const [isModalActive, setIsModalActive] = useState({
    acceptEMI: false,
  });
  // Will come from server-side
  // const totalLoanOutstandingAmount = repaymentSchedule.reduce((accum, {principal, interest}) => accum + (principal + interest), 0);
  useEffect(() => {
    dispatch(storeDispatch, getRepaymentScheduleByLoanId(loanId));
  }, [loanId]);
  async function handleConfirmDialog(type: ConfirmDialogType, fieldData: any) {
    validateAddPaymentFields("TERM_LOAN", fieldData, repaymentSchedule);
    const payload: AddLoanPayment = {
      loanId: loanId,
      amount: parseInt(fieldData.amount),
      transactionRefNo: fieldData.transactionRefNo,
      paidDate: dateInputToString(fieldData.paidDate),
      emiNo: parseInt(fieldData.emiNo),
      medium: "BANK", // TODO: Make it a select item
    };
    const response = await dispatch(
      storeDispatch,
      addLoanPayment(clearFormData(payload))
    );
    // Reload the data after EMI capture
    dispatch(storeDispatch, getRepaymentScheduleByLoanId(loanId));
    return {
      message: `Payment of Rs ${fieldData.amount} for Loan # ${loanId} added successfully with Transaction ID: ${response?._id}`,
    };
  }
  const columns = [
    { field: "emiNumber", label: "No" },
    { field: "emiDate", label: "Date" },
    { field: "principal", label: "Principal" },
    { field: "interest", label: "Interest" },
    { field: "totalAmount", label: "EMI" },
    ...(disbursed ? [{ field: "status", label: "Status" }] : []),
    // { field: "netOutstanding", label: "Net Outstanding" }, // Will come from server-side
  ];
  function getStatusBadge(isPaid?: boolean) {
    if (isPaid) return <Badge type="success">Repaid</Badge>;
    return <Badge type="pending">Due</Badge>;
  }
  function getTableData(repaymentSchedule: RepaymentScheduleDetails[]) {
    return repaymentSchedule.map(
      ({ emiNumber, emiDate, principal, interest, paid }) => ({
        emiNumber,
        emiDate: dateToDDMMYYYY(emiDate),
        principal,
        interest,
        totalAmount: (principal + interest).toFixed(2),
        status: paid ? "Repaid" : "Due",
      })
    );
  }
  return (
    <>
      <div className="mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="user-page-title mb-4">Repayment Schedule</h3>
          {disbursed && (
            <div>
              <Button
                onClick={() =>
                  setIsModalActive((prevState) => ({
                    ...prevState,
                    acceptEMI: true,
                  }))
                }
              >
                Add Payment
              </Button>
              <Button
                variant="info"
                title="Download"
                onClick={() => {
                  downloadCSV(
                    getTableData(
                      repaymentScheduleSortByEmiNo(repaymentSchedule)
                    ),
                    columns,
                    `${loanId}_Repayment_Schedule`
                  );
                }}
              >
                <DownloadIcon
                  height="25"
                  width="25"
                  color="var(--font-color)"
                />
              </Button>
            </div>
          )}
        </div>
      </div>
      <Table
        loading={isLoadingActive(loadingQueue)}
        columns={columns}
        data={getTableData(repaymentScheduleSortByEmiNo(repaymentSchedule))}
      />
      <ConfirmDialog
        active={isModalActive.acceptEMI}
        type="success"
        title="Add EMI Payment"
        message="Please fill the below details to add payment"
        onCancel={() =>
          setIsModalActive((prevState) => ({ ...prevState, acceptEMI: false }))
        }
        onSubmit={handleConfirmDialog}
        submitButtonText="Add"
        cancelButtonText="Cancel"
        inputFields={[
          {
            name: "emiNo",
            label: "EMI No",
            type: "number",
            placeholder: "5",
            required: true,
          },
          {
            name: "amount",
            label: "Amount",
            type: "number",
            placeholder: "amount",
            required: true,
          },
          {
            name: "transactionRefNo",
            label: "Transaction Ref No",
            type: "text",
            placeholder: "refno",
            required: true,
          },
          {
            name: "paidDate",
            label: "Paid On",
            type: "date",
            placeholder: "YYYYMMDD",
            value: getTodayDateForInputField(),
            required: true,
          },
        ]}
      />
    </>
  );
}
