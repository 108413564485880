import { useEffect } from "react";
import DatafieldSection from "../../components/datafield-section";
import {
  RetailODDetails,
  RetailOdLedgerInfoDto,
  TermLoanDetails,
  TermLoanLedgerInfoDto,
} from "../../dto";
import { FieldLabel, LoanStatusEnum, LoanType } from "../../models";
import { dateToDDMMYYYY, splitKeyWithSpace } from "../../utils";
import { refactorLoanData } from "./helpers";

interface LoanSummaryProps {
  loanId: string;
  onMount: () => void;
  loanDetails: TermLoanDetails | RetailODDetails | null;
  termLoanLedgerInfo: TermLoanLedgerInfoDto | null;
  retailOdLedgerInfo: RetailOdLedgerInfoDto | null;
}

export default function LoanSummary({
  loanId,
  onMount,
  loanDetails,
  termLoanLedgerInfo,
  retailOdLedgerInfo,
}: LoanSummaryProps) {
  const leftLabelsForLoanSummary: Record<
    Exclude<LoanType, "WORKING_CAPITAL_TERM_LOAN">,
    FieldLabel[]
  > = {
    TERM_LOAN: [
      {
        key: "loanType",
        label: "Loan Type :",
        formatMethod: splitKeyWithSpace,
      },
      { key: "approvedAmount", label: "Sanctioned Amount :", prefix: "Rs " },
      { key: "irpa", label: "Interest Rate :", suffix: "% PA" },
      { key: "pf", label: "Processing Fee :", prefix: "Rs " },
      { key: "pfPostGst", label: "Processing Fee With GST :", prefix: "Rs " },
      { key: "tenureInMonths", label: "Tenure (Months) :" },
    ],
    RETAIL_OD: [
      {
        key: "loanType",
        label: "Loan Type :",
        formatMethod: splitKeyWithSpace,
      },
      { key: "maxAmount", label: "Sanctioned Amount :", prefix: "Rs " },
      { key: "irpa", label: "Interest Rate :", suffix: "% PA" },
    ],
    COMMERCIAL_OD: [
      {
        key: "loanType",
        label: "Loan Type :",
        formatMethod: splitKeyWithSpace,
      },
      { key: "maxAmount", label: "Sanctioned Amount :", prefix: "Rs " },
      { key: "irpa", label: "Interest Rate :", suffix: "% PA" },
    ],
  };

  const rightLabelsForLoanSummary: Record<
    Exclude<LoanType, "WORKING_CAPITAL_TERM_LOAN">,
    FieldLabel[]
  > = {
    TERM_LOAN: [
      ...(loanDetails?.status === LoanStatusEnum.DISBURSAL_PENDING
        ? [
            {
              key: "createdAt",
              label: "Approved On :",
              formatMethod: dateToDDMMYYYY,
            },
          ]
        : [
            { key: "bankAccountNo", label: "Bank A/c Number" },
            { key: "ifscCode", label: "IFSC" },
            { key: "transactionRefNo", label: "UTR" },
            { key: "disbursedOn", label: "Disbursed On :" },
            { key: "currentOutstandingAmount", label: "Balance Amount" },
          ]),
    ],
    RETAIL_OD: [
      {
        key: "createdAt",
        label: "Sanctioned On :",
        formatMethod: dateToDDMMYYYY,
      },
      { key: "totalAmountWithdrawn", label: "Total Drawdown :", prefix: "Rs " },
      {
        key: "currentOutstandingAmount",
        label: "Total Outstanding :",
        prefix: "Rs ",
      },
    ],
    COMMERCIAL_OD: [
      {
        key: "createdAt",
        label: "Sanctioned On :",
        formatMethod: dateToDDMMYYYY,
      },
      { key: "totalAmountWithdrawn", label: "Total Drawdown :", prefix: "Rs " },
      {
        key: "currentOutstandingAmount",
        label: "Total Outstanding :",
        prefix: "Rs ",
      },
    ],
  };

  useEffect(() => {
    onMount();
  }, [loanId]);

  if (!loanDetails) return null;

  return (
    <div className="my-4">
      <DatafieldSection
        title="Loan Summary"
        labels={[
          {
            items:
              leftLabelsForLoanSummary[
                loanDetails.loanType as keyof typeof leftLabelsForLoanSummary
              ],
          },
          {
            items:
              rightLabelsForLoanSummary[
                loanDetails.loanType as keyof typeof rightLabelsForLoanSummary
              ],
          },
        ]}
        data={refactorLoanData(
          loanDetails,
          termLoanLedgerInfo,
          retailOdLedgerInfo
        )}
      />
    </div>
  );
}
