import { RefundAction } from "../actions/refund.action";
import { RefundsDto } from "../dto/refunds.dto";
import { addToLoadingQueue, removeFromLoadingQueue } from "../utils";

export interface RefundsState {
  loadingQueue: string[];
  refunds: RefundsDto[] | null;
}

const REFUNDS_DEFAULT_STATE: RefundsState = {
  loadingQueue: [],
  refunds: null,
};

export default function RefundsReducer(
  state: RefundsState = REFUNDS_DEFAULT_STATE,
  action: any
): RefundsState {
  switch (action.type) {
    case RefundAction.REQUEST_GET_REFUNDS:
      return {
        ...state,
        loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
      };
    case `${RefundAction.REQUEST_GET_REFUNDS}_SUCCESS`:
      return {
        ...state,
        refunds: action?.payload ?? null,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    case `${RefundAction.REQUEST_GET_REFUNDS}_FAILURE`:
      return {
        ...state,
        refunds: null,
        loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
      };
    default:
      return state;
  }
}
