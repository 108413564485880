import { useEffect, useState } from "react";
import Button from "../../../components/button";
import CustomAlert from "../../../components/custom-alert";
import {
  ExternalApiType,
  ExternallySourcedBankAccInfo,
  ExternallySourcedPanInfo,
} from "../../../models";
import { dateToDDMMYYYYHHMMAP } from "../../../utils";

interface VerifyUserDataFromExternalSourceProps {
  isEnabled: boolean;
  autoFetch?: boolean;
  externalApiType: ExternalApiType;
  onFetchClick: (
    type: ExternalApiType
  ) => Promise<ExternallySourcedPanInfo & ExternallySourcedBankAccInfo>;
  onSuccess?: (type: ExternalApiType) => void;
}

const EXTERNAL_API_TYPE_TO_PROPS_MAP: Record<
  ExternalApiType,
  {
    buttonText: string;
    errorMessage: string;
    nameKeyInResponse: "panHolderName" | "accountHolderName";
    entityValueKeyInResponse: "pan" | "accountNo";
    nameLabel: string;
  }
> = {
  [ExternalApiType.PAN_VERIFICATION]: {
    buttonText: "Verify PAN",
    errorMessage: "Failed to fetch PAN info",
    nameKeyInResponse: "panHolderName",
    entityValueKeyInResponse: "pan",
    nameLabel: "PAN holder name :"
  },
  [ExternalApiType.BANK_ACCOUNT_VERIFICATION]: {
    buttonText: "Verify Bank A/C",
    errorMessage: "Failed to fetch Bank A/C info",
    nameKeyInResponse: "accountHolderName",
    entityValueKeyInResponse: "accountNo",
    nameLabel: "Bank A/C holder name :"
  },
};

function renderMetadata(
  show: boolean,
  context: { nameLabel?: string; name?: string; fetchedAt?: string }
) {
  if (!show) return null;
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <span>
          <b>{context.nameLabel ?? "Name :"}</b>
        </span>
        <span>{context.name || "-"}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span>
          <b>Fetched at :</b>
        </span>
        <span>
          {context.fetchedAt ? dateToDDMMYYYYHHMMAP(context.fetchedAt) : "-"}
        </span>
      </div>
    </div>
  );
}

export default function VerifyUserDataFromExternalSource({
  isEnabled,
  autoFetch = false,
  externalApiType,
  onFetchClick,
  onSuccess,
}: VerifyUserDataFromExternalSourceProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<{
    type: "success" | "danger";
    body: string;
    holderName?: string;
    verifiedAt?: string;
  } | null>();

  useEffect(() => {
    if (autoFetch && isEnabled) fetchExternalDetails();
  }, [])

  const props = EXTERNAL_API_TYPE_TO_PROPS_MAP[externalApiType];

  async function fetchExternalDetails() {
    try {
      setIsLoading(true);
      const resp = await onFetchClick(externalApiType);
      const nameFromSource = resp[props.nameKeyInResponse];
      const entityFromSource = resp[props.entityValueKeyInResponse];

      if (!nameFromSource || !entityFromSource) throw new Error();
      const body =
        externalApiType === ExternalApiType.PAN_VERIFICATION
          ? `Sucessfully Verified data for PAN ${entityFromSource}`
          : externalApiType === ExternalApiType.BANK_ACCOUNT_VERIFICATION
          ? `Successfully Verified data for Bank A/c ${entityFromSource}`
          : "Successfully Verified";
      setMessage({
        type: "success",
        body,
        holderName: nameFromSource,
        verifiedAt: resp.verifiedAt,
      });
      onSuccess?.(externalApiType);
    } catch (error) {
      setMessage({ type: "danger", body: props.errorMessage });
    } finally {
      setIsLoading(false);
    }
  }

  if (!isEnabled) return null;

  if (message)
    return (
      <CustomAlert
        variant={message.type}
        footer={renderMetadata(message.type === "success", {
          nameLabel: props.nameLabel,
          name: message.holderName,
          fetchedAt: message.verifiedAt,
        })}
      >
        {message.body}
      </CustomAlert>
    );
  return (
    <Button loading={isLoading} onClick={fetchExternalDetails}>
      {props.buttonText}
    </Button>
  );
}
