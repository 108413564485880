import { ChangeEvent, FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import Alert from "../../../components/alert";
import { ReactComponent as TickIcon } from "../../../images/tick.svg";
import { HorizontalInputField } from "../../../components/input-functions";
import Button from "../../../components/button";
import dispatch from "../../../middleware";
import { updateCommercialOdApplication } from "../../../actions/application.action";
import { UpdateCommercialApplication } from "../../../models";

interface EditCommercialOdApplicationProps {
  applicationId: string;
  data?: UpdateCommercialApplication;
}

export default function EditCommercialOdApplication({
  data,
  applicationId,
}: EditCommercialOdApplicationProps) {
  const defaultFormData = () => ({
    pfPostGst: data?.pfPostGst ?? 0,
    sanctionedAmount: data?.sanctionedAmount ?? 0,
    drawdownRepaymentPeriodInDays: data?.drawdownRepaymentPeriodInDays ?? 0,
    interestRepaymentPeriodInDays: data?.interestRepaymentPeriodInDays ?? 0,
    irpa: data?.irpa ?? 0,
  });
  const [formData, setFormData] = useState<UpdateCommercialApplication>(
    defaultFormData()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const storeDispatch = useDispatch();

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: Number(e.target.value),
    }));
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      setIsLoading(true);
      setSuccessMessage("");
      setErrorMessage("");
      await dispatch(
        storeDispatch,
        updateCommercialOdApplication(applicationId, formData)
      );
      setSuccessMessage("Application updated");
    } catch (e) {
      setErrorMessage(e?.message);
    } finally {
      setIsLoading(false);
    }
  }
  if (successMessage) {
    return (
      <div className="d-flex flex-column align-items-center">
        <TickIcon color="#5BC97C" />
        <p style={{ marginTop: "20px", marginBottom: "0" }}>{successMessage}</p>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <Alert
        type="danger"
        message={errorMessage}
        canDismiss={true}
        onDismissClick={() => setErrorMessage("")}
      />
      <HorizontalInputField
        label="Sanctioned Amount:"
        name="sanctionedAmount"
        value={formData?.sanctionedAmount}
        onChange={handleChange}
      />
      <HorizontalInputField
        label="Drawdown Repayment Period In (Days):"
        name="drawdownRepaymentPeriodInDays"
        value={formData?.drawdownRepaymentPeriodInDays}
        onChange={handleChange}
      />
      <HorizontalInputField
        label="Interest Repayment Period (Days):"
        name="interestRepaymentPeriodInDays"
        value={formData?.interestRepaymentPeriodInDays}
        onChange={handleChange}
      />
      <HorizontalInputField
        label="IRPA:"
        name="irpa"
        value={formData?.irpa}
        onChange={handleChange}
      />
      <Button loading={isLoading} type="submit">
        Update
      </Button>
    </form>
  );
}
