import { getUserDoc, getUserDocList } from "../actions/user.action";
import { TableColumns } from "../components/table";
import {
  MONTH_FULL_NAME_LIST,
  MONTH_SHORT_NAME_LIST,
  USER_SELFIE_DOC_NAME,
} from "../constants";
import dispatch from "../middleware";
import {
  ArgsAsStringObjectTuple,
  ArgsAsStringOrObject,
  FileType,
  Range,
} from "../models";

export function isEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

export function clearFormData(formData: any) {
  const obj = { ...formData };
  for (const propName in obj) {
    if (typeof obj[propName] === "object" && obj[propName] !== null) {
      if (!Array.isArray(obj[propName])) {
        obj[propName] = clearFormData(obj[propName]);
      }
      if (Object.keys(obj[propName]).length === 0) {
        delete obj[propName];
        continue;
      }
    } else if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      Number.isNaN(obj[propName])
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

export function dateToDateString(dateString: string | number | Date) {
  const date = new Date(dateString);
  return date.toISOString();
}
export function dateInputToString(date?: string) {
  if (!date) return "";
  return date.split("-").join("");
}
export function dateInputToNumber(date?: string) {
  if (!date) return;
  const dateString = date.split("-").join("");
  return Number(dateString);
}
export function dateInputToUTC(date: string) {
  return new Date(date).getTime();
}
export function dateToInputDate(date: Date) {
  const separator = "-";
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();

  return (
    yyyy +
    separator +
    (mm < 10 ? "0" + mm : mm) +
    separator +
    (dd < 10 ? "0" + dd : dd)
  );
}
export function dateNumberToInputDate(dateNumber?: number | string) {
  if (!dateNumber) return "";
  const dateString = dateNumber?.toString();
  if (dateString.length < 8) return "";
  return `${dateString.slice(0, 4)}-${dateString.slice(
    4,
    6
  )}-${dateString.slice(6, 8)}`;
}

export function getTodayDateForInputField() {
  const today = new Date();
  return dateToInputDate(today);
}

export function dateToDDMMYYYY(dateString: string | Date, separator = "-") {
  const date = new Date(dateString);
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();

  return (
    (d < 10 ? "0" + d : d) + separator + (m < 10 ? "0" + m : m) + separator + y
  );
}

export function dateToYYYYMMDD(dateString: string | Date, separator = "-") {
  const date = new Date(dateString);
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();

  return (
    y + separator + (m < 10 ? "0" + m : m) + separator + (d < 10 ? "0" + d : d)
  );
}

export const dateToDDMMYYYYHHMMAP = (
  dateString: string | Date,
  separator = "-"
) => {
  const date = new Date(dateString);
  const d = date.getDate();
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  let h = date.getHours();
  let min: number | string = date.getMinutes();

  const ampm = h >= 12 ? "PM" : "AM";
  h = h % 12;
  h = h ? h : 12; // the hour '0' should be '12'
  min = min < 10 ? "0" + min : min;

  return (
    (d < 10 ? "0" + d : d) +
    separator +
    (m < 10 ? "0" + m : m) +
    separator +
    y +
    " " +
    h +
    ":" +
    min +
    " " +
    ampm
  );
};

export function getCurrentMonthName(type: "short" | "long" = "short"): string {
  const monthIndex = new Date().getMonth();
  if (type === "short") return MONTH_SHORT_NAME_LIST[monthIndex];
  return MONTH_FULL_NAME_LIST[monthIndex];
}

export function utcTimestampToDate(timestamp: number) {
  const length = Math.ceil(Math.log10(timestamp + 1));
  const isValidUTC = length === 13;
  if (!isValidUTC) return;
  const date = new Date(timestamp);
  return dateToDDMMYYYY(date);
}
export function offsetDateByDays(days: number, dateObj?: Date) {
  const date = dateObj ?? new Date();
  date.setDate(date.getDate() + days);
  return date;
}
export function convertLocalDateToUtcWithoutTimeOffset(date: Date): Date {
  const utcDate = new Date();
  utcDate.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
  utcDate.setUTCHours(
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  );

  return utcDate;
}

export function constructErrorMessage(errorResponse?: {
  errorCode?: string;
  message: string;
}) {
  if (!errorResponse) return "Something went wrong, Try again later";
  return errorResponse.message;
}

// Ref: https://stackoverflow.com/a/42761393
export function paginate<T extends object>(
  items: T[],
  pageSize: number,
  pageNumber: number
): T[] {
  return items.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}

export function generateSortComparator<T extends object>(
  property: keyof T,
  sortBy: "ascending" | "descending",
  type: "number" | "string" | "date" = "number"
) {
  const sortOrder = sortBy === "ascending" ? 1 : -1;
  return function (a: T, b: T) {
    const valA = a[property];
    const valB = b[property];

    let result = 0;
    if (type === "number") {
      result = valA < valB ? -1 : valA > valB ? 1 : 0;
    } else if (type === "string") {
      result = String(valA).localeCompare(String(valB));
    } else if (type === "date") {
      const dateTimeA = new Date(String(valA)).getTime();
      const dateTimwB = new Date(String(valB)).getTime();

      result = dateTimeA < dateTimwB ? -1 : dateTimeA > dateTimwB ? 1 : 0;
    }
    return result * sortOrder;
  };
}

export function isIphoneDevice() {
  return navigator.userAgent.indexOf("iPhone") !== -1;
}

export async function getMimeTypeOfUploadedFile(file: File, isIphone: boolean) {
  try {
    if (isIphone) return file.type;
    const blob = file.slice(0, 4);
    let arrayBuffer = await blob.arrayBuffer();
    const uint = new Uint8Array(arrayBuffer);
    let bytes: string[] = [];
    uint.forEach((byte) => {
      bytes.push(byte.toString(16));
    });
    let hex = bytes.join("").toUpperCase();
    return getMimeType(hex);
  } catch (e) {
    return getMimeType("Unknown");
  }
}

export function getMimeType(signature: string): FileType {
  switch (signature) {
    case "89504E47":
      return FileType.IMG_PNG;
    case "47494638":
      return FileType.IMG_GIF;
    case "25504446":
      return FileType.APP_PDF;
    case "FFD8FFDB":
    case "FFD8FFE0":
    case "FFD8FFE1":
      return FileType.IMG_JPEG;
    case "504B0304":
      return FileType.ZIP;
    default:
      return FileType.UNKNOWN;
  }
}

export function capitalize(val?: string) {
  if (typeof val !== "string") return "";
  const normalizeValue = val.toLowerCase();
  return normalizeValue.charAt(0).toUpperCase() + normalizeValue.slice(1);
}

export function splitKeyWithSpace(val?: string, separator: string = "_") {
  if (typeof val !== "string") return "";
  return val.split(separator).join(" ");
}

export function getProp(object: Record<any, any> | undefined, path: string) {
  if (!object || typeof path !== "string") return object;
  const pathKey = path.split(".").filter((key) => key.length);
  return pathKey.reduce((dive, key) => dive && dive[key], object);
}

function arrayOfObjectToCSV(
  objArray: Record<string, any>[],
  customKeys?: TableColumns[]
) {
  if (!objArray.length) return;
  let baseStr: string = "";
  if (customKeys) {
    const customKeysMap: Record<string, string> = customKeys.reduce(
      (accum, curr) => ({ ...accum, [curr.field]: curr.label }),
      {}
    );
    baseStr =
      `${Object.keys(objArray[0])
        .map((value) => `"${customKeysMap[value]}"`)
        .join(",")}` + "\r\n";
  } else {
    baseStr =
      `${Object.keys(objArray[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
  }

  return objArray.reduce(
    (accu, curr) =>
      accu +
      `${Object.values(curr)
        .map((value) => `"${value}"`)
        .join(",")}` +
      "\r\n",
    baseStr
  );
}

export function downloadCSV(
  array: Record<string, any>[],
  customKeys?: TableColumns[],
  fileName?: string
) {
  let csv = arrayOfObjectToCSV(array, customKeys);
  if (!csv) return;

  const link = document.createElement("a");
  const filename = fileName ? `${fileName}.csv` : "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

export function getDataUrlFromDocTypeAndBody(docType: string, body: string) {
  if (docType === FileType.APP_JSON) {
    // INFO: This is a hack to support json content preview & download
    return `data:${FileType.TEXT_JSON};charset=utf-8,${encodeURIComponent(
      body
    )}`;
  }
  return `data:${docType};base64,${body}`;
}

export function downloadFileFromBase64(context: {
  base64: string;
  fileName: string;
  fileType: string;
}) {
  const { base64, fileName, fileType } = context;
  const dataUri = getDataUrlFromDocTypeAndBody(fileType, base64);
  let aTag = document.createElement("a");
  aTag.setAttribute("href", dataUri);
  aTag.setAttribute("download", fileName);
  aTag.click();
  aTag.remove();
}

export function stringToNumber(val?: string | number) {
  if (!val) return;
  if (val === "") return;
  return Number(val);
}

export function rangeToString<T = number>(
  range: Range<T> | undefined,
  {
    placeholder = "-",
    prefix,
    suffix,
    separatorText = "to",
  }: {
    placeholder?: string;
    prefix?: string | number;
    suffix?: string | number;
    separatorText?: string;
  } = {}
) {
  if (!range) return placeholder;
  let minValue = String(range.min);
  let maxValue = String(range.max);
  if (prefix) {
    minValue = `${prefix} ${minValue}`;
    maxValue = `${prefix} ${maxValue}`;
  }
  if (suffix) {
    minValue = `${minValue} ${suffix}`;
    maxValue = `${maxValue} ${suffix}`;
  }
  return `${minValue} ${separatorText} ${maxValue}`;
}
export function isEqualObject(
  obj1: Record<string, any>,
  obj2: Record<string, any>
): boolean {
  for (const key in obj1) {
    if (!obj1.hasOwnProperty(key) || !obj2.hasOwnProperty(key)) return false;
    if (typeof obj1[key] === "object" && !isEqualObject(obj1[key], obj2[key]))
      return false;
    if (obj1[key] !== obj2[key]) return false;
  }

  for (const key in obj2) {
    if (!obj1.hasOwnProperty(key)) return false;
  }
  return true;
}

export function isValueNumeric(value: unknown): value is number {
  return !Number.isNaN(Number(value));
}

export function getPaginationCount(
  count: number | null | undefined,
  fallback?: number
): number {
  if (count !== null && count !== undefined) return count;
  if (fallback !== undefined) return fallback;
  return 0;
}

export function isEmptyString(s: string) {
  return s.trim() === "";
}

export async function getUserSelfieDataUrl(
  storeDispatch: any,
  userId?: string
) {
  try {
    if (!userId) return;
    const documentsList = await dispatch(storeDispatch, getUserDocList(userId));
    if (!Array.isArray(documentsList)) return;
    if (!documentsList.includes("SELFIE")) return;
    const resp: { body: string; type: string } = await dispatch(
      storeDispatch,
      getUserDoc(userId, USER_SELFIE_DOC_NAME)
    );
    return getDataUrlFromDocTypeAndBody(resp.type, resp.body);
  } catch (error) {
    // TODO: Log error using sentry
  }
}

export function hasAnyFileTypesUnAcceptable(
  fileTypes: string[],
  accepts: string[]
): boolean {
  if (accepts.includes(FileType.ANY)) {
    return false;
  }
  if (accepts.includes(FileType.UNKNOWN)) {
    const acceptsKnownTypes = accepts.filter(
      (type) => type != FileType.UNKNOWN
    );
    if (acceptsKnownTypes.length == 0) return false;
    return fileTypes.some((type) => !acceptsKnownTypes.includes(type));
  }
  return fileTypes.some((type) => !accepts.includes(type));
}

export function isEmptyObject(object: object): boolean {
  return Object.keys(object).length === 0;
}

export function isEmpty(value: string | object) {
  if (typeof value == "undefined") {
    return true;
  } else if (Array.isArray(value)) {
    return value.length < 1;
  } else if (typeof value == "object") {
    return isEmptyObject(value);
  } else if (typeof value == "string") {
    return isEmptyString(value);
  }
  return false;
}

export function formatBytes(bytes: number) {
  const units = ["bytes", "Kb", "Mb"];
  let parsedBytes = bytes;
  let count = 0;
  while (parsedBytes > 1024) {
    parsedBytes /= 1024;
    ++count;
  }
  return `${parsedBytes.toFixed(parsedBytes < 10 && count > 0 ? 1 : 0)} ${
    units[count]
  }`;
}

export function getBase64StringFromFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const b64Response = window.btoa(event.target?.result as string);
      resolve(b64Response);
    };
    reader.onerror = () => reject("Failed to convert file type to string type");
    reader.readAsBinaryString(file);
  });
}

export function debounce<F extends (...args: Parameters<F>) => ReturnType<F>>(
  func: F,
  ms: number = 300
) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: Parameters<F>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), ms);
  };
}

export function isRetryExceeded(
  retryCount: number,
  maxRetries?: number
): boolean {
  if (maxRetries === undefined || maxRetries === null) return false;
  if (maxRetries < 0) return false;
  return retryCount >= maxRetries;
}

export function constructClassName(
  ...args: ArgsAsStringOrObject | ArgsAsStringObjectTuple
): string {
  const [arg1, arg2] = args;

  if (arg2) {
    if (typeof arg1 !== "string") {
      throw new Error("First argument is not a String");
    }
    return [
      arg1,
      ...Object.keys(arg2).filter((className) => arg2[className]),
    ].join(" ");
  }
  if (typeof arg1 === "string") return arg1;

  return Object.keys(arg1)
    .filter((className) => arg1[className])
    .join(" ");
}

export function addToLoadingQueue(
  prevLoadingQueue: string[],
  actionName?: string
) {
  if (!actionName) return prevLoadingQueue;
  const tempQueue = [...prevLoadingQueue];
  tempQueue.push(actionName);
  return tempQueue;
}

function hasCommonElement(master: string[], sub: string[]) {
  let obj: Record<string, number> = {};
  master.forEach((el, index) => {
    obj[el] = index;
  });
  const check = sub.some((el) => obj[el] !== undefined);
  return check;
}

export function isLoadingActive(loadingQueue: string[], actionList?: string[]) {
  if (Array.isArray(actionList))
    return hasCommonElement(loadingQueue, actionList);
  return loadingQueue.length > 0;
}

export function removeFromLoadingQueue(
  prevLoadingQueue: string[],
  actionName?: string
) {
  if (!actionName) return prevLoadingQueue;
  if (actionName.endsWith("SUCCESS") || actionName.endsWith("FAILURE")) {
    const tempActionNameArr = actionName.split("_");
    tempActionNameArr.pop();
    actionName = tempActionNameArr.join("_");
  }
  const index = prevLoadingQueue.findIndex((val) => val === actionName);
  if (index < 0) return prevLoadingQueue;
  const tempQueue = [...prevLoadingQueue];
  tempQueue.splice(index, 1);
  return tempQueue;
}
