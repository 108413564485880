import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";
import { ReactComponent as LoaderIcon } from "../../images/loader.svg"
import "./buttonStyles.css";

type ButtonVariant =
  | "primary"
  | "success"
  | "danger"
  | "danger-fill"
  | "info"
  | "light-blue"
  | "blue-outline"
  | "danger-outline"
  | "success-outline"
  | "danger-text"
  | "blue-text"
  | "text"
  | "blue";
type ButtonSize = "xs" | "sm" | "md" | "lg";

interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    loading?: boolean;
    variant?: ButtonVariant;
    wide?: boolean
    size?: ButtonSize;
    iconOnly?: boolean;
    children?: ReactNode;
}

export default function Button({ loading = false, variant = "primary", size = "sm", wide = false, iconOnly = false, children, disabled, ...props }: ButtonProps) {
    return (
        <button disabled={loading || disabled} {...props} className={`btn rf-btn rf-btn-${variant} rf-btn-${size}${iconOnly ? " rf-btn-icon-only" : ""} ${wide ? "btn-block" : ""}`}>
            {loading && <LoaderIcon width="20" height="20" style={{ marginRight: "10px" }} />}{children}
        </button>
    )
}
