import {
  RetailODProduct,
  TermLoanProduct,
  UserDetails,
  UserDocType,
} from "../../dto";
import { EnterpriseStatus } from "../../dto/enterprise.dto";
import {
  BaseApplication,
  BaseProduct,
  UpdateUser,
  UserAddress,
  UserBankInfo,
  UserDocCategory,
  userDocKeyAndTypeMap,
  UserDocumentKey,
  UserStatus,
  UserWorkAddress,
  UserWorkInfo,
} from "../../models";
import { capitalize, splitKeyWithSpace } from "../../utils";

export const isVerified = (status?: UserStatus | EnterpriseStatus) =>
  status === "VERIFIED";
export const isRejected = (status?: UserStatus | EnterpriseStatus) =>
  status === "REJECTED";

export function dobToDateObj(dob: string) {
  const dateYr = parseInt(dob.slice(4));
  const dateMon = parseInt(dob.slice(4, 6));
  const dateDay = parseInt(dob.slice(6, 8));
  return new Date(dateYr, dateMon, dateDay);
}

export function renderDob(dob?: number) {
  if (!dob) return "-";
  const dobString = dob.toString();
  return `${dobString.slice(6, 8)}-${dobString.slice(4, 6)}-${dobString.slice(
    0,
    4
  )}`;
}

export function constructAddress(address: UserAddress | undefined) {
  if (!address?.data) return;
  const { houseInfo, localityInfo, city, state, pinCode } = address.data;
  if (!houseInfo && !localityInfo && !city && !state && !pinCode) return;
  return `${houseInfo}, ${localityInfo}\n${city}, ${state} - ${pinCode}`;
}

export function getDefaultBankAccount(bankInfo?: UserBankInfo[]) {
  if (!bankInfo || bankInfo.length < 1) return;
  const index = bankInfo.findIndex((bank) => bank.data.primary);
  if (index < 0) return;
  return bankInfo[index];
}

export function getKycDocNameAndValue(
  key: "identityProofType" | "addressProofType",
  userData: UserDetails
) {
  const docKey = userData[key];
  let docValue: string | number | undefined;

  switch (docKey) {
    case UserDocumentKey.PAN: {
      docValue = userData[docKey]?.data.pan;
      break;
    }
    case UserDocumentKey.AADHAAR_CARD: {
      docValue = userData[docKey]?.data.aadhaar;
      break;
    }
    case UserDocumentKey.DRIVING_LICENSE: {
      docValue = userData[docKey]?.data.drivingLicenseNumber;
      break;
    }
    case UserDocumentKey.VOTER_ID: {
      docValue = userData[docKey]?.data.voterIdNumber;
      break;
    }
    case UserDocumentKey.PASSPORT: {
      docValue = userData[docKey]?.data.passportNumber;
      break;
    }
  }

  if (!docKey || !docValue) return;
  const docName = splitKeyWithSpace(userDocKeyAndTypeMap[docKey]);
  return `${docValue} (${docName})`;
}

export function renderDataFieldsValue(key: string, data: UserDetails) {
  switch (key) {
    case "name":
      return data.name.data || "-";
    case "_id":
      return data._id || "NA";
    case "phone":
      return data.phone?.data.countryCode && data.phone?.data.number
        ? `${data.phone?.data.countryCode}-${data.phone?.data.number}`
        : "-";
    case "email":
      return data.email?.data || "-";
    case "gender":
      return capitalize(data.gender) || "-";
    case "dob":
      return (data.dob?.data && renderDob(data.dob?.data)) || "-";
    case "pan":
      return data?.pan?.data?.pan || "-";
    case "address":
      return constructAddress(data.address) || "-";
    case "accountNumber":
      return (
        getDefaultBankAccount(data.bankAccountInfo)?.data.accountNumber || "-"
      );
    case "ifsc":
      return getDefaultBankAccount(data.bankAccountInfo)?.data.ifsc || "-";
    case "identityProofType":
      return getKycDocNameAndValue(key, data) || "-";
    case "addressProofType":
      return getKycDocNameAndValue(key, data) || "-";
  }
}

export function getProductNames(
  products: TermLoanProduct[] | RetailODProduct[]
) {
  const productNames = products.map(
    (product: TermLoanProduct | RetailODProduct) => product.productName
  );
  // Due to different version of a single product there might be multiple product with same name
  // thus use Set to get unique one's.
  return Array.from(new Set(productNames));
}

export function getProductVersions(
  products: BaseProduct[],
  productName?: string
) {
  if (!productName) return [];
  return products
    .filter((product) => product.productName === productName)
    .map((product) => product.productVersion);
}

export function getProductByNameAndVersion(
  products: BaseProduct[] | undefined,
  productName: string,
  productVersion: string | number
) {
  if (!Array.isArray(products)) return null;
  if (!productName) return null;
  if (!productVersion) return null;
  const filteredProduct = products.filter(
    (product) =>
      product.productName === productName &&
      product.productVersion == productVersion
  );
  return filteredProduct.length > 0
    ? (filteredProduct[0] as TermLoanProduct | RetailODProduct)
    : null;
}

export function getProductTypeFromApplication(
  application?: BaseApplication | null
) {
  if (application?.applicationType === "TERM_LOAN_APP")
    return "termLoanProducts";
  if (application?.applicationType === "RETAIL_OD_APP")
    return "retailOdProducts";
}

export function getProductById(
  products: BaseProduct[] | undefined,
  id?: string
) {
  if (!Array.isArray(products) || !id) return null;
  const filteredProduct = products.filter((product) => product._id === id);
  return filteredProduct.length > 0
    ? (filteredProduct[0] as TermLoanProduct | RetailODProduct)
    : null;
}

export function isVerifyEnabled(user?: UserDetails) {
  if (!user) return false;
  const defaultBankInfo = getDefaultBankAccount(user.bankAccountInfo);
  // TODO: Check for KYC details also
  return Boolean(
    user.name?.data &&
      user.gender &&
      user.dob?.data &&
      (user.email?.data || user.phone?.data?.number) &&
      user.address?.data?.houseInfo &&
      user.address.data.localityInfo &&
      user.address.data.city &&
      user.address.data.state &&
      user.address.data.pinCode &&
      defaultBankInfo?.data?.accountNumber &&
      defaultBankInfo.data.ifsc
  );
}

export function getUserDocTypeLabels(docTypes?: UserDocType[]) {
  const defaultObj = {
    [UserDocCategory.IDENTITY_PROOF]: [],
    [UserDocCategory.ADDRESS_PROOF]: [],
  };
  if (!docTypes) return defaultObj;

  return docTypes.reduce<
    Record<UserDocCategory, { value: string; label: string }[]>
  >((accu, curr) => {
    const { docType, docCategories = [], docNumberKey } = curr;

    for (const eachDocCategory of docCategories) {
      const selectedDocCategoryArr = accu[eachDocCategory];
      if (
        !docNumberKey ||
        selectedDocCategoryArr.findIndex((val) => val.value === docNumberKey) >
          -1
      )
        continue;
      selectedDocCategoryArr.push({
        label: splitKeyWithSpace(docType),
        value: docNumberKey,
      });
    }

    return accu;
  }, defaultObj);
}

export function clearKycData(
  data: UpdateUser,
  selectedDoc: Record<"id" | "address", UserDocumentKey | "">
): UpdateUser {
  const userDocKeyArr = Object.values(UserDocumentKey);
  const dataObj = { ...data };
  // Only keep the data of selected fields, clear other fields (if any cache is there)
  for (const key of userDocKeyArr) {
    if (key !== selectedDoc.id && key !== selectedDoc.address) {
      delete dataObj[key];
    }
  }
  // Assign the selected dockey for id & address proof
  // Assign null when unselcted from UI
  dataObj.identityProofType = selectedDoc.id || null;
  dataObj.addressProofType = selectedDoc.address || null;
  return dataObj;
}

export function getWorkAddress(address?: UserWorkAddress) {
  return {
    addressLine: address?.addressLine || "",
    pinCode: address?.pinCode || "",
    city: address?.city || "",
    state: address?.state || "",
  };
}
export function getLatestWorkInfo(workInfo?: UserWorkInfo[] | UserWorkInfo) {
  // TODO: Fix this when Schema become consistent
  if (Array.isArray(workInfo)) {
    const latestWorkInfos = workInfo.filter(
      (eachWorkInfo) => eachWorkInfo.data.isLatest
    );
    if (latestWorkInfos.length > 0) return latestWorkInfos[0];
    return;
  }
  return workInfo;
}
export function isUserAnEnterprise(entityId: string) {
  return entityId.startsWith("EP");
}

export function formatDate(isoDate: string): string {
  const date = new Date(isoDate);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getUTCDate();
  const month = monthNames[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  const dayFormatted = day < 10 ? "0" + day : day.toString();

  const formattedDate = `${dayFormatted} ${month} ${year}`;

  return formattedDate;
}
