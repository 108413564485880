import {
  ChangeEvent,
  ChangeEventHandler,
  FormEvent,
  InputHTMLAttributes,
  SelectHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";import { Text } from "../../models";
import Button from "../button";
import { ReactComponent as SearchIcon } from "../../images/search_white.svg";
import "./inputStyles.css";

type InputHTMLType = InputHTMLAttributes<HTMLInputElement>;
type InputOnchangeType = ChangeEventHandler<HTMLInputElement> | undefined;
type SelectOnchangeType = ChangeEventHandler<HTMLSelectElement> | undefined;

export function TextField(props: Text) {
  return (
    <div className="form-label-group my-2">
      <label htmlFor={props.fieldName} style={{ color: "#495057" }}>
        {props.label}
      </label>
      <input
        type={props.type}
        id={props.fieldName}
        className={`form-control ${
          props.error && props.error.trim() !== "" && "error-border"
        }`}
        placeholder={props.placeholder ? props.placeholder : ""}
        onChange={(e) =>
          props.onChange(props.fieldName, e.target.value, props.valueType)
        }
        value={props.value}
        disabled={props.disabled ? props.disabled : false}
      />
      {props.error && props.error.trim() !== "" && (
        <div className={"error-msg mt-1"}>{props.error}</div>
      )}
    </div>
  );
}

interface InputFieldProps extends InputHTMLType {
  label?: string;
  fieldName?: string;
  bgColor?: "white";
  requiredText?: string;
}

export function InputField({ label, fieldName, bgColor = "white", requiredText = "*", type = "text", required, ...props }: InputFieldProps) {
  return (
    <div className={`form-signin-label-group form-group-${bgColor}-bg my-3`}>
      <input {...props} id={fieldName} className="form-control input-login" required={required} type={type} />
      {label && (
        <label htmlFor={fieldName}>
          {label}
          {required && <span className="rf-input-star">{requiredText}</span>}
        </label>
      )}
    </div>
  );
}

export interface SelectOption {
  value: string | number;
  label: string | number;
  selected?: boolean;
}
interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  placeholder?: string;
  options?: SelectOption[]
}

export function Select({ placeholder, options = [], className, ...props }: SelectProps) {
  return (
    <select className={`rf-select form-control ${className}`} {...props}>
      {placeholder && <option value="">{placeholder}</option>}
      {options.map((option, index) => (
        <option key={index} value={option.value} selected={option.selected}>{option.label}</option>
      ))}
    </select>
  )
}

export function HorizontalSelect({
  label,
  name,
  ...props
}: SelectProps & { label?: string }) {
  return (
    <div className="form-group align-items-center row">
      {label && (
        <label htmlFor={name} className="col-sm-3 col-form-label">
          {label}
        </label>
      )}
      <div className="col-sm-9">
        <Select {...props} name={name} />
      </div>
    </div>
  );
}

interface RangeProps extends InputHTMLType {
  label?: string;
}

export function Range({ label, min, max, value, required, ...props }: RangeProps) {
  return (
    <div className="my-3">
      <div className="d-flex flex-row align-items-center justify-content-between mb-2">
        <span className="rf-label">{label}{required && <span className="rf-input-star">*</span>}</span>
        <input {...props} min={min} max={max} type="number" className="rf-range-input" value={value} required={required} />
      </div>
      <input {...props} type="range" min={min} max={max} className="rf-range" value={value || min} required={required} />
      <div className="d-flex flex-row align-items-center justify-content-between">
        <span className="rf-range-val">{min}</span>
        <span className="rf-range-val">{max}</span>
      </div>
    </div>
  )
}

interface RangeInputProps {
  name: string;
  label?: string;
  value?: {
    min: string | number,
    max: string | number
  };
  min?: string | number;
  max?: string | number;
  step?: string | number;
  required?: boolean;
  onChange?: (fieldName: string, rangeType: "min" | "max", value: string | number) => void
}

export function RangeInput({ name, label, value, onChange, required, ...props }: RangeInputProps) {
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (typeof onChange === "function") {
      onChange(name, (e.target.name as "min" | "max"), e.target.value);
    }
  }
  return (
    <div className="row align-items-center">
      <span className="col-sm-4 rf-label">{label}{required && <span className="rf-input-star">*</span>}</span>
      <div className="col">
        <InputField type="number" label="Minimum" {...props} placeholder="Minimum value" name="min" value={value?.min} onChange={handleChange} required={required} />
      </div>
      <div className="col">
        <InputField type="number" label="Maximum" {...props} placeholder="Maximum value" name="max"  value={value?.max} onChange={handleChange} required={required} />
      </div>
    </div>
  )
}

interface SearchBoxProps {
  value?: string;
  label?: string;
  placeholder?: string;
  onSubmit?: (searchText: string) => void;
}

export function SearchBox({ value = "", label, placeholder, onSubmit }: SearchBoxProps) {
  const [searchText, setSearchText] = useState(value);
  const searchInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setSearchText(prevState => {
      if (prevState === value) return prevState;
      return value;
    })
  }, [value])
  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (typeof onSubmit === "function") {
      onSubmit(searchText.trim());
    }
  }
  function handleClear() {
    setSearchText("");
    searchInputRef.current?.focus();
    if (typeof onSubmit === "function") {
      onSubmit("");
    }
  }
  return (
    <form  className="mb-3 mb-lg-0" onSubmit={handleSubmit}>
      {label && <div className="search-box__label">{label}</div>}
      <div className="d-flex">
        <div className="search-input-user mr-3">
          <div className="position-relative">
            <input
              ref={searchInputRef}
              type="search"
              className="form-control rf-search"
              placeholder={placeholder}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
            {searchText && <button type="button" className="btn bg-transparent rf-input-clear-btn search-close-btn" onClick={handleClear}>
              <i className="fa fa-times"></i>
            </button>}
          </div>
        </div>
        <Button type="submit">
          <SearchIcon />
        </Button>
      </div>
    </form>
  )
}

interface HorizontalInputField extends InputHTMLType {
  label?: string;
}

export function HorizontalInputField({
  label,
  name,
  ...props
}: HorizontalInputField) {
  return (
    <div className="form-group align-items-center row">
      {label && (
        <label htmlFor={name} className="col-sm-3 col-form-label">
          {label}
        </label>
      )}
      <div className="col-sm-9">
        <input
          type="text"
          className="form-control"
          {...props}
          name={name}
        />
      </div>
    </div>
  );
}

export type GroupInputFieldsType = Array<(InputHTMLType | SelectProps) & { colWidth?: string, element?: "input" | "select" }>;
interface GroupInputField {
  className?: string;
  onChange?: InputOnchangeType;
  fields?: GroupInputFieldsType;
}

export function GroupInputField({
  className,
  onChange,
  fields = [],
}: GroupInputField) {
  return (
    <div className={`form-row ${className ? className : ""}`}>
      {fields.map(
        (
          {
            colWidth,
            element,
            onChange: individualOnChange,
            ...inputProps
          },
          index
        ) => (
          <div className={`col-sm${colWidth ? `-${colWidth}` : ""} mb-1 mb-sm-0`} key={index}>
            {element === "select" ? (
              <Select
                onChange={
                  (individualOnChange
                    ? individualOnChange
                    : onChange) as SelectOnchangeType
                }
                {...(inputProps as SelectProps)}
              />
            ) : element === "input" ? (
              <input
                type="text"
                className="form-control"
                onChange={
                  (individualOnChange
                    ? individualOnChange
                    : onChange) as InputOnchangeType
                }
                {...(inputProps as InputHTMLType)}
              />
            ) : <></>}
          </div>
        )
      )}
    </div>
  );
}