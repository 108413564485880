import { GenerateReport } from "../dto/report.dto";
import { MiddlewareAction } from "../middleware";

export enum Actions {
  REQUEST_GET_REPORT_LIST = "REQUEST_GET_REPORT_LIST",
  REQUEST_GENERATE_REPORT = "REQUEST_GENERATE_REPORT",
  REQUEST_GET_REPORT_DOC_BY_ID = "REQUEST_GET_REPORT_DOC_BY_ID",
  REQUEST_DELETE_REPORT_BY_ID = "REQUEST_DELETE_REPORT_BY_ID",
}

export function getReportList(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_REPORT_LIST,
    type: "GET",
    url: "/report/get-reports-list",
  };
}

export function generateReport(payload: GenerateReport): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GENERATE_REPORT,
    type: "POST",
    url: "/report",
    body: payload,
  };
}

export function getReportDocById(reportId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_REPORT_DOC_BY_ID,
    type: "GET",
    url: "/report",
    queryParams: { reportId },
  };
}

export function deleteReportById(reportId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_DELETE_REPORT_BY_ID,
    type: "DELETE",
    url: "/report",
    queryParams: { reportId },
    metadata: { reportId },
  };
}
