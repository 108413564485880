import { DatafieldLabel } from "../../../../components/datafield-section";
import { CreditReportEmailInfo, CreditReportPhoneInfo } from "../../../../models";
import { dateToDDMMYYYY } from "../../../../utils";

export const PERSONAL_INFO_LABELS: DatafieldLabel[] = [
  {
    title: "Personal Informations",
    items: [
      {
        label: "Full Name",
        key: "personalInfo.fullName",
      },
      {
        label: "DOB",
        key: "personalInfo.dob",
        formatMethod: dateToDDMMYYYY,
      },
      {
        label: "Gender",
        key: "personalInfo.gender",
      },
      {
        label: "Total Income",
        key: "personalInfo.totalIncome",
        prefix: "Rs",
      },
    ],
  },
  {
    title: "Contact Details",
    items: [
      {
        label: "Phone No(s)",
        key: "phoneInfo",
        formatMethod: (phoneInfos?: CreditReportPhoneInfo[]) =>
          phoneInfos?.reduce(
            (accum, phone) => accum + `${phone.number} (${phone.type})\n`,
            ""
          ),
        wrap: "pre-wrap",
      },
      {
        label: "Email(s)",
        key: "emailInfo",
        formatMethod: (emailInfos?: CreditReportEmailInfo[]) =>
          emailInfos?.reduce((accum, email) => accum + email.email + "\n", ""),
        wrap: "pre-wrap",
      },
    ],
  },
];

export const ADDRESS_TABLE_COLUMNS = [
  {
    label: "Address Type",
    field: "addressType",
  },
  {
    label: "Address",
    field: "address",
  },
  {
    label: "State",
    field: "state",
  },
  {
    label: "PIN",
    field: "pincode",
  },
  {
    label: "Reported Date",
    field: "reportedDate",
  },
];

export const ENQUIRY_TABLE_COLUMNS = [
  {
    label: "Enquiry Date",
    field: "enquiryDate",
  },
  {
    label: "Enquiry Time",
    field: "enquiryTime",
  },
  {
    label: "Institution",
    field: "institution",
  },
  {
    label: "Amount",
    field: "amount",
  },
  {
    label: "Purpose",
    field: "purpose",
  },
];

export const ENQUIRY_SUMMARY_LABELS: DatafieldLabel[] = [
  {
    items: [
      {
        label: "Past 12 Months Count",
        key: "past12Months",
      },
      {
        label: "Past 30 Days Count",
        key: "past30Days",
      },
      {
        label: "Recent Enquiry On",
        key: "recent",
        formatMethod: dateToDDMMYYYY,
      },
    ],
  },
  {
    items: [
      {
        label: "Past 24 Months Count",
        key: "past24Months",
      },
      {
        label: "Total Count",
        key: "total",
      },
    ],
  },
];

export const CREDIT_REPORT_SUMMARY_LABELS: DatafieldLabel[] = [
  {
    items: [
      {
        label: "Number of Accounts",
        key: "noOfAccounts",
      },
      {
        label: "Number of Open Accounts",
        key: "noOfActiveAccounts",
      },
      {
        label: "Number of Write-off Accounts",
        key: "noOfWriteOffs",
      },
    ],
  },
  {
    items: [
      {
        label: "Total Balance Amount",
        key: "totalBalanceAmount",
        prefix: "Rs",
      },
      {
        label: "Total Sanction Amount",
        key: "totalSanctionedAmount",
        prefix: "Rs",
      },
      {
        label: "Total Monthly Payment Amount",
        key: "totalMonthlyPaymentAmount",
        prefix: "Rs",
      },
      {
        label: "Total Credit Limit",
        key: "totalCreditLimit",
        prefix: "Rs",
      },
    ],
  },
];

export const ACCOUNT_DETAILS_LABELS: DatafieldLabel[] = [
  {
    items: [
      {
        label: "Account Number",
        key: "accountNumber",
      },
      {
        label: "Institution",
        key: "institution",
      },
      {
        label: "Type",
        key: "accountType",
      },
      {
        label: "Ownership Type",
        key: "ownershipType",
      },
      {
        label: "Account Status",
        key: "status",
      },
    ],
  },
  {
    items: [
      {
        label: "Balance",
        key: "balance",
        prefix: "Rs",
      },
      {
        label: "Opened Date",
        key: "openedDate",
        formatMethod: dateToDDMMYYYY,
      },
      {
        label: "Repayment Tenure",
        key: "tenure",
      },
      {
        label: "EMI Amount",
        key: "emiAmount",
        prefix: "Rs",
      },
    ],
  },
];
