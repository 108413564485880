import { CSSProperties, ReactNode } from "react";
import "./styles.css";

export type AlertType = "success" | "danger" | "warning" | "info";

interface AlertProps {
  type?: AlertType;
  message?: ReactNode;
  style?: CSSProperties;
  canDismiss?: boolean;
  onDismissClick?: () => void;
  children?: ReactNode;
}

export default function Alert({
  type = "info",
  message,
  canDismiss = false,
  onDismissClick,
  style,
  children,
}: AlertProps) {
  if (!children && !message) return <></>;
  return (
    <div
      className={`alert alert-${type} ${canDismiss ? "alert-dismissible" : ""}`}
      role="alert"
      style={style}
    >
      {message}
      {children}
      {canDismiss && (
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={onDismissClick}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </div>
  );
}
