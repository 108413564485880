import Button from "../../../components/button";
import Table, { TableColumns } from "../../../components/table";
import { FileType, FilterUserDocType } from "../../../models";
import {
  getDataUrlFromDocTypeAndBody,
  splitKeyWithSpace,
} from "../../../utils";
import { ReactComponent as EyeIcon } from "../../../images/eye.svg";
import { ReactComponent as DownloadIcon } from "../../../images/download.svg";
import { useEffect, useState } from "react";
import Modal from "../../../components/modal";

interface DocumentTableProps {
  docOptions: FilterUserDocType[];
  canFetch: (docName: string) => boolean;
  docFetchDispatch: (docName: string) => Promise<any>;
  onViewClick: (doc?: { body: string; type: FileType }) => void;
}

interface ActionButtonProps {
  isLoading: {
    [docName: string]: boolean;
  };
  docOption: FilterUserDocType;
  onViewClick: (docName: string, forPreview: boolean) => Promise<void>;
}

const DOCUMENT_TABLE_COLUMNS: TableColumns[] = [
  {
    field: "documentCategory",
    label: "Document Category",
    style: { textAlign: "center" },
  },
  {
    field: "documentType",
    label: "Document Type",
    style: { textAlign: "center" },
  },
  { field: "action", label: "Action", style: { textAlign: "center" } },
];

export default function DocumentTable({
  canFetch,
  docOptions,
  onViewClick,
  docFetchDispatch,
}: DocumentTableProps) {
  const [isLoading, setIsLoading] = useState<{ [docName: string]: boolean }>(
    {}
  );
  const [previewDocumentData, setPreviewDocumentData] = useState<{
    body: string;
    type: FileType;
  }>({ body: "", type: FileType.IMG_PNG });

  const [errorMessage, setErrorMessage] = useState<{
    active: boolean;
    message: string;
  }>({ active: false, message: "" });

  function handleViewClick() {
    if (onViewClick) {
      onViewClick(previewDocumentData);
    }
  }

  async function handleDocFetch(docName: string, forPreview: boolean = true) {
    if (!docFetchDispatch || !docName) return;
    if (canFetch && !canFetch(docName)) return;

    try {
      setErrorMessage({ active: false, message: "" });
      setIsLoading((prevState) => ({ ...prevState, [docName]: true }));
      const response = await docFetchDispatch(docName);

      if (forPreview) {
        setPreviewDocumentData({ body: response.body, type: response.type });
      } else {
        const downloadLink = document.createElement("a");
        // INFO: Hack to download the zip file for EKYC document
        if (response.type === FileType.APP_JSON && docName === "EKYC") {
          downloadLink.href = `data:application/zip;base64,${
            JSON.parse(atob(response.body))?.eKycZipBase64
          }`;
        } else {
          downloadLink.href = getDataUrlFromDocTypeAndBody(
            response.type,
            response.body
          );
        }
        downloadLink.download = docName;
        downloadLink.click();
      }
    } catch (error) {
      if (forPreview) {
        setPreviewDocumentData({ body: "", type: FileType.IMG_PNG });
      }
      setErrorMessage({ active: true, message: error.message });
    } finally {
      setIsLoading((prevState) => ({ ...prevState, [docName]: false }));
    }
  }

  const rows = docOptions.map((docOption) => ({
    documentCategory: splitKeyWithSpace(docOption.userDocCategory),
    documentType: splitKeyWithSpace(docOption.doc),
    action: (
      <ActionButton
        isLoading={isLoading}
        docOption={docOption}
        onViewClick={handleDocFetch}
      />
    ),
  }));

  useEffect(() => {
    if (previewDocumentData.body && previewDocumentData.type) {
      handleViewClick();
    }
  }, [previewDocumentData]);

  return (
    <>
      <h4 className="mb-2">Documents</h4>
      <Table data={rows} columns={DOCUMENT_TABLE_COLUMNS} />
      <Modal
        open={errorMessage.active}
        title={errorMessage.message}
        size="medium"
        hasSubmitButton={false}
        hasCancelButton={false}
        onClose={() =>
          setErrorMessage((prevState) => ({ ...prevState, active: false }))
        }
      />
    </>
  );
}

function ActionButton({
  isLoading,
  docOption,
  onViewClick,
}: ActionButtonProps) {
  const [previewInProgress, setPreviewInProgress] = useState(false);

  async function handleViewClick(docName: string) {
    setPreviewInProgress(true);
    await onViewClick(docName, true);
    setPreviewInProgress(false);
  }

  return (
    <div className="col-lg rf-btn-group">
      <Button
        loading={isLoading[docOption.doc] || previewInProgress}
        variant="info"
        title="View Document"
        onClick={() => handleViewClick(docOption.doc)}
      >
        <EyeIcon />
      </Button>

      <Button
        variant="info"
        disabled={!docOption.doc || isLoading[docOption.doc]}
        onClick={() => onViewClick(docOption.doc, false)}
      >
        <DownloadIcon color="var(--font-color)" />
      </Button>
    </div>
  );
}
