import { ChangeEvent, useEffect, useState } from "react";
import { FileType } from "../../../models";
import {
  HorizontalInputField,
  HorizontalSelect,
} from "../../../components/input-functions";
import { splitKeyWithSpace } from "../../../utils";
import { ReactComponent as UploadIcon } from "../../../images/upload.svg";
import Button from "../../../components/button";

interface UploadDocumentProps {
  label: string;
  docOptions: string[];
  canUpload?: boolean;
  defaultSelectedFile?: string;
  onUploadClick?: (docName: string) => void;
  onViewClick?: (doc?: { body: string; type: FileType }) => void;
}

export default function UploadDocument({
  label,
  defaultSelectedFile,
  docOptions,
  canUpload,
  onUploadClick,
}: UploadDocumentProps) {
  const [selectedFileName, setSelectedFileName] = useState("");

  function handleFileNameChange(e: ChangeEvent<HTMLSelectElement>) {
    setSelectedFileName(e.target.value);
  }

  function handleUploadClick() {
    const docName = docOptions?.find((el) => el === selectedFileName);
    if (onUploadClick && docName) {
      onUploadClick(docName);
    }
  }

  function getDefaultSelectedFile() {
    if (defaultSelectedFile) return defaultSelectedFile;
    if (!docOptions?.length) return "";
    return docOptions[0];
  }

  useEffect(() => setSelectedFileName(getDefaultSelectedFile()), [docOptions]);

  return (
    <div className="row align-items-center my-4">
      <div className="col mb-3 mb-md-0">
        {docOptions.length > 0 ? (
          <HorizontalSelect
            label={label}
            options={docOptions.map((eachDoc) => ({
              label: splitKeyWithSpace(eachDoc),
              value: eachDoc,
            }))}
            value={selectedFileName}
            onChange={handleFileNameChange}
          />
        ) : (
          <HorizontalInputField label={label} value="Not Available" disabled />
        )}
      </div>
      <div className="col-lg rf-btn-group">
        {canUpload && (
          <Button onClick={handleUploadClick} disabled={!selectedFileName}>
            <UploadIcon />
            Upload
          </Button>
        )}
      </div>
    </div>
  );
}
