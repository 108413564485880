import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Table, { TableColumns } from "../../components/table";
import useScreenName from "../../hooks/useScreenName";
import { ReduxState } from "../../reducers";
import {
  CommercialLoanDetails,
  RetailODDetails,
  TermLoanDetails,
} from "../../dto";
import dispatch from "../../middleware";
import {
  getCommercialODLoansBySearch,
  getLoansBySearch,
  getLoanSearchFilters,
  getPaginationLoanCount,
  getWCTLoansBySearch,
} from "../../actions/loan.action";
import {
  LoanStatusEnum,
  LoanType,
  RoutePaths,
  SearchFilter,
} from "../../models";
import Pagination from "../../components/pagination";
import MultiSelect, {
  convertFilterArrayToObject,
  convertFilterObjectToArray,
  MultiSelectOption,
} from "../../components/multi-select";
import {
  clearFormData,
  dateToDDMMYYYY,
  getPaginationCount,
  isLoadingActive,
  splitKeyWithSpace,
} from "../../utils";
import Badge from "../../components/badge";
import Tabs, { TabItem } from "../../components/tabs";
import useQueryParams from "../../hooks/useQueryParams";
import { SearchBox } from "../../components/input-functions";

const tabItems: TabItem<LoanType>[] = [
  {
    label: "Term Loan",
    value: "TERM_LOAN",
  },
  {
    label: "Retail OD",
    value: "RETAIL_OD",
  },
  {
    label: "Commercial OD",
    value: "COMMERCIAL_OD",
  },
  {
    label: "WCTL",
    value: "WORKING_CAPITAL_TERM_LOAN",
  },
];

function getColumns(loanType: LoanType): TableColumns[] {
  if (loanType === "COMMERCIAL_OD") {
    return [
      { field: "_id", label: "Loan ID" },
      { field: "enterpriseName", label: "Enterprise Name" },
      { field: "createdAt", label: "Date Of Loan" },
      { field: "irpa", label: "IRPA (%)" },
      { field: "pf", label: "PF" },
      { field: "status", label: "Status" },
    ];
  } else if (loanType === "WORKING_CAPITAL_TERM_LOAN") {
    return [
      { field: "_id", label: "Loan ID" },
      { field: "irpa", label: "IRPA (%)" },
      { field: "pf", label: "PF" },
      { field: "approvedAmount", label: "Approved Amount" },
      { field: "tenureInMonths", label: "Tenure In Months" },
      { field: "moratoriumInMonths", label: "Moratorium In Months" },
      { field: "status", label: "Status" },
    ];
  }
  return [
    { field: "_id", label: "Loan ID" },
    { field: "userName", label: "User Name" },
    { field: "employerName", label: "Employer Name" },
    { field: "createdAt", label: "Date Of Loan" },
    { field: "appId", label: "Application ID" },
    { field: "irpa", label: "IRPA (%)" },
    { field: "pf", label: "PF" },
    ...(loanType === "TERM_LOAN"
      ? [
          {
            field: "tenureInMonths",
            label: "Tenure",
            // INFO: `as any` is to resolve the TS issue
            // TODO: Figure out proper fix
            style: { textAlign: "center" as any },
          },
        ]
      : []),
    { field: "status", label: "Status" },
  ];
}

type SearchQueryType = {
  loanType: LoanType;
  pageNo: number;
  searchText: string;
  numberOfEntitiesPerPage: number;
  status: string[];
};

const DEFAULT_SEARCH_QUERY_STATE: SearchQueryType = {
  loanType: "TERM_LOAN",
  pageNo: 1,
  searchText: "",
  numberOfEntitiesPerPage: 10,
  status: [],
};

const LOAN_TYPE_TO_PAGINATION_COUNT_KEY_MAP: Record<
  Exclude<LoanType, "WORKING_CAPITAL_TERM_LOAN">,
  string
> = {
  TERM_LOAN: "termLoanCount",
  RETAIL_OD: "retailODCount",
  COMMERCIAL_OD: "commercialODCount",
};

export default function LoanScreen() {
  useScreenName("Loans");
  const storeDispatch = useDispatch();

  const loadingQueue = useSelector(
    (state: ReduxState) => state.loan.loadingQueue
  );
  const loans: TermLoanDetails[] | RetailODDetails[] | CommercialLoanDetails[] =
    useSelector((state: ReduxState) => state.loan.loans);
  const paginationCount: Record<string, number | null> = useSelector(
    (state: ReduxState) => state.loan.paginationCount
  );
  const loanSearchFilters: Record<LoanType, SearchFilter[]> = useSelector(
    (state: ReduxState) => state.loan.searchFilters
  );

  const [
    { loanType, pageNo, searchText, numberOfEntitiesPerPage, status },
    setSearchQuery,
  ] = useQueryParams<SearchQueryType>(DEFAULT_SEARCH_QUERY_STATE);

  const filterOptions = useMemo(() => {
    const filters = loanSearchFilters[loanType];
    if (!Array.isArray(filters)) return [];
    return filters.reduce(
      (accum, group) => [
        ...accum,
        ...group.values.map((value) => ({
          group: group.name,
          value,
          label: splitKeyWithSpace(value),
        })),
      ],
      [] as MultiSelectOption[]
    );
  }, [loanSearchFilters, loanType]);

  useEffect(() => {
    const filters = loanSearchFilters[loanType];
    if (!Array.isArray(filters)) return;
    if (filters.length > 0) return;
    dispatch(storeDispatch, getLoanSearchFilters(loanType));
  }, [loanType]);

  useEffect(() => {
    if (loanType === "COMMERCIAL_OD") {
      dispatch(
        storeDispatch,
        getCommercialODLoansBySearch(
          clearFormData({
            numberOfEntitiesPerPage,
            pageNo,
            searchText,
            loanType,
            status,
          })
        )
      );
      return;
    }
    if (loanType === "WORKING_CAPITAL_TERM_LOAN") {
      dispatch(
        storeDispatch,
        getWCTLoansBySearch(
          clearFormData({
            numberOfEntitiesPerPage,
            pageNo,
            searchText,
            loanType,
            status,
          })
        )
      );

      return;
    }
    dispatch(
      storeDispatch,
      getLoansBySearch(
        clearFormData({
          numberOfEntitiesPerPage,
          pageNo,
          searchText,
          loanType,
          status,
        })
      )
    );
  }, [pageNo, searchText, loanType, numberOfEntitiesPerPage, status]);

  useEffect(() => {
    if (
      loanType === "COMMERCIAL_OD" ||
      loanType === "WORKING_CAPITAL_TERM_LOAN"
    )
      return;
    dispatch(
      storeDispatch,
      getPaginationLoanCount(clearFormData({ loanType, searchText, status }))
    );
  }, [loanType, searchText, status]);

  function handleSearch(searchText: string) {
    setSearchQuery((prevState) => ({ ...prevState, searchText, pageNo: 1 }));
  }

  function handleFilterChanges(options: MultiSelectOption[]) {
    const filterObject = convertFilterArrayToObject(options);
    setSearchQuery((prevState) => ({
      ...prevState,
      ...{ status: [] },
      ...filterObject,
      pageNo: 1,
    }));
  }

  function getIdLink(path: RoutePaths, id?: string) {
    if (!id) return;
    let navigationLink = `${path}/${id}`;
    if (loanType === "COMMERCIAL_OD")
      navigationLink = `${path}/commercial/${id}`;
    else if (loanType === "WORKING_CAPITAL_TERM_LOAN")
      navigationLink = `${path}/wctl-loan/${id}`;
    return (
      <Link to={navigationLink} className="table-link">
        {id}
      </Link>
    );
  }

  function getEmployeeNameAsLink(userId?: string, userName?: string) {
    if (!userName || !userId) return "-";
    return (
      <Link to={`users/individual/${userId}`} className="table-link">
        {userName}
      </Link>
    );
  }

  function getStatusBadge(status: LoanStatusEnum) {
    if ([LoanStatusEnum.DISBURSED, LoanStatusEnum.APPROVED].includes(status))
      return <Badge type="success">{status}</Badge>;
    if (status === LoanStatusEnum.OVERDUE)
      return <Badge type="reject">{status}</Badge>;
    return <Badge type="pending">{splitKeyWithSpace(status)}</Badge>;
  }

  function getTableData(
    loans: (TermLoanDetails | RetailODDetails | CommercialLoanDetails)[]
  ) {
    return loans.map(({ _id, createdAt, appId, irpa, pf, status, ...rest }) => {
      let user = undefined;
      let userId = undefined;
      let enterpriseId = undefined;
      let entitySnapshot = undefined;
      let approvedAmount = undefined;
      let moratoriumInMonths = undefined;
      if ("entitySnapshot" in rest) {
        entitySnapshot = rest.entitySnapshot;
      }
      if ("user" in rest) {
        user = rest.user;
      }
      if ("userId" in rest) {
        userId = rest.userId;
      }
      if ("enterpriseId" in rest) {
        enterpriseId = rest.enterpriseId;
      }
      if ("approvedAmount" in rest) {
        approvedAmount = rest.approvedAmount;
      }
      if ("moratoriumInMonths" in rest) {
        moratoriumInMonths = rest.moratoriumInMonths;
      }

      return {
        _id: getIdLink(RoutePaths.LOANS, _id),
        userName: getEmployeeNameAsLink(user?._id, user?.employeeName),
        enterpriseName: entitySnapshot?.snapshot?.name,
        employerName: user?.employerName ?? "-",
        createdAt: createdAt ? dateToDDMMYYYY(createdAt) : "-",
        appId: getIdLink(RoutePaths.APPLICATIONS, appId),
        userId: enterpriseId
          ? getIdLink(RoutePaths.ENTERPRISE_USERS, enterpriseId)
          : getIdLink(RoutePaths.INDIVIDUAL_USERS, userId),
        irpa,
        pf,
        tenureInMonths: (rest as TermLoanDetails).tenureInMonths,
        approvedAmount,
        moratoriumInMonths,
        status: getStatusBadge(status),
      };
    });
  }

  return (
    <>
      <div className="dashboard-container p-3">
        <Tabs
          items={tabItems}
          value={loanType}
          disable={isLoadingActive(loadingQueue)}
          onChange={(value) => {
            setSearchQuery({
              ...DEFAULT_SEARCH_QUERY_STATE,
              loanType: value,
            });
          }}
        />
      </div>
      <div className="dashboard-container">
        {loanType === "COMMERCIAL_OD" ||
        loanType === "WORKING_CAPITAL_TERM_LOAN" ? null : (
          <div className="d-flex flex-lg-row flex-column align-items-lg-end mb-4">
            <div className="search-input-user mr-lg-3 mb-4 mb-lg-0">
              <MultiSelect
                label="Filter"
                options={filterOptions}
                groupped
                value={convertFilterObjectToArray({ status })}
                onChange={handleFilterChanges}
              />
            </div>
            <SearchBox
              value={searchText}
              label="Search"
              placeholder="Search by User Name"
              onSubmit={handleSearch}
            />
          </div>
        )}
        <Table
          loading={isLoadingActive(loadingQueue)}
          columns={getColumns(loanType)}
          data={getTableData(loans)}
        />
      </div>
      <Pagination
        currentPage={pageNo}
        totalEntries={getPaginationCount(
          paginationCount[
            LOAN_TYPE_TO_PAGINATION_COUNT_KEY_MAP[
              loanType as keyof typeof LOAN_TYPE_TO_PAGINATION_COUNT_KEY_MAP
            ]
          ],
          numberOfEntitiesPerPage
        )}
        numberOfEntriesPerPage={numberOfEntitiesPerPage}
        onPageChange={(pageNo) =>
          setSearchQuery((prevState) => ({
            ...prevState,
            pageNo,
          }))
        }
        onPerPageCountChange={(countPerPage) =>
          setSearchQuery((prevState) => ({
            ...prevState,
            numberOfEntitiesPerPage: countPerPage,
            pageNo: 1,
          }))
        }
      />
    </>
  );
}
