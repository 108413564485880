import { ChangeEvent, Fragment, useState } from "react";
import { useClickOutside } from "../../hooks/useClickOutside";
import { ReactComponent as UpCaretIcon } from "../../images/up_caret.svg";
import { ReactComponent as DownCaretIcon } from "../../images/down_caret.svg";
import { ReactComponent as CrossIcon } from "../../images/cross.svg";
import { splitKeyWithSpace } from "../../utils";
import camelcase from "camelcase";
import "./styles.css";

// Helper methods
// TODO: This methods are a bit compute heavy, ok for now but can be improved later
export function convertFilterArrayToObject(options: MultiSelectOption[]) {
  const filterObject = options.reduce(function (accum, option) {
    const key = camelcase(option["group"] || "others");
    accum[key] = accum[key] || [];
    accum[key].push(option.value);
    return accum;
  }, {} as Record<string, string[]>);
  return filterObject;
}

export function convertFilterObjectToArray(
  option: Record<string, string[]>
): MultiSelectOption[] {
  return Object.keys(option).reduce((accumulator, groupName) => {
    const groupArr = option[groupName].map((optionValue) => ({
      label: optionValue,
      value: optionValue,
      group: groupName.toUpperCase(),
    }));
    const newAccum = accumulator.concat(groupArr);
    return newAccum;
  }, [] as MultiSelectOption[]);
}

function addNewOption(
  prevOptions: MultiSelectOption[],
  option: MultiSelectOption
): MultiSelectOption[] {
  const newState = [...prevOptions];
  newState.push(option);
  return newState;
}

function removeAnOption(
  prevOptions: MultiSelectOption[],
  option: MultiSelectOption
): MultiSelectOption[] {
  const index = prevOptions.findIndex(
    (selectedOp) =>
      selectedOp.value === option.value && selectedOp.group === option.group
  );
  if (index < 0) return prevOptions;
  const newState = [...prevOptions];
  newState.splice(index, 1);
  return newState;
}

function getSelectedOptions(
  parentValue: MultiSelectOption[] | undefined,
  localState: MultiSelectOption[]
): MultiSelectOption[] {
  if (Array.isArray(parentValue)) return parentValue;
  return localState;
}

export interface MultiSelectOption {
  label: string;
  value: string;
  group?: string;
}

interface MultiSelectProps {
  label?: string;
  placeholder?: string;
  value?: MultiSelectOption[];
  preSelectedOptions?: MultiSelectOption[];
  options: MultiSelectOption[];
  onChange?: (selectedOptions: MultiSelectOption[]) => void;
  groupped?: boolean;
}

// INFO: If `props.value` availabe then it's will work like uncontrolled element, else will use internal state
export default function MultiSelect({
  label,
  placeholder = "Select",
  value,
  preSelectedOptions = [],
  options,
  onChange,
  groupped,
}: MultiSelectProps) {
  const [isActive, setIsActive] = useState(false);
  const [selectedOptions, setSelectedOptions] =
    useState<MultiSelectOption[]>(preSelectedOptions);

  const ref = useClickOutside(() => setIsActive(false));

  function handleChange(options: MultiSelectOption[]) {
    if (onChange) {
      onChange(options);
    }
  }

  function clearAllSelections() {
    handleChange([]);
    if (!value) {
      setSelectedOptions([]);
    }
  }

  function checkIfSelectedUsingValueAndGroup(
    optionValue: string,
    group?: string
  ): boolean {
    const index = getSelectedOptions(value, selectedOptions).findIndex(
      (selectedOp) =>
        selectedOp.value === optionValue &&
        (group ? selectedOp.group === group : true)
    );
    return index >= 0;
  }
  function selectOption(option: MultiSelectOption) {
    if (!!value) {
      const newState = addNewOption(value, option);
      handleChange(newState);
    } else {
      setSelectedOptions((prevState) => {
        const newState = addNewOption(prevState, option);
        handleChange(newState);
        return newState;
      });
    }
  }
  function removeOption(option: MultiSelectOption) {
    if (!!value) {
      const newState = removeAnOption(value, option);
        handleChange(newState);
    } else {
      setSelectedOptions((prevState) => {
        const newState = removeAnOption(prevState, option);
        handleChange(newState);
        return newState;
      });
    }
  }

  function handleSelectChange(option: MultiSelectOption) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      if (checked) {
        selectOption(option);
      } else {
        removeOption(option);
      }
    };
  }

  function renderOptions(options: MultiSelectOption[]) {
    return options.map((option, index) => (
      <li className="multi-select__option-list-item" key={index}>
        <label className="multi-select__option-list-item--checkbox-label">
          <input
            type="checkbox"
            name={option.value}
            onChange={handleSelectChange(option)}
            checked={checkIfSelectedUsingValueAndGroup(
              option.value,
              option.group
            )}
          />
          <span className="multi-select__option-list-item--checkbox-label-text">
            {option.label}
          </span>
        </label>
      </li>
    ));
  }

  function renderGroupedOptions(options: MultiSelectOption[]) {
    const groupedObject = options.reduce(function (r, a) {
      const key = a["group"] || "Others";
      r[key] = r[key] || [];
      r[key].push(a);
      return r;
    }, {} as Record<string, MultiSelectOption[]>);

    return Object.keys(groupedObject).map((groupName) => (
      <Fragment key={groupName}>
        <li className="multi-select__option-list-item multi-select__option-list-item--group-title">
          {splitKeyWithSpace(groupName)}
        </li>
        {renderOptions(groupedObject[groupName])}
      </Fragment>
    ));
  }

  function renderSelectedOptionsOrPlaceholder(
    options: MultiSelectOption[],
    placeholder: string
  ) {
    if (options.length < 1) {
      return (
        <span className="multi-select__box--placeholder">{placeholder}</span>
      );
    }
    return (
      <div className="multi-select__box-badge__container">
        {options.map((option, index) => (
          <div className="multi-select__box-badge" key={index}>
            <span>
              {groupped ? splitKeyWithSpace(option.group) : ""}:{option.label}
            </span>
            <div
              className="multi-select__box-badge--close-btn"
              onClick={(e) => {
                e.stopPropagation();
                removeOption(option);
              }}
            >
              &#10005;
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="multi-select__container" ref={ref}>
      {label && <div className="multi-select__label">{label}</div>}
      <div
        className="multi-select__box"
        onClick={() => setIsActive((prevState) => !prevState)}
      >
        {renderSelectedOptionsOrPlaceholder(
          getSelectedOptions(value, selectedOptions),
          placeholder
        )}
        <div className="multi-select__box__action-btn-group">
          {getSelectedOptions(value, selectedOptions).length > 1 && <div
            className="multi-select__box__action-btn"
            title="Clear all"
            onClick={(e) => {
              e.stopPropagation();
              clearAllSelections();
              setIsActive(false);
            }}
          >
            <CrossIcon />
          </div>}
          <div
            className="multi-select__box__action-indicator"
          >
            {isActive ? <UpCaretIcon /> : <DownCaretIcon />}
          </div>
        </div>
      </div>
      <div
        className={`multi-select__option-list-container ${
          isActive ? "active" : ""
        }`}
      >
        <ul className="multi-select__option-list">
          {groupped ? renderGroupedOptions(options) : renderOptions(options)}
        </ul>
      </div>
    </div>
  );
}
