import React, { forwardRef, Ref, useState } from "react";
import "./styles.css";
import CustomAlert from "../custom-alert";
import { ReactComponent as FilePlus } from "../../images/file-plus.svg";

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
  event.stopPropagation();
}

interface DropZoneProps {
  disabled?: boolean;
  acceptMultiple?: boolean;
  onFilesChange: (files: File[]) => void;
}

function _DropZone(
  { onFilesChange, disabled = true, acceptMultiple = false }: DropZoneProps,
  ref: Ref<HTMLInputElement>
) {
  const [isDropping, setIsDropping] = useState<boolean>(false);
  const [infoMessage, setInfoMessaage] = useState<string>("");

  function onDrop(event: React.DragEvent) {
    onDragLeave(event);
    if (disabled) return;
    setInfoMessaage("");
    const dataTransfer = event.dataTransfer;
    const fileList = dataTransfer?.files;
    handleFiles(fileList);
  }
  function onDragOverAndEnter(event: React.DragEvent) {
    preventDefault(event);
    if (disabled) return;
    setIsDropping(true);
  }
  function onDragLeave(event: React.DragEvent) {
    preventDefault(event);
    setIsDropping(false);
  }

  function onFilesChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const fileList = event.target?.files;
    setInfoMessaage("");
    handleFiles(fileList);
  }

  function handleFiles(fileList?: FileList | null) {
    if (!fileList || fileList.length < 1) {
      setInfoMessaage("Files are empty or inaccessible!!");
      return;
    }
    const files = Array.from(fileList);
    onFilesChange(files);
  }

  return (
    <div className="mt-1 mb-1">
      <div
        className={`drop-zone ${isDropping || disabled ? "disabled" : ""}`}
        onDrop={onDrop}
        onDragEnter={onDragOverAndEnter}
        onDragOver={onDragOverAndEnter}
        onDragLeave={onDragLeave}
      >
        <label className="fileLabel">
          <input
            type="file"
            className="fileElem"
            placeholder="Drop your file(s) here or browse."
            ref={ref}
            disabled={disabled}
            multiple={acceptMultiple}
            onChange={onFilesChangeHandler}
          />
        </label>
        <div className="icon-container">
          <FilePlus
            width={32}
            height={32}
            color={
              disabled ? "var(--clr-grey-default)" : "var(--clr-brand-default)"
            }
          />
        </div>
        <p className="drop-zone-text">
          <span className="mr-1">
            Drop your file(s) here or
          </span>
          <span className={!disabled ? "highlight-text" : ""}>browse.</span>
        </p>
      </div>
      {infoMessage && (
        <div className="d-flex justify-content-center mb-3">
          <CustomAlert
            children={infoMessage}
            variant="warning"
            fullWidth={true}
          />
        </div>
      )}
    </div>
  );
}

export default forwardRef<HTMLInputElement, DropZoneProps>(_DropZone);
