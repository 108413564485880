import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  Ref,
} from "react";
import { constructClassName } from "../../utils";
import CheckboxIcon from "./checkbox-icon";
import { useCheckboxGroupContext } from "./checkbox-group.context";
import "./styles.css";

export type CheckboxColor = "brand";
export type CheckboxSize = "md" | "sm";

export interface CheckboxProps
  extends Omit<ComponentPropsWithoutRef<"input">, "size"> {
  label?: ReactNode;
  value: string;
  color?: CheckboxColor;
  size?: CheckboxSize;
  indeterminate?: boolean;
  labelPosition?: "left" | "right";
}

function _Checkbox(
  {
    id,
    label,
    value,
    color = "brand",
    size: _size = "md",
    indeterminate = false,
    labelPosition = "right",
    ...restProps
  }: CheckboxProps,
  ref: Ref<HTMLInputElement>
) {
  //TODO: use either hook useId or remove _id
  const _id = id;
  const context = useCheckboxGroupContext();
  const contextProps: Partial<
    Pick<ComponentPropsWithoutRef<"input">, "checked" | "onChange">
  > = context
    ? { checked: context.value.includes(value), onChange: context.onChange }
    : {};
  const size = context?.size ?? _size;

  return (
    <div className="rf-checkbox__wrapper">
      <div
        className={`rf-checkbox__inner rf-checkbox__inner-size-${size} rf-checkbox__inner-position-${labelPosition}`}
      >
        <input
          id={_id}
          ref={ref}
          type="checkbox"
          className={constructClassName("rf-checkbox__input", {
            "rf-checkbox__input-indeterminate": indeterminate,
          })}
          value={value}
          {...restProps}
          {...contextProps}
        />
        <CheckboxIcon
          indeterminate={indeterminate}
          className="rf-checkbox__inner-icon"
        />
      </div>
      {label && (
        <label
          htmlFor={_id}
          className={`rf-checkbox__label rf-checkbox__label-position-${labelPosition}`}
        >
          {label}
        </label>
      )}
    </div>
  );
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(_Checkbox);
