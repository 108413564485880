import { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../../components/modal";
import DocumentUpload from "../../../components/document-upload";
import DocumentViewer from "../../../components/document-viewer";
import {
  FileType,
  FilterUserDocType,
  UploadDocType,
  UserDocCategory,
} from "../../../models";
import dispatch from "../../../middleware";
import { ReduxState } from "../../../reducers";
import {
  getUserAgreements,
  getUserDoc,
  getUserDocList,
  storeUserDoc,
} from "../../../actions/user.action";
import { UserDetails, UserDocType } from "../../../dto";
import {
  downloadFileFromBase64,
  getDataUrlFromDocTypeAndBody,
  splitKeyWithSpace,
} from "../../../utils";
import { EnterpriseDetails } from "../../../dto/enterprise.dto";
import { isUserAnEnterprise } from "../helpers";
import {
  getEnterpriseDoc,
  storeEnterpriseDoc,
} from "../../../actions/enterprise.action";
import { USER_DOC_TYPES } from "./constants";
import CkycDocs from "./ckyc";
import Box from "../../../components/box";
import { Agreement } from "../../../dto/agreement.dto";
import { getAgreementDocById } from "../../../actions/agreement.action";
import AgreementsTable from "./agreementsTable";
import UploadDocument from "./uploadDocument";
import DocumentTable from "./documentTable";

function filterUserDocTypeToBeShown(
  availableDocList: string[]
): Array<FilterUserDocType> {
  const defaultObj: Array<FilterUserDocType> = [];

  for (const doc of availableDocList) {
    const identityDoc = USER_DOC_TYPES.IDENTITY_PROOF.find(
      (el) => el.doc === doc
    );
    if (identityDoc) {
      defaultObj.push({
        userDocCategory: UserDocCategory.IDENTITY_PROOF,
        ...identityDoc,
      });
      continue;
    }

    const addressDoc = USER_DOC_TYPES.ADDRESS_PROOF.find(
      (el) => el.doc === doc
    );
    if (addressDoc) {
      defaultObj.push({
        userDocCategory: UserDocCategory.ADDRESS_PROOF,
        ...addressDoc,
      });
      continue;
    }

    const loanAppDoc = USER_DOC_TYPES.LOAN_APPLICATION_DOCS.find(
      (el) => el.doc === doc
    );
    if (loanAppDoc) {
      defaultObj.push({
        userDocCategory: "LOAN_APPLICATION_DOCS",
        ...loanAppDoc,
      });
      continue;
    }

    // INFO: If mapping doesn't match but still we want to show the document
    defaultObj.push({
      userDocCategory: "OTHERS",
      doc,
      acceptedFileTypes: [FileType.ANY],
    });
  }

  return defaultObj;
}
let uploadModalTimeoutId: ReturnType<typeof setTimeout> | undefined;
interface UserDocumentsProps {
  userDetails: UserDetails | EnterpriseDetails;
}

export default function UserDocuments({ userDetails }: UserDocumentsProps) {
  const storeDispatch = useDispatch();

  const userDocList: string[] = useSelector(
    (state: ReduxState) => state.user.singleUserDocList
  );

  const isEnterprise = isUserAnEnterprise(userDetails._id);

  const [userAgreements, setUserAgreements] = useState<Array<Agreement>>([]);

  const FILTERTED_USER_DOC_TYPES = useMemo(() => {
    return filterUserDocTypeToBeShown(userDocList);
  }, [userDocList]);

  const [isLoading, setIsLoading] = useState(false);
  const [documentViewer, setDocumentViewer] = useState<{
    active: boolean;
    docUrl?: string;
    docType?: FileType;
  }>({ active: false });
  const [uploadModal, setUploadModal] = useState<{
    active: boolean;
    docName?: string;
  }>({ active: false });

  function isDocumentAvailableToFetch(docName: string): boolean {
    return userDocList.includes(docName);
  }

  useEffect(() => {
    setIsLoading(true);
    dispatch(storeDispatch, getUserDocList(userDetails._id)).finally(() =>
      setIsLoading(false)
    );
    dispatch(storeDispatch, getUserAgreements(userDetails._id)).then(
      (agreements) => {
        setUserAgreements(agreements);
      }
    );

    return () => {
      if (uploadModalTimeoutId) {
        clearTimeout(uploadModalTimeoutId);
      }
    };
  }, []);
  function showDocumentViewer(doc?: { body: string; type: FileType }) {
    if (!doc) return;
    const docUrl = getDataUrlFromDocTypeAndBody(doc.type, doc.body);
    setDocumentViewer({ active: true, docUrl, docType: doc.type });
  }
  function fetchDocumentDispatch(docName: string) {
    return dispatch(
      storeDispatch,
      isEnterprise
        ? getEnterpriseDoc(userDetails._id, docName)
        : getUserDoc(userDetails._id, docName)
    );
  }

  function handleUploadClick(docName: string) {
    setUploadModal({ active: true, docName });
  }

  function getDocuments(
    docTypes: Record<
      UserDocCategory | "LOAN_APPLICATION_DOCS" | "OTHERS",
      UserDocType[]
    >
  ) {
    const docs: string[] = [];

    Object.values(docTypes).forEach((categoryDocTypes) => {
      categoryDocTypes.forEach((docType) => {
        docs.push(splitKeyWithSpace(docType.doc));
      });
    });

    return docs;
  }

  async function downloadAgreementDoc(agreementId: string) {
    const { base64Doc } = await dispatch(
      storeDispatch,
      getAgreementDocById(agreementId)
    );
    if (!base64Doc) return;
    downloadFileFromBase64({
      base64: base64Doc,
      fileName: agreementId,
      fileType: "application/pdf",
    });
  }

  async function handleFileUpload(fileInB64: string, contentType: string) {
    const { docName } = uploadModal;
    if (!docName) throw new Error();
    const payload = {
      file: fileInB64,
      documentName: docName,
      contentType: contentType as UploadDocType,
    };
    await dispatch(
      storeDispatch,
      isEnterprise
        ? storeEnterpriseDoc({ ...payload, enterpriseId: userDetails._id })
        : storeUserDoc({ ...payload, userId: userDetails._id })
    );
    await dispatch(storeDispatch, getUserDocList(userDetails._id));
    uploadModalTimeoutId = setTimeout(
      () => setUploadModal({ active: false }),
      1000
    );
  }

  if (isLoading) return <h4 className="text-center">Loading...</h4>;

  return (
    <>
      <Box>
        <DocumentTable
          docOptions={FILTERTED_USER_DOC_TYPES}
          onViewClick={showDocumentViewer}
          docFetchDispatch={fetchDocumentDispatch}
          canFetch={isDocumentAvailableToFetch}
        />

        {userDetails.status === "PENDING" && (
          <UploadDocument
            label="Doc Upload"
            docOptions={getDocuments(USER_DOC_TYPES)}
            canUpload={userDetails.status === "PENDING"}
            onUploadClick={handleUploadClick}
          />
        )}
      </Box>
      <AgreementsTable
        userAgreements={userAgreements}
        downloadFn={downloadAgreementDoc}
      />
      <CkycDocs onShowDocumentClick={showDocumentViewer} />
      <Modal
        open={uploadModal.active}
        title={`Upload ${splitKeyWithSpace(uploadModal.docName)}`}
        onClose={() => setUploadModal({ active: false })}
        hasCancelButton={false}
        hasSubmitButton={false}
      >
        <DocumentUpload
          accepts={[
            FileType.APP_PDF,
            FileType.IMG_JPEG,
            FileType.IMG_JPEG,
            FileType.IMG_GIF,
          ]}
          onFileSubmit={handleFileUpload}
        />
      </Modal>
      <DocumentViewer
        {...documentViewer}
        onClose={() => setDocumentViewer({ active: false })}
      />
    </>
  );
}
