import { BankStatementFile, IdentifierFile } from ".";
import {
  getApplicationDoc,
  storeApplicationDoc,
} from "../../actions/application.action";
import { BANK_STATEMENT } from "../../constants";
import dispatch from "../../middleware";
import {
  ApplicantType,
  MonthlyAnalysisStatementsFields,
  UploadDocType,
} from "../../models";
import {
  getBase64StringFromFile,
  hasAnyFileTypesUnAcceptable,
  isEmpty,
} from "../../utils";
import { ACCEPTED_FILE_TYPES, MONTHLY_ANALYSIS_PARAMETERS } from "./constants";

export function getAnalyeButtonText(
  isMonthlyAnalysisAvailable: boolean,
  loading: boolean
) {
  if (loading) {
    return "Analysing";
  }
  if (isMonthlyAnalysisAvailable) return "Re Analyse";
  return "Analyse";
}

export function getUploadButtonText(loading: boolean) {
  if (loading) {
    return "Uploading";
  }
  return "Upload";
}

export async function getDocFetchAction({
  entityId,
  appId,
  applicantType,
}: {
  entityId: string;
  appId: string;
  applicantType: ApplicantType;
}) {
  return getApplicationDoc({
    entityId,
    appId,
    documentName: BANK_STATEMENT,
    applicantType,
  });
}

export function formatMonthlyAnalysis(
  monthlyAnalysis: MonthlyAnalysisStatementsFields
) {
  if (isEmpty(monthlyAnalysis)) return { rows: [], columns: [] };
  //INFO: monthly analysis value types are not fixed
  //INFO: Have to employ this way of mapping table columns and rows
  const rows = MONTHLY_ANALYSIS_PARAMETERS.map(({ key, parameter }) => {
    const value = monthlyAnalysis[key];
    return { parameter, ...value };
  });
  const unknownColumns = Object.keys(rows[0])
    .slice(1)
    .map((column) => ({ field: column, label: column }));
  const columns = [
    {
      field: "parameter",
      label: "Parameter",
    },
    ...unknownColumns,
  ];
  return { rows, columns };
}

export async function getNewFiles(
  prevFileNamesAndTypes: IdentifierFile[],
  nextFiles: BankStatementFile[]
) {
  if (nextFiles.length < 1) return [];

  const isAnyFileTypeUnAcceptable = hasAnyFileTypesUnAcceptable(
    nextFiles.map(({ file }) => file.type),
    ACCEPTED_FILE_TYPES
  );
  if (isAnyFileTypeUnAcceptable) {
    throw new Error("File type not allowed.");
  }
  const prevFileNames = prevFileNamesAndTypes.map((file) => file.name);
  const prevFileTypes = prevFileNamesAndTypes.map((file) => file.type);

  return nextFiles.filter(
    ({ file }) =>
      !prevFileNames.includes(file.name) && !prevFileTypes.includes(file.type)
  );
}

//TODO: refactor this to handle multiple bank statement files
//INFO: this function assumes we have a single bank statement file
//INFO: response interface with multiple files is yet unknown
export async function getUploadedBankStatements(context: {
  userId: string;
  appId: string;
  applicantType: ApplicantType;
  storeDispatch: any;
}): Promise<BankStatementFile[]> {
  const { userId, appId, applicantType, storeDispatch } = context;
  const action = await getDocFetchAction({
    entityId: userId,
    appId,
    applicantType,
  });
  const response = await dispatch(storeDispatch, action);
  const {
    body,
    type,
    metadata,
  }: { body: string; type: string; metadata: any } = response;
  const file = new File([body], `${BANK_STATEMENT}`, { type });

  return [{ file, ...(metadata ? { metadata } : {}) }];
}

export async function postBankStatements({
  files,
  appId,
  storeDispatch,
}: {
  files: BankStatementFile[];
  appId: string;
  storeDispatch: any;
}) {
  try {
    const formattedFiles = await Promise.all(
      files.map(async ({ file, metadata }) => {
        const fileBase64 = await getBase64StringFromFile(file);
        return {
          file: fileBase64,
          documentName: BANK_STATEMENT,
          contentType: file.type as UploadDocType,
          ...(metadata?.password
            ? { password: metadata?.password as string }
            : {}),
        };
      })
    );
    await Promise.all(
      formattedFiles.map((file) =>
        dispatch(storeDispatch, storeApplicationDoc({ ...file, appId }))
      )
    );
  } catch (e) {
    throw new Error(
      e?.message ?? "Upload failed but some might have succeeded."
    );
  }
}
