export const MONTH_FULL_NAME_LIST = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const MONTH_SHORT_NAME_LIST = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const USER_SELFIE_DOC_NAME = "SELFIE";
export const BANK_STATEMENT = "BANK_STATEMENT";
export const USER_ID_MISSING_ERROR_MESSAGE = "UserId is missing for the user.";
export const STATUS_CHANGE_MODAL_THRESHOLD_AMOUNT = 25_000;
export const STATUS_CHANGE_MODAL_MESSGE =
  "Please note that the application amount is <amount>, would you like to proceed?";
export const AGREEMENTS_SCREEN_NAME = "Agreements";
