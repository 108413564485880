import Table from "../../../components/table";
import { CommercialODLoanStatement } from "../../../dto";
import { dateToYYYYMMDD, downloadCSV } from "../../../utils";
import { ReactComponent as DownloadIcon } from "../../../images/download.svg";
import Button from "../../../components/button";
import DateRange from "../../../components/date-range";
import { useState } from "react";

interface LoanStatementProps {
  loanStatementDetails: CommercialODLoanStatement[] | null;
  isLoanLoading: boolean;
  loanId: string;
}

export default function LoanStatement({
  loanStatementDetails,
  isLoanLoading,
  loanId,
}: LoanStatementProps) {
  const [date, setDate] = useState<{ startDate: string; endDate: string }>({
    startDate: "",
    endDate: "",
  });
  const fromDateObj = new Date(date.startDate);
  const toDateObj = new Date(date.endDate);

  const loanStatementColumn = [
    { field: "date", label: "Date (YYMMDD)" },
    { field: "event", label: "Event" },
    { field: "amount", label: "Amount" },
    { field: "principalOutstanding", label: "Principle O/S" },
    { field: "unpaidChargedInterest", label: "Unpaid Interest Charge" },
    { field: "accruedInterest", label: "Accrued Interest" },
  ];

  function getTableData(loanStatements: CommercialODLoanStatement[] | null) {
    if (!loanStatements) return [];

    return loanStatements.map((loanStatemen) => ({
      date: dateToYYYYMMDD(loanStatemen.loanEvent.onDate),
      event: loanStatemen.loanEvent.event,
      amount: loanStatemen.loanEvent.amount,
      principalOutstanding: loanStatemen.computedValues.principalOutstanding,
      unpaidChargedInterest: loanStatemen.computedValues.unpaidChargedInterest,
      accruedInterest: loanStatemen.computedValues.accruedInterest,
    }));
  }

  function handleDownloadButtonClick() {
    downloadCSV(
      getTableData(loanStatementDetails),
      loanStatementColumn,
      `${loanId}_Loan_Statement`
    );
  }

  function handleChange(fieldName: string, val: string) {
    setDate((prevDate) => ({ ...prevDate, [fieldName]: val }));
  }

  function getFilterDataBasedOnDate(
    loanStatementDetails: CommercialODLoanStatement[] | null
  ) {
    if (!loanStatementDetails) return [];
    return loanStatementDetails?.filter((loanStatement) => {
      const eventDate = new Date(loanStatement.loanEvent.onDate);
      return eventDate >= fromDateObj && eventDate <= toDateObj;
    });
  }

  const filteredDataBasedOnDate =
    getFilterDataBasedOnDate(loanStatementDetails);

  return (
    <div>
      <div className="d-flex align-items-lg-center justify-content-between mb-4">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <h4>Loan Statement</h4>
          <form onSubmit={(e) => {}}>
            <DateRange
              startDate={date.startDate}
              endDate={date.endDate}
              handleDateChange={handleChange}
            />
          </form>
        </div>
        <Button variant="info" title="Download">
          <DownloadIcon
            style={{ cursor: "pointer" }}
            onClick={handleDownloadButtonClick}
            height="25"
            width="25"
            color="var(--font-color)"
          />
        </Button>
      </div>
      <Table
        loading={isLoanLoading}
        columns={loanStatementColumn}
        data={getTableData(filteredDataBasedOnDate)}
      />
    </div>
  );
}
