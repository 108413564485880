import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReportById,
  getReportDocById,
  getReportList,
} from "../../actions/report.action";
import Button from "../../components/button";
import ConfirmDialog from "../../components/confirm-dialog";
import Modal from "../../components/modal";
import Table, { TableColumns } from "../../components/table";
import { ReportDetails, ReportDoc, ReportType } from "../../dto/report.dto";
import dispatch from "../../middleware";
import { ReduxState } from "../../reducers";
import {
  downloadFileFromBase64,
  generateSortComparator,
  paginate,
} from "../../utils";
import GenerateReportForm from "./generateReportForm";
import { ReactComponent as DownloadIcon } from "../../images/download.svg";
import useScreenName from "../../hooks/useScreenName";
import useQueryParams from "../../hooks/useQueryParams";
import Pagination from "../../components/pagination";
import Alert from "../../components/alert";
import { MONTH_SHORT_NAME_LIST } from "../../constants";
import Tabs, { TabItem } from "../../components/tabs";

function formateMonthYear(YYYY_MM?: string, separator: string = "-") {
  if (!YYYY_MM) return;
  const [year, month] = YYYY_MM.split(separator);
  return `${MONTH_SHORT_NAME_LIST[parseInt(month) - 1]} ${year}`;
}

function removeFileExtensionFromFileName(
  fileNameWithExtension: string,
  extensionPrefix: string = "."
): string {
  return fileNameWithExtension.split(extensionPrefix)[0];
}

function comparatorForFilterByNameSearch(
  searchText: string
): (report: { reportId: string }) => boolean {
  return (report) => {
    const { reportId } = report;
    return reportId.toLowerCase().includes(searchText.toLowerCase().trim());
  };
}

const TAB_ITEMS: TabItem<ReportType>[] = [
  {
    label: "Accounting Report",
    value: ReportType.ACCOUNTING_REPORT,
  },
  {
    label: "Bureau Report",
    value: ReportType.BUREAU_REPORT,
  },
];

const columns: TableColumns[] = [
  { field: "reportId", label: "Report ID", style: { minWidth: "115px" } },
  { field: "periodAsOf", label: "Period" },
  // INFO: Temp change, might need to revert back
  // { field: "reportType", label: "Type" },
  // { field: "fromDate", label: "From", style: { minWidth: "135px" } },
  // { field: "toDate", label: "To", style: { minWidth: "135px" } },
  // { field: "createdDate", label: "Generated On", style: { minWidth: "150px" } },
  { field: "actions", label: "", style: { maxWidth: "60px" } },
];

type ReportActionMethod = (reportId: string) => void;

function getFormattedDataForTable(
  data: ReportDetails[],
  actions: { onDownload: ReportActionMethod; onDelete: ReportActionMethod },
  isReportDownloading: {
    [reportId: string]: boolean;
  }
) {
  return data.map((report) => ({
    reportId: removeFileExtensionFromFileName(report.reportId),
    periodAsOf: formateMonthYear(report.asOf) || "-",
    // reportType: splitKeyWithSpace(report.reportType),
    // fromDate: report.fromDate ? dateToDDMMYYYY(report.fromDate) : "-",
    // toDate: report.toDate ? dateToDDMMYYYY(report.toDate) : "-",
    // createdDate: report.createdDate ? dateToDDMMYYYYHHMMAP(report.createdDate) : "-",
    actions: (
      <>
        <Button
          variant="blue"
          loading={isReportDownloading[report.reportId] || false}
          onClick={() => actions.onDownload(report.reportId)}
          iconOnly
        >
          <DownloadIcon />
        </Button>
        {/* <Button
          variant="danger"
          onClick={() => actions.onDelete(report.reportId)}
          iconOnly
        >
          <DeleteIcon />
        </Button> */}
      </>
    ),
  }));
}

export default function ReportsScreen() {
  useScreenName("Reports");
  const storeDispatch = useDispatch();
  const isLoading: boolean = useSelector(
    (state: ReduxState) => state.report.loading
  );
  const reportList: ReportDetails[] = useSelector(
    (state: ReduxState) => state.report.reportList
  );
  const [isModalActive, setIsModalActive] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState<{
    active: boolean;
    reportId?: string;
  }>({ active: false });
  const [errorMessage, setErrorMessage] = useState("");
  const [isReportDownloading, setIsReportDownloading] = useState<{
    [reportId: string]: boolean;
  }>({});
  const [currentTab, setCurrentTab] = useState<ReportType>(
    ReportType.ACCOUNTING_REPORT
  );

  const [{ pageNo, numberOfEntitiesPerPage }, setSearchQuery] = useQueryParams<{
    pageNo: number;
    numberOfEntitiesPerPage: number;
  }>({ pageNo: 1, numberOfEntitiesPerPage: 10 });

  const reports = useMemo(
    () =>
      paginate(
        reportList
          .filter(comparatorForFilterByNameSearch(currentTab.split("_")[0]))
          .sort(
            generateSortComparator<ReportDetails>("asOf", "descending", "date")
          ),
        numberOfEntitiesPerPage,
        pageNo
      ),
    [reportList, pageNo, numberOfEntitiesPerPage, currentTab]
  );

  async function fetchReportList() {
    try {
      await dispatch(storeDispatch, getReportList());
    } catch (error) {
      setErrorMessage(error?.message || "Cann't able to fetch reports");
    }
  }

  useEffect(() => {
    fetchReportList();
  }, []);

  async function handleDownload(reportId: string) {
    setIsReportDownloading((prevState) => ({ ...prevState, [reportId]: true }));
    try {
      const { fileName, base64, fileType }: ReportDoc = await dispatch(
        storeDispatch,
        getReportDocById(reportId)
      );
      downloadFileFromBase64({ base64, fileName, fileType });
    } catch (error) {
      setErrorMessage(error?.message || "Unable to download report.");
    } finally {
      setIsReportDownloading((prevState) => ({
        ...prevState,
        [reportId]: false,
      }));
    }
  }

  function handleDeleteConfirmation(reportId: string) {
    setDeleteConfirmModal({ active: true, reportId });
  }

  async function handleDelete() {
    try {
      const { reportId } = deleteConfirmModal;
      if (!reportId) {
        throw new Error("Something went wrong");
      }
      await dispatch(storeDispatch, deleteReportById(reportId));
      return { message: `Report id ${reportId} deleted successfully` };
    } catch (error) {
      throw error;
    }
  }

  if (errorMessage) {
    return <h4 className="text-center">{errorMessage}</h4>;
  }
  return (
    <>
      <div className="dashboard-container p-3">
        <Tabs items={TAB_ITEMS} value={currentTab} onChange={setCurrentTab} />
      </div>
      {/* <div className="d-flex justify-content-end mb-4">
        <Button
          size="sm"
          onClick={() => setIsModalActive((prevState) => !prevState)}
        >
          Generate Report
        </Button>
      </div> */}
      <div className="dashboard-container">
        <Table
          loading={isLoading}
          columns={columns}
          data={getFormattedDataForTable(
            reports,
            {
              onDownload: handleDownload,
              onDelete: handleDeleteConfirmation,
            },
            isReportDownloading
          )}
        />
      </div>
      <Pagination
        currentPage={pageNo}
        totalEntries={reports.length}
        numberOfEntriesPerPage={numberOfEntitiesPerPage}
        onPageChange={(pageNo) =>
          setSearchQuery((prevState) => ({
            ...prevState,
            pageNo,
          }))
        }
        onPerPageCountChange={(countPerPage) =>
          setSearchQuery((prevState) => ({
            ...prevState,
            numberOfEntitiesPerPage: countPerPage,
            pageNo: 1,
          }))
        }
      />
      <Modal
        open={isModalActive}
        title="Generate Report"
        onClose={() => setIsModalActive((prevState) => !prevState)}
        hasCancelButton={false}
        hasSubmitButton={false}
        size="large"
      >
        <GenerateReportForm onSuccess={fetchReportList} />
      </Modal>
      <ConfirmDialog
        active={deleteConfirmModal.active}
        title="Delete Report"
        submitButtonText="No"
        cancelButtonText="Yes"
        primaryActionButton="cancel"
        message={`Do you want to delete the report ID: ${deleteConfirmModal.reportId}?`}
        onCancel={() => setDeleteConfirmModal({ active: false })}
        onSubmit={handleDelete}
      />
    </>
  );
}
