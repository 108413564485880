import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addLoanPayment,
  addLoanWithdrawal,
  getTransactionsByLoanId,
} from "../../actions/loan.action";
import Button from "../../components/button";
import Table from "../../components/table";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../components/confirm-dialog";
import dispatch from "../../middleware";
import {
  AddLoanPayment,
  AddLoanWithdrawal,
  Transaction,
  TransactionType,
} from "../../models";
import {
  clearFormData,
  dateInputToString,
  dateToDateString,
  dateToDDMMYYYY,
  downloadCSV,
  getTodayDateForInputField,
  isLoadingActive,
} from "../../utils";
import {
  validateAddPaymentFields,
  validateAddWithdrawalFields,
} from "./helpers";
import { ReactComponent as DownloadIcon } from "../../images/download.svg";
import { ReduxState } from "../../reducers";

interface RetailOdTransactionProps {
  loanId: string;
  onSuccess?: () => void;
}

export default function RetailOdTransaction({
  loanId,
  onSuccess,
}: RetailOdTransactionProps) {
  const storeDispatch = useDispatch();
  const [isModalActive, setIsModalActive] = useState({
    payment: false,
    withdrawal: false,
  });

  const loadingQueue = useSelector(
    (state: ReduxState) => state.loan.loadingQueue
  );
  const loanTransactions: Transaction[] = useSelector(
    (state: ReduxState) => state.loan.singleLoanTransactions
  );

  useEffect(() => {
    dispatch(storeDispatch, getTransactionsByLoanId(loanId)).catch(() => {});
  }, []);
  const columns = [
    { field: "transactedAt", label: "Date" },
    { field: "transactionRefNo", label: "Transaction Id" },
    { field: "transactionType", label: "Type" },
    { field: "amount", label: "Amount" },
    { field: "txnFeeInAmount", label: "Transaction Fees" },
    // TODO: Add when backend is ready
    // { field: "interestDue", label: "Interest Due" },
    // { field: "netOut", label: "Net Outstanding" },
  ];
  const transactionTypeMap: Record<TransactionType, string> = {
    CREDIT: "Repaid",
    DEBIT: "Drawdown",
  };
  function getTableData(transactions: Transaction[]) {
    return transactions.map(
      ({
        transactedAt,
        transactionRefNo,
        transactionType,
        amount,
        txnFeeInAmount,
      }) => ({
        transactedAt: dateToDDMMYYYY(transactedAt) || "-",
        transactionRefNo,
        transactionType: transactionTypeMap[transactionType],
        amount,
        txnFeeInAmount: txnFeeInAmount || 0,
      })
    );
  }
  async function handlePaymentDialog(type: ConfirmDialogType, fieldData: any) {
    validateAddPaymentFields("RETAIL_OD", fieldData);
    const payload: AddLoanPayment = {
      loanId: loanId,
      amount: parseInt(fieldData.amount),
      transactionRefNo: fieldData.transactionRefNo,
      paidDate: dateInputToString(fieldData.paidDate),
      medium: "BANK", // TODO: Make it a select item
    };
    const response = await dispatch(
      storeDispatch,
      addLoanPayment(clearFormData(payload))
    );
    if (typeof onSuccess === "function") {
      onSuccess();
    }
    return {
      message: `Payment of Rs ${fieldData.amount} for Loan # ${loanId} added successfully with Transaction ID: ${response?._id}`,
    };
  }
  async function handleWithdrawalDialog(
    type: ConfirmDialogType,
    fieldData: any
  ) {
    validateAddWithdrawalFields(fieldData);
    const payload: AddLoanWithdrawal = {
      loanId: loanId,
      amount: parseInt(fieldData.amount),
      transactionRefNo: fieldData.transactionRefNo,
      withdrawalAt: dateToDateString(fieldData.withdrawalAt),
      medium: "BANK",
      txnFeeInAmount: parseInt(fieldData.txnFeeInAmount),
    };
    const response = await dispatch(
      storeDispatch,
      addLoanWithdrawal(clearFormData(payload))
    );
    if (typeof onSuccess === "function") {
      onSuccess();
    }
    return {
      message: `Withdrawal of Rs ${fieldData.amount} for Loan # ${loanId} added successfully with Transaction ID: ${response?._id}`,
    };
  }
  return (
    <>
      <div className="d-flex flex-md-row flex-column align-items-center justify-content-between mb-4">
        <h3 className="user-page-title mb-4">Transaction Statement</h3>
        <div className="rf-btn-group">
          <Button
            onClick={() =>
              setIsModalActive({ payment: false, withdrawal: true })
            }
          >
            Add Drawdown
          </Button>
          <Button
            onClick={() =>
              setIsModalActive({ payment: true, withdrawal: false })
            }
          >
            Add Repayment
          </Button>
          <Button
            variant="info"
            title="Download"
            onClick={() => {
              downloadCSV(
                getTableData(loanTransactions),
                columns,
                `${loanId}_Transaction_Statement`
              );
            }}
          >
            <DownloadIcon height="25" width="25" color="var(--font-color)" />
          </Button>
        </div>
      </div>
      <Table
        loading={isLoadingActive(loadingQueue)}
        columns={columns}
        data={getTableData(loanTransactions)}
      />
      <ConfirmDialog
        active={isModalActive.payment}
        type="success"
        title="Add Repayment"
        message="Please fill the below details to add Repayment"
        onCancel={() => setIsModalActive({ payment: false, withdrawal: false })}
        onSubmit={handlePaymentDialog}
        submitButtonText="Add"
        cancelButtonText="Cancel"
        inputFields={[
          {
            name: "amount",
            label: "Amount",
            type: "text",
            inputMode: "numeric",
            placeholder: "amount",
            required: true,
          },
          {
            name: "transactionRefNo",
            label: "Transaction Ref No",
            type: "text",
            placeholder: "refno",
            required: true,
          },
          {
            name: "paidDate",
            label: "Paid On",
            type: "date",
            placeholder: "YYYYMMDD",
            value: getTodayDateForInputField(),
            required: true,
          },
        ]}
      />
      <ConfirmDialog
        active={isModalActive.withdrawal}
        type="success"
        title="Add Drawdown"
        message="Please fill the below details to add drawdown"
        onCancel={() => setIsModalActive({ payment: false, withdrawal: false })}
        onSubmit={handleWithdrawalDialog}
        submitButtonText="Add"
        cancelButtonText="Cancel"
        inputFields={[
          {
            name: "amount",
            label: "Amount",
            type: "text",
            inputMode: "numeric",
            placeholder: "amount",
            required: true,
          },
          {
            name: "transactionRefNo",
            label: "Transaction Ref No",
            type: "text",
            placeholder: "refno",
            required: true,
          },
          {
            name: "withdrawalAt",
            label: "Withdrawal At",
            type: "date",
            placeholder: "YYYYMMDD",
            value: getTodayDateForInputField(),
            required: true,
          },
          {
            name: "txnFeeInAmount",
            label: "Transaction Fee",
            type: "text",
            inputMode: "numeric",
            placeholder: "10",
            required: true,
          },
        ]}
      />
    </>
  );
}
