import {
  ChargeDetail,
  InvoiceDetails,
  InvoiceType,
} from "../../dto/invoice.dto";
import { splitKeyWithSpace } from "../../utils";

export function getFormattedAddress(address: {
  locationType: string;
  localityInfo: string;
  state: string;
  pincode: string;
}): string {
  const { localityInfo, state, pincode } = address;
  const formattedAddress = `${localityInfo}, ${state} - ${pincode}`;
  return splitKeyWithSpace(formattedAddress);
}

function getAmountAndGstOnAmount(
  amountBreakup: ChargeDetail[],
  chargeType: InvoiceType
): { amount: number; gstOnAmount?: number } {
  const charge = amountBreakup.find(
    (charge) => charge.chargeType === chargeType
  );

  if (charge) {
    const { amount, gstOnAmount } = charge;

    if (
      chargeType === InvoiceType.INTEREST_CHARGES ||
      chargeType === InvoiceType.PENAL_CHARGES
    ) {
      return {
        amount,
      };
    } else {
      return {
        amount,
        gstOnAmount: gstOnAmount ?? 0,
      };
    }
  }

  return {
    amount: 0,
  };
}

function getCharge(amountBreakup: ChargeDetail[], invoice: InvoiceType) {
  const { amount, gstOnAmount } = getAmountAndGstOnAmount(
    amountBreakup,
    invoice
  );
  return amount + (gstOnAmount ?? 0);
}

function getTotalProcessingCharge(amountBreakup: ChargeDetail[]) {
  return getCharge(amountBreakup, InvoiceType.PROCESSING_CHARGES);
}

function getTotalTransactionCharge(amountBreakup: ChargeDetail[]) {
  return getCharge(amountBreakup, InvoiceType.TRANSACTION_CHARGES);
}

function getTotalEscrowCharge(amountBreakup: ChargeDetail[]) {
  return getCharge(amountBreakup, InvoiceType.ESCROW_CHARGES);
}

export function getTotalCharge(amountBreakup: ChargeDetail[]): number {
  return amountBreakup.reduce((total, { amount }) => total + amount, 0);
}

export function getTotalGst(amountBreakup: ChargeDetail[]): number {
  return amountBreakup.reduce(
    (totalGst, { gstOnAmount = 0 }) => totalGst + gstOnAmount,
    0
  );
}

export function getTotalAmount(amountBreakup: ChargeDetail[]): number {
  const totalCharge = getTotalCharge(amountBreakup);
  const totalGst = getTotalGst(amountBreakup);
  return totalCharge + totalGst;
}

export function getTableData(invoices: InvoiceDetails[]) {
  return invoices.map(
    ({ _id, name, address, amountBreakup, gstNumber, vpa }) => ({
      id: _id,
      name,
      vpa: vpa?.accountNumber ?? "-",
      address: getFormattedAddress(address),
      gstNumber: gstNumber ?? "-",
      interestCharge: getAmountAndGstOnAmount(
        amountBreakup,
        InvoiceType.INTEREST_CHARGES
      ).amount,
      penalCharge: getAmountAndGstOnAmount(
        amountBreakup,
        InvoiceType.PENAL_CHARGES
      ).amount,
      processingCharge: getAmountAndGstOnAmount(
        amountBreakup,
        InvoiceType.PROCESSING_CHARGES
      ).amount,
      processingChargeGst:
        getAmountAndGstOnAmount(amountBreakup, InvoiceType.PROCESSING_CHARGES)
          .gstOnAmount ?? 0,
      totalProcessingCharge: getTotalProcessingCharge(amountBreakup),
      transactionCharge: getAmountAndGstOnAmount(
        amountBreakup,
        InvoiceType.TRANSACTION_CHARGES
      ).amount,
      transactionChargeGst:
        getAmountAndGstOnAmount(amountBreakup, InvoiceType.TRANSACTION_CHARGES)
          .gstOnAmount ?? 0,
      totalTransactionCharge: getTotalTransactionCharge(amountBreakup),
      escrowCharge: getAmountAndGstOnAmount(
        amountBreakup,
        InvoiceType.ESCROW_CHARGES
      ).amount,
      escrowChargeGst:
        getAmountAndGstOnAmount(amountBreakup, InvoiceType.ESCROW_CHARGES)
          .gstOnAmount ?? 0,
      totalEscrowCharge: getTotalEscrowCharge(amountBreakup),
      totalCharge: getTotalCharge(amountBreakup),
      totalGst: getTotalGst(amountBreakup),
      totalAmount: getTotalAmount(amountBreakup),
    })
  );
}
