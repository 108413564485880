import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { CommercialLoanDetails } from "../../../dto";
import { RoutePaths } from "../../../models";
import { splitKeyWithSpace } from "../../../utils";

interface LoanDetailsProps {
  commercialLoanDetails: CommercialLoanDetails | null;
}

export default function LoanDetails({
  commercialLoanDetails,
}: LoanDetailsProps) {
  function renderHorizontalField(
    left: string | ReactNode,
    right: string | ReactNode
  ) {
    return (
      <p className="d-flex flex-row align-items-start">
        <span className="user-details-item-left text-thin">{left}</span>
        <span className="user-details-item-right">{right}</span>
      </p>
    );
  }

  return (
    <div className="row align-items-start justify-content-between my-3">
      <div className="col d-flex align-items-center">
        <div>
          <h3 className="user-page-title mb-1">
            {commercialLoanDetails?.entitySnapshot?.snapshot.name}
          </h3>
          <p className="user-page-subtitle">
            <span className="text-thin">Enterprise Id:</span>{" "}
            {commercialLoanDetails?.borrowerInfo?.borrowerId}
          </p>
        </div>
      </div>
      <div className="col">
        {renderHorizontalField(
          "Application ID :",
          <Link
            to={`${RoutePaths.APPLICATIONS}/${commercialLoanDetails?.appId}`}
          >
            {commercialLoanDetails?.appId}
          </Link>
        )}
        {renderHorizontalField("Loan ID :", commercialLoanDetails?._id)}
        {renderHorizontalField(
          "Source :",
          splitKeyWithSpace(commercialLoanDetails?.clientId)
        )}
      </div>
    </div>
  );
}
