import Table, { TableColumns } from "../../../components/table";

type BaseSingleEnterpriseDataType = {
  _id: JSX.Element;
  sanctionedAmount: number;
};

type SingleEnterpriseApplicationLoanType = BaseSingleEnterpriseDataType & {
  irpa: number;
  status?: never;
};

type SingleEnterpriseApplicationDataType = BaseSingleEnterpriseDataType & {
  status: JSX.Element | null;
  irpa?: never;
};

type SingleEnterpriseDataType =
  | SingleEnterpriseApplicationLoanType
  | SingleEnterpriseApplicationDataType;

interface SingleEnterpriseLoansOrApplicationTableProps {
  loading: boolean;
  title?: string;
  data: SingleEnterpriseDataType[];
  columns: TableColumns[];
}

export default function SingleEnterpriseLoansOrApplicationTable({
  loading,
  title,
  data,
  columns,
}: SingleEnterpriseLoansOrApplicationTableProps) {
  return (
    <div className="dashboard-container">
      <h3 className="user-page-title mb-4">{title}</h3>
      <Table loading={loading} columns={columns} data={data} />
    </div>
  );
}
