import { UserSearchDto, UserSearchFilterDto } from "../dto";
import { MiddlewareAction } from "../middleware";
import {
  NewUser,
  UpdateUser,
  VerifyUser,
  StoreUserDoc,
  UserStatus,
  AddVkycDetailsBody,
} from "../models";

export enum UserActions {
  REQUEST_ADD_USER = "REQUEST_ADD_USER",
  REQUEST_UPDATE_USER = "REQUEST_UPDATE_USER",
  REQUEST_GET_USER_BY_ID = "REQUEST_GET_USER_BY_ID",
  REQUEST_GET_PAGINATED_USERS = "REQUEST_GET_PAGINATED_USERS",
  REQUEST_VERIFY_USER = "REQUEST_VERIFY_USER",
  REQUEST_STORE_DOC_OF_USER = "REQUEST_STORE_DOC_OF_USER",
  REQUEST_GET_DOC_OF_USER = "REQUEST_GET_DOC_OF_USER",
  REQUEST_UPDATE_USER_STATUS = "REQUEST_UPDATE_USER_STATUS",
  CLEAR_USER = "CLEAR_USER",
  REQUEST_GET_USER_COUNT = "REQUEST_GET_USER_COUNT",
  REQUEST_GET_PAGINATION_USER_COUNT = "REQUEST_GET_PAGINATION_USER_COUNT",
  REQUEST_GET_USER_DOC_TYPES = "REQUEST_GET_USER_DOC_TYPES",
  REQUEST_GET_USERDOC_LIST_BY_USER_ID = "REQUEST_GET_USERDOC_LIST_BY_USER_ID",
  REQUEST_GET_USER_SEARCH_FILTERS = "REQUEST_GET_USER_SEARCH_FILTERS",
  REQUEST_GET_USERS_BY_SEARCH = "REQUEST_GET_USERS_BY_SEARCH",
  REQUEST_GET_CITY_STATE_BY_PIN = "REQUEST_GET_CITY_STATE_BY_PIN",
  REQUEST_GET_CREDIT_DETAILS_BY_USER_ID = "REQUEST_GET_CREDIT_DETAILS_BY_USER_ID",
  REQUEST_GET_OR_FETCH_CREDIT_DETAILS_BY_USER_ID = "REQUEST_GET_OR_FETCH_CREDIT_DETAILS_BY_USER_ID",
  REQUEST_GET_CKYC_DATA_BY_USER_ID = "REQUEST_GET_CKYC_DATA_BY_USER_ID",
  REQUEST_GET_V1_CKYC_DATA_BY_USER_ID = "REQUEST_GET_V1_CKYC_DATA_BY_USER_ID",
  CLEAR_CKYC_DATA = "CLEAR_CKYC_DATA",
  REQUEST_GET_USER_AGREEMENTS = "REQUEST_GET_USER_AGREEMENTS",
  REQUEST_GET_USER_VKYC_DETAILS = "REQUEST_GET_USER_VKYC_DETAILS",
  REQUEST_ADD_USER_VKYC_DETAILS = "REQUEST_ADD_USER_VKYC_DETAILS",
}

export function getUserById(userId: string): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_USER_BY_ID,
    type: "GET",
    url: `/user?userId=${userId}`,
  };
}

export function getPaginatedUsers(
  pageNo: number,
  numberOfEntitiesPerPage: number,
  lenderId?: string
): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_PAGINATED_USERS,
    type: "POST",
    url: "/user/paginated-users",
    body: { pageNo, numberOfEntitiesPerPage, lenderId },
  };
}

export function addUser(newUserObj: NewUser): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_ADD_USER,
    type: "POST",
    url: "/user",
    body: newUserObj,
  };
}

export function updateUser(updateUserObj: UpdateUser): MiddlewareAction {
  const { userId, ...updateUserPayload } = updateUserObj; // Did this for backward compatibility to be changed later
  return {
    actionName: UserActions.REQUEST_UPDATE_USER,
    type: "PATCH",
    url: `/v1/users/${userId}`,
    body: updateUserPayload,
  };
}

export function clearUser(): MiddlewareAction {
  return {
    actionName: UserActions.CLEAR_USER,
  };
}

export function verifyUser(verifyUserObj: VerifyUser): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_VERIFY_USER,
    type: "POST",
    url: "/user/verify",
    body: verifyUserObj,
  };
}

export function storeUserDoc(storeUserDocObj: StoreUserDoc): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_STORE_DOC_OF_USER,
    type: "POST",
    url: "/user/store-doc",
    body: storeUserDocObj,
  };
}

export function getUserDoc(
  userId: string,
  documentName: string
): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_DOC_OF_USER,
    type: "POST",
    url: "/user/get-doc",
    body: { userId, documentName },
  };
}

export function updateUserStatus(
  userId: string,
  status: UserStatus,
  comment?: string
): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_UPDATE_USER_STATUS,
    type: "POST",
    url: "/user/update-status",
    body: { userId, status, comment },
  };
}

export function getUserCount(): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_USER_COUNT,
    type: "GET",
    url: "/user/users-count",
  };
}

export function getUserDocTypes(): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_USER_DOC_TYPES,
    type: "GET",
    url: "/user/doc-types",
  };
}

export function getUserSearchFilters(): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_USER_SEARCH_FILTERS,
    type: "GET",
    url: "/user/search-filters",
  };
}

export function getUserBySearch({
  pageNo,
  numberOfEntitiesPerPage,
  searchText,
  source,
  status,
}: UserSearchDto): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_USERS_BY_SEARCH,
    type: "GET",
    url: "/user/search",
    queryParams: {
      pageNo,
      numberOfEntitiesPerPage,
      searchText,
      source,
      status,
    },
  };
}

export function getPaginationUserCount(
  payload: UserSearchFilterDto
): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_PAGINATION_USER_COUNT,
    type: "GET",
    url: "/user/pagination-count",
    queryParams: { ...payload },
  };
}

export function getCityAndStateByPin(pincode: string): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_CITY_STATE_BY_PIN,
    type: "POST",
    url: "/pin-code",
    body: { pincodes: [pincode] },
  };
}

export function getCreditDetailsByUserId(userId: string): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_CREDIT_DETAILS_BY_USER_ID,
    type: "GET",
    url: "/credit-details/credit-reports",
    queryParams: { userId },
  };
}

export function getUserDocList(userId: string): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_USERDOC_LIST_BY_USER_ID,
    type: "GET",
    url: "/user/get-user-doc-list",
    queryParams: { userId },
  };
}

export function getUserAgreements(userId: string): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_USER_AGREEMENTS,
    type: "GET",
    url: "/v1/agreement",
    queryParams: { userId, pageSize: 100 },
  };
}

export function getOrFetchCreditDetailsByUserId(
  userId: string
): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_OR_FETCH_CREDIT_DETAILS_BY_USER_ID,
    type: "GET",
    url: "/credit-details",
    queryParams: { userId },
  };
}

export function getCkycDataByUserId(userId: string): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_CKYC_DATA_BY_USER_ID,
    type: "GET",
    url: "/user/ckyc",
    queryParams: { userId },
  };
}

export function getV1CkycDataByUserId(userId: string): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_V1_CKYC_DATA_BY_USER_ID,
    type: "GET",
    url: `/v1/users/${userId}/ckyc`,
  };
}

export function clearCkycData(): MiddlewareAction {
  return {
    actionName: UserActions.CLEAR_CKYC_DATA,
  };
}

export function getUserVkycDetails(userId: string): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_GET_USER_VKYC_DETAILS,
    type: "GET",
    url: `/vkycs?userId=${userId}`,
  };
}

export function addUserVkycDetails(body: AddVkycDetailsBody): MiddlewareAction {
  return {
    actionName: UserActions.REQUEST_ADD_USER_VKYC_DETAILS,
    type: "POST",
    url: "/vkycs",
    body,
  };
}
