import "./styles.css";
import { SvgIconType } from "../../models";

function renderIcon(Icon?: SvgIconType) {
  if (!Icon) return;
  return <Icon className="mr-3" />;
}

function getActiveClass(isActive: boolean, className: string = "active") {
  if (!isActive) return "";
  return ` ${className}`;
}

export interface TabItem<T> {
  label: string;
  value: T;
  Icon?: SvgIconType;
}

export interface TabsProps<T = string> {
  items: TabItem<T>[];
  value: T;
  disable?: boolean;
  onChange?(val: T): void;
}

export default function Tabs<T>({
  items,
  disable,
  value: selectedValue,
  onChange,
}: TabsProps<T>) {
  return (
    <ul className="nav nav-pills nav-fill">
      {items.map(({ label, value, Icon }, index) => (
        <li className="nav-item" key={index}>
          <button
            disabled={disable}
            className={`btn btn-link nav-link d-flex flex-row align-items-center justify-content-center${getActiveClass(
              value === selectedValue
            )}`}
            style={{ width: "100%" }}
            onClick={() => {
              if (onChange && value !== selectedValue) {
                onChange(value);
              }
            }}
          >
            {renderIcon(Icon)}
            <span>{label}</span>
          </button>
        </li>
      ))}
    </ul>
  );
}
